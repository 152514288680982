<div class="main-page">
    <div class="left-side">
      <img src="../../../assets/images/bio2Logo.png" alt="bio2Logo" />
    </div>
    <div class="right-side" *ngIf="!loading">
      <img src="../../assets/images/bio2Logo.png" class="logoMobile" alt="Bio2LogoMobile">
      <form>
        <div class="d-flex flex-column">
          <span class="title">Bem-vindo à Loja Bio2!</span>
          <span class="semiTitle">Para entrar precisa de selecionar uma morada preferida:</span>
        </div>
  
        <!--*Moradas Radio Buttons-->
        <div class="d-flex flex-column" style="margin-left: 5px">
          <span class="user-pass" for="address">Morada
            <span class="required">*</span>
          </span>
          <div *ngIf="currentUser != undefined" class="btn-radio-span">
            <div *ngFor="let address of currentUser.addresses">
              <label for="address_{{ address.id }}" class="d-flex w-10">
                <input class="d-flex btn-radio-check" id="address_{{ address.id }}" [value]="address" type="radio"
                  required="true" name="addresses" [(ngModel)]="addressSelected" />
                <span class="span-addresses-label d-flex">{{ address.street }}, {{ address.locality }},
                  {{ address.postCode }}</span>
              </label>
            </div>
          </div>
        </div>
  
        <!--*Button Submit-->
        <button type="submit" [disabled]="!addressSelected || loading" (click)="saveAddress()">
          <span>Login</span>
          <img src="../../assets/Icons/smallRow.png" alt="smallRow" class="smallRow">
        </button>
      </form>
    </div>
  </div>
  