import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'campaignsTypeConvert'
})
export class CampaignsTypeConvertPipe implements PipeTransform {

  transform(value: string ): any {
    if(value == "P1" || value == "P2" || value == "P3" || value == "P4"){
      value = "Publicidade"
    }
    if(value == "P")
    {
      value = "Parceria"
    }
    if(value == "N")
    {
      value = "Novidade"
    }
    if(value == "F")
    {
      value = "Flash"
    }
    if(value == "E")
    {
      value = "Exclusiva"
    }
    if(value == "C")
    {
      value = "Campanha"
    }
    if(value == "DH")
    {
      value = "Display Loja"
    }
    if(value == "B"){
      value = "Banner Home"
    }
    return value;
  }

}
