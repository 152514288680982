<div class="d-flex flex-row">
    <div>
        <app-dashboard-side-menu></app-dashboard-side-menu>
    </div>
    <div class="p-content d-flex flex-column w-100">
        <app-dashboard-top-menu></app-dashboard-top-menu>
        <!-- !!Loading animation -->
        <div *ngIf="isLoading; else contentLoaded" class="loading-animation">
            <app-loading></app-loading>
        </div>
        <ng-template #contentLoaded>
            <div class="template-content">
                <div class="w-100 d-flex flex-column justify-content-start">
                    <form [formGroup]="myForm">
                        <h2>Importação de Clientes</h2>
                        <div style="max-width: 440px;">
                            <p>Número de Cliente<span>* </span><span
                                    *ngIf="myForm.get('nCliente')?.invalid && submitted">Obrigatório.</span></p>
                            <mat-form-field class="w-100">
                                <input matInput formControlName="nCliente">
                            </mat-form-field>
                        </div>

                        <div class="mt-4">
                            <button class="btn-processar" type="button" (click)="processar()" [disabled]="isLoading">
                                <span>Processar</span>
                                <img src="../../../../assets/Icons/check-line.png" alt="Check">
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
        <app-footer></app-footer>
    </div>
</div>