import { Component, OnInit } from '@angular/core';
import { ApiConfiguration } from 'src/app/api/api-configuration';
import { InterfaceExportOrders } from 'src/app/api/models/orders';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { EncomendasService } from 'src/app/encomendas/encomendas.service';

@Component({
  selector: 'app-export-encomendas',
  templateUrl: './export-encomendas.component.html',
  styleUrls: ['./export-encomendas.component.css'],
})
export class ExportEncomendasComponent implements OnInit {
  responses!: InterfaceExportOrders[];
  route = new ApiConfiguration();

  isLoading: boolean = false;
  encomendaInicio!: string;
  encomendaFim!: string;
  seeXML: boolean = false;
  xml: boolean = false;

  isSubmitted: boolean = false;
  inicioValid!: boolean;
  fimValid!: boolean;

  constructor(private encomendasService: EncomendasService, private snackBarService: SnackBarService) {}

  ngOnInit(): void {}

  processar() {
    this.isSubmitted = true;
    const inicio = Number(this.encomendaInicio);
    const fim = Number(this.encomendaFim);
    this.xml = this.seeXML;

    this.inicioValid = inicio != null && inicio != undefined && !Number.isNaN(inicio);
    this.fimValid = fim != null && fim != undefined && !Number.isNaN(inicio);

    if (!this.inicioValid || !this.fimValid) {
      return;
    } else {
      this.responses = [];
      this.isLoading = true;
      this.encomendasService
        .exportEncomendas(inicio, fim, this.xml)
        .subscribe({
          next: (resp) => {
            this.responses = resp;
            if(resp.length > 0) {
              this.snackBarService.openSnackBar(
                `Exportação concluída.`,
                '#0AA174',
                '../../../../assets/Icons/correct.png'
              );
            }
            else {
              this.snackBarService.openSnackBar(
                resp.length == 0 ? `Sem encomendas para exportação`: `Erro na exportação de encomendas.`,
                '#F97066',
                '../../../../assets/Icons/ErrorIcon.png'
              );
            }
          },
          error: (err) => console.error(err),
          complete: () => {
            this.isLoading = false;
          },
        });
    }
  }

  statusChanged(event: any) {
    this.seeXML = event.target.checked;
  }

  updateEncomendaFim() {
    this.encomendaFim = this.encomendaInicio;
  }
}
