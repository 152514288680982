<div class=" main-content">
    <app-dashboard-side-menu></app-dashboard-side-menu>
    <div class="d-flex flex-column w-100">
        <app-dashboard-top-menu></app-dashboard-top-menu>

        <div class="d-flex flex-column m-4" style="padding: 0 100px 50px">

            <div class="row align-items-center">
                <a routerLink="/backoffice/comunicados">
                    <button mat-icon-button>
                        <mat-icon svgIcon="arrow_left_sm" class="h-auto w-auto"></mat-icon>
                    </button>
                </a>
                <h2 class="m-0 p-0">{{ isAddMode ? "Novo Comunicado" : ''}}</h2>
            </div>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <p>Tipo Utilizador <span>*</span> <span
                                *ngIf="form.get('type')?.invalid && submitted">Obrigatório.</span></p>
                        <mat-form-field>
                            <mat-select formControlName="type">
                                <mat-option value="P">P (PetVet)</mat-option>
                                <mat-option value="C">C (Cliente)</mat-option>
                                <mat-option value="L">L (Laboratorio)</mat-option>
                                <mat-option value="CP">CP (Cliente e PetVet)</mat-option>
                                <mat-option value="CPL">CPL (Todos)</mat-option>
                            </mat-select>
                        </mat-form-field>   
                    </div>

                    <div class="col-md-6 col-xs-12">
                        <p>Permanente<span>*</span> <span *ngIf="form.get('isPermanent')?.invalid && submitted">Obrigatório.</span></p>
                        <mat-form-field>
                            <mat-select formControlName="isPermanent">
                                <mat-option [value]="true">Sim</mat-option>
                                <mat-option [value]="false">Não</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6 col-xs-12">
                        <p>Data Início<span>*</span>
                            <span *ngIf="form.get('startingDate')?.invalid && submitted">Obrigatório. </span>
                            <span *ngIf="!startDateValid && submitted">Não pode ser maior que a Data Fim.</span>
                        </p>
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerInicio" formControlName="startingDate">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerInicio"></mat-datepicker-toggle>
                            <mat-datepicker #pickerInicio></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6 col-xs-12">
                        <p>Data Fim<span>*</span>
                            <span *ngIf="form.get('endDate')?.invalid && submitted">Obrigatório. </span>
                            <span *ngIf="!startDateValid && submitted">Não pode ser menor que a Data Início.</span>
                        </p>
                        <mat-form-field>
                            <input matInput [matDatepicker]="pickerFim" formControlName="endDate">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerFim"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFim></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 col-xs-12">
                        <p *ngIf="!comunicadoValid" class="text-danger">{{comunicadoError}}</p>
                        <div class="NgxEditor__Wrapper">
                            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                            <ngx-editor [editor]="editor" formControlName="description"
                                placeholder="Comunicado..."></ngx-editor>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <p>
                            Imagem do comunicado
                        </p>
                        <mat-form-field>
                            <ngx-mat-file-input formControlName="imageComunicado" [accept]="imgType" #FileInputImg
                                (change)="checkFileSize(FileInputImg, 5)">
                            </ngx-mat-file-input>
                        </mat-form-field>
                    </div>
                    <div class="d-flex" style="align-self: center; width: 50%; justify-content: right;">
                        <button mat-flat-button class="btn-green mr-3 float-right">
                            {{isAddMode ? 'Adicionar Comunicado': 'Guardar Comunicado'}}
                            <mat-icon svgIcon="check" class="h-auto" iconPositionEnd></mat-icon>
                        </button>
                    </div>

                </div>
            </form>

        </div>
        <app-footer></app-footer>
    </div>
</div>