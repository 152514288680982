import { Subscription } from 'rxjs';
import { PendingOrders } from './../../api/models/pending-orders';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Campaigns, SpDashboard } from 'src/app/api/models';
import { Orders } from 'src/app/api/models/orders';
import { DashboardService } from 'src/app/api/services';
import { ExtensionCountResponse } from 'src/app/api/models/storage';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy{
  public campaigns: Campaigns[] = [];
  public orders: Orders[] = [];
  public pendingOrders: PendingOrders[] = [];
  public loading = true;
  public pdfNumber: Number = 0;
  public jpgNumber: Number = 0;
  public jpegNumber: Number = 0;
  public pngNumber: Number = 0;
  unsubscribe: Subscription = new Subscription();

  constructor(private api: DashboardService, private storageService: DashboardService) {}
  
  ngOnInit(): void {
    this.unsubscribe.add(this.api.apiInterfaceDashboard().subscribe((res: SpDashboard) => {
      this.campaigns = res.campaigns ? res.campaigns : [];
      this.orders = res.orders ? res.orders : [];
      this.pendingOrders = res.pendingOrders ? res.pendingOrders : [];
      this.loading = false;
    }));

    this.unsubscribe.add(this.storageService.getExtensionCount().subscribe((res: ExtensionCountResponse) => {
      this.pdfNumber = res.pdf;
      this.jpgNumber = res.jpg;
      this.jpegNumber = res.jpeg;
      this.pngNumber = res.png;
    }));
  }
  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe()
  }
}
