import {
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, map, Observable, of, startWith, Subscription, switchMap } from 'rxjs';
import { TbProduto } from 'src/app/api/models/statistics';
import { SearchInputProductsService } from './search-input-products.service';

@Component({
  selector: 'app-search-input-products',
  templateUrl: './search-input-products.component.html',
  styleUrls: ['./search-input-products.component.css'],
})
export class SearchInputProductsComponent implements OnInit, OnDestroy {
  @Input() isMultiple: boolean = true;
  @Input() plist: TbProduto[] = [];
  @Input() searchControl!: FormControl;
  @Input() selectProducts!: FormControl;
  @Input() selectedItems: TbProduto[] = [];

  @Output() selectionChange = new EventEmitter<any>();

  selectedTexts: string[] = [];
  filteredProductLists!: Observable<TbProduto[]>;
  private searchTermSubject = new BehaviorSubject<string>('');

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort!: CdkVirtualScrollViewport;
  @ViewChild('matSelectProducts') matSelectProducts!: MatSelect;

  private subscriptions: Subscription[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    readonly sd: ScrollDispatcher,
    private searchInputProducts: SearchInputProductsService
  ) {}

  ngOnInit(): void {
    const subscription = this.searchInputProducts.plist$.subscribe((value) => {
      if (value) {
        this.filteredProductLists = this.searchTermSubject.pipe(
          debounceTime(300), 
          distinctUntilChanged(),
          switchMap((searchTerm) => {
            const filteredList = this._filterList(searchTerm);
            return filteredList;
          })
        );
        this.updateSelectedOptions();
      }
    });

    this.subscriptions.push(subscription);
  }

  ngAfterViewInit(): void {
    this.itemSelectedChange();
  }

  displaySelectedTexts() {
    this.selectedTexts = this.selectedItems.map((x) => x.memoria);
  }

  foropen() {
    this.cdkVirtualScrollViewPort.scrollToIndex(5);
  }

  openChange($event: boolean) {
    if ($event) {
      this.foropen();
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
      this.displaySelectedTexts();
      this.updateSelectedOptions();
    }
  }

  onSelectionChange(change: any): void {
    if (!change.isUserInput) {
      this.updateSelectedOptions();
      return;
    }

    const value = change.source.value as TbProduto;
    const idx = this.selectedItems.findIndex(
      (item) => item.referencia === value.referencia
    );

    if (this.isMultiple) {
      if (idx > -1) {
        this.selectedItems.splice(idx, 1);
      } else {
        this.selectedItems.push(value);
      }
      this.displaySelectedTexts();
    } 
    else {
      this.selectedItems = [];
      this.selectedItems.push(value);
    }

    this.selectionChange.emit(this.selectedItems);
  }

  private itemSelectedChange(): void {
    const subscription = this.sd
      .scrolled()
      .pipe(
        filter((scrollable) => this.cdkVirtualScrollViewPort === scrollable)
      )
      .subscribe(() => {
        this.updateSelectedOptions();
      });

    this.subscriptions.push(subscription);
  }

  private updateSelectedOptions(): void {
    if (!this.matSelectProducts) {
      return;
    }
  
    const options = this.matSelectProducts.options.toArray();
    options.forEach((option) => {
      const prod = option.value as TbProduto;
      const selected = this.selectedItems.some(
        (item) => item.referencia === prod.referencia
      );
  
      if (selected && !option.selected) {
        option.select();
      } else if (!selected && option.selected) {
        option.deselect();
      }
    });
  }

  getPlaceholderText(): string {
    if (this.plist.length == 0) {
      return 'A carregar produtos...';
    } else if (this.selectedItems.length === 0) {
      return 'Selecionar Produto(s)';
    } else if (this.selectedItems.length <= 3) {
      return this.selectedItems.map((item) => item.memoria).join(', ');
    } else {
      const firstThreeItems = this.selectedItems
        .slice(0, 3)
        .map((item) => item.memoria)
        .join(', ');
      const remainingCount = this.selectedItems.length - 3;
      return `${firstThreeItems} (+${remainingCount} outros)`;
    }
  }

  private _filterList(searchTerm: string): Observable<TbProduto[]> {
    const filteredList = this.plist.filter((op) =>
      op.memoria.toLowerCase().includes(searchTerm.toLowerCase()) ||
      op.referencia.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return of(filteredList);
  }

  onSearchInputChanged(event: any): void {
    this.searchTermSubject.next(event.target.value);
  }

  handleInputFocus(): void {
    this.matSelectProducts.open();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
