<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div *ngIf="isload" class="loading-animation">
      <app-loading></app-loading>
    </div>
    <div *ngIf="!isload" class="content">
      <div class="back-btn">
        <button class="btn" (click)="goToCampaigns()" style="float: left">
          <img src="../../assets/Icons/arrow-left-line.png" alt="Back" />
        </button>
        <span style="overflow: hidden;">
          Campanhas /
        </span>
        <span class="separador">
          {{campaign?.name}}
        </span>
      </div>
      <div *ngIf="!(showCampaignImage().endsWith('mp4') || showCampaignImage().endsWith('webm'))">
        <img [src]="showCampaignImage()" alt="Imagem da Campanha" class="img-campanha" />
      </div>
      <div *ngIf="showCampaignImage().endsWith('mp4' || 'webm')">
        <video autoplay muted loop [src]="showCampaignImage()" alt="Imagem da Campanha" class="img-campanha"></video>
      </div>
      <div *ngIf="campaign?.group?.length! > 1" class="div-span">
        <span class="span-inter">
          Grupos em Destaque
        </span>
      </div>
      <div class="d-flex flex-row aligments">
        <ng-container *ngFor="let group of campaign?.group">
          <div *ngIf="!(showGroupImage(group).endsWith('mp4') || showGroupImage(group).endsWith('webm'))"
            class="d-flex justify-content-center" (click)="goToGroup(group)" class="img-grupo">
            <img class="banner" [src]="showGroupImage(group)" />
          </div>
          <div *ngIf="showGroupImage(group).endsWith('mp4' || 'webm')">
            <video autoplay muted loop [src]="showGroupImage(group)" alt="Imagem da Campanha" class="img-grupo"
              (click)="goToGroup(group)"></video>
          </div>
        </ng-container>
      </div>
    </div>
    <div>
      <ng-container>
        <div class="content">
          <div *ngIf="campaign?.products?.length! > 1" class="div-span">
            <span class="span-inter">
              Produtos em Destaque
            </span>
          </div>
          <div class="mt-4">
            <div class="d-flex flex-row flex-wrap justify-content-start" style="gap:20px">
              <app-product-shop *ngFor="let product of campaign?.products" [product]="product">
              </app-product-shop>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <app-footer></app-footer>
  </div>
</div>
