import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { MoveFileResponse } from '../models/media';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  route = new ApiConfiguration();

  constructor(private http: HttpClient) { }

  importMedia(): Observable<MoveFileResponse[]> {
    const apiImportMedia = this.route.rootUrl + '/api/interface/media/import';
    return this.http.get<MoveFileResponse[]>(apiImportMedia)
    .pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  uploadFiles(files: FormData):Observable<MoveFileResponse[]> {
    const apiUploadFiles = this.route.rootUrl + '/api/interface/media/upload';

    const httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data',
      }),
    };
    
    return this.http.post<MoveFileResponse[]>(apiUploadFiles, files, {
      headers: httpOptions.headers,
    })  
    .pipe(
      catchError((error) => {
        throw error;
      })
    );
  }
}
