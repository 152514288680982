import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardNumbersConverter'
})
export class DashboardNumbersConverterPipe implements PipeTransform {

  transform(value: any, propertyName?: string): any {
    if (propertyName === 'totalValue' && typeof value === 'number') {
      const parts = value.toFixed(0).split('.');
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return integerPart + (parts[1] ? '.' + parts[1] : '');
    } else if (typeof value === 'number') {
      return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
      return value;
    }
  }
}