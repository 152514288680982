import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FamilySort,
  FavoriteRequest,
  FilterItemResponse,
  FilterItensRequest,
  FilterRequest,
  Filteritens,
  ProductSearchResponse,
  ShopItemModelDetails,
  ShopItemRequest,
  ShopItemResponse,
  SpecieSort,
} from '../models/shop-item.model';

@Injectable({ providedIn: 'root' })
export class ShopService {
  constructor(private config: ApiConfiguration, private http: HttpClient) {}

  ApiShopGetPath = this.config.rootUrl + '/api/shop';

  getShopItems(request: ShopItemRequest): Observable<ShopItemResponse> {
    let params = new HttpParams();
    params = params.append('Page', String(request.page));
    params = params.append('PageSize', String(request.pageSize));
    params = params.append('SortColumn', request.sortColumn || '');
    params = params.append('SortOrder', request.sortOrder || '');
    params = params.append('FilterName', request.filterName || '');
    params = params.append('FilterValue', request.filterValue || '');
    params = params.append('userId', request.userId || '');
    params = params.append('clientNumber', request.clientNumber || '');
    params = params.append('username', request.username || '');
    params = params.append('Search', request.Search?.Search || '');
    params = params.append('Family', request.Search?.Family || '');
    params = params.append('Type', request.Search?.Type || '');
    params = params.append('Brand', request.Search?.Brand || '');
    params = params.append('Structure', request.Search?.Structure || '');
    params = params.append('Specie', request.Search?.Specie || '');
    return this.http.get<ShopItemResponse>(this.ApiShopGetPath, { params });
  }

  getCardDetails(productReference: string, clientNumber: string): Observable<ShopItemModelDetails> {
    let params = new HttpParams();
    params = params.append('reference', productReference || '');
    params = params.append('clientNumber', clientNumber || '');
    return this.http.get<ShopItemModelDetails>(this.ApiShopGetPath + '/getProductByReference', {params});
  }


  getFavoriteItems(request: ShopItemRequest): Observable<ShopItemResponse> {
    let params = new HttpParams();
    params = params.append('Page', String(request.page));
    params = params.append('PageSize', String(request.pageSize));
    params = params.append('SortColumn', request.sortColumn || '');
    params = params.append('SortOrder', request.sortOrder || '');
    params = params.append('FilterName', request.filterName || '');
    params = params.append('FilterValue', request.filterValue || '');
    params = params.append('userId', request.userId || '');
    params = params.append('clientNumber', request.clientNumber || '');
    params = params.append('username', request.username || '');
    params = params.append('Search', request.Search?.Search || '');
    params = params.append('Family', request.Search?.Family || '');
    params = params.append('Type', request.Search?.Type || '');
    params = params.append('Brand', request.Search?.Brand || '');
    params = params.append('Structure', request.Search?.Structure || '');
    params = params.append('Specie', request.Search?.Specie || '');
    return this.http.get<ShopItemResponse>(this.ApiShopGetPath + '/favorite', {
      params,
    });
  }

  getFilterFamilyItems(): Observable<FamilySort[]> {
    return this.http.get<FamilySort[]>(this.ApiShopGetPath + '/search/family');
  }

  getFilterSpecieItems(): Observable<SpecieSort[]> {
    return this.http.get<SpecieSort[]>(this.ApiShopGetPath + '/search/specie');
  }

  getAlltheFilterItems(request1: FilterItensRequest, request2: FilterItensRequest, request3: FilterItensRequest,  codClass: string ): Observable<Filteritens> {
    let params = new HttpParams();
    params = params.append('page1', String(request1.page));
    params = params.append('pageSize1', String(request1.pageSize));
    params = params.append('page2', String(request2.page));
    params = params.append('pageSize2', String(request2.pageSize));
    params = params.append('page3', String(request3.page));
    params = params.append('pageSize3', String(request3.pageSize));
    params = params.append('codFamily', codClass);
    return this.http.get<Filteritens>(this.ApiShopGetPath + '/search/sort', {
      params,
    });
  }

  // added new method
  getFilterItems(request: FilterRequest, codClass: string , searchType: string, searchTerm: string): Observable<FilterItemResponse> {
    let params = new HttpParams();
    params = params.append('Page', String(request.page));
    params = params.append('PageSize', String(request.pageSize));
    params = params.append('SortColumn', request.sortColumn || '');
    params = params.append('SortOrder', request.sortOrder || '');
    params = params.append('FilterName', request.filterName || '');
    params = params.append('FilterValue', request.filterValue || '');
    params = params.append('codClass', codClass);
    params = params.append('searchType', searchType);
    params = params.append('searchTerm', searchTerm);

    return this.http.get<FilterItemResponse>(this.ApiShopGetPath + '/search/sort',  { params });
  }


  getTopFilterItems(user: string, searchedString: string): Observable<ProductSearchResponse[]> {
    let params = new HttpParams();
    params = params.append('user', String(user));
    params = params.append('searched', String(searchedString));
    return this.http.get<ProductSearchResponse[]>(this.ApiShopGetPath + '/search/general', {
      params,
    });
  }

  createFavorite(request: FavoriteRequest): Observable<FavoriteRequest> {
    return this.http.post<FavoriteRequest>(
      this.ApiShopGetPath + '/favorite',
      request
    );
  }

  deleteFavorite(request: FavoriteRequest) {
    return this.http.delete(this.ApiShopGetPath + '/favorite', {
      body: request,
    });
  }

  public searching: boolean = false;

  setSearching(value: boolean): void {
    this.searching = value;
  }

  getSearching(): boolean {
    return this.searching;
  }
}
