import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CustomIconsService } from 'src/app/api/services/custom-icons.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService, ComunicadosService } from 'src/app/api/services';

@Component({
  selector: 'app-condicoes',
  templateUrl: './condicoes.component.html',
  styleUrls: ['./condicoes.component.css'],
})
export class CondicoesComponent {
  termosText: string = environment.termos;
  constructor(
    private authService: AuthService,
    private iconService: CustomIconsService,
    private router: Router,
    private cookieService: CookieService,
    private comunicadoServ: ComunicadosService
  ) {}

  onCancelar(): void {
    this.router.navigate(['/login']);
  }
  async onAccept(): Promise<void> {
    this.cookieService.set('cookiesAcceptedTC', '1', 365);

    const decodedToken = this.authService.getDecodedAccessToken(
      localStorage.getItem('BIO2_AccessToken')!
    );
    const role = decodedToken['role'];

    let activeComunicado = await this.comunicadoServ.getComunicadoByUser(
      decodedToken['unique_name']
    );

    if (decodedToken['avatar'] === 'NULL') {
      this.router.navigate(['/avatar']);
    } else if (activeComunicado.length > 0) {
      this.router.navigate(['/comunicado']);
    } else if (role === 'Administrador') {
      this.router.navigate(['/login/selection']);
    } else if (role === 'Laboratorio') {
      this.router.navigate(['/login/selection/subLogin'])
    } else {
      this.router.navigate(['/home']);
    }
  }
}
