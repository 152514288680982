import { Injectable } from '@angular/core';
import { updateProdutoRequest } from '../models/statistics';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InterfaceProductService {
  route = new ApiConfiguration();
  ApiGetProductInterface = this.route.rootUrl + '/api/interface/products';

  constructor(private http: HttpClient) { }

  updateProduto(request: updateProdutoRequest): Observable<updateProdutoRequest>{
    const formData = new FormData();
    if (request.productImage) {
      formData.append(
        'files',
        request.productImage!,
        'product_image_' +
          request.productImage.name
      );
    }if (request.technicalFile) {
      formData.append(
        'files',
        request.technicalFile!,
        'product_technicalFile_' +
        request.technicalFile.name
      );
    }
    if (request.flyerFile) {
      formData.append(
        'files',
        request.flyerFile!,
        'product_flyer_' +
        request.flyerFile.name
      );
    }
    formData.append('reference', JSON.stringify(request));
    return this.http.post<updateProdutoRequest>(
      this.ApiGetProductInterface + '/produtos-update/' + request.referencia,
      formData
    );
  }
}
