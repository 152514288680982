<div class="dialog-content" *ngIf="infoProdutos.length == boosters.length">
    <div class="close-section">
        <div (click)="closeModal()" class="close-icon">
            <img src="../../../assets/Icons/close-line.png" alt="close">
        </div>
    </div>

    <mat-dialog-content class="mat-typography">
        <div class="table-responsive">
                <table mat-table [dataSource]="shopItemWrappers" class="w-100 h-100 position-relative" aria-describedby="Tabela de Bonus">
                    <ng-container matColumnDef="referencia">
                    <th mat-header-cell *matHeaderCellDef> Referência </th>
                    <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Referência:">
                        {{element.idProduto}}
                        <span class="cursor-pointer" (click)="openProductDetails(element)" aria-label="Mostrar detalhes do produto">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg">
                            <path
                            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                            fill="rgba(113,210,214,1)">
                            </path>
                        </svg>
                        </span>
                    </td>
                    </ng-container>

                    <ng-container matColumnDef="descricao">
                        <th mat-header-cell *matHeaderCellDef> Descrição </th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Descrição:">
                            {{ getDescricao(element) }}
                        </td>
                    </ng-container>

                
                    <ng-container matColumnDef="bonusdisponivel">
                    <th mat-header-cell *matHeaderCellDef> Bónus Disponível </th>
                    <td mat-cell *matCellDef="let element" class="has_label_on_mobile td-center" data-label="Bónus Disponível:">
                        Ver Promoções Em Vigor
                        <span class="cursor-pointer" (click)="openProductBonus(element)" aria-label="Mostrar detalhes do produto">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg">
                                <path
                                d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                                fill="rgba(113,210,214,1)">
                                </path>
                            </svg>
                            </span>
                    </td>
                    </ng-container>

                    
                <ng-container matColumnDef="acao">
                    <th mat-header-cell *matHeaderCellDef> Selecione a Campanha a Adquirir </th>
                    <td mat-cell *matCellDef="let element" class="has_label_on_mobile td-center" data-label="Stock:">

                        <div class="btn-group d-flex justify-content-start" role="group" aria-label="Toggle button group">
                            <input type="radio" class="btn-check" name="{{element.idProduto}}" id="default{{element.idProduto}}" autocomplete="off" checked>
                            <label (click)="addToOrder(element,'Nenhum')" class="btn btn-outline-primary flex-none" for="default{{element.idProduto}}">Nenhum</label>
                            
                            <div  *ngFor="let bonus of getBonus(element)" class="d-flex w-100">
                                <input type="radio" class="btn-check" name="{{element.idProduto}}" id="bonus{{bonus}}{{element.idProduto}}" autocomplete="off">
                                <label (click)="addToOrder(element,bonus)" class="btn btn-outline-primary flex-grow-1" for="bonus{{bonus}}{{element.idProduto}}">{{bonus}}</label>
                            </div>                     
                        </div> 
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantidadeadquirir">
                    <th mat-header-cell *matHeaderCellDef> Quantidade a Comprar </th>
                    <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Quantidade:">
                        <div class="d-flex flex-row" style="justify-content: center;">
                        <span class="d-flex flex-row justify-content-center span-quantity">
                            {{getQuantidadeAdquirir(element)}}
                        </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="preco">
                    <th mat-header-cell *matHeaderCellDef > Preço por Unidade </th>
                    <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Quantidade:">
                    <div class="d-flex flex-row" style="justify-content: center;">
                        <span class="d-flex flex-row justify-content-center span-quantity">
                            {{element.preco | currency : 'EUR':'symbol':undefined:'pt-PT'}}
                        </span>
                    </div>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumnsExtract"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsExtract;"></tr>
            </table>
        </div>  
    </mat-dialog-content>

    <div id="checkout-address-container">
        <label for="addressesSelect" class="selectLabel">Escolher morada da encomenda</label>
        <mat-select class="form-control" [(value)]="selectedAddress" name="addressesSelect" id="addressesSelect"
          (selectionChange)="onAddressSelect($event)" [compareWith]="compareFamilyObjects">
            <mat-option *ngFor="let address of deliveryAddressList" [value]="address">
                {{ address.clientAddressCode }} - {{ address.address }}, {{address.locality}},
                {{address.zipCodeCP4}}-{{address.zipCodeCP3}} {{address.zipCodeDesignation}}
            </mat-option>
        </mat-select>
    </div>

    <div id="obs-textarea">
        <label for="obs-textarea-input">Observações</label>
        <textarea matInput [(ngModel)]="observations" id="obs-textarea-input" class="form-control"></textarea>
      </div>

      <div class="d-flex justify-content-center mt-4" *ngIf="isLoading">
        <div style="width: fit-content">
          <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
        </div>
      </div>

    <div class="submeter-section" *ngIf="!isLoading" >
        <div class="submeter" (click)="makeOrder()" aria-label="Submeter">
            <span>Submeter Encomenda</span>
        </div>
    </div>
</div>