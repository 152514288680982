<div *ngIf="campanhas as camp; else loading" class="mt-3 h-100">
  <div *ngIf="camp.campaignShop.length > 0 && !isLoading" class="divs-orientation">
    <div *ngIf="camp.campaignShop.length > 0" class="banner-campaign">
      <div *ngFor="let campaign of camp.campaignShop" [class.hover15]="campaign.type === 'DH'"
        [class.hover14]="campaign.type !== 'DH' && campaign.name !== 'Destaques'"
        [class.hover16]="campaign.name === 'Destaques'"
        [class.hover17]="campaign.type === 'P1' || campaign.type === 'P2' || campaign.type === 'P3' || campaign.type === 'P4' || campaign.type === 'DH'">

        <ng-container
          *ngIf="!campaign.image?.endsWith('.mp4') && !campaign.image?.endsWith('.webm') && campaign.name !== 'Destaques' && !publicidades">
          <figure>
            <img
              [src]="campaign.image === 'banner_placeholder.png' ? '../../../assets/images/banner_placeholder.png' : env.campaigns_images + campaign.image"
              alt="{{campaign.name}}" (click)="getCampaignDetails(campaign)" class="adjusts" />
          </figure>
        </ng-container>

        <ng-container
          *ngIf="!campaign.image?.endsWith('.mp4') && !campaign.image?.endsWith('.webm') && publicidades && campaign.name !== 'Destaques'">
          <figure>
            <img
              [src]="campaign.header ? env.campaigns_images + campaign.header : '../../../assets/images/banner_placeholder.png'"
              alt="{{campaign.header}}" (click)="getCampaignDetails(campaign)" class="adjusts" />
          </figure>
        </ng-container>

        <ng-container *ngIf="campaign.name === 'Destaques'">
          <app-product-shop *ngFor="let product of campaign.products" [product]="product">
          </app-product-shop>
        </ng-container>
        <ng-container *ngIf="campaign.image?.endsWith('.mp4')">
          <video autoplay muted loop id="campaign-video-mp4" alt="Vídeo de campanha" (click)="getCampaignDetails(campaign)" class="adjusts" >
            <source [src]="env.campaigns_images + campaign.image" type="video/mp4">
          </video>
        </ng-container>
        <ng-container *ngIf="campaign.image?.endsWith('.webm')">
          <video autoplay muted loop id="campaign-video-webm" alt="Vídeo de campanha" (click)="getCampaignDetails(campaign)" class="adjusts">
            <source [src]="env.campaigns_images + campaign.image" type="video/webm">
          </video>
        </ng-container>
        <span *ngIf="campaign.name !== 'Destaques'">{{campaign.name}}</span>
      </div>
    </div>
  </div>
  <div class="content centering h-100" *ngIf="camp.campaignShop.length == 0">
    <div class="unavailable">De momento não existem campanhas ativas</div>
  </div>
</div>
<ng-template #loading>
  <div class="loading-animation h-100" role="alert" aria-live="assertive">
    <app-loading></app-loading>
  </div>
</ng-template>
