import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Booster, BoosterOrderModel, CheckoutBoosterOrderModel } from "src/app/api/models/booster";
import { ProductModalComponent } from "../product-modal/product-modal.component";
import { Subscription } from "rxjs";
import { DeliveryAddress, ProfileDataRequest } from "src/app/api/models/perfil";
import { ShopItemModel } from "src/app/api/models/shop-item.model";
import { DialogData, ProductBonusModalComponent } from "src/app/Custom/product-bonus-modal/product-bonus-modal.component";
import { BoostersService } from "src/app/api/services/boosters.service";
import { CheckoutLineModel, CheckoutOrderModel, CheckoutOrderResponseModel } from "src/app/api/models/checkout";
import { SnackBarService } from "src/app/api/services/snack-bar.service";

@Component({
    selector: 'booster-list',
    templateUrl: './booster.component.html',
    styleUrls: ['./booster.component.css'],
})

export class BoosterComponent implements OnInit, OnChanges {

    @Input() boosters!: Array<Booster>;
    @Input() currentUser!: ProfileDataRequest;
    @Output() isModalOpen = new EventEmitter<boolean>();
    @Output() shouldBoosterBeOpened = new EventEmitter<boolean>();


    isLoading = false;
    observations: string;
    infoProdutos: ShopItemModel[] = [];
    unsubscribe: Subscription = new Subscription();
    productsToBuy: Booster[] = [];
    deliveryAddressList: DeliveryAddress[];
    selectedAddress: DeliveryAddress = {
        deliveryAddressId: '',
        clientAddressCode: '',
        address: '',
        zipCodeDesignation: '',
        locality: '',
        zipCodeCP4: '',
        zipCodeCP3: '',
        clientId: '',
        isPreferedAddress: false
    };


    shopItemWrappers: MatTableDataSource<Booster> =
        new MatTableDataSource();
    displayedColumnsExtract = [
        'referencia',
        'descricao',
        'bonusdisponivel',
        'bonusganhos',
        'quantidadeadquirida',
        'acao',
        'quantidadeadquirir',
        'preco',
    
    ];


    constructor(
        public dialog: MatDialog,
        private boostersService: BoostersService,
        private snackBarService: SnackBarService
    ) {
        this.deliveryAddressList = [];
        this.observations = '';
    }

    ngOnInit(): void {

        this.unsubscribe.add(
            this.boostersService.getUserAddress(this.currentUser.clientId).subscribe({
                next: (response) => {

                    this.deliveryAddressList = response.addressLists;
                    this.selectedAddress = this.deliveryAddressList.find((x) => x.clientAddressCode == response.userDefaultAddress)!;
                },
                error: (error) => {
                    console.log(error);
                },
            }));

        this.unsubscribe.add(
            this.boostersService.getBoosterProductInfo(this.currentUser.clientId).subscribe({
                next: (response) => {

                    this.infoProdutos = response.map((x) => {
                        return {
                            reference: x.reference,
                            decription: x.memory,
                            stock: 0,
                            oldPrice: 0,
                            currentPrice: x.price,
                            class: '',
                            group: x.group,
                            atc: x.atc,
                            lab: x.lab,
                            points: 0,
                            iva: '',
                            family: x.family,
                            subfamily: [],
                            activeSubstance: [],
                            species: x.species,
                            situacao: '',
                            bonus: x.bonus,
                            favorite: false,
                            imageId: '',
                            productQuantity: 0,
                            isPoints: false,
                            request: false,
                            validate: x.validate,
                            color: 0,
                            files: x.files,
                            productImage: x.image
                        };
                    });

                    this.infoProdutos.forEach(element => {
                        var boosterProduto = this.boosters.find((x) => x.idProduto == element.reference);
                        var AllProductBonus = boosterProduto!.bonus.split(',');

                        element.bonus.forEach(InsideBonus => {
                            var bonus = AllProductBonus.find((x) => x.includes(InsideBonus.bonus));

                            if (!bonus)
                                element.bonus = element.bonus.filter((x) => x.bonus != InsideBonus.bonus);
                        });
                    });
                },
                error: (error) => {
                    console.log(error);
                },
            }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['boosters'] && changes['boosters'].currentValue)
            this.shopItemWrappers.data = this.boosters;
    }

    compareFamilyObjects(f1: any, f2: any): boolean {
        return f1 && f2 ? f1.address === f2.address && f1.clientAddressCode === f2.clientAddressCode : f1 === f2;
    }

    onAddressSelect(event: any) {
        this.selectedAddress = event.value
    }

    closeModal() {
        this.shouldBoosterBeOpened.emit(false);
    }

    openProductBonus(product: Booster): void {
        this.isModalOpen.emit(true);

        var selectedProduct = this.infoProdutos.find((x) => x.reference == product.idProduto);

        var dataToSend: DialogData = {
            bonusData: selectedProduct!,
            addButton: false
        };
        const dialogRef = this.dialog.open(ProductBonusModalComponent, {
            data: dataToSend
        });

        this.unsubscribe.add(
            dialogRef.afterClosed().subscribe(() => this.isModalOpen.emit(false))
        );
    }

    async openProductDetails(product: Booster): Promise<void> {
        this.isModalOpen.emit(true);
        var selectedProduct = this.infoProdutos.find((x) => x.reference == product.idProduto);
        this.dialog.open(ProductModalComponent, { data: selectedProduct, });
    }

    addToOrder(toBuy: Booster, selectedBonus: string) {
        let productIndex = this.productsToBuy.findIndex((x) => x.idProduto == toBuy.idProduto);
    
        if (selectedBonus === "Nenhum" && toBuy.unidadesOferta === 0) {
            // Remove the product only if "Nenhum" is selected and unidadesOferta is 0
            if (productIndex !== -1) {
                this.productsToBuy.splice(productIndex, 1);
            }
        } else {
            if (productIndex !== -1) {
                // Update existing product instead of re-adding it
                this.productsToBuy[productIndex].bonus = selectedBonus;
            } else {
                // Add product if it doesn't exist
                toBuy.bonus = selectedBonus;
                this.productsToBuy.push(toBuy);
            }
        }
    }

    getQuantidadeValue(booster: Booster) {
        if (booster)
            return booster.unidadesSemBonus
        else
            return 0;
    }

    getDescricao(booster: Booster) {
        var selectedProduct = this.infoProdutos.find((x) => x.reference == booster.idProduto);
        return selectedProduct!.decription;
    }

    getBonus(booster: Booster) {
        var selectedProduct = this.infoProdutos.find((x) => x.reference == booster.idProduto);

        var bonus = selectedProduct!.bonus.map((x) => x.bonus);
        return bonus;
    }

    getBonusGanhos(booster: Booster) {
        if (booster)
            return booster.unidadesOferta;
        else
            return 0;
    }

    getQuantidadeAdquirir(booster: Booster) {   
        var parsedBonus = parseInt(booster.bonus.split('+')[0]);
        if (isNaN(parsedBonus)) {
            return 0;
        }
    
        var UnidadesSemBonus = booster.unidadesSemBonus ?? 0;
    
        // Return how much is needed to reach the bonus
        return parsedBonus - UnidadesSemBonus;
    }
    
    
    makeOrder() {
        this.isLoading = true;
        var addressId = this.selectedAddress.clientAddressCode;
        var observations = 'Encomenda Booster!';
        if(this.observations != '')
            observations += " - " + this.observations;

        var CheckoutLines: BoosterOrderModel[] = [];

        this.productsToBuy.forEach(element => {

            //get the product info and map it to the checkout model
            var p = this.infoProdutos.find((x) => x.reference == element.idProduto);

            if (p) {
                if(element.bonus!="Nenhum"){

                    var quantidadeParaBonus = parseInt(element.bonus.split('+')[0]);
                    var unidadesSemBonus = element.unidadesSemBonus ?? 0;
    
                    // Determine how much is needed to reach the bonus
                    var quantidadeadquirir = quantidadeParaBonus - unidadesSemBonus;
                }
                else
                    var quantidadeadquirir = 0;

                var pc: BoosterOrderModel = {
                    referencia: p.reference,
                    memoria: p.decription,
                    valorLiquido: p.currentPrice,
                    qtd: quantidadeadquirir,
                    produtoBonus: element.idProdutoBonus,
                    paraBonus: element.bonus,
                    bonusComprados : element.bonusComprados
                };

                CheckoutLines.push(pc);
            }
        });

        let checkoutOrder: CheckoutBoosterOrderModel = {
            UserId: '',
            ClientId: this.currentUser.clientId,
            Address: addressId,
            Obs: observations,
            Currency: 'EUR',
            OrderLines: CheckoutLines
        };

        this.unsubscribe.add(
            this.boostersService.executeCheckout(checkoutOrder).subscribe({
                next: (response) => {
                    
                    let checkoutResponse: CheckoutOrderResponseModel = response;
                    var existsInDb = Number(checkoutResponse.dbOrderNumber) > 0;
                    // No responses returned
                    if (checkoutResponse == null) 
                    {
                        this.snackBarService.openSnackBar(
                            'Não foi possível efetuar a encomenda, por favor tente mais tarde',
                            '#F97066',
                            '../../../assets/Icons/ErrorIcon.png'
                        );
                        this.isLoading = false;
                    }
                    // There are responses
                    else {
                        // For each entry, check if there is a success and register of what type of order it was
                        let anySuccess = checkoutResponse.sggSuccess;
                                                
                        // if there was no success and Order DB Id equal to zero
                        if (!anySuccess && !existsInDb) 
                        {
                            this.snackBarService.openSnackBar(
                                'Não foi possível efetuar a encomenda, por favor tente mais tarde',
                                '#F97066',
                                '../../../assets/Icons/ErrorIcon.png'
                            );
                            this.isLoading = false;
                        }
                        else 
                        {
                            this.snackBarService.openSnackBar(
                                checkoutResponse.message,
                                '#12B76A',
                                '../../assets/Icons/correct.png'
                            );
                            this.isLoading = false;
                            
                        }
                    }

                    this.closeModal();
                },
                error: (error) => {
                    console.log(error);
                },
            }));

    }


    ngOnDestroy() {
        this.unsubscribe.unsubscribe();
    }

}