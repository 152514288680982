const apihost = 'https://loja-pre.bio2.pt';
const mediahost = 'https://loja-pre.bio2.pt';

export const environment = {
  production: true,
  backend_api_url: apihost,
  shop_images: mediahost + '/media/produtos/',
  campaigns_images: mediahost + '/media/upload/',
  productPDFs: mediahost + '/media/produtos/',
  uploadTemplates: mediahost + '/media/templates/',
  faturas_files: mediahost + '/media/faturas/',
  encryptKey: '1203199320052021',
  IV: '1203199320052021',
  prefix:"bio2_",    //Portal prefix
  contacts_info: {
    geralEmail: 'info@bio2.pt',
    financasEmail: 'contabilidade@bio2.pt',
    fornecedoresEmail: 'compras@bio2.pt',
    encomendasEmail: 'encomendas@bio2.pt',
    adicionarEmail: 'adicionar@bio2.pt',
    telefoneNacional: '(+351) 212 137 630',
    telefoneFax: '(+351) 212 137 631',
    morada: 'Edifício Schnellecke',
    morada2: 'Quinta da Marquesa',
    postal: '2950-557 Quinta do Anjo',
    gps: '38.58266974283026, -9.007468918287287',
  },
  termos: `
    <p>
    <b>1. Condições Gerais de uso da página Web</b><br><p>
    As presentes condições legais regulam o uso legal permitido para esta página Web, cujo responsável legal é do representante <b>Bio2®</b>. Estas condições gerais regulam o acesso e utilização que o titular da Web põe gratuitamente à disposição dos usuários da Internet. O acesso à mesma implica a sua aceitação sem reservas. O mero uso da Web, assim como solicitar informação ou emitir um pedido, supõe a aceitação plena das presentes condições.
    </p>

    <p>
    <b>2. Uso da Página Web</b><br><p>
    O usuário obriga-se a usar a Web, os Serviços e Conteúdos de forma diligente, correta e lícita e, em particular, compromete-se
    a abster-se de: suprimir, iludir ou manipular o “copyright” e os demais dados identificativos dos direitos de titulares incorporados
    aos Conteúdos, assim como os dispositivos técnicos de proteção, ou quaisquer mecanismos de informação que podem conter os Conteúdos.
    </p>

    <p>
    <b>3. Propriedade intelectual e industrial</b><br><p>
    A <b>Bio2®</b> é uma marca registada.<br>
    Os textos, imagens, logotipos, signos distintivos, sons, animações, vídeos, código fonte e os restantes conteúdos incluídos nesta Web são propriedade exclusiva da marca <b>Bio2®</b>.<br> Qualquer transmissão, distribuição, reprodução ou armazenamento, total ou parcial, dos conteúdos armazenados nesta Web, fica expressamente proibida salvo prévio ou expresso consentimento do titular. Não obstante, os usuários poderão levar a cabo a reprodução ou armazenamento dos conteúdos da Web para exclusivo uso pessoal, ficando expressa e terminantemente proibida a reprodução de elementos ou conteúdos desta Web,
    realizados com fins lucrativos ou comerciais.
    </p>

    <p>
    <b>4. Responsabilidade por ‘links’</b><br><p>
    A <strong>media</strong>details, empresa responsável pela criação da página Website, declina qualquer responsabilidade pelos serviços e/ou informação que se prestem noutras páginas enlaçadas com esta Web. O representante <b>Bio2®</b> não controla nem exerce nenhum tipo de supervisão quando inclui o enlace das mesmas, acreditando que estes conteúdos correspondem à legislação aplicável.<br> Aconselhamos aos visitantes das mesmas atuar com prudência e consultar as eventuais condições gerais que se exponham nas ditas Webs.
    </p>

    <p>
    <b>5. Exclusão de responsabilidade</b><br><p>
    O representante <b>Bio2®</b> não se faz responsável do uso do conteúdo ou da informação gerada ou publicada por terceiros.<br>
    O representante <b>Bio2®</b> não se faz responsável das perdas ou prejuízos que possam ocasionar ao usuário ou visitante uma eventual caída do serviço, assim como não será responsável pelos problemas surgidos pela falta de interatividade entre os usuários ou visitantes nestas situações.<br>
    O representante <b>Bio2®</b> não se faz responsável pela administração incorreta do(s) produto(s) no(s) animal(ais) do Utilizador mesmo que estes sejam fornecidos através de uma compra em <b>Bio2®</b>. Cabe, assim, ao Utilizador, verificar os produtos recebidos através do processo de compra online antes da abertura das embalagens.
    </p>

    <p>
    <b>6. Condições legais para compra no representante <b>Bio2®</b></b><br><p>
    As presentes Condições Legais de Compra de Produtos, regulam o uso da loja online do sitio Web <a href="https://loja.bio2.pt">https://loja.bio2.pt</a> da qual é responsável legal o representante <b>Bio2®</b>.<br>
    <b>6.1    – Compra de produtos</b><br>
    O representante <b>Bio2®</b> reserva-se em cada momento, e de forma unilateral, ao direito da modificação dos preços dos produtos e serviços promovidos a partir da sua Página Website. Para garantir ao Utilizador, com certeza e segurança, os preços de venda dos produtos promovidos, o representante <b>Bio2®</b> garante que o preço praticado será o preço publicitado no momento da formalização do pedido.
    Para realizar um pedido é necessário conectar-se ao portal <a href="https://loja.bio2.pt">https://loja.bio2.pt</a>, registar-se como Utilizador e preencher o formulário eletrónico que em cada momento possa aparecer. Para tal, deverá seguir as instruções de preenchimento constantes do mesmo.<br>
    Salvo prova em contrário, os dados registados pelo representante <b>Bio2®</b> constituem a prova do conjunto de transações celebradas entre o representante <b>Bio2®</b> e os seus clientes. Uma vez efetuada uma transação comercial, o representante <b>Bio2®</b> arquiva o documento eletrónico que formaliza o contrato de compra e venda. O representante <b>Bio2®</b> utiliza um sistema automático de processamento de encomendas e faturação pelo que os dados apresentados nestes documentos são da inteira responsabilidade do Utilizador.
    Todos os produtos comercializados pelo representante <b>Bio2®</b> destinam-se exclusivamente a consumo próprio e não a revenda ou comercialização a terceiros.<br>
    Em caso de não existir disponibilidade do produto incluído na proposta, o representante <b>Bio2®</b> poderá substituir os produtos constituintes da mesma por produtos equivalentes e/ou por conjuntos de produtos que perfaçam mesmo peso total.<br>
    Para efeitos de cumprimentos do Decreto-lei nº 166/2013 de 20 de Dezembro, declara-se que os produtos em venda são adquiridos pelo representante <b>Bio2®</b> com os descontos e outras contrapartidas decorrentes de todos os contratos e acordos celebrados com os fornecedores.
    </p>

    <p>
    <b>7. Registo como Utilizador</b><br><p>
    Para aceder aos produtos que são promovidos através do Website do representante <b>Bio2®</b>, é exigido a cada Utilizador o seu registo como Utilizador Registado. Para concluir esse registo, será enviado um email para o endereço de correio eletrónico indicado pelo Utilizador, nos termos do qual poderá ser solicitada a confirmação do registo. Se aplicável, só após essa confirmação é que o representante <b>Bio2®</b> confirmará o Utilizador como Utilizador Registado.
    O Utilizador Registado pode aceder, a qualquer momento, às informações fornecidas, assistindo-lhe um direito de modificar ou cancelar o seu registo, o que poderá fazer através de um e-mail para info@bio2.pt.<br>
    Os dados "Utilizador" e "Password" são dados pessoais e intransmissíveis e, portanto, o Utilizador Registado compromete-se a não divulgar essa informação a ninguém, nem ceder a sua utilização a terceiros. Se o representante <b>Bio2®</b> detetar uma utilização fraudulenta do "Utilizador" ou da "Password", este reserva-se ao direito de cancelar o acesso à referida conta.
    Para se registar, o Utilizador deve obrigatoriamente preencher todos os campos constantes do Formulário; Uma vez registado, o Utilizador Registado acederá a "Minha Conta” através da introdução do seu "Utilizador" e "Password".<br>
    As informações ou dados pessoais inseridos em cada um dos campos do Formulário devem ser exatas, precisas e verdadeiras. O utilizador compromete-se a manter atualizada a informação constante da "Minha Conta".<br>
    O representante <b>Bio2®</b> não se responsabiliza pela veracidade das informações fornecidas pelo(s) Utilizador(es). O(s) Utilizador(es) garante(m) e responde(m), em qualquer caso, pela veracidade, exatidão e autenticidade dos dados pessoais introduzidos.<br>
    Cada conta é pessoal, única e intransmissível, estando expressamente proibida a possibilidade de um Utilizador Registado ter mais do que uma conta ativa. Caso o representante <b>Bio2®</b> apague contas que contenham dados semelhantes ou relacionados com um único Utilizador Registado, reserva-se ao direito de cancelar o acesso do Utilizador a todas ou a algumas das contas.
    O Utilizador Registado responde por todas as operações realizadas na "Minha Conta", cujo acesso é restrito, em virtude de o “Utilizador” e a “Password” apenas estarem ao alcance do próprio Utilizador.<br>
    O Utilizador Registado compromete-se a informar imediatamente o representante <b>Bio2®</b>, de qualquer uso não autorizado da "Minha Conta", bem como do acesso não autorizado à sua conta, o que deverá fazer enviando um e-mail para <u>portal@bio2.pt</u>. É proibida a venda, cessão ou transmissão da conta, sob qualquer título.<br>
    O representante <b>Bio2®</b> reserva-se ao direito de suspender ou recusar, temporariamente ou permanentemente, o pedido de registo, bem como o direito de cancelar um registo previamente aceite, sem necessidade de comunicar ou explicar as razões da sua decisão e sem que isso possa acarretar qualquer responsabilidade.
    </p>

    <p>
    <b>8. Processo de Compra</b><br><p>
    Para realizar uma compra em <a href="https://loja.bio2.pt">https://loja.bio2.pt</a> deverá seguir o seguinte processo:<br>
    1º - Selecionar os produtos que deseja adquirir entre aqueles oferecidos pelo representante <b>Bio2®</b> na sua página Website.<br>
    2º Uma vez selecionados o(s) produto(s) que deseja comprar, deverá clicar no botão “adicionar ou diminuir quantidades ao carrinho”, ficando incorporado(s) o(s) produto(s) no seu carrinho de compras. Pode, se assim o desejar, adicionar mais produtos ao seu carrinho de compras.<br>
    3º - Concluída a seleção dos produtos, irá aparecer um resumo que identificará o(s) artigos selecionados. Nesta fase deverá indicar a zona onde será enviado para poder obter o preço dos gastos de envio. Para concluir o seu pedido deverá clicar no botão “Finalizar”.<br>
    4º - Para finalizar o processo deverá identificar-se com os dados da sua conta de utilizador ou completar o formulário de registo se ainda não se encontrar registado.<br>
    5º - Uma vez finalizado o processo de pedido de encomenda no representante <b>Bio2®</b>, ser-lhe-á fornecido o número da encomenda realizada. Se não receber este email em 24h ou se encontrar algum erro nos dados introduzidos no seu pedido, por favor consulte no WebSite <a href="https://loja.bio2.pt">https://loja.bio2.pt</a> em encomendas a situação da mesma ou contacte-nos através dos seguintes meios:<br>
    Contacto telefónico:  (00351) 212 137 630<br>
    Email: info@bio.2pt
    </p>

    <p>
    <b>9. Envios</b><br><p>
    Uma vez concluído o processo de compra, o Utilizador acederá automaticamente a uma página de confirmação da mesma, e onde está mencionada o número da sua encomenda, ou em alternativa receberá um email a confirmar da sua encomenda.<br>
    Os produtos adquiridos pelo Utilizador serão enviados para a "morada de entrega" facultada pelo Utilizador aquando do preenchimento no “carrinho de compras” respeitante aos "Dados de Entrega".<br>
    Os gastos com o envio e/ou gestão não estão incluídos no preço que aparece na oferta promocional, todavia serão comunicados a todos os Utilizadores antes de estes finalizarem o processo de compra, podendo estes, contudo, variar em função da "morada entrega" ou “horário de entrega” de cada pedido, podendo estes também, ser oferecidos ao Utilizador quando o valor da compra ultrapassar um determinado limite. Os envios processam-se nas 24h a 48h úteis que procedem o pagamento efetivo da compra. O representante <b>Bio2®</b>l não se responsabiliza, compromete ou está vinculado por e a qualquer prazo de entrega, desde que este seja inferior aos 7 dias úteis que procedem o pagamento efetivo de encomenda. O tempo de entrega indicado na plataforma <a href="https://loja.bio2.pt">https://loja.bio2.pt</a> tem como base o tempo médio estatístico de sucesso da entrega de compras nas moradas de destino do Utilizador, podendo este ser inferior ao limite pelo qual o representante <b>Bio2®</b> se compromete a efetuar a entrega.
    No momento da receção dos produtos encomendados, cabe ao Utilizador a conferência e verificação dos mesmos. Caso não estejam de acordo com o solicitado, o Utilizador deverá informar o representante <b>Bio2®</b> por escrito com o detalhe da sua reclamação através do Serviço de Apoio ao Cliente.<br>
    Os preços indicados na nossa loja online são válidos para Portugal Continental e não incluem IVA, à taxa em vigor.
    </p>

    <p>
    <b>10. Devoluções</b><br><p>
    O Utilizador dispõe, por lei, de um prazo de 14 (catorze) dias para resolver, se assim o desejar, a sua declaração contratual de compra, não necessitando, para o efeito, de indicar qualquer motivo justificativo.<br>
    O prazo de 14 (catorze) dias conta-se a partir da data de receção do produto pelo Utilizador, sempre que tenham sido cumpridas as obrigações de informação decorrentes do Decreto – Lei nº 7/2004, de 07 de Janeiro.<br>
    A resolução deve ser efetuada por escrito e enviada, por correio registado, para a seguinte morada:<br>
    Bio2 – Comércio e Representações de Produtos Agro-Pecuários, SA<br>
    Polo Industrial Brejos dos Carreteiros, Fase 2, Armazém A e B<br>
    2950-554 Quinta do Anjo<br>
    O exercício do direito de resolução faz com que o representante <b>Bio2®</b> fique a lançar em conta corrente, no prazo máximo de 30 (trinta) dias, contados a partir da receção da comunicação a formalizar o direito de resolução.<br>
    Cabe ao Utilizador suportar o custo da devolução correspondente ao transporte dos produtos ou produto que pretende devolver.<br>
    O representante <b>Bio2®</b> trabalha para que fique satisfeito com a sua compra e por isso facilita diversos métodos para efetuar a devolução.
    </p>

    <p>
    <b>11. Alterações e atualizações</b><br><p>
    Devido à importância que o representante <b>Bio2®</b> dá à proteção e segurança dos seus Utilizadores, os Termos e Condições e Política de Privacidade poderão ser modificados a qualquer momento, com o intuito de se manterem totalmente atualizados no tempo, devendo essas modificações ser notificadas aos Utilizadores.<br>
    Como os presentes Termos e Condições são de carácter obrigatório e vinculativo, se o Utilizador não concordar com as modificações efetuadas deverá abster-se de utilizar o portal e de contratar os conteúdos, produtos e serviços do Site.<br>
    Estes Termos e Condições não criam qualquer sociedade, mandato, franquia ou relação de emprego entre o representante <b>Bio2®</b> e os seus Utilizadores.<br>
    O representante <b>Bio2®</b> reserva-se ao direito de rejeitar qualquer pedido de registo ou de cancelar um registo previamente aceite, sem ser obrigado a comunicar ou explicar as razões da sua decisão e sem que isso implique qualquer direito a indemnização ou compensação.
    </p>

    <p>
    <b>12. Legislação e Jurisdição</b><br><p>
    Em tudo o que for omisso, o presente contrato reger-se-á pelo disposto na Lei Portuguesa.<br>
    Qualquer litígio emergente do presente contrato, poderá ser resolvido com recurso à Arbitragem, de acordo com as suas regras próprias.<br>
    Não existindo acordo quanto ao recurso à arbitragem, serão competentes, para dirimir qualquer questão ou divergência que possa surgir sobre os conteúdos, atividades e produtos contratados pelo Utilizador na página Website do representante <b>Bio2®</b>, os Tribunais Portugueses.<br>
    Quando o litígio seja entre duas pessoas coletivas, será competente o Tribunal da Comarca de Palmela, com renúncia expressa de qualquer outro foro.<br>
    Quando o litígio envolva uma pessoa singular, será competente o tribunal do domicílio do Utilizador.<br>
    Para resolução de conflitos de consumo contacte o Centro de Arbitragem de Conflitos de Consumo de Lisboa (<a href="http://www.centroarbitragemlisboa.pt" target="_blank">http://www.centrodearbitragemlisboa.pt</a>).
    </p>
    `,
  information:
    '*Informamos que para o envio da encomenda com valor inferior a 150€ + IVA (Continente) e 350€ + IVA (Regiões autonónomas) de produtos em stock, serão cobrados portes de envio. Campanhas e preços sujeitos a condições de laboratório. Caso necessite esclarecimento por favor entre em contacto com o seu delegado Bio2 ou com o nosso escritório.',
  ibaninfo:
    'IBAN: PT50 0010 0000 39343590001 20',
  comprovativo:
    'Envio comprovativo: contabilidade@bio2.pt'
  };
