import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Campaign, CampaignGroup, CampaignShop } from 'src/app/api/models/campaigns';
import { CartProductModel } from 'src/app/api/models/checkout';
import { ShopItemModel } from 'src/app/api/models/shop-item.model';
import { CampaignsService } from 'src/app/api/services/campaigns.service';
import { CheckoutService } from 'src/app/api/services/checkout.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign-main',
  templateUrl: './campaign-main.component.html',
  styleUrls: ['./campaign-main.component.css'],
})
export class CampaignMainComponent {
  loggedUser = this.perfilService.getCurrentUserInfo();
  @Input() shopCartChild!: CartProductModel[];
  @Input() campanhas!: CampaignShop;
  @Input() isLoading!: boolean;
  @Output() isModalOpen = new EventEmitter<boolean>();
  env = environment;
  unsubscribe: Subscription = new Subscription();
  quantity: number = 0;
  @Output() quantityUpdate = new EventEmitter<number>();
  publicidades = ['P1', 'P2', 'P3', 'P4'];

  constructor(
    private router: Router,
    private perfilService: PerfilService,
    private checkoutService: CheckoutService,
    private snackBarService: SnackBarService,
    private campaignService: CampaignsService
  ) { }

  ngOnInit(): void { }

  goToGroup(type: string, id: number, group: CampaignGroup) {
    this.router.navigate(['/campaigns', type, 'group', id.toString()], {
      state: { group },
    });
  }

  getCampaignDetails(campaign: Campaign) {
    this.campaignService.getCampaignDetailsById(campaign.id, this.loggedUser.userId, this.loggedUser.commercialCondition).subscribe(campaign => {
      const res = campaign
      sessionStorage.setItem('campaign', JSON.stringify(res));
      this.router.navigate(['/campaigns' + '/' + campaign.id])
    })
  }
}
