import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CheckoutService } from './checkout.service';
import { PerfilService } from './perfil.service';
import { ProfileDataRequest } from '../models/perfil';

@Injectable({
  providedIn: 'root'
})
export class PointsService {
  currentUser!: ProfileDataRequest

  private readPointsSubject = new BehaviorSubject<number>(0);
  private isReadingPointsSubject = new BehaviorSubject<boolean>(true);
  private loadingCoinsSubject = new BehaviorSubject<boolean>(true);

  readPoints$ = this.readPointsSubject.asObservable();
  isReadingPoints$ = this.isReadingPointsSubject.asObservable();
  loadingCoins$ = this.loadingCoinsSubject.asObservable();

  constructor(private checkoutService: CheckoutService, private perfilService: PerfilService) { }

  getUserPoints() {
    this.currentUser = this.perfilService.getCurrentUserInfo();
    this.loadingCoinsSubject.next(true);
    this.isReadingPointsSubject.next(true);
    this.checkoutService.getClientPoints(this.currentUser.userId, this.currentUser.clientId)
      .subscribe({
        next: points => {
          this.readPointsSubject.next(points);
          this.loadingCoinsSubject.next(false);
          this.isReadingPointsSubject.next(false);
        },
        error: error => {
          console.error('Error fetching points:', error);
          this.loadingCoinsSubject.next(false);
          this.isReadingPointsSubject.next(false);
        }
      });
  }
}
