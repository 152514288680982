import { Bio2Tokens } from '../models/bio2token';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { UserDto } from '../models/user-dto';
import jwtDecode from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private router: Router
  ) {
    super(config, http);
  }

  route = new ApiConfiguration();
  currentUser = {};

  ApiAuthPostPath = this.route.rootUrl + '/api/auth';

  login(body: UserDto): Observable<Bio2Tokens> {
    return this.http
      .post<Bio2Tokens>(this.ApiAuthPostPath, body, {
        headers: { clearCache: 'clear' },
      })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  logout():Observable<string>{
    var token = localStorage.getItem('BIO2_RefreshToken') ?? '';
    let params = new HttpParams();
    params = params.append('token', token);

    return this.http.post<string>(this.ApiAuthPostPath + '/revoke-token', null, {params : params})
  }

  getRefreshToken(): Observable<Bio2Tokens> {
    var refreshToken = localStorage.getItem('BIO2_RefreshToken') ?? '';
    const ApiAuthRefreshTokenPostPath = this.route.rootUrl + '/api/auth/refresh-token';

    let headers = new HttpHeaders();
    headers = headers.append('Bio2_refreshtoken', refreshToken);

    return this.http
      .get<Bio2Tokens>(ApiAuthRefreshTokenPostPath, { headers })
      .pipe(
        map((resp) => {
          localStorage.setItem('BIO2_AccessToken', resp.biO2_AccessToken);
          localStorage.setItem('BIO2_RefreshToken', resp.biO2_RefreshToken);
          return resp;
        })
      );
  }

  decodeToken(accessToken: string) {
    const decodedToken = this.getDecodedAccessToken(accessToken);
    const name = decodedToken['unique_name'];
    const role = decodedToken['role'];
    const morada = decodedToken['morada'];
    const expiration =
      decodedToken[
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/expiration'
      ];
    const clientId = decodedToken['clientId'];
    const userId = decodedToken['registoId'];
    const exp = decodedToken['exp'];
    const avatar = decodedToken['avatar'];
  }

  getUniqueName(accessToken: string) {
    const decodedToken = this.getDecodedAccessToken(accessToken);
    const name = decodedToken['unique_name'];
    return name;
  }

  getRole(accessToken: string | null) {
    if (!accessToken) {
      console.error('Access token is null.');
      return null;
    }

    const decodedToken = this.getDecodedAccessToken(accessToken);
    const role = decodedToken['role'];
    return role;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Error decoding token:');
      this.router.navigate(['/login']);
      return null;
    }
  }

  storeTokens(tokens: Bio2Tokens) {
    localStorage.setItem('BIO2_AccessToken', tokens.biO2_AccessToken);
    localStorage.setItem('BIO2_RefreshToken', tokens.biO2_RefreshToken);
  }

  getAccessToken(): string | null {
    return localStorage.getItem('BIO2_AccessToken');
  }

  getUserPermissions(): string[] {
    const bio2Token = localStorage.getItem('BIO2_AccessToken');
    if (bio2Token) {
      const decodedToken = this.getDecodedAccessToken(bio2Token);
      let userRole: string = decodedToken['role'];
      let permissions: string[] = decodedToken["permissions"] ? decodedToken["permissions"].split(',') : [];
      if (userRole === "PetVet") {
        permissions = permissions.filter((permission: string) => permission !== "27");
      }
      return permissions;
    }
    return [];
  }

  hasPermissions(permissions: string[]): boolean {
    const listPermissions: string[] = this.getUserPermissions();
    return permissions.some(permission => listPermissions.includes(permission));
  }

  clearSessionStorage(): void {
    sessionStorage.clear();
    localStorage.clear();
  }
}
