<div class="d-flex flex-row" style="height: 100vh">
  <div>
    <app-dashboard-side-menu></app-dashboard-side-menu>
  </div>
  <div class="p-content d-flex flex-column">
    <app-dashboard-top-menu></app-dashboard-top-menu>
    <!-- !!Content to be displayed when loading is complete -->
    <div *ngIf="loading; else contentLoaded" class="loading-animation">
      <app-loading></app-loading>
    </div>
    <ng-template #contentLoaded>
      <div class="template-content">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row-reverse">
            <button routerLink="create" class="add-campaign-button d-flex">
              Nova Campanha<mat-icon class="">add</mat-icon>
            </button>
          </div>
          <div class="d-flex flex-column justify-content-between" style="margin-top: 40px;">
            <table *ngIf="!loading" mat-table [dataSource]="dataSourceCampaigns.data" class="table-bordered"
              [ngClass]="{'body-opacity': loading}">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let element" data-label="Nome:">
                  {{element.name}}
                  <button class="link-button" matTooltip={{element.link}} cdkCopyToClipboard={{element.link}}
                    matTooltipClass="tooltip-button" matTooltipPosition="above">
                    <img src="../../../assets/Icons/links.png" alt="imagem">
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="startingDate">
                <th mat-header-cell *matHeaderCellDef>
                  <div class="d-flex flex-row" style="align-items: center; justify-content: space-between;">
                    <span>Data Início</span>
                    <div class="d-flex flex-row rows" style="gap: 8px;">
                      <img src="../../../../assets/Icons/arrow-up.png" alt="top-row" class="arrows" (click)="sortTable('StartingDate', 'asc')">
                      <img src="../../../../assets/Icons/arrow-down.png" alt="down-row" class="arrows" (click)="sortTable('StartingDate', 'desc')">
                    </div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let element" data-label="Data Início:">
                  {{element.startingDate | extractDatePt}}</td>
              </ng-container>

              <ng-container matColumnDef="endingDate">
                <th mat-header-cell *matHeaderCellDef>
                  <div class="d-flex flex-row" style="align-items: center; justify-content: space-between;">
                    <span>Data Fim</span>
                    <div class="d-flex flex-row rows" style="gap: 8px;">
                      <img src="../../../../assets/Icons/arrow-up.png" alt="top-row" class="arrows" (click)="sortTable('EndingDate', 'asc')">
                      <img src="../../../../assets/Icons/arrow-down.png" alt="down-row" class="arrows" (click)="sortTable('EndingDate', 'desc')">
                    </div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let element" data-label="Data Fim:">{{element.endingDate | extractDatePt}}
                </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>
                  <div class="d-flex flex-row" style="align-items: center; justify-content: space-between;">
                    <span>Tipo</span>
                    <div class="d-flex flex-row rows" style="gap: 8px;">
                      <img src="../../../../assets/Icons/arrow-up.png" alt="top-row" class="arrows" (click)="sortTable('Type', 'asc')">
                      <img src="../../../../assets/Icons/arrow-down.png" alt="down-row" class="arrows" (click)="sortTable('Type', 'desc')">
                    </div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let element" data-label="Tipo:">{{element.type | campaignsTypeConvert}}</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Ações </th>
                <td mat-cell *matCellDef="let row">
                  <button class="button" [routerLink]="['edit', row.id]">
                    <img src="../../../assets/Icons/edit.png" width="20px" height="20px" />
                  </button>
                  <button class="button" (click)="handleRemoveCampaign(row)">
                    <img src="../../../assets/Icons/trash.png" width="20px" height="20px" />
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="d-flex flex-row justify-content-center">
              <mat-paginator #backofficeCampaignPaginator [hidden]="dataSourceCampaigns.data.length <= 0"
                [pageSize]="campaignPageSize" [pageSizeOptions]="[]" [length]="apiResponse"
                (page)="pageListChanged($event)" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <app-footer></app-footer>
  </div>
</div>
