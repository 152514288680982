<ng-container *ngIf="data">
    <div class="campaign-dialog-content">
        <mat-dialog-content class="pb0">
            <div class="col">
                <div class="row teste1">
                    <div class="header">
                        <div class="header-text">Eliminar Campanha</div>
                        <div [mat-dialog-close]="false" class="btn d-flex flex-row close">
                            <span></span>
                            <img src="../../../../assets/Icons/close-line.png" alt="imagem">
                        </div>
                    </div>
                </div>
                <div class="row information">
                    Tem a certeza que pretende eliminar a campanha {{campaign.name}}?
                </div>
                <div class="buttons">
                    <div class="btn d-flex flex-row cancel-button" (click)="cancelDelete()">
                        <span>Cancelar</span>
                    </div>
                    <div [mat-dialog-close]="true" class="btn d-flex flex-row confirm-button">
                        <span>Eliminar</span>
                        <img src="../../../../assets/Icons/delete.png" alt="imagem">
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
</ng-container>
