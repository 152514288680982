import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { CampaignsService } from 'src/app/api/services/campaigns.service';

import { PerfilService } from 'src/app/api/services/perfil.service';
import { Observable, Subscription } from 'rxjs';

import {
  Campaign,
  CampaignShop,
  CampaignType,
} from 'src/app/api/models/campaigns';
import { ShopItemModel } from 'src/app/api/models/shop-item.model';
import { environment } from 'src/environments/environment';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { InterfaceProductsService } from 'src/app/api/services/interface-products.service';
import { AuthService } from 'src/app/api/services';
import { BoostersService } from 'src/app/api/services/boosters.service';
import { Booster } from 'src/app/api/models/booster';
import { EncryptionService } from 'src/app/api/services/encryption.service';
import { StorageKeys } from 'src/app/api/models/storageKeys';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  isVideo: boolean = false;
  isLoading: boolean = false;
  currentUser!: ProfileDataRequest;
  novidades!: Observable<CampaignShop>;
  campanhas!: Observable<CampaignShop>;
  outlet!: Observable<CampaignShop>;
  exclusivos!: Observable<CampaignShop>;
  parcerias!: Observable<CampaignShop>;
  allCampaigns!: CampaignShop[];
  homeCampList!: Campaign[];
  shopPublicity: Campaign[] = [];
  isModalOpen: boolean = false;
  isBoosterModalOpen: boolean = true
  unsubscribe: Subscription = new Subscription();
  emDestaque!: ShopItemModel[];
  boosters!: Booster[];
  shouldBoosterBeOpened: boolean = true;
  areBoosterLoaded: boolean = false;

  randomVideoSrc!: string;

  thereIsBCampaign!: boolean;
  isBSlotToShowVideo!: boolean;
  BCampaignSelected!: Campaign | undefined;

  thereIsP1Campaign!: boolean;
  isP1SlotToShowVideo!: boolean;
  P1CampaignSelected!: Campaign | undefined;

  thereIsP2Campaign!: boolean;
  isP2SlotToShowVideo!: boolean;
  P2CampaignSelected!: Campaign | undefined;

  thereIsP3Campaign!: boolean;
  isP3SlotToShowVideo!: boolean;
  P3CampaignSelected!: Campaign | undefined;

  thereIsP4Campaign!: boolean;
  isP4SlotToShowVideo!: boolean;
  P4CampaignSelected!: Campaign | undefined;

  constructor(
    private router: Router,
    private campaignsService: CampaignsService,
    private perfil: PerfilService,
    public AuthService: AuthService,
    private interProducts: InterfaceProductsService,
    private boostersService: BoostersService,
    private snackBarService: SnackBarService,
    private encrypt: EncryptionService
  ) {
    this.isLoading = true;
    /*!!!TESTING!!! */
    const biot = localStorage.getItem('BIO2_AccessToken');
    this.AuthService.decodeToken(biot!);
    /*!!!TESTING!!! */
  }
  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }

  ngOnInit() {
    this.currentUser = this.perfil.getCurrentUserInfo();
    this.randomVideoSrc = this.randomVideo();

    if(this.currentUser.role == "Administrador" || this.currentUser.role == "Laboratorio")
    {
      let numCliente = this.encrypt.getDecryptedItem(StorageKeys.BIO2_CLIENT_ID);
      
      this.fetchHomeCampaigns(this.currentUser.username ?? "" , numCliente ?? "");
    }
    else
      this.fetchHomeCampaigns( this.currentUser.username,"");

    this.getDestaqueShop();

    if(this.AuthService.hasPermissions(['31']))
      this.getBoosters();

  }

  getBoosters() {
    this.boostersService.getProdutosBoosters(this.currentUser.clientId)
      .subscribe({
        next: (resp) => {
          this.boosters = resp as Booster[];
          
          if (this.boosters.length > 0) 
            this.isBoosterModalOpen = true;
          else 
            this.isBoosterModalOpen = false

          this.areBoosterLoaded = true;
        },
        error: (err) => console.error(err),
      });
  }

  isBoosterProdModalOpen(value: boolean) {
    this.isBoosterModalOpen = value;
  }



  shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  fetchHomeCampaigns(userName: string, numCliente:string): void {
    this.isLoading = true;

    this.homeCampList = [];

    const campaignTypes = `${CampaignType.B},${CampaignType.P1},${CampaignType.P2},${CampaignType.P3},${CampaignType.P4}`;

    const getAllCampaignsForHomeSub = this.campaignsService
      .getAllCampaigns(userName, numCliente, campaignTypes, environment.prefix)
      .subscribe({
        next: (resp) => {
          if (
            resp === undefined ||
            resp.campaignShop === undefined ||
            resp.campaignShop.length == 0
          )
            return;

          this.homeCampList = resp.campaignShop;

          const typeBCampaigns = this.homeCampList?.filter(
            (x) => x.type === CampaignType.B
          );
          const typeP1Campaigns = this.homeCampList?.filter(
            (x) => x.type === CampaignType.P1
          );
          const typeP2Campaigns = this.homeCampList?.filter(
            (x) => x.type === CampaignType.P2
          );
          const typeP3Campaigns = this.homeCampList?.filter(
            (x) => x.type === CampaignType.P3
          );
          const typeP4Campaigns = this.homeCampList?.filter(
            (x) => x.type === CampaignType.P4
          );
          this.thereIsBCampaign =
            typeBCampaigns !== undefined && typeBCampaigns?.length > 0;
          this.thereIsP1Campaign =
            typeP1Campaigns !== undefined && typeP1Campaigns?.length > 0;
          this.thereIsP2Campaign =
            typeP2Campaigns !== undefined && typeP2Campaigns?.length > 0;
          this.thereIsP3Campaign =
            typeP3Campaigns !== undefined && typeP3Campaigns?.length > 0;
          this.thereIsP4Campaign =
            typeP4Campaigns !== undefined && typeP4Campaigns?.length > 0;

          const bCampaignInfo = this.getCampaign(typeBCampaigns, this.thereIsBCampaign);
          this.BCampaignSelected = bCampaignInfo.campaign;
          this.isBSlotToShowVideo = bCampaignInfo.isVideo;

          const p1CampaignInfo = this.getCampaign(typeP1Campaigns, this.thereIsP1Campaign);
          this.P1CampaignSelected = p1CampaignInfo.campaign;
          this.isP1SlotToShowVideo = p1CampaignInfo.isVideo;

          const p2CampaignInfo = this.getCampaign(typeP2Campaigns, this.thereIsP2Campaign);
          this.P2CampaignSelected = p2CampaignInfo.campaign;
          this.isP2SlotToShowVideo = p2CampaignInfo.isVideo;

          const p3CampaignInfo = this.getCampaign(typeP3Campaigns, this.thereIsP3Campaign);
          this.P3CampaignSelected = p3CampaignInfo.campaign;
          this.isP3SlotToShowVideo = p3CampaignInfo.isVideo;

          const p4CampaignInfo = this.getCampaign(typeP4Campaigns, this.thereIsP4Campaign);
          this.P4CampaignSelected = p4CampaignInfo.campaign;
          this.isP4SlotToShowVideo = p4CampaignInfo.isVideo;

          this.isLoading = false;
        },
        error: () => {
          getAllCampaignsForHomeSub.unsubscribe();
          this.isLoading = false;

          this.snackBarService.openSnackBar(
            'Não foi possível obter as campanhas. Tente novamente mais tarde ou contacte o seu comercial BIO2.',
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
        },
        complete: () => {
          getAllCampaignsForHomeSub.unsubscribe();
          this.isLoading = false;
        },
      });
  }

  getCampaign(campaigns: Campaign[], campaignExists: boolean): { campaign: Campaign | undefined; isVideo: boolean } {
    if (!campaignExists) {
      return { campaign: undefined, isVideo: true };
    } else {
      const campaign = campaigns[Math.floor(Math.random() * campaigns.length)];
      const extensionHeader = campaign.header?.split('.').pop();
      const extensionImage = campaign.image?.split('.').pop();
      const isVideo =
        extensionImage?.includes('webm') || extensionImage?.includes('mp4')
          ? true
          : false;
      campaign.image = environment.campaigns_images + campaign.image;
      campaign.header = environment.campaigns_images + campaign.header;
      return { campaign, isVideo };
    }
  }

  randomVideo() {
    const videoId = Math.floor(Math.random() * (14 - 1) + 1);
    const videoIdStr = ('00' + videoId).slice(-2);
    return `../../../../assets/videos/video_${videoIdStr}.webm`;
  }

  imgExists(): boolean {
    const imageSrc = this.BCampaignSelected?.image;
    if (imageSrc && imageSrc.includes('placeholder')) {
      return true;
    } else {
      return false;
    }
  }

  getDestaqueShop() {
    this.isLoading = true;
    this.interProducts
      .getProdutosDestaqueShop(this.currentUser.userId, this.currentUser.clientId, this.currentUser.username)
      .subscribe({
        next: (resp) => {
          this.emDestaque = resp;
        },
        error: (err) => console.error(err),
        complete: () => (this.isLoading = false),
      });
  }

  goToCampaign(campaignId: Number) {
    const campaign = this.homeCampList.find((x) => x.id == campaignId);
    this.router.navigate(['/campaigns', campaignId], {
      state: { campaign },
    });
  }

  goTodosProdutos() {
    const tabIndex = 5; //index tab dos destaques;
    this.router.navigate(['/campaigns'], { queryParams: { tabIndex } });
  }

  isOddNumberItems(): boolean {
    return this.homeCampList.length % 2 !== 0;
  }

  isProdModalOpen(value: boolean) {
    this.isModalOpen = value;
  }
}
