<div class="d-flex flex-row">
  <div>
    <app-dashboard-side-menu></app-dashboard-side-menu>
  </div>
  <div class="p-content d-flex flex-column w-100">
    <app-dashboard-top-menu></app-dashboard-top-menu>
    <div *ngIf="loading; else contentLoaded" class="loading-animation">
      <app-loading></app-loading>
    </div>
    <ng-template #contentLoaded>
      <div class="content">
        <form [formGroup]="myForm">
          <span class="title">Adicionar produto sugerido</span>
          <div class="green-div">
            <div class="row-double">
              <div>
                <div class="upload-template">
                  <span class="text-description">Selecione o produto</span>
                  <app-search-input-products
                    [isMultiple]="true"
                    [plist]="plist"
                    [searchControl]="myControl"
                    [selectedItems]="selectedItens"
                    (selectionChange)="onSelectionChange($event)">
                  </app-search-input-products>
                </div>
              </div>
            </div>
          </div>
          <!-- Selected Products List -->
          <div *ngIf="selectedItens.length > 0" class="selected-products">
            <h3>Produtos selecionados como sugeridos:</h3>
              <div *ngFor="let product of selectedItens; let i = index" class="products-list">
                <div>
                  <span>{{ product.referencia}} - </span>
                  <span>{{ product.memoria }}</span>
                </div>
                <img src="../../../assets/Icons/trash.png" alt="trash" (click)="deleteProduct(i)">
              </div>
          </div>
          <button (click)="submitSelectedProducts()" class="btn-next btn-processar d-flex">
            <span>Guardar seleção</span>
            <img src="../../../assets/Icons/icon-done.png" alt="done" />
          </button>
        </form>
      </div>
    </ng-template>
    <app-footer></app-footer>
  </div>
</div>
