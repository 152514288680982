<div class="main-page">
  <div class="left-side-cat-selection">
    <img class="right-side-logo" src="../../../assets/images/bio2Logo.png" alt="Logótipo da Bio 2" />
    <div class="cards-selection d-flex flex-row">
      <span class="titlemobile">Bem-vindo à Loja Bio2!</span>
      <div class="left-card" (click)="navigateToSubLogin()">
        <span>Loja</span>
      </div>
      <div class="right-card" (click)="navigateToBackofice()">
        <span>Backoffice</span>
      </div>
    </div>
  </div>
</div>
