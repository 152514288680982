<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div class="content">

      <form #myForm="ngForm" (ngSubmit)="onSaveData()" autocomplete="off" class="h-100">
        <div *ngIf="(!userData) || isLoading; else contentLoaded" class="loading-animation h-100">
          <app-loading></app-loading>
        </div>
        <ng-template #contentLoaded>
          <div class="row">
            <div class="col-lg-4 col-md-12 col-xs-12 p-0">
              <h2 class="ml-3 mobileDisplay">Área Pessoal</h2>
            </div>

            <div class="col-lg-8 col-md-12 col-xs-12 text-right p-0">
              <!--*Not editing button-->
              <div class="btns-editing" *ngIf="authService.hasPermissions(['28', '29', '19'])">
                <button type="button" class="btn-green mr-3" [disabled]="!userData"
                  [style.background-color]="isEditing ? '#367072' : ''" (click)="isEditing = !isEditing">
                  <span>{{ isEditing ? "Ver dados" : "Editar dados" }}</span>
                  <mat-icon [svgIcon]="!isEditing ? 'pencil' : 'info'" iconPositionEnd class="ml-2"></mat-icon>
                </button>

                <!--*Editing button-->
                <button [disabled]="isLoading || (!passwordChanged && !addressChanged)" class="btn-green mr-3"
                  type="submit" *ngIf="isEditing">
                  <span>Guardar alterações</span>
                  <mat-icon svgIcon="check" iconPositionEnd class="ml-2"></mat-icon>
                </button>
              </div>
            </div>

          </div>
          <!--*Is NOT Editing-->
          <div *ngIf="!isEditing && userData">
            <div class="personal">
              <span class="personal-title">Dados Pessoais</span>
              <div class="row mt-4">
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">Nome de Faturação</h3>
                  <p>{{ userData.client.name }}</p>
                </div>
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">NIF</h3>
                  <p>{{ userData.client.nif }}</p>
                </div>

                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">Morada de Faturação</h3>
                  <p>{{ userData.client.address }}</p>
                </div>
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">Morada de Entrega por Defeito</h3>
                  <p>{{ moradaDefeito.address }}, {{moradaDefeito.locality}}, {{moradaDefeito.zipCodeCP4}}-{{moradaDefeito.zipCodeCP3}} {{moradaDefeito.zipCodeDesignation}}</p>
                </div>

                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">Código Postal</h3>
                  <div class="d-inline-flex">
                    <p>
                      {{ userData.client.zipCodeCP4 }}-{{
                    userData.client.zipCodeCP3
                    }}
                    </p>
                    <span class="pl-1">
                      <li class="m-dot">
                        <p>{{ userData.client.zipCodeDesignation }}</p>
                      </li>
                    </span>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">Código Postal</h3>
                  <div class="d-inline-flex">
                    <p>
                      {{ moradaDefeito.zipCodeCP4 }}-{{
                    moradaDefeito.zipCodeCP3
                    }}
                    </p>
                    <span class="pl-1">
                      <li class="m-dot">
                        <p>{{ moradaDefeito.zipCodeDesignation }}</p>
                      </li>
                    </span>
                  </div>
                </div>

                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">FAX</h3>
                  <p>{{ userData.client.faxNumber }}</p>
                </div>
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">Contacto Telefónico</h3>
                  <p>{{ userData.client.phoneNumber }}</p>
                </div>
              </div>
            </div>

            <div class="personal">
              <h3 class="personal-title">Dados Plataforma</h3>
              <div class="row mt-4">

                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">Utilizador</h3>
                  <p>{{ userData.userInfo.userName }}</p>
                </div>
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">Nome Utilizador</h3>
                  <p>{{ userData.userInfo.name }}</p>
                </div>


                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">Password</h3>
                  <p>********</p>
                </div>
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label">Email</h3>
                  <p>{{ userData.userInfo.userEmail }}</p>
                </div>
              </div>

            </div>
          </div>

          <!--*Is Editing-->
          <div *ngIf="isEditing">
            <div class="personal">
              <h3 class="personal-title">Dados Pessoais</h3>
              <div class="row mt-4">
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label-editing">Nome de Faturação</h3>
                  <mat-form-field class="input-forms">
                    <input matInput disabled type="text" id="nome" name="nome" [(ngModel)]="userData.client.name">
                  </mat-form-field>
                </div>
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label-editing">NIF</h3>
                  <mat-form-field class="input-forms">
                    <input matInput disabled type="text" id="nif" name="nif" [(ngModel)]="userData.client.nif">
                  </mat-form-field>
                </div>

                <div class="col">
                  <h3 class="item-label-editing">Morada de Faturação</h3>
                  <mat-form-field class="input-forms">
                    <input matInput disabled type="text" id="morada" name="morada"
                      [(ngModel)]="userData.client.address">
                  </mat-form-field>
                </div>

                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label-editing">Morada de Entrega por Defeito</h3>
                  <mat-form-field class="input-forms">
                    <mat-select name="moradaDefeito" id="moradaDefeito"
                      [(ngModel)]="loggedUser.role === 'Administrador' ? moradaDefeito.deliveryAddressId : +userData.userInfo.preferedDeliveryAddressId"
                      (selectionChange)="onAddressChange($event)" [disabled]="!authService.hasPermissions(['19'])">
                      <mat-option *ngFor="let item of userData.deliveryAddresses" [value]="item.deliveryAddressId">
                        <p>{{ item.address }}, {{item.locality}}, {{item.zipCodeCP4}}-{{item.zipCodeCP3}} {{item.zipCodeDesignation}}</p>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label-editing">Código Postal</h3>
                  <div class="input-group input-forms">
                    <mat-form-field class="post-inputs mr-2 cod4">
                      <input matInput disabled type="text" id="codigoPostal4" name="codigoPostal4"
                        [(ngModel)]="userData.client.zipCodeCP4">
                    </mat-form-field>
                    <span class="dash-postal">-</span>
                    <mat-form-field class="post-inputs mr-2 cod3">
                      <input matInput disabled type="text" id="codigoPostal3" name="codigoPostal3"
                        [(ngModel)]="userData.client.zipCodeCP3">
                    </mat-form-field>
                    <mat-form-field class="post-inputs local">
                      <input matInput disabled type="text" id="localidade" name="localidade"
                        [(ngModel)]="userData.client.zipCodeDesignation">
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label-editing">Código Postal</h3>
                  <div class="input-group input-forms">
                    <mat-form-field class="post-inputs mr-2 cod4">
                      <input matInput disabled type="text" id="codigoPostal04" name="codigoPostal04"
                        [(ngModel)]="moradaDefeito.zipCodeCP4">
                    </mat-form-field>
                    <span class="dash-postal">-</span>
                    <mat-form-field class="post-inputs mr-2 cod3">
                      <input matInput disabled type="text" id="codigoPostal03" name="codigoPostal03"
                        [(ngModel)]="moradaDefeito!.zipCodeCP3">
                    </mat-form-field>
                    <mat-form-field class="post-inputs local">
                      <input matInput disabled type="text" id="localidade2" name="localidade2"
                        [(ngModel)]="moradaDefeito!.zipCodeDesignation">
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label-editing">Fax</h3>
                  <mat-form-field class="input-forms">
                    <input matInput disabled type="text" id="fax" name="fax" [(ngModel)]="userData.client.faxNumber">
                  </mat-form-field>
                </div>
                <div class="col">
                  <h3 class="item-label-editing">Contacto Telefónico</h3>
                  <mat-form-field class="input-forms">
                    <input matInput disabled type="text" id="telefone" name="telefone"
                      [(ngModel)]="userData.client.phoneNumber">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="personal">
              <h3 class="personal-title">Dados Plataforma</h3>
              <div class="row mt-4">
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label-editing">Utilizador</h3>
                  <mat-form-field class="input-forms">
                    <input matInput disabled type="text" id="username" name="username"
                      [(ngModel)]="userData.userInfo.userName">
                  </mat-form-field>
                </div>
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label-editing">Nome Utilizador</h3>
                  <mat-form-field class="input-forms">
                    <input matInput disabled type="text" id="userNome" name="userNome"
                      [(ngModel)]="userData.userInfo.name">
                  </mat-form-field>
                </div>

                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label-editing">Password</h3>
                  <div class="input-forms">
                    <mat-form-field class="w-100">
                      <input matInput type="{{ showPassword ? 'text' : 'password' }}" class="w-75 float-left"
                        autocomplete="new-password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="inputPassChanged()" [disabled]="!authService.hasPermissions(['29'])">
                      <div class="float-right" style="border: none;">
                        <span (click)="showPassword = !showPassword" style="cursor: pointer"><img
                            src="../../assets/Icons/Eye.png" alt="Lock Icon" /></span>
                      </div>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6 col-xs-12">
                  <h3 class="item-label-editing">Email</h3>
                  <mat-form-field class="input-forms">
                    <input matInput disabled type="text" id="email" name="email"
                      [(ngModel)]="userData.userInfo.userEmail">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </form>
    </div>
    <app-footer></app-footer>
  </div>
</div>
