import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse, ImportProductsRequest, ImportProductsResponse } from '../models/interface-products';
import { Observable, catchError } from 'rxjs';
import { ShopItemModel } from '../models/shop-item.model';

@Injectable({
  providedIn: 'root'
})
export class InterfaceProductsService {
  route = new ApiConfiguration();
  ApiInterfaceRegistos = this.route.rootUrl + '/api/interface/products';

  constructor(private http: HttpClient) { }

  importProducts(request: ImportProductsRequest ): Observable<ImportProductsResponse[]> {
    let params = new HttpParams();
    params = params.append('checkXML', request.checkXML);
    params = params.append('checkFile', request.checkFile);
    return this.http.put<ImportProductsResponse[]>(this.ApiInterfaceRegistos, request.references, { params });
  }

  downloadFile(): Observable<ApiResponse> {
    const apiDownloadFile = this.route.rootUrl + '/api/interface/products/download-file';
    return this.http.get<ApiResponse>(apiDownloadFile); 
  }

  setProdutosDestaque(referencias: string[], destaque: string) : Observable<ApiResponse> {
    const apiSetProdutosDestaque = this.route.rootUrl + '/api/interface/products/produtos-destaque';
    let params = new HttpParams();
    params = params.append('destaque', destaque);
    return this.http.post<ApiResponse>(apiSetProdutosDestaque, referencias, {params: params}).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  getProdutosDestaque(): Observable<ShopItemModel[]> {
    const apiGetProdutosDestaque = this.route.rootUrl + '/api/interface/products/produtos-destaque';
    return this.http.get<ShopItemModel[]>(apiGetProdutosDestaque).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  getProdutosDestaqueShop(userId: string, numClient: string, userName: string): Observable<ShopItemModel[]> {
    const apiGetProdutosDestaque = this.route.rootUrl + '/api/interface/products/produtos-destaque-shop';
    let params = new HttpParams();
    params = params.append("userId", userId);
    params = params.append("numClient", numClient);
    params = params.append("userName", userName);
    return this.http.get<ShopItemModel[]>(apiGetProdutosDestaque, {params: params}).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }
}