import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { FamilySort, FilterItemInfo, FilterItemResponse, FilterRequest, Filteritens, SaveFilters, SpecieSort, searchModel } from 'src/app/api/models/shop-item.model';
import { ShopService } from 'src/app/api/services/shop.service';
import { BehaviorSubject, Observable, Subscription, lastValueFrom } from 'rxjs';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-shop-filter',
  templateUrl: './shop-filter.component.html',
  styleUrls: ['./shop-filter.component.css']
})
export class ShopFilterComponent implements OnInit, OnDestroy {
  sortFamilyItems: FamilySort[] = []
  sortSpecieItems: SpecieSort[] = []
  selectedFamily: FamilySort | null = null;
  selectedType: FamilySort | null = null;
  selectedStructure: FamilySort | null = null;
  selectedBrand: FamilySort | null = null;
  selectedSpecie: SpecieSort | null = null;
  selectedAllItens!: Filteritens;
  filters!: searchModel;
  @Input() search!: string;
  @Output() isOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("filter") sendFilter: EventEmitter<any> = new EventEmitter<any>();
  unsubscribe: Subscription = new Subscription();

  shouldChange: boolean = false;
  typeSize: number = 0;
  brandSize: number = 0;
  structureSize: number = 0;
  oldFilter: string = '';

  private timeoutId: any;
  private delay: number = 300;
  
  private brandSugestionsSubs!: Subscription;
  private structureSugestionsSubs!: Subscription;


  //#region  Related to the 2nd filter
  request2ndFilter: FilterRequest = {
    page: 1,
    pageSize: 10,
    sortColumn: '',
    sortOrder: '',
    filterName: '',
    filterValue: '',
    searchTerm: '',
    oldSearchTerm: ''
  };
  request2ndFilterInfo: FilterItemInfo = {
    nextPage: false,
    apiResponse: 0,
  };

  filter2ndControl = new FormControl<string>('');
  shouldShow2ndFilter: boolean = false;
  private typeSugestionsSubs!: Subscription;

  //#endregion


  request3rdFilter: FilterRequest = {
    page: 1,
    pageSize: 10,
    sortColumn: '',
    sortOrder: '',
    filterName: '',
    filterValue: '',
    searchTerm: '',
    oldSearchTerm: ''
  };

  request3rdFilterInfo: FilterItemInfo = {
    nextPage: false,
    apiResponse: 0,
  };
  filter3rdControl = new FormControl<string>('');
  shouldShow3rdFilter: boolean = false

  request4thFilter: FilterRequest = {
    page: 1,
    pageSize: 10,
    sortColumn: '',
    sortOrder: '',
    filterName: '',
    filterValue: '',
    searchTerm: '',
    oldSearchTerm: ''
  };

  request4thFilterInfo: FilterItemInfo = {
    nextPage: false,
    apiResponse: 0,
  };

  filter4thControl = new FormControl<string>('');
  shouldShow4thFilter: boolean = false


  private typeSubject: BehaviorSubject<FamilySort[]> = new BehaviorSubject<FamilySort[]>([]);
  public typeObservable: Observable<FamilySort[]> = this.typeSubject.asObservable();

  private brandsSubject: BehaviorSubject<FamilySort[]> = new BehaviorSubject<FamilySort[]>([]);
  public brandObservable: Observable<FamilySort[]> = this.brandsSubject.asObservable();

  private stuctureSubject: BehaviorSubject<FamilySort[]> = new BehaviorSubject<FamilySort[]>([]);
  public structureObservable: Observable<FamilySort[]> = this.stuctureSubject.asObservable();

  constructor(private shopService: ShopService) { }

  ngOnInit(): void {

    //#region Get Filters from session storage 

    var stored_filters = JSON.parse(sessionStorage.getItem("Productfilters") ?? '{}') as SaveFilters;
    
    
    if (stored_filters.Family != null) {
      this.selectedFamily = stored_filters.Family
    }
    if (stored_filters.Type != null) {
      this.selectedType = stored_filters.Type as FamilySort
      this.filter2ndControl.setValue(this.selectedType.descricao);
    }
    if (stored_filters.Brand != null) {
      this.selectedBrand = stored_filters.Brand as FamilySort
      this.filter3rdControl.setValue(this.selectedBrand!.descricao)
    }
    if (stored_filters.Structure != null) {
      this.selectedStructure = stored_filters.Structure as FamilySort
      this.filter4thControl.setValue(this.selectedStructure!.descricao)
    }
    if (stored_filters.Specie != null)
      this.selectedSpecie = stored_filters.Specie

    if (stored_filters.Search != null)
      this.search = stored_filters.Search
    
    //#endregion

    this.selectedAllItens = {
      brand: {
        data: [],
        hasNextPage: false,
        hasPreviousPage: false,
        page: 0,
        pageSize: 0,
        totalCount: 0
      },
      structure: {
        data: [],
        hasNextPage: false,
        hasPreviousPage: false,
        page: 0,
        pageSize: 0,
        totalCount: 0
      },
      type: {
        data: [],
        hasNextPage: false,
        hasPreviousPage: false,
        page: 0,
        pageSize: 0,
        totalCount: 0
      }
    }

    this.unsubscribe.add(this.shopService.getFilterFamilyItems().subscribe(
      response => {
        this.sortFamilyItems = response;
        if (this.selectedFamily != null)
          this.GetOtherFiltersForFamily();

      }));
    this.unsubscribe.add(this.shopService.getFilterSpecieItems().subscribe(
      response => {
        this.sortSpecieItems = response;
      }));

    this.typeSugestionsSubs = this.filter2ndControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter(value => value != null && typeof value === 'string'),
      tap(value => {
        var inputValue = value as string;

        if (this.request2ndFilter.oldSearchTerm !== value) 
          {
            this.request2ndFilter.page = 1;
            this.request2ndFilter.oldSearchTerm = inputValue;
            this.selectedAllItens.type.data = [];
            this.typeSubject.next(this.selectedAllItens.type.data);
          }

        if (inputValue.length === 0) 
        {
          this.request2ndFilter = {
            page: 1,
            pageSize: 10,
            sortColumn: '',
            sortOrder: '',
            filterName: '',
            filterValue: '',
            searchTerm: '',
            oldSearchTerm: ''
          }
          this.selectedType = this.dv;
        }
        else if (inputValue.length > 1) 
        {
          if(inputValue === "---")
            inputValue = ""
          this.request2ndFilter.searchTerm = inputValue;
        }

        this.onTipoChange();

      })
    ).subscribe();

    this.brandSugestionsSubs = this.filter3rdControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter(value => value != null && typeof value === 'string'),
      tap(value => {

        var inputValue = value as string;

        if (this.request3rdFilter.oldSearchTerm !== value) {
          this.request3rdFilter.page = 1;
          this.request3rdFilter.oldSearchTerm = inputValue;
          this.selectedAllItens.brand.data = [];
          this.brandsSubject.next(this.selectedAllItens.brand.data);
        }

        if (inputValue.length === 0) {
          this.request3rdFilter =  {
            page: 1,
            pageSize: 10,
            sortColumn: '',
            sortOrder: '',
            filterName: '',
            filterValue: '',
            searchTerm: '',
            oldSearchTerm: ''
          };
          this.selectedBrand = this.dv;
        }
        else if (inputValue.length > 1) 
        {
          if(inputValue === "---")
            inputValue = ""
          this.request3rdFilter.searchTerm = inputValue;
        }

        this.onBrandChange();
      
      })
    ).subscribe();

    this.structureSugestionsSubs = this.filter4thControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter(value => value != null && typeof value === 'string'),
      tap(value => {

        var inputValue = value as string;



        if (this.request4thFilter.oldSearchTerm !== value) {
          this.request4thFilter.page = 1;
          this.request4thFilter.oldSearchTerm = inputValue;
          this.selectedAllItens.structure.data = [];
          this.stuctureSubject.next(this.selectedAllItens.structure.data);
        }
        if (inputValue.length === 0) {

          this.request4thFilter = {
            page: 1,
            pageSize: 10,
            sortColumn: '',
            sortOrder: '',
            filterName: '',
            filterValue: '',
            searchTerm: '',
            oldSearchTerm: ''
          };
          this.selectedStructure = this.dv;
        }
        else if (inputValue.length > 1) {
          if(inputValue === "---")
            inputValue = ""
          this.request4thFilter.searchTerm = inputValue;
        }

        this.onStructureChange();

      })
    ).subscribe();

  }

  compareFamilyObjects(f1: any, f2: any): boolean {
    return f1 && f2 ? f1.codClasse === f2.codClasse && f1.descricao === f2.descricao : f1 === f2;
  }

  compareSpeciesObjects(f1: any, f2: any): boolean {
    return f1 && f2 ? f1.idEspecie === f2.idEspecie && f1.nome === f2.nome : f1 === f2;
  }

  displayFn(item: FamilySort): string {
    return item ? item.descricao : '';
  }

  onScroll(filtro: string) {

    if (filtro === "type" && this.selectedAllItens.type.data.length < 100) {
      if (this.request2ndFilterInfo.nextPage) {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId); // clear the previous timer
        }

        this.timeoutId = setTimeout(() => {
          this.request2ndFilter.page++;
          this.onTipoChange();
        }, this.delay);
      }
    }

    if (filtro === "brand") {
      if (this.request3rdFilterInfo.nextPage) {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId); // clear the previous timer
        }

        this.timeoutId = setTimeout(() => {
          this.request3rdFilter.page++;
          this.onBrandChange();
        }, this.delay);
      }
    }

    if (filtro === "structure") {
      if (this.request4thFilterInfo.nextPage) {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId); // clear the previous timer
        }

        this.timeoutId = setTimeout(() => {
          this.request4thFilter.page++;
          this.onStructureChange();
        }, this.delay);
      }
    }
  }

  onFamilyChange(): void {

    this.selectedBrand = null;
    this.selectedType = null;
    this.selectedStructure = null;
    this.selectedSpecie = null;

    if(this.filter2ndControl.value !== '')
      this.filter2ndControl.setValue('');
    else
      this.onTipoChange();

    if(this.filter3rdControl.value !== '')
        this.filter3rdControl.setValue('');
    else
      this.onBrandChange();

    if(this.filter4thControl.value !== '')
        this.filter4thControl.setValue('');
    else
      this.onStructureChange();

    this.CheckFamilyInfo();
  }


    CheckFamilyInfo()
    {
      if (this.selectedFamily == undefined) {
        this.selectedAllItens = {
          brand: {
            data: [],
            hasNextPage: false,
            hasPreviousPage: false,
            page: 0,
            pageSize: 0,
            totalCount: 0
          },
          structure: {
            data: [],
            hasNextPage: false,
            hasPreviousPage: false,
            page: 0,
            pageSize: 0,
            totalCount: 0
          },
          type: {
            data: [],
            hasNextPage: false,
            hasPreviousPage: false,
            page: 0,
            pageSize: 0,
            totalCount: 0
          }
        }
        this.shouldChange = false
      }
      else {
        if (this.oldFilter !== this.selectedFamily.codClasse) 
        {
          this.request2ndFilter = {
            page: 1,
            pageSize: 10,
            sortColumn: '',
            sortOrder: '',
            filterName: '',
            filterValue: '',
            searchTerm: '',
            oldSearchTerm: ''
          };
          this.request3rdFilter = {
            page: 1,
            pageSize: 10,
            sortColumn: '',
            sortOrder: '',
            filterName: '',
            filterValue: '',
            searchTerm: '',
            oldSearchTerm: ''
          };
          this.request4thFilter = {
            page: 1,
            pageSize: 10,
            sortColumn: '',
            sortOrder: '',
            filterName: '',
            filterValue: '',
            searchTerm: '',
            oldSearchTerm: ''
          };
          this.oldFilter = this.selectedFamily.codClasse
  
          if (this.selectedFamily.codClasse === "80" || this.selectedFamily.codClasse === "90")
            this.shouldChange = true
          else
            this.shouldChange = false
        }
      }
    }


    GetOtherFiltersForFamily() {
      this.CheckFamilyInfo();
      this.onTipoChange();
      this.onBrandChange();
      this.onStructureChange();
    }

  dv = { codClasse: "-99", descricao: "---" } as FamilySort;

  //2nd filter
  onTipoChange(): void {
    const typeSubs = this.shopService.getFilterItems(this.request2ndFilter, this.selectedFamily!.codClasse, "type", this.request2ndFilter.searchTerm).subscribe({
      next: (response: FilterItemResponse) => {

        if(this.request2ndFilter.searchTerm === '' && response.totalCount === 0)
          this.shouldShow2ndFilter = false;
        else
          this.shouldShow2ndFilter = true;

        if (this.request2ndFilter.page > 1) {
          this.selectedAllItens.type.data = this.selectedAllItens.type.data.concat(response.data);
          this.typeSubject.next(this.selectedAllItens.type.data);
        }
        else if (this.request2ndFilter.page == 1) 
        {
          this.selectedAllItens.type = response;
          this.typeSubject.next(this.selectedAllItens.type.data);
        }

        this.typeSize = response.totalCount;
        this.request2ndFilterInfo.apiResponse = response.totalCount;
        this.request2ndFilterInfo.nextPage = response.hasNextPage;
      },
      complete: () => {
        if (typeSubs) typeSubs.unsubscribe();
      },
      error: error => {
        console.log(error);
        if (typeSubs) typeSubs.unsubscribe();
      }
    })
  }

  //3rd filter
  onBrandChange(): void {
    const brandSubs = this.shopService.getFilterItems(this.request3rdFilter, this.selectedFamily!.codClasse, "brand", this.request3rdFilter.searchTerm).subscribe({
      next: (response: FilterItemResponse) => {

        if(this.request3rdFilter.searchTerm === '' && response.totalCount === 0)
          this.shouldShow3rdFilter = false;
        else
          this.shouldShow3rdFilter = true;

        if (this.request3rdFilter.page > 1) {
          this.selectedAllItens.brand.data = this.selectedAllItens.brand.data.concat(response.data);
          this.brandsSubject.next(this.selectedAllItens.brand.data);
        }
        else if (this.request3rdFilter.page == 1) {
          this.selectedAllItens.brand = response;
          this.brandsSubject.next(this.selectedAllItens.brand.data);
        }

        this.request3rdFilterInfo.apiResponse = response.totalCount;
        this.request3rdFilterInfo.nextPage = response.hasNextPage;
        this.brandSize = response.totalCount;
      },
      complete: () => {
        if (brandSubs) brandSubs.unsubscribe();
      },
      error: error => {
        console.log(error);
        if (brandSubs) brandSubs.unsubscribe();
      }
    })
  }

  //4th filter
  onStructureChange(): void {
    const structureSubs = this.shopService.getFilterItems(this.request4thFilter, this.selectedFamily!.codClasse, "structure", this.request4thFilter.searchTerm).subscribe({
      next: (response: FilterItemResponse) => {

        if(this.request4thFilter.searchTerm === '' && response.totalCount === 0)
          this.shouldShow4thFilter = false;
        else
          this.shouldShow4thFilter = true;

        if (this.request4thFilter.page > 1) {
          this.selectedAllItens.structure.data = this.selectedAllItens.structure.data.concat(response.data);
          this.stuctureSubject.next(this.selectedAllItens.structure.data);
        }
        else if (this.request4thFilter.page == 1) {
          this.selectedAllItens.structure = response;
          this.stuctureSubject.next(this.selectedAllItens.structure.data);
        }

        this.request4thFilterInfo.apiResponse = response.totalCount;
        this.request4thFilterInfo.nextPage = response.hasNextPage;
        this.structureSize = response.totalCount;
      },
      complete: () => {
        if (structureSubs) structureSubs.unsubscribe();
      },
      error: error => {
        console.log(error);
        if (structureSubs) structureSubs.unsubscribe();
      }
    })
  }

  typeSelection(event: any) {
    this.selectedType = event.option.value;
    this.filter2ndControl.setValue(this.selectedType?.descricao ?? '');
  }

  brandSelection(event: any) {
    this.selectedBrand = event.option.value;
    this.filter3rdControl.setValue(this.selectedBrand?.descricao ?? '');
  }

  structureSelection(event: any) {
    this.selectedStructure = event.option.value;
    this.filter4thControl.setValue(this.selectedStructure?.descricao ?? '');
  }

  applyFilters(): void {
    const searchStored = sessionStorage.getItem("searched") ?? '';

    if (this.selectedType?.codClasse === "-99") {
      this.selectedType = null
    }

    if (this.selectedBrand?.codClasse === "-99") {
      this.selectedBrand = null
    }

    if (this.selectedStructure?.codClasse === "-99") {
      this.selectedStructure = null
    }
    

    this.filters = {
      Search: searchStored,
      Family: this.selectedFamily?.codClasse,
      Type: this.selectedType?.codClasse,
      Brand: this.selectedBrand?.codClasse,
      Structure: this.selectedStructure?.codClasse,
      Specie: this.selectedSpecie?.idEspecie.toString(),
    }

    if (this.selectedFamily === null || this.selectedFamily === undefined) {
      this.selectedBrand = null
      this.selectedType = null
      this.selectedStructure = null
    }

    // save the filters info in the session storage in the form of FamilySort object
    var saveFilters: SaveFilters = {
      Search: searchStored,
      Family: this.selectedFamily ?? null,
      Type: this.selectedType ?? null,
      Brand: this.selectedBrand ?? null,
      Structure: this.selectedStructure ?? null,
      Specie: this.selectedSpecie ?? null
    }

    sessionStorage.setItem("Productfilters", JSON.stringify(saveFilters))
    const values = Object.values(this.filters)

    const test = values.filter(x => x !== undefined)
    if (test.length > 0) {
      this.search = ""
    }
    const emitValue = { search: this.search, filters: this.filters }

    this.sendFilter.emit(emitValue)
    this.isOpen.emit(false)
  }

  closeMenu(): void {
    this.isOpen.emit(false)
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe()
    if (this.typeSugestionsSubs) this.typeSugestionsSubs.unsubscribe();
    if (this.brandSugestionsSubs) this.brandSugestionsSubs.unsubscribe();
    if (this.structureSugestionsSubs) this.structureSugestionsSubs.unsubscribe();
  }
}
