import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';
import {
  AllCampaignTypes,
  Campaign,
  CampaignGroup,
  CampaignShop,
  CampaignType,
} from 'src/app/api/models/campaigns';
import { CartProductModel } from 'src/app/api/models/checkout';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { ShopItemModel } from 'src/app/api/models/shop-item.model';
import { StorageKeys } from 'src/app/api/models/storageKeys';
import { CampaignsService } from 'src/app/api/services/campaigns.service';
import { EncryptionService } from 'src/app/api/services/encryption.service';
import { InterfaceProductsService } from 'src/app/api/services/interface-products.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css'],
})
export class CampaignsComponent implements OnInit, OnDestroy {
  loggedUser = this.perfilService.getCurrentUserInfo();
  tabIndex: number = 0;
  isLoading: boolean = false;
  subscriptions: Subscription[] = [];
  novidades!: CampaignShop;
  campanhas!: CampaignShop;
  publicidade!: CampaignShop;
  outlet!: CampaignShop;
  exclusivos!: CampaignShop;
  parcerias!: CampaignShop;
  shopCart: CartProductModel[] = [];
  shopItems: ShopItemModel[] = [];

  groupCampaing!: CampaignGroup;
  groupId!: string;
  type!: string;

  isModalOpen: boolean = false;
  homeCampaign!: Campaign;
  homeId!: string;
  unsubscribe: Subscription = new Subscription();
  env = environment;
  emDestaque!: CampaignShop;
  private destroy$ = new Subject<void>();

  isSidebarOpen = false;
  currentUser!: ProfileDataRequest;

  constructor(
    private campaignsService: CampaignsService,
    private perfil: PerfilService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackBarService,
    private perfilService: PerfilService,
    private interProducts: InterfaceProductsService,
    private encrypt: EncryptionService
  ) { }

  ngOnInit() {
    const currentRoute = this.route.snapshot.routeConfig?.path;
    const state = this.router.getCurrentNavigation()?.extras.state;

    const state1 = history.state;

    if (state1 && state1.tabIndex !== undefined) {
      this.tabIndex = state1.tabIndex;
    }

    if (state && state['tabIndex'] !== undefined) {
      this.tabIndex = state['tabIndex'];
    }

    if (currentRoute === 'campaigns') {
      if(this.loggedUser.role == "Administrador" || this.loggedUser.role == "Laboratorio")
        {
          let numCliente = this.encrypt.getDecryptedItem(StorageKeys.BIO2_CLIENT_ID);
          
          this.fetchAllCampaigns(this.loggedUser.username ?? "" , numCliente ?? "");
        }
        else
          this.fetchAllCampaigns( this.loggedUser.username,"");

      //!Destaques
      this.getDestaque();
    }

    this.route.queryParams.subscribe((params) => {
      const tabIndex = +params['tabIndex'];
      if (!isNaN(tabIndex)) {
        this.tabIndex = tabIndex;
      }
    });

    this.homeId = this.route.snapshot.paramMap.get('homeId') ?? '';
    if (state && this.homeId) {
      this.homeCampaign =
        this.router.getCurrentNavigation()!.extras.state!['campaign'];
    }

    if (!this.homeCampaign && currentRoute === 'campaigns/:homeId') {
      this.isLoading = true;
      this.campaignsService
        .getCampaignDetailsById(
          parseInt(this.homeId),
          this.loggedUser.usernameClient ?? this.loggedUser.username,
          this.loggedUser.commercialCondition
        )
        .subscribe({
          next: (resp) => {
            this.homeCampaign = resp;
          },
          error: (e) => console.error(e),
          complete: () => (this.isLoading = false),
        });
    }

    this.groupId = this.route.snapshot.paramMap.get('id') ?? '';
    this.type = this.route.snapshot.paramMap.get('type') ?? '';
    if (state && this.groupId && this.type) {
      this.groupCampaing =
        this.router.getCurrentNavigation()!.extras.state!['group'];
    }

    if (!this.groupCampaing && currentRoute === 'campaigns/:type/group/:id') {
      //TO BE REFACTORED -> NEW GROUP LOGIC BASED ON CAMPAIGN ID
      /*this.fetchAllCampaignsGroup(
        this.loggedUser.usernameClient ?? this.loggedUser.username,
        this.type,
        this.groupId
      );*/
    }
    //!!Verificar esta parte para ler a tab correta Pub
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const fragment = this.route.snapshot.fragment;
        if (fragment) {
          // Set the tab index based on the fragment identifier
          this.navigateToTab(fragment);
        }
      });

      this.currentUser = this.perfil.getCurrentUserInfo();
  }

  //!!Verificar a navegacao
  navigateToTab(tabLabel: string) {
    if (tabLabel === 'Publicidade') {
      this.tabIndex = this.getTabIndexForLabel(tabLabel);
    }
  }

  private getTabIndexForLabel(tabLabel: string): number {
    return 0;
  }

  fetchAllCampaigns(userName: string, numCliente:string): void {
    this.isLoading = true;
    const getAllCampaignsSub = this.campaignsService.getAllCampaigns(userName, numCliente, AllCampaignTypes, environment.prefix).subscribe({
      next: (resp) => {
        this.novidades = {
          campaignShop: resp.campaignShop.filter((item) => item.type == 'N'),
        };
        this.campanhas = {
          campaignShop: resp.campaignShop.filter((item) => item.type == 'C'),
        };
        this.publicidade = {
          campaignShop: resp.campaignShop.filter((item) => (item.type == 'DH') || (item.type == 'DH') || (item.type == 'P1') || (item.type == 'P2') || (item.type == 'P3') || (item.type == 'P4')),
        };
        this.outlet = {
          campaignShop: resp.campaignShop.filter((item) => item.type == 'F'),
        };
        this.exclusivos = {
          campaignShop: resp.campaignShop.filter((item) => item.type == 'E'),
        };
        this.parcerias = {
          campaignShop: resp.campaignShop.filter((item) => item.type == 'P'),
        };
        getAllCampaignsSub.unsubscribe();
      },
      error: () => {
        getAllCampaignsSub.unsubscribe();
      },
      complete: () => {
        this.isLoading = false;
        getAllCampaignsSub.unsubscribe();
      }
    });
  }

  //TO REFACTOR. CREATE A METHOD TO CALL THE GET CAMPAIGN BY ID TO GET ALL THE DETAILS WHEN A CAMPAIGN IS CLICKED
  /*fetchAllCampaignsGroup(
    userName: string,
    type: string,
    groupId: string
  ): void {
    this.unsubscribe.add(
      this.campaignsService.getAllCampaigns(userName).subscribe({
        next: (resp) => {
          const temp = {
            campaingShop: resp.campaingShop.filter((item) => item.type == type),
          };
          this.groupCampaing = temp.campaingShop[0].group?.find(
            (g) => g.idCampaignGroup.toString() === groupId
          )!;
          this.groupCampaing.image = temp.campaingShop[0].image;
        },
        error: (e) => console.error(e),
        complete: () => (this.isLoading = false),
      })
    );
  }*/

  getDestaque() {
    this.isLoading = true;
    this.interProducts
      .getProdutosDestaqueShop(this.loggedUser.userId, this.loggedUser.clientId, this.loggedUser.username)
      .subscribe({
        next: (resp) => {
          let destaque: CampaignShop = { campaignShop: [] };
          if (resp.length > 0) {
            destaque = {
              campaignShop: [
                {
                  id: 0,
                  name: 'Destaques',
                  link: '',
                  startingDate: '',
                  endingDate: '',
                  type: '',
                  products: resp,
                },
              ],
            };
          }
          this.emDestaque = destaque;
        },
        error: (err) => console.error(err),
        complete: () => (this.isLoading = false),
      });
  }

  isProdModalOpen(value: boolean) {
    this.isModalOpen = value;
  }

  shopCartItens(shopItens: any): void {
    this.shopCart = shopItens;
  }

  onSidebarToggle(isOpen: boolean) {
    this.isSidebarOpen = isOpen;
    if (this.isSidebarOpen) {
      document.body.style.overflow = 'hidden';
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
