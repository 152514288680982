import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';
import { Observable, catchError } from 'rxjs';
import { AllCampaigns, AllCampaignsRequest, Campaign, CampaignEditRequest, CampaignGroupRequest, CampaignShop } from '../models/campaigns';
import { TbLaboratorio } from '../models/statistics';

@Injectable({
  providedIn: 'root'
})
export class InterfaceCampaignsService {
  route = new ApiConfiguration();
  ApiInterfaceCampaign = this.route.rootUrl + '/api/interface/campaign';

  constructor(private http: HttpClient) { }

  getCampaigns(request: AllCampaignsRequest): Observable<AllCampaigns> {
    let params = new HttpParams()
      .append('Page', String(request.page))
      .append('PageSize', String(request.pageSize))
      .append('SortColumn', request.sortColumn || '')
      .append('SortOrder', request.sortOrder || '')
      .append('FilterName', request.filterName || '')
      .append('FilterValue', request.filterValue || '')
      .append('defaultMonthsInterval', '-1'); // To retrieve all campaigns without time constraints (only in the backoffice)
    return this.http.get<AllCampaigns>(this.ApiInterfaceCampaign, {params});
  }

  createCampaign(request: CampaignShop): Observable<CampaignShop> {
    return this.http.post<CampaignShop>(this.ApiInterfaceCampaign, request);
  }

  getCampaign(id: number): Observable<CampaignShop> {
    return this.http.get<CampaignShop>(this.ApiInterfaceCampaign + `/${id}`);
  }

  deleteCampaign(id: number) {
    return this.http.delete(this.ApiInterfaceCampaign + `/${id}`);
  }

  editCampaign(id: number, request: CampaignEditRequest) { //mudar
    return this.http.put(this.ApiInterfaceCampaign + `/${id}`, request);
  }

  addCampaignProducts(id: number, request: string[]) {
    return this.http.post(this.ApiInterfaceCampaign + `/${id}/products`, request);
  }

  editCampaignGroup(idCmp: number, idCmpGrp: number, request: string) {
    return this.http.put(this.ApiInterfaceCampaign + `/${idCmp}/group/${idCmpGrp}`, request);
  }

  deleteCampaignGroup(idCmp: number, idCmpGrp: number) {
    return this.http.delete(this.ApiInterfaceCampaign + `/${idCmp}/group/${idCmpGrp}`);
  }

  addCampaignGroup(id: number, request: CampaignGroupRequest) {
    return this.http.post(this.ApiInterfaceCampaign + `/${id}/group`, request)
  }

  addCampaignGroupProducts(idCmp: number, idCmpGrp: number, request: string[]) {
    return this.http.post(this.ApiInterfaceCampaign + `/${idCmp}/group/${idCmpGrp}/products`, request);
  }

  deleteCampaignGroupProducts(idCmp: number, idCmpGrp: number, request: string[]) {
    return this.http.delete(this.ApiInterfaceCampaign + `/${idCmp}/group/${idCmpGrp}/products`, {body: request});
  }
}
