import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShopItemModel } from 'src/app/api/models/shop-item.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductModalComponent {

  files: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShopItemModel,
    public dialogRef: MatDialogRef<ProductModalComponent>
  ) {
    if(this.data.files)
      this.files = this.data.files.split(",");
  }

  ngOnInit() {
    this.files = this.files == null || this.files == undefined ? this.data.files.split(",") : this.files;
  }

  showModalImage() {
    return environment.shop_images + this.data.productImage;
  }

  getValidityMessage(validate: Date): string {
    if (!validate) {
      return 'Sem validade';
    }
    const expirationDate = new Date(validate);
    if (isNaN(expirationDate.getTime())) {
      return 'Sem validade';
    }
    if (
      expirationDate.toISOString() ===
      new Date('1990-01-01T00:00:00').toISOString()
    ) {
      return 'Sem validade';
    }
    const sixMonths = new Date();
    sixMonths.setMonth(sixMonths.getMonth() + 6);
    const threeMonths = new Date();
    threeMonths.setMonth(threeMonths.getMonth() + 3);
    if (expirationDate > sixMonths) {
      return 'Mais que 6 meses';
    } else if (expirationDate < threeMonths) {
      return 'Menos de 3 meses';
    } else if (expirationDate >= threeMonths && expirationDate <= sixMonths) {
      return 'Entre 3 a 6 meses';
    }
    return 'Outro caso (se necessário)';
  }

  onFileClick(fileName: string) {
    const fullFilePath = `${environment.productPDFs}${fileName}`;
    //New tab
    window.open(fullFilePath, '_blank');
  }
}
