<div class="form-container">
    <h2 mat-dialog-title>Aviso</h2>

    <mat-dialog-content>
        <p>Tem certeza que deseja remover este item?</p>
    </mat-dialog-content>

    <mat-dialog-actions class="d-flex justify-content-around mat-dialog-actions">
        <button type="cancel" class="checkout-dialog-btn-cancel" (click)="HandleAction(false)" >Cancelar</button>
        <button type="submit" class="checkout-dialog-btn-green" (click)="HandleAction(true)" >
            Remover  
        <mat-icon class="delete-icon">delete</mat-icon>
        </button>
    </mat-dialog-actions>
</div>
