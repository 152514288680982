<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div class="content mx-2">
      <div *ngIf="isLoading" class="loading-container">
        <app-loading frameborder="0"></app-loading>
      </div>
      <div *ngIf="!isLoading">
        <div id="checkout-content" *ngIf="!isCheckoutComplete">
          <span class="checkoutTitle">Checkout</span>
          <div class="content centering h-100" *ngIf="checkoutLines.length == 0">
            <div class="unavailable">Não existem produtos no carrinho</div>
          </div>
          <div *ngIf="checkoutLines.length != 0">
            <div [hidden]="!isMasterUser" id="checkout-address-container">
              <label for="addressesSelect" class="selectLabel">Escolher morada da encomenda</label>
              <mat-select class="form-control" [(value)]="selectedAddress" name="addressesSelect" id="addressesSelect"
                (selectionChange)="onAddressSelect($event)" [compareWith]="compareFamilyObjects">
                <mat-option *ngFor="let address of deliveryAddressList" [value]="address">
                  {{ address.clientAddressCode }} - {{ address.address }}, {{address.locality}},
                  {{address.zipCodeCP4}}-{{address.zipCodeCP3}} {{address.zipCodeDesignation}}
                </mat-option>
              </mat-select>
            </div>
            <!-- Checkout Table -->
            <div id="checkout-table">
              <table class="table" id="checkoutTable">
                <thead>
                  <tr>
                    <th>Produto</th>
                    <th class="td-text-center quantity-cell">Quantidade</th>
                    <th class="td-text-center">Referencia</th>
                    <th class="td-text-center">Valor</th>
                    <th class="td-text-center">IVA</th>
                    <th *ngIf="authService.hasPermissions(['27'])" class="td-text-center">
                      Usar Pontos
                    </th>
                    <th class="td-text-center">Eliminar</th>
                    <th class="td-text-center">Total do Produto</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let line of this.normalCurrencyCheckoutLines">
                    <tr>
                      <td>{{ line.memoria }}</td>
                      <td class="quantity-cell">
                        <div class="checkout-custom-container">
                          <button (click)="
                              AddOrRemoveLineQuantity(line, 'remove', '')
                            " class="custom-qty-icon-button">
                            <i class="material-icons">remove</i>
                          </button>
                          <input [ngModel]="line.qtd" (keyup.enter)="onEnterPress(normalInput)"
                            (blur)="AddOrRemoveLineQuantity(line, 'update', normalInput.value)"
                            (keydown)="onKeyDown($event)" class="line-checkout-quantity-input" #normalInput />
                          <button (click)="AddOrRemoveLineQuantity(line, 'add', '')" class="custom-qty-icon-button">
                            <i class="material-icons">add</i>
                          </button>
                        </div>
                      </td>
                      <td class="td-text-center">{{ line.referencia }}</td>
                      <td class="td-currency">
                        {{ line.valor_Liquido | number : "1.2-2" }}€
                      </td>
                      <td class="td-currency">{{ line.iva }}%</td>
                      <td *ngIf="authService.hasPermissions(['27'])">
                        <label class="checkout-table-points-column">
                          <input #pointsCheckbox type="checkbox" class="points-checkbox"
                            (change)="SignalToUsePoints(line)" [(ngModel)]="line.usePoints" [disabled]="
                              changingCheckbox ||
                              (line.usePoints === false &&
                                this.points -
                                  line.pontos * line.qtd <
                                  0)
                            " />
                          <div class="spacer"></div>
                          <span>{{ line.pontos }} pontos</span>
                        </label>
                      </td>
                      <td class="td-text-center">
                        <button mat-icon-button (click)="OpenDeleteConfirmationModal(line)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </td>
                      <td class="td-currency">
                        {{ line.qtd * line.valor_Liquido | number : "1.2-2" }} €
                      </td>
                    </tr>
                    <ng-container *ngFor="let bonusInfo of line.bonusProductInfo">
                      <tr *ngIf="bonusInfo.bonusAccumulated > 0" class="bonus-row">
                        <td>
                          <span>Bónus: </span>
                          <span>{{ bonusInfo.bonusProductName }}</span>
                        </td>
                        <td class="td-text-center">
                          <div class="checkout-bonus-container">
                            <span>{{ bonusInfo.bonusAccumulated }}</span>
                          </div>
                        </td>
                        <td class="td-text-center">
                          <span>{{ bonusInfo.bonusProductReference }}</span>
                        </td>
                        <td colspan="4"></td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
                <tbody>
                  <ng-container *ngFor="let line of this.pointsProductCheckoutLines">
                    <tr>
                      <td>{{ line.memoria }}</td>
                      <td class="quantity-cell">
                        <div class="checkout-custom-container">
                          <button (click)="
                              AddOrRemoveLineQuantity(
                                line,
                                'remove',
                                pointsInput.value
                              )
                            " class="custom-qty-icon-button">
                            <i class="material-icons">remove</i>
                          </button>
                          <input [ngModel]="line.qtd" (keyup.enter)="onEnterPress(pointsInput)" (blur)="
                              AddOrRemoveLineQuantity(
                                line,
                                'update',
                                pointsInput.value
                              )
                            " (keydown)="OnkeyDown($event)" class="line-checkout-quantity-input" #pointsInput />
                          <button (click)="AddOrRemoveLineQuantity(line, 'add', '')" class="custom-qty-icon-button">
                            <i class="material-icons">add</i>
                          </button>
                        </div>
                      </td>
                      <td class="td-text-center">{{ line.referencia }}</td>
                      <td class="td-currency">
                        {{ line.valor_Liquido | number : "1.2-2" }}€
                      </td>
                      <td class="td-currency">{{ line.iva }}%</td>
                      <td *ngIf="authService.hasPermissions(['27'])">
                        <label class="checkout-table-points-column">
                          <input type="checkbox" class="points-checkbox" (change)="SignalToUsePoints(line)"
                            [(ngModel)]="line.usePoints" [disabled]="changingCheckbox" />
                          <div class="spacer"></div>
                          <span>{{ line.pontos }} pontos</span>
                        </label>
                      </td>
                      <td class="td-text-center">
                        <button mat-icon-button (click)="OpenDeleteConfirmationModal(line)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </td>
                      <td class="td-currency">
                        {{ line.qtd * line.pontos }} Pontos
                      </td>
                    </tr>
                    <ng-container *ngFor="let bonusInfo of line.bonusProductInfo">
                      <tr *ngIf="bonusInfo.bonusAccumulated > 0" class="bonus-row">
                        <td>
                          <span>Bónus: </span>
                          <span>{{ bonusInfo.bonusProductName }}</span>
                        </td>
                        <td class="td-text-center">
                          <div class="checkout-bonus-container">
                            <span>{{ bonusInfo.bonusAccumulated }}</span>
                          </div>
                        </td>
                        <td class="td-text-center">
                          <span>{{ bonusInfo.bonusProductReference }}</span>
                        </td>
                        <td colspan="4"></td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <!-- Card checkout -->
            <ng-container *ngFor="let line of this.normalCurrencyCheckoutLines">
              <div class="full-cards">
                <div>
                  Produto:
                  <span class="produto-span" style="word-wrap: break-word">{{
                    line.memoria
                  }}</span>
                </div>
                <div class="card-checkout">
                  <span class="quantidade-span">Quantidade:</span>
                  <div class="checkout-custom-container">
                    <button (click)="AddOrRemoveLineQuantity(line, 'remove', '')" class="custom-qty-icon-button">
                      <i class="material-icons">remove</i>
                    </button>
                    <input [ngModel]="line.qtd" (keyup.enter)="onEnterPress(normalInput)" (blur)="
                        AddOrRemoveLineQuantity(
                          line,
                          'update',
                          normalInput.value
                        )
                      " (keydown)="OnkeyDown($event)" class="line-checkout-quantity-input" #normalInput />
                    <button (click)="AddOrRemoveLineQuantity(line, 'add', '')" class="custom-qty-icon-button">
                      <i class="material-icons">add</i>
                    </button>
                  </div>
                </div>
                <div class="card-checkout">
                  Referencia:
                  <span class="referencia-span">{{ line.referencia }}</span>
                </div>
                <div class="card-checkout">
                  Valor:
                  <span class="valor-span">{{ line.valor_Liquido | number : "1.2-2" }}€</span>
                </div>
                <div class="card-checkout">
                  Iva:
                  <span class="iva-span">{{ line.iva }}%</span>
                </div>
                <div *ngIf="authService.hasPermissions(['27'])" class="card-checkout">
                  <label class="checkout-table-points-column">
                    <input type="checkbox" class="points-checkbox" (change)="SignalToUsePoints(line)"
                      [(ngModel)]="line.usePoints" />
                    <div class="spacer"></div>
                    <span>{{ line.pontos }} pontos</span>
                  </label>
                </div>
                <div class="card-checkout">
                  <span class="delete-span" (click)="OpenDeleteConfirmationModal(line)">Eliminar</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngFor="let line of this.pointsProductCheckoutLines">
              <div class="full-cards">
                <div class="card-checkout-points-name">
                  Produto:
                  <span class="produto-span">{{ line.memoria }}</span>
                </div>
                <div class="card-checkout-points">
                  <span class="quantidade-span">Quantidade:</span>
                  <div class="checkout-custom-container">
                    <button (click)="AddOrRemoveLineQuantity(line, 'remove', '')" class="custom-qty-icon-button">
                      <i class="material-icons">remove</i>
                    </button>
                    <input [ngModel]="line.qtd" (keyup.enter)="onEnterPress(normalInput)" (blur)="
                        AddOrRemoveLineQuantity(
                          line,
                          'update',
                          normalInput.value
                        )
                      " (keydown)="OnkeyDown($event)" class="line-checkout-quantity-input" #normalInput />
                    <button (click)="AddOrRemoveLineQuantity(line, 'add', '')" class="custom-qty-icon-button">
                      <i class="material-icons">add</i>
                    </button>
                  </div>
                </div>
                <div class="card-checkout-points">
                  Referencia:
                  <span class="referencia-span">{{ line.referencia }}</span>
                </div>
                <div class="card-checkout-points">
                  Valor:
                  <span class="valor-span">{{ line.valor_Liquido | number : "1.2-2" }}€</span>
                </div>
                <div class="card-checkout-points">
                  Iva:
                  <span class="iva-span">{{ line.iva }}%</span>
                </div>
                <div *ngIf="authService.hasPermissions(['27'])" class="card-checkout">
                  <label class="checkout-table-points-column">
                    <input type="checkbox" class="points-checkbox" (change)="SignalToUsePoints(line)"
                      [(ngModel)]="line.usePoints" [disabled]="this.isReadingPoints" />
                    <div class="spacer"></div>
                    <span>{{ line.pontos }} pontos</span>
                  </label>
                </div>
                <div class="card-checkout">
                  <span class="delete-span" (click)="OpenDeleteConfirmationModal(line)">Eliminar</span>
                </div>
              </div>
            </ng-container>

            <div class="d-flex currencyData">
              <div class="td-currency">
                <div>
                  <label class="finish-order-labels">Total: </label>
                  <span class="finish-order-values">
                    {{ orderTotal | number : "1.2-2" }}€
                  </span>
                </div>
                <div *ngIf="authService.hasPermissions(['27'])">
                  <label class="finish-order-labels">Pontos: </label>
                  <span class="finish-order-values">
                    {{ orderPointsTotal }}
                  </span>
                </div>
                <div *ngIf="authService.hasPermissions(['27'])">
                  <label class="finish-order-labels">Saldo: </label>
                  <span class="finish-order-values"> {{ points }} </span>
                </div>
              </div>
              <div class="d-flex flex-column full-data">
                <span class="data-title"> Dados da encomenda: </span>
                <div class="d-flex flex-row data-aligments">
                  <span class="data-semiTitle"> Nome: </span>
                  <span>
                    {{ userData.name }}
                  </span>
                </div>
                <div class="d-flex flex-row data-aligments1">
                  <span class="data-semiTitle"> NIF: </span>
                  <span>
                    {{ userData.nif }}
                  </span>
                </div>
                <div class="d-flex flex-row data-aligments1" *ngIf="!isMasterUser">
                  <span class="data-semiTitle"> Morada: </span>
                  <span>
                    {{ selectedAddress.address }}, {{selectedAddress.locality}},
                    {{selectedAddress.zipCodeCP4}}-{{selectedAddress.zipCodeCP3}} {{selectedAddress.zipCodeDesignation}}
                  </span>
                </div>
              </div>
            </div>
            <div id="obs-textarea">
              <label for="obs-textarea-input">Observações</label>
              <textarea matInput [(ngModel)]="observations" id="obs-textarea-input" class="form-control"
                (change)="saveObservations()"></textarea>
            </div>
            <div style=" padding-bottom: 7px;">
              <span>
                {{ this.information }}
              </span>
            </div>
            <div>
              <span style="font-weight: bold;">
                {{ this.iban }}
              </span>
            </div>
            <div style="padding-bottom: 20px;">
              <span style="font-weight: bold;">
                {{ this.comprovativo }}
              </span>
            </div>
            <!-- Checkout Button -->
            <button [disabled]="isButtonDisabled()"
              [matTooltip]="isReadingPoints ? 'A ler pontos...' : 'Pontos insuficientes'" (click)="openModal()"
              [matTooltipDisabled]="!isButtonDisabled()" type="submit" id="checkout-btn"
              [ngClass]="{'disabled-btn-check': isButtonDisabled()}">
              Finalizar Compra
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </div>
        <!-- Modal address warning -->
        <div *ngIf="isModalOpen" class="modal" (click)="closeOuside($event)">
          <div class="d-flex flex-column full-data-modal" (click)="$event.stopPropagation()">
            <span class="data-title-modal"> Confirmação dos dados de encomenda : </span>
            <div class="line"></div>
            <div class="d-flex flex-row data-aligments">
              <span class="data-semiTitle"> Nome: </span>
              <span>
                {{ userData.name }}
              </span>
            </div>
            <div class="d-flex flex-row data-aligments1">
              <span class="data-semiTitle"> NIF: </span>
              <span>
                {{ userData.nif }}
              </span>
            </div>
            <div class="d-flex flex-row data-aligments1" style="margin-bottom: 15px;">
              <span class="data-semiTitle"> Morada: </span>
              <span>
                {{ selectedAddress.address }}, {{selectedAddress.locality}},
                {{selectedAddress.zipCodeCP4}}-{{selectedAddress.zipCodeCP3}} {{selectedAddress.zipCodeDesignation}}
              </span>
            </div>
            <div class="d-flex flex-row">
              <button id="checkout-btn-modal-close" (click)="closeModal()">Cancelar</button>
              <button id="checkout-btn-modal" (click)="BeginCheckout()">Confirmar</button>
            </div>
          </div>
        </div>
        <!-- Checkout Complete -->
        <div id="checkout-complete" *ngIf="isCheckoutComplete" class="mobileAdust">
          <h1>Confirmação de encomenda</h1>
          <!-- Cover Image-->
          <div id="checkout-finish-cover"></div>

          <!-- Order Details-->
          <div id="checkout-finish-details">
            <div class="grid-container">
              <!-- Grid Header-->
              <div id="checkout-grid-head" class="checkout-flex-container">
                <div class="justify-content-end">
                  <div *ngIf="orderPointsTotal !== 0">
                    <label class="finish-order-labels">Valor Total: </label>
                    <span class="finish-order-values">
                      €{{ orderTotal | number : "1.2-2" }}
                    </span>
                  </div>
                  <div *ngIf="orderPointsTotal == 0">
                    <label class="finish-order-labels">Valor Total: </label>
                    <span class="finish-order-values">
                      €{{ orderTotal | number : "1.2-2" }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Grid Regular Currency Rows-->
              <div id="checkout-grid-body-products" [hidden]="!regularOrderSuccess">
                <div *ngFor="let line of normalCurrencyCheckoutLines" class="checkout-flex-container">
                  <div>
                    <label class="finish-order-labels">Produto: </label>
                    <span class="finish-order-values">
                      {{ line.memoria }}
                    </span>
                  </div>
                  <div class="spacer"></div>
                  <div>
                    <label class="finish-order-labels">Valor: </label>
                    <span class="finish-order-values">
                      €{{ line.qtd * line.valor_Liquido | number : "1.2-2" }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Grid Points Currency Rows -->
              <div id="checkout-grid-body-products" [hidden]="!pointsOrderSuccess">
                <div *ngFor="let line of pointsProductCheckoutLines" class="checkout-flex-container">
                  <div>
                    <label class="finish-order-labels">Produto: </label>
                    <span class="finish-order-values">
                      {{ line.memoria }}
                    </span>
                  </div>
                  <div class="spacer"></div>
                  <div>
                    <label class="finish-order-labels">Valor: </label>
                    <span class="finish-order-values">
                      {{ line.qtd * line.pontos }} Pontos</span>
                  </div>
                </div>
              </div>

              <!-- Grid Bonus Rows -->
              <div id="checkout-grid-body-bonus">
                <div class="checkout-flex-container" *ngFor="let line of bonusProductCheckoutLines">
                  <ng-container *ngIf="line.bonusAccumulated !== 0">
                    <div>
                      <label class="finish-order-labels">Bónus: </label>
                      <span class="finish-order-values">
                        {{ line.bonusProductName }}
                      </span>
                    </div>
                    <div class="spacer"></div>
                    <div>
                      <label class="finish-order-labels">Quantidade: </label>
                      <span class="finish-order-values">
                        {{ line.bonusAccumulated }}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
