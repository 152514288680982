import { PointsService } from './../../api/services/points.service';
import {
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { CartProductModel } from 'src/app/api/models/checkout';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { CheckoutService } from 'src/app/api/services/checkout.service';
import { CustomIconsService } from 'src/app/api/services/custom-icons.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { ShopService } from 'src/app/api/services/shop.service';
import { CartService } from 'src/app/api/services/cart.service';
import { AuthService } from 'src/app/api/services';
import { ProductSearchResponse } from 'src/app/api/models/shop-item.model';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        height: '0px'
      })),
      transition(':enter, :leave', [
        animate(800)
      ])
    ])
  ],
})
export class TopMenuComponent implements OnInit, OnChanges, OnDestroy {
  currentUser: ProfileDataRequest = this.perfilService.getCurrentUserInfo();
  isAdmin: Boolean = false;
  sessStorageAvatar = localStorage.getItem('avatar');
  showAutocomplete = false;
  allProducts: string[] = [''];
  filteredProducts!: ProductSearchResponse[];
  searchformControl = new FormControl('');
  avatar = this.sessStorageAvatar;
  shopCart: CartProductModel[] = [];
  userPoints: number = 0;
  cartQuantity: number = 0;
  @Output() searchLoja = new EventEmitter<string>();
  unsubscribe: Subscription = new Subscription();
  private searchSubject = new BehaviorSubject('');
  ReadPoints!: number;
  isReadingPoints: boolean = true;
  loadingCoins: boolean = true;
  private subscriptions: Subscription[] = [];
  private loggedUser!: ProfileDataRequest;
  cartSubscription!: Subscription;
  private cartFetchTriggered = false;

  constructor(
    private perfilService: PerfilService,
    private router: Router,
    private iconService: CustomIconsService,
    private serviceShop: ShopService,
    private cartService: CartService,
    public authService: AuthService,
    public pointsService: PointsService,
    private checkoutService: CheckoutService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    const pathsToMatch = ['/home', '/shop', '/campaigns', '/favorites', '/finances', '/checkout'];
    const currentUrl = this.router.url;
    this.loggedUser = this.perfilService.getCurrentUserInfo();
    if (pathsToMatch.includes(currentUrl)) {
      this.pointsService.getUserPoints();
      this.cartSubscription = this.cartService.getCart().subscribe(cart => {
        this.shopCart = cart;
        if (!this.cartFetchTriggered) 
        {
          this.cartFetchTriggered = true;
          this.checkoutService.getUserCart(this.loggedUser.userId, this.loggedUser.clientId)
            .subscribe(
              (response: CartProductModel[]) => {
                this.cartService.updateShopCart(response);
              },
              (error) => {
                console.error(error);
              }
            );
        }
      });
    } else {
      this.loadingCoins = false;
    }

    const isShopRoute = this.router.url === '/shop';

    if (!this.avatar) {
      this.avatar = this.currentUser.avatar!;
    }

    // Apply conditional background color based on the route
    if (isShopRoute) {
      document.body.classList.add('shop-background'); // Add a CSS class for background color
    } else {
      document.body.classList.remove('shop-background'); // Remove the CSS class if the route changes
    }

    const message = sessionStorage.getItem('searched');
    if (message) {
      this.searchformControl.setValue(message);
    }

    if (!this.router.url.includes('/shop')) {
      this.searchformControl.setValue('');
      this.serviceShop.setSearching(false);
      sessionStorage.setItem('searched', '');
    }

    if (this.currentUser.role === 'Administrador') {
      this.isAdmin = true;
    }

    this.subscriptions.push(
      this.cartService.shopCart$.subscribe((shopCart) => {
        this.shopCart = shopCart;
        this.cartQuantity = this.calculateCartQuantity(this.shopCart);
      }),
      this.cartService.userTotalPoints$.subscribe((points) => {
        this.userPoints = points;
      }),
      this.pointsService.readPoints$.subscribe(points => {
        this.ReadPoints = points;
      }),
      this.pointsService.isReadingPoints$.subscribe(isReading => {
        this.isReadingPoints = isReading;
      }),
      this.pointsService.loadingCoins$.subscribe(loading => {
        this.loadingCoins = loading;
      })
    );

    this.updateProductList();
  }

  private calculateCartQuantity(cart: CartProductModel[]): number {
    return cart.reduce((total, item) => total + item.quantity, 0);
  }

  search(reference?: string) {
    const myFormControlValue = this.searchformControl.value;
    const searchTerm: string | null =
      myFormControlValue !== null ? myFormControlValue : '';

    sessionStorage.setItem('searched', searchTerm);
    this.serviceShop.setSearching(true);
    if (!this.router.url.includes('/shop')) {
      this.router.navigate(['/shop']);
    } else {
      if (reference)
        this.searchLoja.emit(reference);
      else
        this.searchLoja.emit(searchTerm);
    }
    this.showAutocomplete = false;
    if (myFormControlValue === '') {
      this.serviceShop.setSearching(false);
    }
  }

  cleanSearch() {
    this.searchformControl.setValue("");
  }

  onLogout(): void {
    this.authService.logout().subscribe(_ => {
      localStorage.clear();
      sessionStorage.removeItem('Productfilters');
      this.router.navigate(['/login']);
    })

  }

  onChangeClient(): void {
    this.router.navigate(['/login/selection/subLogin']);
  }

  onChangeToBackoffice(): void {
    this.router.navigate(['/login/selection']);
  }

  goPerfil(): void {
    this.router.navigate(['/perfil']);
  }

  goFavoritos(): void {
    this.router.navigate(['/favorites']);
  }

  goProduto(): void {
    this.router.navigate(['/add/produtos']);
  }

  goCampanha(): void {
    this.router.navigate(['add/campanhas']);
  }

  toggleSideMenu() {
    this.cartService.toggleSideMenu();
  }

  handleInputSearch(event: any) {
    const searchTerm = (event.target as HTMLInputElement).value.trim();
    this.searchSubject.next(searchTerm);
  }

  updateProductList() {
    this.unsubscribe.add(
      this.searchSubject
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap(searchTerm => {
            if (searchTerm.trim() !== '') {
              return this.serviceShop.getTopFilterItems(this.currentUser.username, searchTerm);
            } else {
              return of([]);
            }
          })
        )
        .subscribe({
          next: (res) => {
            this.filteredProducts = res;
            this.showAutocomplete = true;
          }
        })
    );
  }



  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }
}
