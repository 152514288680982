import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';
import { Observable, catchError } from 'rxjs';
import {
  sendEmailCampanha,
  sendEmailProduts,
  uploadProduct,
} from '../models/uploadProduct';

@Injectable({
  providedIn: 'root',
})
export class CreateProductService {
  route = new ApiConfiguration();
  ApiSendNewData = this.route.rootUrl + '/api/shop/campaign/send-new-data';
  ApiSubmitNewProduct = this.route.rootUrl + '/api/interface/products/submit-new-product';

  constructor(private http: HttpClient) {}

  sendNewData(files: FormData, username: string, userId: string): Observable<any> {
      // Appending name and code to the form data
      files.append('name', username);
      files.append('code', userId);

    return this.http.post(this.ApiSendNewData, files);
  }

  SubmitNewProduct(files: FormData, username: string, userId: string): Observable<any> {
    // Appending name and code to the form data
    files.append('name', username);
    files.append('code', userId);

  return this.http.post(this.ApiSubmitNewProduct, files);
}
}
