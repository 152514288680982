<div style="display: flex">
  <div class="main-div" (click)="toggleSidebar()">
  </div>
  <div class="navbar-top-products">
    <div style="display: flex; justify-content: space-between; align-items: center">
      <span class="title">
        Top Produtos sugeridos
      </span>
      <img src="/assets/Icons/close-line.png" alt="close" style="height: 24px;cursor: pointer"
        (click)="toggleSidebar()">
    </div>
    <div class="tabs">
      <button class="tab" [class.active]="activeTab === 'semanal'" (click)="setActiveTab('semanal')">
        Semanal
      </button>
      <button class="tab" [class.active]="activeTab === 'quinzenal'" (click)="setActiveTab('quinzenal')">
        Quinzenal
      </button>
      <button class="tab" [class.active]="activeTab === 'mensal'" (click)="setActiveTab('mensal')">
        Mensal
      </button>
    </div>
    <div class="tab-content">
      <div *ngIf="activeTab === 'semanal'">
        <div class="btn-adicionar" (click)="addAllProductsToCart(products.semanal)">
          Adicionar todo o Top-20 ao carrinho
        </div>
        <div *ngFor="let product of products.semanal" class="list-products"
          style="display: flex; justify-content: space-between">
          <div>
            <span>
              {{ product.reference}}
            </span>
            -
            <span class="description">
              {{ product.decription }}
            </span>
          </div>
          <div class="d-flex flex-row plus-less">
            <div class="less-btn">
              <img src="../../../assets/Icons/less-icon.png" alt="lessIcon"
                (click)="removeQuantityClick(product, 'semanal')" />
            </div>
            <input [ngClass]="{ disabled: isProcessing }" class="number-input" [ngModel]="product.productQuantity"
              type="number" max="1000" (keydown)="onKeyDown($event)" (keyup.enter)="onEnterPress(input)"
              (blur)="changeQuantity(input.value, product, product.productQuantity, 'semanal')" #input />
            <div class="plus-btn">
              <img src="../../../assets/Icons/plus-icon.png" alt="PlusIcon"
                (click)="addQuantityClick(product, 'semanal')" />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="activeTab === 'quinzenal'">
        <div class="btn-adicionar" (click)="addAllProductsToCart(products.quinzenal)">
          Adicionar todo o Top-20 ao carrinho
        </div>
        <div *ngFor="let product of products.quinzenal" class="list-products"
          style="display: flex; justify-content: space-between">
          <div>
            <span>
              {{ product.reference}}
            </span>
            -
            <span class="description">
              {{ product.decription }}
            </span>
          </div>
          <div class="d-flex flex-row plus-less">
            <div class="less-btn">
              <img src="../../../assets/Icons/less-icon.png" alt="lessIcon"
                (click)="removeQuantityClick(product, 'quinzenal')" />
            </div>
            <input [ngClass]="{ disabled: isProcessing }" class="number-input" [ngModel]="product.productQuantity"
              type="number" max="1000" (keydown)="onKeyDown($event)" (keyup.enter)="onEnterPress(input)"
              (blur)="changeQuantity(input.value, product, product.productQuantity, 'quinzenal')" #input />
            <div class="plus-btn">
              <img src="../../../assets/Icons/plus-icon.png" alt="PlusIcon"
                (click)="addQuantityClick(product, 'quinzenal')" />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="activeTab === 'mensal'">
        <div class="btn-adicionar" (click)="addAllProductsToCart(products.mensal)">
          Adicionar todo o Top-20 ao carrinho
        </div>
        <div *ngFor="let product of products.mensal" class="list-products"
          style="display: flex; justify-content: space-between">
          <div>
            <span>
              {{ product.reference}}
            </span>
            -
            <span class="description">
              {{ product.decription }}
            </span>
          </div>
          <div class="d-flex flex-row plus-less">
            <div class="less-btn">
              <img src="../../../assets/Icons/less-icon.png" alt="lessIcon"
                (click)="removeQuantityClick(product, 'mensal')" />
            </div>
            <input [ngClass]="{ disabled: isProcessing }" class="number-input" [ngModel]="product.productQuantity"
              type="number" max="1000" (keydown)="onKeyDown($event)" (keyup.enter)="onEnterPress(input)"
              (blur)="changeQuantity(input.value, product, product.productQuantity, 'mensal')" #input />
            <div class="plus-btn">
              <img src="../../../assets/Icons/plus-icon.png" alt="PlusIcon"
                (click)="addQuantityClick(product, 'mensal')" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsLab">
      <button class="tab" [class.active]="activeTabLab === 'sugestoes'" (click)="setActiveTabLab('sugestoes')"
        style="text-align-last: left;">
        Sugestões de laboratórios
      </button>
    </div>
    <div class="tab-content">
      <div *ngIf="activeTabLab === 'sugestoes'">
        <div class="btn-adicionar" (click)="addAllProductsToCart(products.sugestoes)">
          Adicionar produtos sugeridos ao carrinho
        </div>
        <div *ngFor="let product of products.sugestoes" class="list-products"
          style="display: flex; justify-content: space-between">
          <div>
            <span>
              {{ product.reference}}
            </span>
            -
            <span class="description">
              {{ product.decription }}
            </span>
          </div>
          <div class="d-flex flex-row plus-less">
            <div class="less-btn">
              <img src="../../../assets/Icons/less-icon.png" alt="lessIcon"
                (click)="removeQuantityClick(product, 'sugestoes')" />
            </div>
            <input [ngClass]="{ disabled: isProcessing }" class="number-input" [ngModel]="product.productQuantity"
              type="number" max="1000" (keydown)="onKeyDown($event)" (keyup.enter)="onEnterPress(input)"
              (blur)="changeQuantity(input.value, product, product.productQuantity, 'sugestoes')" #input />
            <div class="plus-btn">
              <img src="../../../assets/Icons/plus-icon.png" alt="PlusIcon"
                (click)="addQuantityClick(product, 'sugestoes')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>