<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div *ngIf="(contactsInfo ) as contactInfo">

      <div class="container-header">
        <div class="overlay-text">
          <h3 class="small-text text-border">Sempre preparados para ajudar</h3>
          <h1 class="big-text text-border">Entre em contacto</h1>
        </div>
      </div>

      <div class="container-info ultraWAdjusts">
        <div class="contact-card-container">
          <app-contact-card [icon]="'mail'" [name]="'Geral'" [email]="contactInfo.geralEmail" role="region" aria-labelledby="Contacto Geral"></app-contact-card>
          <app-contact-card [icon]="'coin'" [name]="'Departamento Financeiro'" [email]="contactInfo.financasEmail" role="region" aria-labelledby="Contacto do departamento financeiro">
          </app-contact-card>
          <app-contact-card [icon]="'package'" [name]="'Encomendas'" [email]="contactInfo.encomendasEmail" role="region" aria-labelledby="Contacto para encomendas">
          </app-contact-card>
          <app-contact-card [icon]="'truck'" [name]="'Fornecedores'" [email]="contactInfo.fornecedoresEmail" role="region" aria-labelledby="Contacto para fornecedores">
          </app-contact-card>
        </div>
        <div class="wrapper">
          <div class="contacts-info">
            <div class="text-row">
              <h1 class="d-flex align-items-center fw-600">
                <mat-icon class="contacts-icons h-auto mr-2" aria-label="Ícone de horários"  svgIcon="clock"></mat-icon>
                Horários
              </h1>
              <p class="m-0 fw-600">Dias Úteis</p>
              <p>9h-13h e 14h30-18h30</p>
            </div>
            <div class="text-row">
              <h1 class="d-flex align-items-center fw-600">
                <mat-icon class="contacts-icons h-auto mr-2" aria-label="Ícone de telefone" svgIcon="phone"></mat-icon>
                Telefones
              </h1>
              <p class="m-0">{{contactInfo.telefoneNacional}} (Chamada para a rede fixa nacional)</p>
              <p>{{contactInfo.telefoneFax}} (fax)</p>
            </div>
            <div class="text-row">
              <h1 class="d-flex align-items-center fw-600">
                <mat-icon class="contacts-icons h-auto mr-2" aria-label="Ícone de localização" svgIcon="pin_map"></mat-icon>
                Onde Estamos
              </h1>
              <p class="m-0">{{contactInfo.morada}}</p>
              <p class="m-0">{{contactInfo.morada2}}</p>
              <p>{{contactInfo.postal}}</p>
              <p class="m-0">GPS: <a
                  href="https://www.google.com/maps/place/38%C2%B034'57.6%22N+9%C2%B000'26.9%22W/@38.5826739,-9.0100438,17z/data=!3m1!4b1!4m4!3m3!8m2!3d38.5826697!4d-9.0074689?entry=ttu"
                  target="_blank">{{contactInfo.gps}}
                </a>
              </p>
            </div>
          </div>
          <!--*MAP-->
          <div class="map mt-4 mt-md-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3118.8863775622212!2d-9.01000092270301!3d38.58246426522283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1946a1981dc285%3A0x4fe1f4a180f51af7!2sSchnellecke-Log%C3%ADstica%20e%20Transportes%20Lda!5e0!3m2!1spt-PT!2spt!4v1720604375329!5m2!1spt-PT!2spt"
              loading="lazy" referrerpolicy="no-referrer-when-downgrade" frameborder="0" allowfullscreen>
            </iframe>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
