import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './services';
import { PerfilService } from './services/perfil.service';
import { StorageKeys } from './models/storageKeys';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private injector: Injector
  ) {}

  /**
   * Injects the Bearer Token in every interface call
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.includes("maps.google.com"))
      return next.handle(request);

    const authService = this.injector.get(AuthService);
    const perfilService = this.injector.get(PerfilService);
    const accessToken = authService.getAccessToken();
    const profileData = perfilService.getCurrentUserInfo();
    if (!!profileData && !!accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          'X-User-Id': profileData.userId ? profileData.userId : '',
          'X-Client-Id': profileData.clientId ? profileData.clientId : '',
        },
      });
    }

    //Check if there is a client selected when is a admin
    const client = localStorage.getItem(StorageKeys.BIO2_CLIENT_ADDRESS_ID);
    if(accessToken){
      const decodedToken = authService.getDecodedAccessToken(accessToken);
      const role = decodedToken['role'];
      const ignored = ['login', 'backoffice']
      const routesIgnored = ignored.some(r => this.router.url.includes(r));
      if(role === 'Administrador' && !client && !routesIgnored){
        this.router.navigate(['/login/selection/subLogin']);
      }
    }

    return next.handle(request);
  }
}
