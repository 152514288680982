import { EncryptionService } from 'src/app/api/services/encryption.service';
import { Router } from '@angular/router';
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { PerfilService } from 'src/app/api/services/perfil.service';
import {
  DeliveryAddress,
  PendingOrders,
  PendingOrdersRequest,
} from 'src/app/encomendas/encomendas.model';
import { EncomendasService } from 'src/app/encomendas/encomendas.service';
import { MatDialog, MatDialogActions, MatDialogRef } from '@angular/material/dialog';
import { MatTab } from '@angular/material/tabs';
import { StorageKeys } from 'src/app/api/models/storageKeys';


@Component({
  selector: 'app-dialog-encomendas',
  templateUrl: './dialog-encomendas.component.html',
  styleUrls: ['./dialog-encomendas.component.css'],
})
export class DialogEncomendasComponent {
  selectedAddressId: string = '';
  isLoadingPending: boolean = false;
  queryPagePending: number = 0;
  querySizePending: number = 10;
  pendingTotal: number = 0;
  deliveryAddresses: DeliveryAddress[] = [];
  loggedUser = this.perfilService.getCurrentUserInfo();
  unsubscribe: Subscription = new Subscription();
  pendingOrdersDataSource: MatTableDataSource<PendingOrders> =
    new MatTableDataSource();
  @ViewChild('pendingPaginator') paginatorPending!: MatPaginator;
  displayedColumnsPending: string[] = [
    'encomendaNr',
    'ref',
    'produto',
    'quantidade',
    'regularizado',
    'bonus',
  ];

  constructor(
    private perfilService: PerfilService,
    private encomendaService: EncomendasService,
    private router: Router,
    private encryptSvc: EncryptionService,
    public dialogRef: MatDialogRef<DialogEncomendasComponent>
  ) { }

  ngOnInit() {
    const decryptedAddresses = this.encryptSvc.getDecryptedItem(StorageKeys.BIO2_CLIENT_ADDRESSES);
    if (decryptedAddresses) {
      let storageAdresses = JSON.parse(decryptedAddresses);
      this.deliveryAddresses = storageAdresses.addresses;
    } else {
      this.listDeliverAddresses();
    }
  }

  onPendingOrdersRequest() {
    this.isLoadingPending = true;
    const pendingOrdersRequest: PendingOrdersRequest = {
      Page: this.queryPagePending + 1,
      PageSize: this.querySizePending,
      userId: this.loggedUser.userId,
      numClient: this.loggedUser.clientId,
      codigoMorada: this.selectedAddressId,
    };

    this.unsubscribe.add(
      this.encomendaService.getPendingOrders(pendingOrdersRequest).subscribe(
        (resp) => {
          this.pendingOrdersDataSource.data = resp.encomendas.data;
          setTimeout(() => {
            this.paginatorPending.pageIndex = this.queryPagePending;
            this.paginatorPending.length = resp.encomendas.totalCount;
            this.pendingTotal = resp.encomendas.totalCount;
          });
          this.isLoadingPending = false;
        },
        (error) => {
          console.error('Error fetching pending orders:', error);
        }
      )
    );
  }

  pagePendingChanged(event: PageEvent) {
    this.querySizePending = event.pageSize;
    this.queryPagePending = event.pageIndex;
    this.onPendingOrdersRequest();
  }

  listDeliverAddresses() {
    let clientId = this.loggedUser.clientId.toString();
    let loggedUserId = this.loggedUser.userId
    this.unsubscribe.add(
      this.encomendaService.getDeliveryAddress(clientId, loggedUserId).subscribe(
        (resp) => {
          this.deliveryAddresses = resp.addresses;
        },
        (error) => {
          console.log('Error fetching delivery addresses: ', error);
        }
      )
    );
  }

  nagivateToPendentes(){
    this.router.navigate(['/orders'], { queryParams: { tab: 'pendentes' } });
    this.dialogRef.close();
  }
}
