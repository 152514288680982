import { Directive, ElementRef, EventEmitter, Input, Output, Host, Self, Optional, AfterViewInit, OnDestroy } from '@angular/core';
import { } from '@angular/material';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Observable, fromEvent, of, Subject, merge, combineLatest } from 'rxjs';
import { map, startWith, switchMap, tap, debounceTime, filter, scan, withLatestFrom, mergeMap, takeUntil, takeWhile, distinctUntilChanged, skipUntil, exhaustMap, endWith, timeout } from 'rxjs/operators';

export interface IAutoCompleteScrollEvent {
    autoComplete: MatAutocomplete;
    scrollEvent: Event;
}

@Directive({
    selector: 'mat-autocomplete[optionsScroll]'
})
export class ScrollDirective implements OnDestroy {

    @Input() thresholdPercent = 0.98;
    @Output('optionsScroll') scroll = new EventEmitter<IAutoCompleteScrollEvent>();
    _onDestroy = new Subject();

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened.pipe(
            tap(() => {
                setTimeout(() => {
                    this.removeScrollEventListener();
                    this.autoComplete.panel.nativeElement.addEventListener('scroll', this.onScroll.bind(this))
                });
            }),
            takeUntil(this._onDestroy)).subscribe();

        this.autoComplete.closed.pipe(
            tap(() => this.removeScrollEventListener()),
            takeUntil(this._onDestroy)).subscribe();
    }

    private removeScrollEventListener() {
        this.autoComplete.panel.nativeElement.removeEventListener('scroll', this.onScroll);
    }

    ngOnDestroy() {
        //this.removeScrollEventListener();
        this._onDestroy.next(null);
        this._onDestroy.complete();
    }

    onScroll(event: Event) {
        if (this.thresholdPercent === undefined) 
        {
            this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
        } 
        else 
        {
            const threshold = this.thresholdPercent * 100 * (<HTMLInputElement>event.target).scrollHeight / 100;
            const current = (<HTMLInputElement>event.target).scrollTop + (<HTMLInputElement>event.target).clientHeight;
            
            if (current > threshold) 
                this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
            
        }
    }
}