<!-- <app-side-bar-products *ngIf="isSidebarOpen" (sidebarToggle)="onSidebarToggle($event)"
  [currentUser]="currentUser"></app-side-bar-products> -->
<div class="main-content" [ngClass]="{ 'modal-open': isMenuOpen }">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu (searchLoja)="load($event)"></app-top-menu>
    <!-- <div class="div-top-products-btn">
      <div (click)="onSidebarToggle(true)" class="top-produtos">
        <span>
          Top produtos
        </span>
      </div>
    </div> -->
    <div class="content">
      <div *ngIf="isLoading; else contentLoaded" class="loading-animation">
        <app-loading></app-loading>
      </div>
      <ng-template #contentLoaded>
        <div class="d-flex flex-column w-100 position-relative ultraWAdjusts">
          <div class="d-flex flex-row flex-wrap justify-content-between align-items-center mb-2">
            <div class="d-flex flex-row justify-content-center fit-content">
              <div>
                <button mat-stroked-button class="filter-icon fit-content" (click)="toggleMenu()" aria-label="Mostrar/Esconder Filtros">
                  <mat-icon svgIcon="filter" iconPositionEnd></mat-icon>Filtros
                </button>
              </div>
              <div class="order-icon-spacing fit-content">
                <button mat-button class="order-icon-left" [ngClass]="{ selected: viewOption === 1 }"
                  (click)="viewOption = 1" aria-label="Visualizar em grelha" >
                  <mat-icon svgIcon="grid"></mat-icon>
                </button>
                <button mat-button class="order-icon-right" [ngClass]="{ selected: viewOption === 2 }"
                  (click)="viewOption = 2" aria-label="Visualizar em lista">
                  <mat-icon svgIcon="list_check"></mat-icon>
                </button>
              </div>
            </div>
            <div class="filter-right d-flex flex-row order">
              <span class="ordenar">Ordenar:</span>
              <mat-form-field class="select-box">
                <mat-select [(value)]="orderByOption" (valueChange)="sortProducts()" aria-label="Ordenar produtos por" id="order-options-result-select">
                  <mat-select-trigger>
                    <ng-container *ngIf="orderByOption !== 'none'">
                      {{ orderByOption.includes('preco') ? 'Preço' : 'Stock' }}
                      <img *ngIf="orderByOption === 'preco-mais'"
                        src="../../../assets/Icons/dashboard-arrow-up-black.png" alt="Ordenar por preço ascendente" />
                      <img *ngIf="orderByOption === 'preco-menos'"
                        src="../../../assets/Icons/dashboard-arrow-down-black.png" alt="Ordenar por preço descendente" />
                      <img *ngIf="orderByOption === 'stock-menos'"
                        src="../../../assets/Icons/dashboard-arrow-down-black.png" alt="Ordenar por stock ascendente" />
                      <img *ngIf="orderByOption === 'stock-mais'"
                        src="../../../assets/Icons/dashboard-arrow-up-black.png" alt="Ordenar por stock descendente" />
                    </ng-container>
                  </mat-select-trigger>
                  <mat-option value="none">Nenhum</mat-option>
                  <mat-option value="preco-mais">
                    Preço
                    <img src="../../../assets/Icons/dashboard-arrow-up-black.png" alt="Preço ascendente" class="option-icon" />
                  </mat-option>
                  <mat-option value="preco-menos">
                    Preço
                    <img src="../../../assets/Icons/dashboard-arrow-down-black.png" alt="Preço descendente" class="option-icon" />
                  </mat-option>
                  <mat-option value="stock-menos">
                    Stock
                    <img src="../../../assets/Icons/dashboard-arrow-down-black.png" alt="Stock ascendente" class="option-icon" />
                  </mat-option>
                  <mat-option value="stock-mais">
                    Stock
                    <img src="../../../assets/Icons/dashboard-arrow-up-black.png" alt="Stock descendente" class="option-icon" />
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="results" matTooltip="{{ apiResponse }} resultados" [matTooltipPosition]="'above'"
                [matTooltipShowDelay]="'500'" [matTooltipHideDelay]="'100'">
                <span>{{ apiResponse }}</span>
                <img src="../../../assets/Icons/shopping-basket-line.png" alt="Número de resultados apresentados"
                  class="results-icon" />
              </div>
            </div>
          </div>
          <!-- Novo layout Standbye -->
          <!-- <span class="title-mobileL">Loja</span> -->
          <div class="d-flex flex-column justify-content-between align-items-center align-self-stretch mt-2">
            <div class="products-grid" *ngIf="viewOption === 1">
              <ng-container *ngFor="let product of shopItems; let i = index">
                <app-product-shop [product]="product" (isModalOpen)="isProdModalOpen($event)"></app-product-shop>
                <ng-container *ngFor="let pub of fullPub; let x = index">
                  <ng-container *ngIf="fullPub[x] === i && pub%2 == 0">
                    <div class="red-div" (click)="goToCampaign(firstDHCampaign.id)"
                      [ngClass]="{'dontShowPub': backgroundImgPublicity1().endsWith('bio2Logo.png')}">
                      <img [src]="backgroundImgPublicity1()" alt="Banner da campanha" style="border-radius: 8px; width: 100%;
                      height: 100%; object-fit: cover;">
                    </div>
                  </ng-container>
                  <ng-container *ngIf="fullPub[x] === i && pub%2 != 0">
                    <!-- Display second red div -->
                    <div class="red-div" (click)="goToCampaign(secondDHCampaign.id)"
                      [ngClass]="{'dontShowPub': backgroundImgPublicity2().endsWith('bio2Logo.png')}">
                      <img [src]="backgroundImgPublicity2()" alt="Banner da campanha" style="border-radius: 8px; width: 100%;
                      height: 100%; object-fit: cover;">
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="shopItems.length == 1">
                  <!-- Display second red div -->
                  <div class="red-div" (click)="goToCampaign(firstDHCampaign.id)"
                    [ngClass]="{'dontShowPub': backgroundImgPublicity2().endsWith('bio2Logo.png')}">
                    <img [src]="backgroundImgPublicity2()" alt="Banner da campanha" style="border-radius: 8px; width: 100%;
                    height: 100%; object-fit: cover;">
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <button id="scrollToTopBtn" (click)="goToTop()">
              <img src="../../../assets/Icons/arrow-up.png" alt="arrow-up">
            </button>

            <div class="d-flex justify-content-center mt-4" *ngIf="viewOption === 1">
              <div style="width: fit-content">
                <mat-spinner *ngIf="loadMore" diameter="30"></mat-spinner>
              </div>
            </div>
                        
            <div class="w-100">
              <ng-container *ngIf="viewOption === 2">
                <app-product-shop-list [shopItems]="shopItems" (isModalOpen)="isProdModalOpen($event)"
                  [isPageChange]="pageChanged" [sortOption]="orderByOption" *ngIf="isLoading == false" />
                <div>
                  <!-- <mat-paginator #shopListPaginator [disabled]="pageChanged" [hidden]="shopItems.length <= 0"
                    [pageSize]="shopListPageSize" [pageSizeOptions]="[]" [length]="apiResponse" (page)="LoadMore()"
                    showFirstLastButtons>
                  </mat-paginator> -->
                  <div class="d-flex justify-content-center mt-4">
                    <div style="width: fit-content">
                      <button class="btn-more" *ngIf="nextPage" [disabled]="loadMore" (click)="LoadMore()">
                        <mat-spinner *ngIf="loadMore" diameter="20"></mat-spinner>
                        <span>Mostrar mais resultados</span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <app-footer></app-footer>
  </div>
  <div class="app-shop-filter" [ngClass]="{ open: isMenuOpen }">
    <app-shop-filter [search]="search" (filter)="applyFilters($event)" (isOpen)="closeMenu()"
      class="position-absolute top-0 right-0 w-100 h-100">
    </app-shop-filter>
  </div>
  <div class="app-backdrop" [ngClass]="{ open: isMenuOpen || isModalOpen }" (click)="closeMenu()"></div>
</div>