import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchInputProductsService {
  private plistSubject = new BehaviorSubject<boolean>(false);
  plist$ = this.plistSubject.asObservable();

  constructor() { }

  setPlist(isLoaded: boolean) {
    this.plistSubject.next(isLoaded);
  }
}
