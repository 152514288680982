import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription, delay } from 'rxjs';
import { TbProduto, updateProdutoRequest } from 'src/app/api/models/statistics';
import { BackofficeCampaignService } from 'src/app/api/services/backoffice-campaign.service';
import { CampaignsService } from 'src/app/api/services/campaigns.service';
import { InterfaceProductService } from 'src/app/api/services/interface-product.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { SearchInputProductsService } from 'src/app/Custom/search-input-products/search-input-products.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css'],
})
export class EditProductComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  unsubscribe: Subscription = new Subscription();
  selectedItens: TbProduto[] = [];
  nextSize: number = 50;
  groupForm!: FormGroup;
  myForm!: FormGroup;
  selectedProduct!: TbProduto;
  productToUpdate!: updateProdutoRequest;
  imgTypeUpdt = '.png,.jpeg,.jpg,';
  fileTypeUpdt = '.pdf';
  plist: TbProduto[] = [];
  filteredProductLists!: Observable<TbProduto[]>;
  myControl = new FormControl('');

  constructor(
    private campaignService: CampaignsService,
    private intefaceProductService : InterfaceProductService,
    private fb: FormBuilder,
    private router: Router,
    private searchInputProducts: SearchInputProductsService,
    private snack: SnackBarService
  ) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      selectProducts: [''],
      technicalFileUpdt: [''],
      imgFileUpdt: [''],
      flyerFileUpdt: [''],
    });
    this.productToUpdate = {} as updateProdutoRequest;
    this.unsubscribe.add(
      this.campaignService.getAllProducts('').subscribe({
        next: (response) => {
            this.plist = response;
        },
        complete: () => {
          this.searchInputProducts.setPlist(true);
        }
      })
    );
  }

  get technicalFileUpdt() {
    return this.myForm.controls['technicalFileUpdt'] as FormControl;
  }
  get imgFileUpdt() {
    return this.myForm.controls['imgFileUpdt'] as FormControl;
  }
  get flyerFileUpdt() {
    return this.myForm.controls['flyerFileUpdt'] as FormControl;
  }
  get selectProducts(){
    return this.myForm.controls['selectProducts'] as FormControl;
  }

  alterProd() {
    this.loading = true;
    const product: TbProduto[] = this.selectProducts.value;

    this.productToUpdate.referencia = product[0].referencia;
    this.productToUpdate.productImage = this.myForm.value.imgFileUpdt
    this.productToUpdate.flyerFile = this.myForm.value.flyerFileUpdt
    this.productToUpdate.technicalFile = this.myForm.value.technicalFileUpdt
    this.intefaceProductService.updateProduto(this.productToUpdate).subscribe({
      next: (resp) => {
        if(resp) {
          this.snack.openSnackBar(
            'Produto atualizado.',
            '#0AA174',
            '../../../assets/Icons/correct.png'
          );
        }else {
          this.snack.openSnackBar(
            'Produto não atualizado.',
            '#F97066',
            '../../../assets/Icons/Warning-icon.png'
          );
        }
      },
      error: () => {
        this.loading = false;
        this.snack.openSnackBar(
          'Produto não atualizado.',
          '#F97066',
          '../../../assets/Icons/Warning-icon.png'
        );
      },
      complete: () => {
        this.loading = false;
      }
    })

  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }
}
