import { PasswordRecService } from './../api/services/password-rec.service';
import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { trigger } from '@angular/animations';
import { SnackBarService } from '../api/services/snack-bar.service';
import { TbRegisto } from '../api/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css'],
  animations: [
    // Ensure that you have a valid animation configuration with @state.done trigger
    trigger('stateAnimation', [
      // ... Animation states and transitions ...
    ]),
  ],
})
export class RecoverPasswordComponent {
  userEmail!: string;
  subscription: Subscription[] = [];
  firstBtnClick: boolean = false;
  userRegisto: TbRegisto[] = [];
  inputSelected: boolean = false;
  selectedIdRegisto: number | null = null;


  constructor(
    private PasswordRecService: PasswordRecService,
    private snackBarService: SnackBarService,
    private router: Router,
  ) { }

  async recoverPassword(): Promise<void> {
    this.subscription.push(
      this.PasswordRecService.recover(this.userEmail).subscribe(
        (resp) => {
          if (resp.status === 200) {
            this.userRegisto = Array.isArray(resp.body) ? resp.body : [resp.body];
          }
        },
        (error) => {
          this.snackBarService.openSnackBar(
            'O email inserido não corresponde a um utilizador',
            '#F97066',
            '../../assets/Icons/ErrorIcon.png'
          );
        }
      )
    );
  }

  sendEmailUser(): void {
    if (this.userEmail && this.selectedIdRegisto) {
      this.PasswordRecService.recoverEmailUser(this.userEmail, this.selectedIdRegisto)
        .subscribe(
          () => {
            this.router.navigate(['/login']);
            this.snackBarService.openSnackBar(
              'Email enviado com sucesso',
              '#0AA174',
              '../../assets/Icons/correct.png'
            );
          },
          (error) => {
            this.snackBarService.openSnackBar(
              'Erro no envio do email',
              '#F97066',
              '../../assets/Icons/ErrorIcon.png'
            );
          }
        );
    }
  }
}
