import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  EncomendaPage,
  EncomendaRequest,
  PendingOrdersRequest,
  PendingOrdersPage,
  DeliveryAddress,
  DeliveryAddressPage,
  ProductEncomendaInfo,
  GetEncomendaResponse,
  PendingOrders,
  RemovalPendingOrders,
  ProductEncomendaInfoRequest,
} from './encomendas.model';
import { ApiConfiguration } from '../api/api-configuration';
import { Observable } from 'rxjs';
import { InterfaceExportOrders } from '../api/models/orders';
@Injectable({
  providedIn: 'root',
})
export class EncomendasService {
  route = new ApiConfiguration();

  constructor(private http: HttpClient) {}

  getOrders(encomendaRequest: EncomendaRequest) {
    const apiUrl = this.route.rootUrl + '/api/encomendas';
    const accessToken = localStorage.getItem('BIO2_AccessToken');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    });

    let httpParams = new HttpParams()
      .set('Page', encomendaRequest.Page)
      .set('PageSize', encomendaRequest.PageSize)
      .set('userId', encomendaRequest.userId)
      .set('clientNumber', encomendaRequest.clientNumber)
      .set('startDate', encomendaRequest.startDate)
      .set('endDate', encomendaRequest.endDate);


    const options = {headers, httpParams, observe: 'response'};
    return this.http.get<GetEncomendaResponse>(apiUrl, {
      params: httpParams,
      headers,
    });
  }

  getPendingOrders(prendingRequest: PendingOrdersRequest) {
    const apiUrl = this.route.rootUrl + '/api/encomendas/pending';
    const accessToken = localStorage.getItem('BIO2_AccessToken');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    });
    let httpParams = new HttpParams()
      .set('Page', prendingRequest.Page)
      .set('PageSize', prendingRequest.PageSize)
      .set('userId', prendingRequest.userId)
      .set('numClient', prendingRequest.numClient)
      .set('codigoMorada', prendingRequest.codigoMorada);
    return this.http.get<GetEncomendaResponse>(apiUrl, {
      params: httpParams,
      headers,
    });
  }

  getDeliveryAddress(customerId: string, loginId: string) {
    const apiUrl = this.route.rootUrl + '/api/sublogin/' + customerId + '/' + loginId;
    const accessToken = localStorage.getItem('BIO2_AccessToken');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    });
    return this.http.get<DeliveryAddressPage>(apiUrl, { headers: headers });
  }


  getOrderProducts(request: ProductEncomendaInfoRequest): Observable<ProductEncomendaInfo> {
    const apiUrl = this.route.rootUrl + '/api/encomendas/details';
    let params = new HttpParams();
    params = params.append("userId", request.userId);
    params = params.append("clientNumber", request.clientNumber);
    params = params.append("encomendaNumber", request.encomendaNumber);
    params = params.append("page", request.page);
    params = params.append("pageSize", request.pageSize);
    return this.http.get<ProductEncomendaInfo>(apiUrl, { params });
  }

  exportEncomendas(encomendaInicio: number, encomendaFim: number, seeXML: boolean): Observable<InterfaceExportOrders[]> {
    const apiUrl = this.route.rootUrl + '/api/encomendas/interface/export';
    let params = new HttpParams();
    params = params.append("encomendaInicio", encomendaInicio);
    params = params.append("encomendaFim", encomendaFim);
    params = params.append("seeXML", seeXML);
    return this.http.get<InterfaceExportOrders[]>(apiUrl, { params });
  }

  removePendingOrders(request: RemovalPendingOrders): Observable<boolean> {
    const apiUrl = this.route.rootUrl + '/api/encomendas/email-pending-orders';
    return this.http.post<boolean>(apiUrl, request);
  }
}
