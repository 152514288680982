import { Component, OnInit, ViewChild } from '@angular/core';
import { Comunicado} from 'src/app/api/models/tb-comunicado';
import { ComunicadosService } from 'src/app/api/services';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';

interface ComunicadoRow {
  comunicado: Comunicado;
  showDescription: boolean;
}

@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
  styleUrls: ['./comunicados.component.css'],
  providers: [DatePipe],
})
export class ComunicadosComponent implements OnInit {
  subscription : Subscription[] = [];
  comunicados : ComunicadoRow[] = [];
  comunicadosDisplay : ComunicadoRow [] = [];
  comunicadosListCurrentPage: number = 1;
  loading = true;
  @ViewChild('comunicadosListPaginator') paginator!: MatPaginator;
  unsubscribe: Subscription = new Subscription();


  constructor(private comunicadosService : ComunicadosService, public dialog: MatDialog, private snackServ: SnackBarService){
  }

  ngOnInit (){
    const sub = 
    this.comunicadosService.getComunicados()
    .subscribe(response => {
      this.comunicados = response.map(comunicado => ({
        comunicado: comunicado, 
        showDescription: false,
      }));
      this.loading=false;
      this.displayTable();
    })
    this.subscription.push(sub);
  }

  displayTable(){
    this.comunicadosDisplay = this.comunicados.slice(0, 10);
  }

  togglePanel(obj: ComunicadoRow): void {
    obj.showDescription = !obj.showDescription;
  }

  pageComunicadosChanged(event: PageEvent){
    this.comunicadosListCurrentPage = event.pageIndex + 1;
    const startIndex = (this.comunicadosListCurrentPage - 1) * 10;
    const endIndex = startIndex + 10;
    this.comunicadosDisplay = this.comunicados.slice(startIndex, endIndex);
  }

  deleteComunicado(id: number): void {
    const indexInComunicados = this.comunicados.findIndex(
      (comunicadoRow) => comunicadoRow.comunicado.id === id
    );
  
    if (indexInComunicados !== -1) {
      this.comunicados.splice(indexInComunicados, 1);
      const startIndex = (this.comunicadosListCurrentPage - 1) * 10;
      const endIndex = startIndex + 10;
  
      this.comunicadosDisplay = this.comunicados.slice(startIndex, endIndex);
    }
    
    const cdelete = this.comunicadosService.deleteComunicado(id).subscribe(
      {
        next: (resp) => {
          this.snackServ.openSnackBar(
            'Comunicado Eliminado!',
            '#0AA174',
            '../../../assets/Icons/correct.png'
          );
        },
        error: (err) => {
          console.error(err);
          this.snackServ.openSnackBar(
            'Erro ao eliminar o comunicado!',
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
        }
      }
    );
    this.subscription.push(cdelete);
  }

  openDialog(comunicado: Comunicado): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: comunicado,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'Delete') {
        this.deleteComunicado(comunicado.id);
      }
    });
  }

  ngOnDestroy(){
    this.subscription.forEach(x=> x.unsubscribe());
  }
}
