import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  ApproveComunicado,
  Comunicado,
} from 'src/app/api/models/tb-comunicado';
import { ComunicadosService } from 'src/app/api/services';
import { CustomIconsService } from 'src/app/api/services/custom-icons.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-comunicado',
  templateUrl: './comunicado.component.html',
  styleUrls: ['./comunicado.component.css'],
})
export class ComunicadoComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  currentUser = this.perfilService.getCurrentUserInfo();
  activeComunicado!: Comunicado;
  unsubscribe: Subscription = new Subscription();

  constructor(
    private iconsService: CustomIconsService,
    private router: Router,
    private perfilService: PerfilService,
    private comunicadoServ: ComunicadosService
  ) {}
  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe()
  }

  ngOnInit() {
    this.getComunicado();
  }

  getComunicado(): void {
    this.isLoading = true;
    this.comunicadoServ.getActiveComunicado().subscribe(
      (comunicado) => {
        if (comunicado) {
          this.activeComunicado = comunicado[0];
          this.isLoading = false;
        } else {
          this.comunicadoServ.getComunicadoByUserObs(this.currentUser.username)
            .subscribe({
              next: (resp) => {
                this.activeComunicado = resp[0];
                this.comunicadoServ.setActiveComunicado(this.activeComunicado);
              },
              error: (e) => console.error(e),
              complete: () => (this.isLoading = false),
            });
        }
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  getImage() {
    let imageComunicado = this.activeComunicado.image;
    if (imageComunicado) {
      return `${environment.campaigns_images}${imageComunicado}`;
    } else {
      return;
    }
  }

  onContinue(): void {
    this.isLoading = true;
    const isPermanent: boolean = this.activeComunicado.isPermanent;

    //Add to table of already seen comunicado.
    if (!isPermanent) {
      const requestApprove: ApproveComunicado = {
        clientId: this.currentUser.clientId,
        id: this.activeComunicado.id!,
      };

      this.unsubscribe.add(this.comunicadoServ.approveComunicado(requestApprove).subscribe({
        next: (resp) => console.log(resp),
        error: (e) => console.error(e),
        complete: () => (this.isLoading = false),
      }));
    }

    if (this.currentUser.role === 'Administrador') {
      this.router.navigate(['/login/selection']);
    } else if (this.currentUser.role === 'Laboratorio') {
      this.router.navigate(['login/selection/subLogin'])
    }
    else {
      this.router.navigate(['/home']);
    }
  }
}
