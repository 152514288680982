import { Component, ViewChild, ViewEncapsulation, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import {
  Balance,
  BalanceReport,
  CurrentExtract,
  CurrentExtractItem,
  CurrentExtractPoints,
  CurrentExtractPointsItem,
  RequestPdf,
} from 'src/app/api/models/finances';
import { FinancesService } from 'src/app/api/services/finances.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CustomIconsService } from 'src/app/api/services/custom-icons.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { DateAdapter } from '@angular/material/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { EncryptionService } from 'src/app/api/services/encryption.service';
import { StorageKeys } from '../../api/models/storageKeys';
import { AuthService } from 'src/app/api/services';
import { PointsService } from 'src/app/api/services/points.service';

@Component({
  selector: 'app-finances',
  templateUrl: './finances.component.html',
  styleUrls: ['./finances.component.css']
})
export class FinancesComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  isLoadingBalance: boolean = false;
  isLoadingExtract: boolean = false;
  isLoadingExtractPoints: boolean = false;
  isPageChange: boolean = false;
  isLoadingPdf: boolean = false;
  isMobile: boolean = false;
  currentUser = this.perfilServ.getCurrentUserInfo();
  pdfSrc: any;
  allItemsBalance: BalanceReport[] = [];
  allItemsExtract: CurrentExtractItem[] = [];
  unsubscribe: Subscription = new Subscription();

  /*Balance */
  balancePageSize: number = 10;
  balanceCurrentPage: number = 0;
  financesData!: Balance;
  dataSourceBalance: MatTableDataSource<BalanceReport> = new MatTableDataSource();
  @ViewChild('balancePaginator') paginator!: MatPaginator;
  displayedColumns: string[] = [
    'balanceType',
    'balanceNumber',
    'balanceDate',
    'balanceOverdueDate',
    'balanceOverdueDays',
    'balanceStatus',
    'balanceValue',
    'balanceDiscont',
    'balanceValueToPay',
    'balanceObsv',
  ];
  /*End Balance */

  /*Extract*/
  extractNull: string = '';
  extractPageSize: number = 10;
  extractCurrentPage: number = 0;
  displayedColumnsExtract = [
    'data',
    'docNumber',
    'docType',
    'sinal',
    'valor',
    'saldo'
  ];
  currentExtract!: CurrentExtract;
  dataSourceExtract: MatTableDataSource<CurrentExtractItem> = new MatTableDataSource();
  @ViewChild('extractPaginator') extractPaginator!: MatPaginator;
  lastUpdateDate: string = '';
  lastUpdateTime: string = '';
  formDates = new FormGroup({
    startDate: new FormControl(new Date(), [Validators.required]),
    endDate: new FormControl(new Date(), [Validators.required]),
  });
  /*End Extract*/

  /*Points */
  currentExtractPoints!: CurrentExtractPoints;
  lastUpdateDatePoints: string = '';
  lastUpdateTimePoints: string = '';
  extractPointsPageSize: number = 10;
  extractCurrentPointsPage: number = 0;
  extractPointsNull: string = '';
  ReadPoints!: number;
  dataSourceExtractPoints: MatTableDataSource<CurrentExtractPointsItem> = new MatTableDataSource();
  @ViewChild('extractPointsPaginator') extractPointsPaginator!: MatPaginator;
  formDatesPoints = new FormGroup({
    startDatePoints: new FormControl(new Date(), [Validators.required]),
    endDatePoints: new FormControl(new Date(), [Validators.required]),
  });
  displayedColumnsExtractPoints = [
    'extractDate',
    'extractNumber',
    'extractType',
    'realizacao',
    'regularizacao',
    'vencimento',
    'estimados',
    'realizados',
    'perdidos',
    'regularizados',
    'equipamentos',
    'saldoadata'
  ];
  /*End points */

  constructor(
    private financesServ: FinancesService,
    private perfilServ: PerfilService,
    private iconServ: CustomIconsService,
    private snackBarService: SnackBarService,
    private dateAdapter: DateAdapter<Date>,
    private encryptSvc: EncryptionService,
    public authService: AuthService,
    public pointsService: PointsService
  ) {
    this.dateAdapter.setLocale('pt-PT');
    this.getBalance();
  }
  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe()
  }

  ngOnInit(): void {
    this.pointsService.readPoints$.subscribe(points => {
      this.ReadPoints = points;
    }),

    window.screen.width <= 960
      ? (this.isMobile = true)
      : (this.isMobile = false);
  }

  getBalance() {
    this.isLoadingBalance = true;
    if (!this.isPageChange) {
      this.balanceCurrentPage = 0;
    }

    this.unsubscribe.add(this.financesServ
      .getBalance(
        this.currentUser.userId,
        this.currentUser.clientId,
        this.balanceCurrentPage + 1,
        this.balancePageSize
      )
      .subscribe({
        next: (resp: Balance) => {
          this.dataSourceBalance.data = [];
          this.financesData = resp;

          if (this.financesData && this.financesData.financeBalances && this.financesData.financeBalances.data.length > 0) {
            this.dataSourceBalance.data = this.financesData.financeBalances.data;
            this.paginator.pageIndex = this.balanceCurrentPage;
            this.paginator.length = this.financesData.financeBalances.totalCount;
          }
        },
        error: (e) => {
          this.isLoadingBalance = false;
          this.isPageChange = false;
          console.error(e)
        },
        complete: () => {
          //to see the loading animation when in cache
          setTimeout(() => {
            this.isLoadingBalance = false;
            this.isPageChange = false;
          }, 500);
        },
      }));
  }

  onPageChangeBalance(event: PageEvent) {
    this.balanceCurrentPage = event.pageIndex;
    this.isPageChange = true;
    this.getBalance();
  }

  onPdfClick(row: any, isBalance: boolean): void {
    this.isLoadingPdf = true;
    const request: RequestPdf = {
      userId: this.currentUser.userId,
      type: isBalance ? (row.balanceType || row.extractDocType) : row.extractDocType,
      number: isBalance ? row.balanceNumber : row.extractNumber,
      date: isBalance ? row.balanceDate : row.extractDate,
      isBalance: isBalance || (!isBalance && this.instanceOfPoints(row))
    };

    this.unsubscribe.add(this.financesServ.getPdf(request).subscribe({
      next: (resp) => {
        if (resp != null) {
          let fileUrl: string = `${environment.faturas_files}${resp}`;
          let win = window.open(fileUrl, "_blank");
        }
        else {
          this.snackBarService.openSnackBar(
            'Ficheiro PDF não encontrado.',
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
        }
      },
      error: (e) => {
        console.log(e);
        this.isLoadingPdf = false;
      },
      complete: () => (this.isLoadingPdf = false),
    }));
  }


  getCurrentExtract(): void {
    this.isLoadingExtract = true;
    if (!this.isPageChange) {
      this.extractCurrentPage = 0;
    }
    const startDate = this.formDates.controls.startDate.value?.toLocaleDateString("en-US");
    const endDate = this.formDates.controls.endDate.value?.toLocaleDateString("en-US");

    this.unsubscribe.add(this.financesServ
      .getCurrentExtract(
        this.currentUser.userId,
        this.currentUser.clientId,
        startDate!,
        endDate!,
        this.extractCurrentPage + 1,
        this.extractPageSize
      )
      .subscribe({
        next: (resp: CurrentExtract) => {
          this.dataSourceExtract.data = [];
          this.currentExtract = resp;
          if (this.currentExtract && this.currentExtract.financeExtractItems && this.currentExtract.financeExtractItems.data.length > 0) {
            this.dataSourceExtract.data = this.currentExtract.financeExtractItems.data;
            this.extractPaginator.pageIndex = this.extractCurrentPage;
            this.extractPaginator.length = this.currentExtract.financeExtractItems.totalCount;
            this.extractNull = '';
          } else {
            this.extractNull = 'Sem dados para datas selecionadas.'
          }
        },
        error: (e) => {
          this.isLoadingExtract = false;
          this.isPageChange = false;
          console.error(e)
        },
        complete: () => {
          //to see the loading animation when in cache
          setTimeout(() => {
            this.isLoadingExtract = false;
            this.isPageChange = false;
          }, 500);
          const data = new Date();
          this.lastUpdateDate = data.toLocaleDateString('pt-PT');
          this.lastUpdateTime = `${data.getHours().toString().padStart(2, '0')}:${data.getMinutes().toString().padStart(2, '0')}`;
        },
      }));
  }


  onPageExtractChange(event: PageEvent) {
    this.extractCurrentPage = event.pageIndex;
    this.isPageChange = true;
    this.getCurrentExtract();
  }

  getCurrentExtractPontos(): void {
    this.isLoadingExtractPoints = true;
    if (!this.isPageChange) {
      this.extractCurrentPointsPage = 0;
    }

    const startDatePontos = this.formDatesPoints.controls.startDatePoints.value?.toLocaleDateString("en-US");
    const endDatePontos = this.formDatesPoints.controls.endDatePoints.value?.toLocaleDateString("en-US");

    this.unsubscribe.add(this.financesServ
      .getCurrentExtractPontos(
        this.currentUser.userId,
        this.currentUser.clientId,
        startDatePontos!,
        endDatePontos!,
        this.extractCurrentPointsPage + 1,
        this.extractPointsPageSize
      )
      .subscribe({
        next: (resp: CurrentExtractPoints) => {
          this.dataSourceExtractPoints.data = [];
          this.currentExtractPoints = resp;

          if (this.currentExtractPoints && this.currentExtractPoints.financeExtractPointsItem && this.currentExtractPoints.financeExtractPointsItem.data.length > 0) {
            this.dataSourceExtractPoints.data = this.currentExtractPoints.financeExtractPointsItem.data;
            this.extractPointsPaginator.pageIndex = this.extractCurrentPointsPage;
            this.extractPointsPaginator.length = this.currentExtractPoints.financeExtractPointsItem.totalCount;
            this.extractPointsNull = '';
          } else {
            this.extractPointsNull = 'Sem dados para datas selecionadas.'
          }
        },
        error: (e) => console.error(e),
        complete: () => {
          //to see the loading animation when in cache
          setTimeout(() => {
            this.isLoadingExtractPoints = false;
            this.isPageChange = false;
          }, 500);
          const data = new Date();
          this.lastUpdateDatePoints = data.toLocaleDateString('pt-PT');
          this.lastUpdateTimePoints = `${data.getHours().toString().padStart(2, '0')}:${data.getMinutes().toString().padStart(2, '0')}`;
        },
      }));
  }


  onPageChangePoints(event: PageEvent) {
    this.extractCurrentPointsPage = event.pageIndex;
    this.isPageChange = true;
    this.getCurrentExtractPontos();
  }

  instanceOfPoints(object: any): object is CurrentExtractPointsItem {
    return 'saldoadata' in object;
  }
}
