/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { TbRegisto } from '../models/tb-registo';

@Injectable({ providedIn: 'root' })
export class RegistosService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiRegistosGet()` */
  static readonly ApiRegistosGetPath = '/api/registos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRegistosGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRegistosGet$Response(
    params: {
      page: number;
      pageSize: number;
      sortColumn?: string;
      sortOrder?: string;
      filterName?: string;
      filterValue?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RegistosService.ApiRegistosGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {"style":"form"});
      rb.query('pageSize', params.pageSize, {"style":"form"});
      rb.query('sortColumn', params.sortColumn, {"style":"form"});
      rb.query('sortOrder', params.sortOrder, {"style":"form"});
      rb.query('filterName', params.filterName, {"style":"form"});
      rb.query('filterValue', params.filterValue, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRegistosGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRegistosGet(
    params: {
      page: number;
      pageSize: number;
      sortColumn?: string;
      sortOrder?: string;
      filterName?: string;
      filterValue?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiRegistosGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRegistosPost()` */
  static readonly ApiRegistosPostPath = '/api/registos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRegistosPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRegistosPost$Response(
    params?: {
      body?: TbRegisto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RegistosService.ApiRegistosPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRegistosPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRegistosPost(
    params?: {
      body?: TbRegisto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiRegistosPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRegistosIdGet()` */
  static readonly ApiRegistosIdGetPath = '/api/registos/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRegistosIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRegistosIdGet$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RegistosService.ApiRegistosIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRegistosIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRegistosIdGet(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiRegistosIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRegistosIdPut()` */
  static readonly ApiRegistosIdPutPath = '/api/registos/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRegistosIdPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRegistosIdPut$Response(
    params: {
      id: string;
      body?: TbRegisto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RegistosService.ApiRegistosIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRegistosIdPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRegistosIdPut(
    params: {
      id: string;
      body?: TbRegisto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiRegistosIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiRegistosIdDelete()` */
  static readonly ApiRegistosIdDeletePath = '/api/registos/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRegistosIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRegistosIdDelete$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RegistosService.ApiRegistosIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRegistosIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRegistosIdDelete(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiRegistosIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
