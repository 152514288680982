import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { AuthService } from 'src/app/api/services';
import { CartService } from 'src/app/api/services/cart.service';
import { PerfilService } from 'src/app/api/services/perfil.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  currentUser!: ProfileDataRequest;
  sessStorageAvatar!: string | null;
  avatar!: string | null | undefined; 
  userPoints: number = 0;
  unsubscribe: Subscription = new Subscription();
  isSideMenuOpen: boolean = false;

  constructor(private cartService: CartService, private perfilService: PerfilService, private router: Router, public authService: AuthService) {
    this.unsubscribe.add(this.cartService.sideMenu$.subscribe((isMenuOpen) => {
      this.isSideMenuOpen = isMenuOpen;
    }));
  }

  ngOnInit(): void {
    this.currentUser = this.perfilService.getCurrentUserInfo();
    this.sessStorageAvatar = sessionStorage.getItem('avatar');
    this.avatar = this.currentUser.avatar != 'NULL' ? this.currentUser.avatar : this.sessStorageAvatar;
    this.unsubscribe.add(this.cartService.userTotalPoints$.subscribe((points) => {
      this.userPoints = points;
    }));
  }

  closeSideMenu(){
    this.cartService.toggleSideMenu();
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }
}
