import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckoutLineModel } from 'src/app/api/models/checkout';

@Component({
  selector: 'app-checkout-dialog',
  templateUrl: './checkout-dialog.component.html',
  styleUrls: ['./checkout-dialog.component.css']
})
export class CheckoutDialogComponent {

  constructor(public dialogRef: MatDialogRef<CheckoutDialogComponent>) { }

  HandleAction(action : boolean){
    this.dialogRef.close(action);
  }
}
