import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { SideProductsService } from '../../api/services/side-products.service';
import { ProfileDataRequest } from '../../api/models/perfil';
import { ProductResponse } from '../../api/services/side-products.service';
import {ShopItemModel} from "../../api/models/shop-item.model";
import {of, Subscription} from "rxjs";
import {CartService} from "../../api/services/cart.service";
import {SnackBarService} from "../../api/services/snack-bar.service";

@Component({
  selector: 'app-side-bar-products',
  templateUrl: './side-bar-products.component.html',
  styleUrls: ['./side-bar-products.component.css']
})
export class SideBarProductsComponent implements OnInit {
  activeTab: string = 'semanal';
  activeTabLab: string = 'sugestoes';
  @Output() sidebarToggle = new EventEmitter<boolean>();
  @Input() currentUser!: ProfileDataRequest;
  isSidebarOpen = false;
  @Input() product!: ShopItemModel;
  private productQueue: ShopItemModel[] = [];
  isProcessing = false;
  isCartButtonActive: boolean = true;
  unsubscribe: Subscription = new Subscription();

  products: ProductResponse = {
    semanal: [],
    mensal: [],
    quinzenal: [],
    sugestoes: []
  };

  constructor(
    private productService: SideProductsService,
    private cartService: CartService,
    private snackBarService: SnackBarService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    if (!this.currentUser || !this.currentUser.clientId) {
      return;
    }
    this.fetchProducts();
    console.log(this.currentUser);
  }

  fetchProducts(): void {
    this.productService.getProducts(this.currentUser.clientId,this.currentUser.userId).subscribe({
      next: (response) => {
        console.log('[Products Fetched]', response);
        this.products = response;
      },
      error: (error) => {
        console.error('[Error Fetching Products]', error);
      },
    });
  }


setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  setActiveTabLab(tab: string) {
    this.activeTabLab = tab;
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.sidebarToggle.emit(this.isSidebarOpen);
  }

  removeQuantityClick(shopItem: ShopItemModel, category: keyof ProductResponse): void {
    if (!this.products[category]) return;
  
    this.products[category] = this.products[category].map(product =>
      product.reference === shopItem.reference
        ? { ...product, productQuantity: Math.max((product.productQuantity || 0) - 1, 0) }
        : product
    );
  
    this.cdr.detectChanges();
    this.productQueue.push(shopItem);
    this.startProcessingRemove();
    this.finalizeUpdate(true);
  }  

  startProcessingRemove(): void {
    const shopItem = this.productQueue[this.productQueue.length - 1];
    if (shopItem && shopItem.productQuantity > 0) {
      this.unsubscribe.add(
        this.cartService
          .decreaseCartMethod(shopItem, !this.isCartButtonActive, false)
          .subscribe((operation) => {
            setTimeout(() => {
              if (operation) {
                this.snackBarService.openSnackBar(
                  'Produto removido do carrinho!',
                  '#0AA174',
                  '../../assets/Icons/correct.png'
                );
              }
              this.isProcessing = false;
            }, 250);
          })
      );
    }
  }

  addQuantityClick(shopItem: ShopItemModel, category: keyof ProductResponse): void {
    if (!this.products[category]) return; // Ensure category exists
  
    // Update the correct array
    this.products[category] = this.products[category].map(product =>
      product.reference === shopItem.reference
        ? { ...product, productQuantity: (product.productQuantity || 0) + 1 }
        : product
    );
  
    this.cdr.detectChanges();
    this.productQueue.push(shopItem);
    this.startProcessing();
    this.finalizeUpdate(true);
  }  

  private startProcessing(): void {
    if (this.productQueue.length === 0) {
      this.isProcessing = false;
      return;
    }

    const shopItem = this.productQueue[this.productQueue.length - 1];
    {
      this.unsubscribe.add(
        this.cartService
          .increaseCartMethod(shopItem!, !this.isCartButtonActive, false)
          .subscribe()
      );
    }
  }

  private finalizeUpdate(isAdding: boolean): void {
    this.isProcessing = true;
    const lastProduct = this.productQueue[this.productQueue.length - 1];
    if (lastProduct) {
      if (isAdding) {
        this.unsubscribe.add(
          this.cartService.increaseCartMethod(lastProduct, !this.isCartButtonActive, true).subscribe({
            next: (resp) => {
              if (resp) {
                this.snackBarService.openSnackBar(
                  'Produto adicionado ao carrinho!',
                  '#0AA174',
                  '../../assets/Icons/correct.png'
                );
              }
              this.isProcessing = false;
            }
          })
        );
      }
      else {
        this.unsubscribe.add(
          this.cartService
            .decreaseCartMethod(lastProduct, !this.isCartButtonActive, true)
            .subscribe((operation) => {
              setTimeout(() => {
                if (operation) {
                  this.snackBarService.openSnackBar(
                    'Produto removido do carrinho!',
                    '#0AA174',
                    '../../assets/Icons/correct.png'
                  );
                }
                this.isProcessing = false;
              }, 250);
            })
        );
      }
    }
  }

  isControlKey(event: KeyboardEvent): boolean {
    const controlKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'];
    return controlKeys.includes(event.key);
  }

  onEnterPress(inputElement: HTMLInputElement): void {
    inputElement.blur();
  }

  onKeyDown(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    if (+currentValue >= 1000 && !this.isControlKey(event)) {
      event.preventDefault();
    }
  }
  
  changeQuantity(
    newValue: string,
    shopItem: ShopItemModel,
    oldValue: number,
    category: keyof ProductResponse
  ): void {
    let numericValue = parseInt(newValue, 10);
  
    // Prevent invalid or unchanged values
    if (numericValue === oldValue || isNaN(numericValue) || numericValue < 0) {
      shopItem.productQuantity = oldValue;
      this.cdr.detectChanges();
      return;
    }
  
    // Ensure the category exists
    if (!this.products[category]) return;
  
    // Update the correct product in the list
    this.products[category] = this.products[category].map(product =>
      product.reference === shopItem.reference
        ? { ...product, productQuantity: numericValue }
        : product
    );
  
    this.cdr.detectChanges();
  
    // Send update request to cart service
    this.unsubscribe.add(
      this.cartService
        .changeQuantityCartMethod(shopItem, !this.isCartButtonActive, numericValue)
        .subscribe((operation) => {
          if (operation) {
            if (oldValue > 0 && numericValue > 0) {
              this.snackBarService.openSnackBar(
                'Quantidade do produto alterada no carrinho!',
                '#0AA174',
                '../../assets/Icons/correct.png'
              );
            }
            if (numericValue === 0) {
              this.snackBarService.openSnackBar(
                'Produto removido com sucesso!',
                '#0AA174',
                '../../assets/Icons/correct.png'
              );
            }
            if (oldValue === 0 && numericValue > 0) {
              this.snackBarService.openSnackBar(
                'Produto adicionado com sucesso!',
                '#0AA174',
                '../../assets/Icons/correct.png'
              );
            }
          }
        })
    );
  }
  

  addAllProductsToCart(products: ShopItemModel[]): void {
    const addObservables = products.map((product) =>
      this.cartService.increaseCartMethod(product, !this.isCartButtonActive, false)
    );

    this.unsubscribe.add(
      of(...addObservables).subscribe({
        next: (observable) => observable.subscribe(),
        error: () =>
          this.snackBarService.openSnackBar(
            'Erro a adicionar lista de produtos',
            '#F97066',
            '../../assets/Icons/Warning-icon.png'
          ),
        complete: () =>
          this.snackBarService.openSnackBar(
          'Produtos adicionadoss com sucesso!',
          '#0AA174',
          '../../assets/Icons/correct.png'
        )
      })
    );
  }
}
