<div class="bg-color">
    <div>
        <h1>
            <nav class="d-flex flex-row gap-10">
                <span (click)="cancelOperationButton()">
                    <img class="icon" src="../../../../assets/Icons/ArrowLeft.png">
                </span>
                <span  class="breadcrumb-title">
                        {{group.description}}
                </span>
                <span>
                    <img class="icon" src="../../../../assets/Icons/ArrowRight.png">
                </span>
                <span>
                    Edição {{group.description}}
                </span>
            </nav>
        </h1>
    </div>
        <form [formGroup]="groupForm">
            <div class="w-100 d-flex flex-column" style="gap:32px">
                <div class="d-flex flex-column">
                  <span class="text-description">Nome de Grupo <span class="required">*</span></span>
                  <mat-form-field>
                    <input matInput type="text" formControlName="groupName" (input)="updateGroup()">
                  </mat-form-field>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-description">Produtos <span class="required">*</span></span>
                  <app-search-input-products
                    [isMultiple]="true"
                    [plist]="plist" 
                    [searchControl]="myControl" 
                    [selectProducts]="selectProducts" 
                    [selectedItems]="selectedItens"
                    (selectionChange)="updateGroup()">
                  </app-search-input-products>
                </div>
                <div class="d-flex flex-row w-100" style="gap:98px">
                  <div class="d-flex flex-column w-50">
                    <label class="text-description">Imagem p/ Header</label>
                    <mat-form-field>
                      <ngx-mat-file-input [formControl]="imgHeader" [accept]="imgGroupType" (input)="updateGroup()">
                      </ngx-mat-file-input>
                    </mat-form-field>
                  </div>
                  <div class="d-flex flex-column w-50">
                    <label class="text-description">Imagem p/ Grupo</label>
                    <mat-form-field>
                      <ngx-mat-file-input [formControl]="imgGroup" [accept]="imgGroupType" (input)="updateGroup()">
                      </ngx-mat-file-input>
                    </mat-form-field>
                  </div>
                </div>
            </div>
        </form>
</div>