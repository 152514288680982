<div class="modal-prod">
  <div class="ticket-counter">
    <span class="bonus-count">{{ productData?.bonus?.length }}</span>
    <img src="../../../../assets/Icons/ticket.png" alt="ticket">
  </div>
  <div *ngFor="let bonusItem of productData?.bonus" class="d-flex flex-column data">
    <span class="justified">
      <span class="justi-title">Produto: </span>
      <span>{{ bonusItem.description }}</span>
    </span>
    <span class="justified">
      <span class="justi-title">Referencia: </span>
      <span>{{ bonusItem.referenceBonus }}</span>
    </span>
    <span class="justified">
      <span class="justi-title">Bonus: </span>
      <span>{{ bonusItem.bonus }}</span>
    </span>
    <div class="line"></div>
  </div>
</div>
