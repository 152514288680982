import { Component } from '@angular/core';
import { ContactsInfo } from 'src/app/api/models/contacts';
import { environment } from '../../../environments/environment';
import { CustomIconsService } from 'src/app/api/services/custom-icons.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
})
export class ContactsComponent {
  isLoading: boolean = false;
  contactsInfo!: ContactsInfo;

  constructor(private iconService: CustomIconsService) {}

  ngOnInit() {
    this.contactsInfo = environment.contacts_info;
  }
}
