<div class="d-flex flex-row">
    <div>
        <app-dashboard-side-menu></app-dashboard-side-menu>
    </div>
    <div class="p-content d-flex flex-column">
        <app-dashboard-top-menu></app-dashboard-top-menu>
        <!--!Necessario estar aqui para não desaparecer quando faz o loading-->
        <a #downloadLink style="display: none;"></a>
        <!-- !!Content to be displayed when loading is complete -->
        <div *ngIf="loading; else contentLoaded" class="loading-animation">
            <app-loading></app-loading>
        </div>
        <ng-template #contentLoaded>
            <div class="template-content">
                <div class="w-100 d-flex flex-column justify-content-start">
                    <div class="d-flex">
                        <h2>Importação de produtos</h2>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                        <form>
                            <div class="row">
                                <div class="col-md-6 col-xs-12" style="max-width: 440px;">
                                    <p>Referência <span>*</span> 
                                        <span *ngIf="mandatory"> Obrigatório.</span>
                                        <span *ngIf="!valid && !mandatory"> Só pode conter números separados por vírgula, sem espaços. Ex.: 0001,0002</span>
                                    </p>
                                    <mat-form-field class="w-100">
                                        <input matInput name="referencia" [(ngModel)]="referencia" placeholder="Ex. 0001,0002,0004">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-xs-12" style="max-width: 440px;">
                                    <p>Número de Referências</p>
                                    <mat-form-field class="w-100">
                                        <input matInput type="number" min="1" name="numReferencias"
                                            [(ngModel)]="numReferencias">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="mt-3" style="width: 440px;">
                                <!--
                                <label class="form-control checkbox-label">
                                    <input type="checkbox" name="checkbox" />
                                    <span class="pl-1">Atualizar ficheiro JS</span>
                                    <button type="button" class="btn btn-upload ml-3">
                                        <span>Upload Excel</span>
                                        <img src="../../../../assets/Icons/upload.png" alt="Upload">
                                    </button>
                                </label>
                                -->

                                <label class="form-control checkbox-label">
                                    <input type="checkbox" name="checkbox" (change)="xmlStatusChanged($event)" />
                                    <span class="pl-1">Ver XML</span>
                                </label>

                                <label class="form-control checkbox-label">
                                    <input type="checkbox" name="checkbox" (change)="fileStatusChanged($event)" />
                                    <span class="pl-1">Ficheiro "{{fileDate}}"</span>
                                    <button type="button" class="btn btn-download ml-3" (click)="downloadFile()">
                                        <span>Download</span>
                                        <img src="../../../../assets/Icons/download.png" alt="Download">
                                    </button>
                                </label>
                            </div>
                            <button class="btn-processar mt-4" (click)="processar()" [disabled]="loading">
                                <span>Processar</span>
                                <img src="../../../../assets/Icons/check-line.png" alt="Check">
                            </button>
                        </form>

                        <!--*REGISTOS IMPORTADOS-->
                        <div *ngIf="!loading && data">
                            <div *ngIf="data.length > 0 && data[0].statusCode === 200">
                                <h2 class="mt-4">Resultado:</h2>
                                <div class="response-container">
                                    <div class="file-container" *ngFor="let produto of data">
                                        <div class="file-info">
                                            <p>Produto #{{ produto.reference }} <span *ngIf="produto.errorMessage">| {{produto.errorMessage}}</span></p>
                                            <div *ngIf="xml">
                                                <br>
                                                <p><span>Pedido:</span> {{ produto.pedido }}</p>
                                                <br>
                                                <p><span>Resposta:</span> {{ produto.resposta }}</p>
                                                <br>
                                                <p><span>Pedido Tabela Preço:</span> {{ produto.pedidoPreco }}</p>
                                                <br>
                                                <p><span>Resposta Tabela Preço:</span> {{ produto.respostaPreco }}</p>
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <app-footer></app-footer>
    </div>
</div>