import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import {
  DeliveryAddress,
  Encomenda,
  EncomendaRequest,
  PendingOrders,
  PendingOrdersRequest,
  ProductEncomenda,
  ProductEncomendaInfo,
  ProductEncomendaInfoRequest,
  RemovalPendingOrders,
} from './encomendas.model';
import { EncomendasService } from './encomendas.service';
import { formatDate } from '@angular/common';
import { PerfilService } from '../api/services/perfil.service';
import { CustomIconsService } from '../api/services/custom-icons.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SnackBarService } from '../api/services/snack-bar.service';
import { RequestPdf } from '../api/models/finances';
import { FinancesService } from '../api/services/finances.service';
import { AuthService } from '../api/services';
import { ProfileDataRequest } from '../api/models/perfil';

@Component({
  selector: 'app-encomendas',
  templateUrl: './encomendas.component.html',
  styleUrls: ['./encomendas.component.css'],
})
export class EncomendasComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  isLoadingPdf: boolean = false;
  pageChanged: boolean = false;
  isLoadingPending: boolean = false;
  startDate: Date = new Date();
  endDate: Date = new Date();
  queryPage: number = 0;
  querySize: number = 10;
  queryPagePending: number = 0;
  querySizePending: number = 10;
  encomendaDataSource: MatTableDataSource<Encomenda> = new MatTableDataSource();
  pendingOrdersDataSource: MatTableDataSource<PendingOrders> =
    new MatTableDataSource();
  deliveryAddresses: DeliveryAddress[] = [];
  selectedAddressId: string = '';
  loggedUser = this.perfilService.getCurrentUserInfo();
  selectedRows: any[] = [];

  displayedColumns: string[] = [
    'encomendaNr',
    'dataEnc',
    'dataAvi',
    'valorEnc',
    'valorNaoAv',
    'ValorFat',
    'actions',
  ];
  displayedColumnsPending: string[] = [
    'encomendaNr',
    'ref',
    'produto',
    'quantidade',
    'regularizado',
    'bonus',
    'stock',
    'eliminar',
  ];

  @ViewChild('historicoPaginator') paginator!: MatPaginator;
  @ViewChild('pendingPaginator') paginatorPending!: MatPaginator;
  unsubscribe: Subscription = new Subscription();
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  encomendaInfo!: Encomenda | null;
  encomendaProdutos!: ProductEncomendaInfo;
  displayedEncomendaProdutos: ProductEncomenda[] = [];
  encomendasTotal: number = 0;
  pendingTotal: number = 0;
  inputText: string = '';
  csvData!: string;
  infoPage: number = 1;
  infoPageSize: number = 5;

  constructor(
    private encomendaService: EncomendasService,
    private perfilService: PerfilService,
    private iconsService: CustomIconsService,
    private dateAdapter: DateAdapter<Date>,
    private router: Router,
    private route: ActivatedRoute,
    private snack: SnackBarService,
    private financesServ: FinancesService,
    public authService: AuthService
  ) {
    this.listDeliverAddresses();
    this.dateAdapter.setLocale('pt-PT');
  }
  ngOnInit(): void {}

  ngAfterViewInit() {
    this.encomendaDataSource.paginator = this.paginator;
    this.pendingOrdersDataSource.paginator = this.paginatorPending;
    this.route.queryParams.subscribe((params) => {
      const tabToNavigate = params['tab'];
      if (tabToNavigate === 'pendentes' && this.tabGroup) {
        this.tabGroup.selectedIndex = 1;
      }
    });
  }

  onOrdersRequest() {
    this.isLoading = true;
    const encomendaRequest: EncomendaRequest = {
      Page: this.queryPage,
      PageSize: this.querySize,
      userId: this.loggedUser.userId,
      clientNumber: this.loggedUser.clientId,
      startDate: this.formatDateToString(this.startDate),
      endDate: this.formatDateToString(this.endDate),
    };

    this.unsubscribe.add(
      this.encomendaService.getOrders(encomendaRequest).subscribe((resp) => {
        this.encomendaDataSource.data = resp.encomendas.data;
        this.csvData = resp.csv;
        setTimeout(() => {
          this.paginator.pageIndex = this.queryPage;
          this.paginator.length = resp.encomendas.totalCount;
          this.encomendasTotal = resp.encomendas.totalCount;
        });
        this.isLoading = false;
      })
    );
  }

  pageOrdersChanged(event: PageEvent) {
    this.querySize = event.pageSize;
    this.queryPage = event.pageIndex;
    this.onOrdersRequest();
  }

  onPendingOrdersRequest() {
    this.isLoadingPending = true;
    const pendingOrdersRequest: PendingOrdersRequest = {
      Page: this.queryPagePending + 1,
      PageSize: this.querySizePending,
      userId: this.loggedUser.userId,
      numClient: this.loggedUser.clientId,
      codigoMorada: this.selectedAddressId,
    };

    this.unsubscribe.add(
      this.encomendaService.getPendingOrders(pendingOrdersRequest).subscribe(
        (resp) => {
          this.pendingOrdersDataSource.data = resp.encomendas.data;
          setTimeout(() => {
            this.paginatorPending.pageIndex = this.queryPagePending;
            this.paginatorPending.length = resp.encomendas.totalCount;
            this.pendingTotal = resp.encomendas.totalCount;
          });
          this.isLoadingPending = false;
        },
        (error) => {
          console.error('Error fetching pending orders:', error);
        }
      )
    );
  }

  pagePendingChanged(event: PageEvent) {
    this.querySizePending = event.pageSize;
    this.queryPagePending = event.pageIndex;
    this.onPendingOrdersRequest();
  }

  listDeliverAddresses() {
    let clientId = this.loggedUser.clientId.toString();
    
    let loggedUserId = this.loggedUser.userId
    this.unsubscribe.add(
      this.encomendaService.getDeliveryAddress(clientId, loggedUserId).subscribe(
        (resp) => {
          this.deliveryAddresses = resp.addresses;
        },
        (error) => {
          console.log('Error fetching delivery addresses: ', error);
        }
      )
    );
  }

  convertDate(inputDate: string): string {
    // Convert the month abbreviation to a numeric representation
    const monthAbbreviations = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];
    const monthIndex = monthAbbreviations.findIndex((abbr) =>
      inputDate.includes(abbr)
    );
    const monthNumeric =
      monthIndex >= 0 ? (monthIndex + 1).toString().padStart(2, '0') : '';

    // Extract day and year from the inputDate
    const day = inputDate.slice(0, 2);
    const year = inputDate.slice(inputDate.length - 4);

    // Form the final date string in "dd-MM-yyyy" format
    return `${day}-${monthNumeric}-${year}`;
  }

  formatDateToString(date: Date): string {
    return formatDate(date, 'MM-dd-yyyy', 'en-US'); // Format the date
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }

  goToEncomendaInfo(element: Encomenda): void {
    this.isLoading = true;
    this.encomendaInfo = element;
    const requestEncomenda: ProductEncomendaInfoRequest = 
    {
      clientNumber: this.loggedUser.clientId,
      userId: this.loggedUser.userId,
      encomendaNumber: element.numero,
      page: this.infoPage,
      pageSize: this.infoPageSize,
    }
    this.encomendaService.getOrderProducts(requestEncomenda).subscribe({
      next: (resp) => {
        this.encomendaProdutos = resp;
        this.displayedEncomendaProdutos = [...this.displayedEncomendaProdutos, ...resp.productsEncomenda.data];
      },
      error: (e) => console.log(e),
      complete: () => {
        this.isLoading = false;
        this.pageChanged = false;
      }
    });
  }

  onPdfClick(): void {
    this.isLoadingPdf = true;
    const request: RequestPdf = {
      userId: this.loggedUser.userId,
      type: "0",
      number: this.encomendaProdutos.fatura,
      date: "0",
      isBalance: false
    };

    this.unsubscribe.add(this.financesServ.getPdf(request).subscribe({
      next: (resp) => {
        if (resp != null) {
          let fileUrl: string = `${environment.faturas_files}${resp}`;
          let win = window.open(fileUrl, "_blank");
        }
        else {
          this.snack.openSnackBar(
            'Ficheiro PDF não encontrado.',
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
        }
      },
      error: (error: Error) => {
        console.error(error);
        this.isLoadingPdf = false;
      },
      complete: () => (this.isLoadingPdf = false),
    }));
  }

  goToOrders(): void {
    this.encomendaInfo = null;
    this.displayedEncomendaProdutos = [];

    setTimeout(() => {
      this.paginator.pageIndex = this.queryPage;
      this.paginator.length = this.encomendasTotal;
      this.paginatorPending.pageIndex = this.queryPagePending;
      this.paginatorPending.length = this.pendingTotal;
    });
  }

  loadMore(): void {
    this.infoPage += 1;
    this.infoPageSize = 5;
    this.pageChanged = true;
    this.goToEncomendaInfo(this.encomendaInfo!);
  }

  productImage(product: ProductEncomenda): string {
    return `${environment.shop_images}${product.imageId}`;
  }

  downloadCsv() {
    if (this.csvData != null) {
      this.isLoading = true;
      // Trigger the file download
      const blob = new Blob([this.csvData], { type: 'text/csv' });
      const csvUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = csvUrl;
      const sData = this.startDate
        .toISOString()
        .split('T')[0]
        .replace('-', '')
        .replace('-', '');
      const eData = this.endDate
        .toISOString()
        .split('T')[0]
        .replace('-', '')
        .replace('-', '');
      link.download = `encomendas_${sData}_${eData}.csv`;
      link.click();
      this.isLoading = false;
    }
  }

  /* onSlideToggleChange(event: MatSlideToggleChange, row: any) {
    if (event.checked) {
      console.log('Row data:', row);
    }
  } */
  toggleRowSelection(row: any) {
    const index = this.selectedRows.indexOf(row);
    if (index === -1) {
      // If row is not in the array, add it
      this.selectedRows.push(row);
    } else {
      // If row is already in the array, remove it
      this.selectedRows.splice(index, 1);
    }
  }

  logSelectedRows() {
    this.isLoading = true;
    const request: RemovalPendingOrders = {
      clientNum: this.loggedUser.clientId,
      registoNum: this.loggedUser.userId,
      clientAddress: this.selectedAddressId,
      observations: this.inputText,
      pendingOrders: this.selectedRows,
    }

    this.unsubscribe.add(
      this.encomendaService.removePendingOrders(request)
        .subscribe({
          next: (resp) => {
            if(resp) {
              this.snack.openSnackBar(
                'Pedido de remoção foi enviado com sucesso',
                '#0AA174',
                '../../../assets/Icons/correct.png'
              );
            }
            else {
              this.snack.openSnackBar(
                'Erro ao enviar pedido',
                '#F97066',
                '../../assets/Icons/Warning-icon.png'
              );
            }
          },
          error: (error) => {
            this.isLoading = false;
            this.snack.openSnackBar(
              'Erro ao enviar pedido',
              '#F97066',
              '../../assets/Icons/Warning-icon.png'
            );
          },
          complete: () => {
            this.isLoading = false;
            if(this.tabGroup)
              this.tabGroup.selectedIndex = 1;
          }
        })  
    );
  }
}
