<div class="main-page">

  <div class="left-side">
    <img src="../../../assets/images/bio2Logo.png" alt="Logótipo da Bio 2" />
  </div>

  <div class="right-side">
    <img src="../../assets/images/bio2Logo.png" alt="Logótipo da Bio 2" class="logoMobile">
    <div *ngIf="isLoading; else contentLoaded" class="loading-animation">
      <app-loading></app-loading>
    </div>
    <ng-template #contentLoaded>
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="d-flex flex-column">
          <span class="title">Bem-vindo à Loja Bio2!</span>
          <span class="semiTitle">Para fazer login, insira as suas credenciais:</span>
        </div>
        <div class="d-flex flex-column">
          <label class="user-pass" for="username">Username
          </label>
          <input type="text" id="username" formControlName="username" required
            [class.error-border]="loginForm.controls['username'].invalid && loginForm.controls['username'].touched" />
        </div>
        <div *ngIf="loginForm.controls['username'].invalid && (loginForm.controls['username'].touched)"
          style="color: red; padding-top: 8px;">
          É necessário o nome de utilizador.
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row justify-content-between align-items-end">
            <label class="user-pass" for="password">Password
            </label>
            <span routerLink="/recover-password" class="link">Recuperar password</span>
          </div>
          <div class="password-input-wrapper">
            <input type="{{ showPassword ? 'text' : 'password' }}" id="password" formControlName="password" required
              [class.error-border]="loginForm.controls['password'].invalid && loginForm.controls['password'].touched" />
            <div class="eye-icon" [style.background-image]="'url(../../assets/Icons/Eye.png)'" aria-label="Mostrar/Esconder password"
              (click)="togglePasswordVisibility()">
            </div>
          </div>
        </div>
        <div *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched"
          style="color: red; padding-top: 8px;">
          É necessária uma palavra-passe.
        </div>
        <span routerLink="/recover-password" class="linkMobile">Recuperar password</span>
        <button type="submit" [disabled]="loginForm.invalid || isLoading === true">
          <span>Login</span>
          <img src="../../assets/Icons/smallRow.png" alt="Seta para avançar" class="smallRow">
        </button>
      </form>
    </ng-template>
  </div>
</div>
