<div class="d-flex flex-row">
    <div>
        <app-dashboard-side-menu></app-dashboard-side-menu>
    </div>
    <div class="p-content d-flex flex-column w-100">
        <app-dashboard-top-menu></app-dashboard-top-menu>
        <!-- !!Loading animation -->
        <div *ngIf="loading; else contentLoaded" class="loading-animation">
            <app-loading></app-loading>
        </div>
        <ng-template #contentLoaded>
            <div class="template-content">
                <div class="w-100 d-flex flex-column justify-content-start">
                    <div style="max-width: 440px;">
                        <p>Referências <span>*</span>
                            <span *ngIf="mandatory"> Obrigatório.</span>
                            <span *ngIf="!valid && !mandatory"> Só pode conter números separados por vírgula, sem
                                espaços.
                                Ex.: 0001,0002</span>
                        </p>
                        <mat-form-field class="w-100">
                            <input matInput name="referencia" [(ngModel)]="referencias"
                                placeholder="Ex. 0001,0002,0004">
                        </mat-form-field>

                        <button class="btn-processar mt-4" (click)="processar()" [disabled]="loading">
                            <span>Processar</span>
                            <img src="../../../../assets/Icons/check-line.png" alt="Check">
                        </button>
                    </div>

                    <div class="mt-4">
                        <mat-accordion>
                            <div>
                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="d-flex title-justify-content">
                                            <div>
                                                <p style="font-family: Poppins; margin-top: 10px;">Produtos em destaque
                                                </p>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <table class="w-100">
                                        <tr>
                                            <th class="w-column bordered-cell no-border-left">Referencia</th>
                                            <th class="w-column bordered-cell">Descrição do Produto</th>
                                            <th class="bordered-cell no-border-right"> </th>
                                        </tr>
                                        <ng-container *ngIf="emDestaque.length > 0">
                                            <tr *ngFor="let product of emDestaque">
                                                <td class="w-column bordered-cell no-border-left">{{ product.reference
                                                    }}</td>
                                                <td class="w-column bordered-cell">{{ product.decription }}</td>
                                                <td class="buttons-content bordered-cell no-border-right">
                                                    <button class="button" (click)="openModal(product.reference)">
                                                        <img src="../../../assets/Icons/trash.png" width="20px"
                                                            height="20px" />
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </table>
                                </mat-expansion-panel>
                            </div>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </ng-template>
        <app-footer></app-footer>
    </div>
</div>