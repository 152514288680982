import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ShopItemModel } from '../models/shop-item.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';

export interface ProductResponse {
  semanal: ShopItemModel[];
  mensal: ShopItemModel[];
  quinzenal: ShopItemModel[];
  sugestoes: ShopItemModel[];
}

@Injectable({
  providedIn: 'root',
})
export class SideProductsService {

  constructor(private config: ApiConfiguration, private http: HttpClient) {}

  private apiProductPath = this.config.rootUrl + '/api/interface/products/top-produtos';
  
  getProducts(clientId: string, userId: string): Observable<ProductResponse> {
    const params = new HttpParams().set('clientId', clientId).set('userId', userId);
    return this.http.get<ProductResponse>(this.apiProductPath, { params });
  }

}
