import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core'
import { ContactsService } from 'src/app/api/services/contacts.service';
import { SendEmail } from 'src/app/api/models/email';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';

@Component({
  selector: 'app-contact-diaglo',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.css']
})
export class ContactDialogComponent {

  submitted: boolean = false;
  myForm!: FormGroup;
  recipient: string = '';

  constructor(private contact: ContactsService, private snackBarService: SnackBarService, private fb: FormBuilder, private dialogRef: MatDialogRef<ContactDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.recipient = data.email;
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      sender: new FormControl('', [Validators.required, Validators.email]),
      body: new FormControl('', [Validators.required,]),
      subject: new FormControl('', [Validators.required,])
    });
  }

  send() {
    this.submitted = true;
    let emailObj: SendEmail = this.myForm.value;
    emailObj.recipient = this.recipient;

    if (this.myForm.valid) {
      this.contact.sendEmail(emailObj).subscribe({
        next: (resp) => {
          if (resp === 'Email Sended') {
            this.snackBarService.openSnackBar(
              'Email enviado com sucesso',
              '#0AA174',
              '../../../assets/Icons/correct.png'
            );
            this.dialogRef.close();
          }
        },
        error: (e) => {
          this.snackBarService.openSnackBar(
            `Erro ao enviar o email. ${e}`,
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
          console.error(e);
        },
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
