import { SubLoginService } from './../../api/services/sub-login.service';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, map, startWith } from 'rxjs';
import { TbRegisto } from 'src/app/api/models';
import { User } from 'src/app/api/models/getUsers';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import {
  DeliveryAddress,
  SearchStatsModel,
  StatisticsViewModel,
  TbLaboratorio,
  TbProduto,
  TbSub,
  YearValues,
} from 'src/app/api/models/statistics';
import { TbCliente } from 'src/app/api/models/tb-cliente';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { StatisticsService } from 'src/app/api/services/statistics.service';
import { StatisticsComponent } from 'src/app/Main/statistics/statistics.component';

@Component({
  selector: 'app-stats-filter',
  templateUrl: './stats-filter.component.html',
  styleUrls: ['./stats-filter.component.css'],
})
export class StatsFilterComponent {
  profileRequest: ProfileDataRequest = this.perfilService.getCurrentUserInfo();
  @Output() isOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchStatsEvent = new EventEmitter<SearchStatsModel>();
  @Input() statsViewModel!: StatisticsViewModel;
  @Input() previousSearchModel: SearchStatsModel = {
    userId: this.profileRequest.userId,
    clientEntityId: this.profileRequest.clientId,
    productId: '',
    laboratoryId: '',
    substanceId: '',
    addressId: '',
    startDate: '',
    endDate: '',
    year: '',
  };

  isLoading: boolean = false;
  // statsViewModel: any;
  productsList: TbProduto[];
  substancesList: TbSub[];
  laboratoriesList: TbLaboratorio[];
  deliveryAddressList: DeliveryAddress[];
  yearsList: number[];

  sessionClientNumber = this.profileRequest.clientId;

  selectedProduct?: TbProduto;
  selectedSubstance?: TbSub;
  selectedLaboratory?: TbLaboratorio;
  selectedUserSub?: User;
  selectedAddress?: DeliveryAddress;
  activeProduct: string = '';
  activeSubstance: string = '';
  activeLaboratory: string = '';
  selectedYear: any;
  startDate?: Date;
  endDate?: Date;

  searchStatsModel: SearchStatsModel = {
    userId: '',
    clientEntityId: '',
    productId: '',
    laboratoryId: '',
    substanceId: '',
    addressId: '',
    startDate: '',
    endDate: '',
    year: '',
  };

  productControl = new FormControl<string | TbProduto>('');
  filteredProductOptions!: Observable<TbProduto[]>;

  laboratoryControl = new FormControl<string | TbLaboratorio>('');
  filteredLaboratoryOptions!: Observable<TbLaboratorio[]>;

  substanceControl = new FormControl<string | TbSub>('');
  filteredSubstanceOptions!: Observable<TbSub[]>;

  myControl = new FormControl<string | User>('');
  filteredOptions!: Observable<User[]>;
  users: User[] = [];

  constructor(
    private perfilService: PerfilService,
    private statisticsService: StatisticsService,
    private snackBarService: SnackBarService,
    private SubLoginService: SubLoginService
  ) {
    // this.productInput = '';
    this.productsList = [];
    this.substancesList = [];
    this.laboratoriesList = [];
    this.deliveryAddressList = [];
    this.yearsList = [];
    // this.GetStatisticsViewModel(this.sessionClientNumber);
  }

  async ngOnInit() {
    // Monitors everytime there is a text change and filters product list
    this.filteredProductOptions = this.productControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        let name = typeof value === 'string' ? value : value?.memoria;
        return name
          ? this.FilterProduct(name as string)
          : this.productsList.slice();
      })
    );

    // Monitors everytime there is a text change and filters substance list
    this.filteredSubstanceOptions = this.substanceControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        let name = typeof value === 'string' ? value : value?.nome;
        return name
          ? this.FilterSubstance(name as string)
          : this.substancesList.slice();
      })
    );

    const currentUser = this.perfilService.getCurrentUserInfo();
    if (currentUser.role === 'Administrador' && currentUser.username.toLocaleLowerCase().startsWith('l')) {
      const clientId = currentUser.clientId;
      const clientName = currentUser.name;
      this.laboratoryControl.setValue({ labName: clientName, id: clientId });
      this.laboratoryControl.disable();
      this.selectedLaboratory = {
        id: currentUser.clientId,
        labName: currentUser.name,
      };
    }

    this.filteredLaboratoryOptions = this.laboratoryControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        let name = typeof value === 'string' ? value : value?.labName;
        return name
          ? this.FilterLaboratory(name as string)
          : this.laboratoriesList;
      })
    );

    await this.SetupFilters(this.statsViewModel);

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.users.slice();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['previousSearchModel']) {
      let searchModel = changes['previousSearchModel'].currentValue;

      if (searchModel) {
        this.previousSearchModel = changes['previousSearchModel'].currentValue;
      }
    }
  }

  // FUNCTION : Based on data retrieved of the view model, updates the filters with data
  async SetupFilters(viewModel: StatisticsViewModel) {
    this.productsList = viewModel.productsList || [];
    this.substancesList = viewModel.substancesList || [];
    this.laboratoriesList = viewModel.laboratoriesList || [];
    this.deliveryAddressList = viewModel.deliveryAddressesList || [];
    this.yearsList = [];

    let earliastYear: number = 2019;
    let currentYear = new Date().getFullYear();

    while (currentYear >= earliastYear) {
      this.yearsList.push(currentYear);

      currentYear--;
    }

    this.selectedAddress = this.deliveryAddressList[0];
    this.selectedYear = this.yearsList[0];
  }

  CloseMenu(): void {
    this.isOpen.emit(false);
  }

  //Type L logic needs to be checked!!
  callSearchStats(): void {
    const selectedOption = this.selectedUserSub;
    const clindIdSelected = selectedOption?.clientNumber;
    const searchData: SearchStatsModel = {
      userId: this.profileRequest.userId,
      clientEntityId: clindIdSelected!,
      productId: '',
      laboratoryId: this.sessionClientNumber,
      substanceId: '',
      addressId: '000000',
      startDate: '',
      endDate: '',
      year: '',
    };
    this.SearchStats();
  }

  // Function : Responsible for validating the user choices and triggers the search event
  SearchStats() {
    if (
      (this.startDate !== null && this.endDate === null) ||
      (this.endDate !== null && this.startDate === null)
    ) {
      this.snackBarService.openSnackBar(
        'Insira duas datas válidas.',
        '#F97066',
        '../../../assets/Icons/ErrorIcon.png'
      );
      return;
    }

    // Validates if start and end dates are in reasonable intervals
    else if (
      this.startDate != null &&
      this.endDate != null &&
      this.startDate > this.endDate
    ) {
      this.snackBarService.openSnackBar(
        'Data inicial não pode ser superior à final.',
        '#F97066',
        '../../../assets/Icons/ErrorIcon.png'
      );
      return;
    }

    let startDate = '',
      endDate = '';

    if (this.startDate === null && this.endDate === null) {
      startDate = new Date(this.selectedYear.yearValue, 0, 1)
        .toISOString()
        .slice(0, 10);
      this.startDate = new Date(this.selectedYear.yearValue, 0, 1);

      if (this.IsCurrentYear(this.selectedYear)) {
        endDate = new Date().toISOString().slice(0, 10);
        this.endDate = new Date();
      } else {
        endDate = new Date(this.selectedYear.yearValue, 11, 31)
          .toISOString()
          .slice(0, 10);
        this.endDate = new Date(this.selectedYear.yearValue, 11, 31);
      }
    } else {
      startDate = this.startDate
        ? this.startDate.toISOString().slice(0, 10)
        : '';
      endDate = this.endDate ? this.endDate.toISOString().slice(0, 10) : '';
    }


    let laboratoryIdValue = '';

    if (this.profileRequest.role === "Laboratorio") {
      laboratoryIdValue = this.profileRequest.username.slice(1);
    } else {
      laboratoryIdValue = this.selectedLaboratory
        ? this.selectedLaboratory.id
        : '';
    }

    let substanceIdValue = this.selectedSubstance
      ? this.selectedSubstance.idSubs
      : '';

    let productIdValue = this.selectedProduct
      ? this.selectedProduct.referencia
      : '';

    this.searchStatsModel = {
      userId: '',
      clientEntityId: this.sessionClientNumber,
      productId: productIdValue as string,
      laboratoryId: laboratoryIdValue as string,
      substanceId: substanceIdValue as string,
      addressId: this.selectedAddress?.clientAddressCode as string,
      startDate: startDate,
      endDate: endDate,
      year: this.selectedYear.toString(),
    };

    this.searchStatsEvent.emit(this.searchStatsModel);
    this.isOpen.emit(false);
  }

  // AUX FUNCTION : Validates if year selected is the current year
  IsCurrentYear(year: number) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return year == currentYear;
  }

  // PRODUCT AUTOCOMPLETE
  // FUNCTION : Responsible for showcasing the selected product on the input
  DisplayProduct(product: TbProduto): string {
    return product && product.memoria ? `${product.memoria}` : '';
  }

  // FUNCTION : Assigns to the variable the product selected by the user
  SelectedProduct(event: any) {
    this.selectedProduct = event.option.value;
  }

  // FUNCTION : Filters the list of products based on user input
  private FilterProduct(value: string): TbProduto[] {
    let filterValue = value.toLowerCase();
    return this.productsList.filter((option) =>
      option.memoria.toLowerCase().includes(filterValue)
    );
  }

  // LABORATORY AUTOCOMPLETE
  DisplayLaboratory(laboratory: TbLaboratorio): string {
    return laboratory && laboratory.labName ? `${laboratory.labName}` : '';
  }

  SelectedLaboratory(event: any) {
    this.selectedLaboratory = event.option.value;
  }

  SelectedUserSub(event: any) {
    this.selectedUserSub = event.option.value;
  }

  private FilterLaboratory(value: string): TbLaboratorio[] {
    let filterValue = value.toLowerCase();
    return this.laboratoriesList.filter((option) =>
      option.labName.toLowerCase().includes(filterValue)
    );
  }

  // SUBSTANCE AUTOCOMPLETE
  DisplaySubstance(substance: TbSub): string {
    return substance && substance.nome ? `${substance.nome}` : '';
  }

  SelectedSubstance(event: any) {
    this.selectedSubstance = event.option.value;
  }

  private FilterSubstance(value: string): TbSub[] {
    let filterValue = value.toLowerCase();
    return this.substancesList.filter((option) =>
      option.nome.toLowerCase().includes(filterValue)
    );
  }

  /* Aux */
  displayFn(user: User): string {
    return user && user.name ? `${user.clientNumber} - ${user.name}` : '';
  }

  private _filter(value: string): User[] {
    const filterValue = value.toLowerCase();
    return this.users.filter(
      (option) =>
        option.name.toLowerCase().includes(filterValue) ||
        option.clientNumber.includes(filterValue)
    );
  }
}
