import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackBarComponent } from 'src/app/Custom/custom-snack-bar/custom-snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(message: string, color: string, iconUrl: string, duration: number = 5000): void {
    const snackBarRef = this.snackBar.openFromComponent(
      CustomSnackBarComponent,
      {
        data: { message, backgroundColor: color, iconUrl },
        duration: duration,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'custom-snack-bar',
      }
    );
  }
}
