<div class="w-100 d-flex flex-column justify-content-start">
  <div *ngIf="(isPageChange)" class="loading-animation loading-animation-table" role="alert" aria-live="assertive">
    <app-loading></app-loading>
  </div>

  <table mat-table [dataSource]="shopItemWrappers" class="w-100 h-100 position-relative"
    [ngClass]="{'opacity-30':isPageChange}" aria-describedby="Tabela de produtos em lista">
    <ng-container matColumnDef="codigo">
      <th mat-header-cell *matHeaderCellDef> Código </th>
      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Código:">
        {{element.reference}}</td>
    </ng-container>

    <ng-container matColumnDef="produto">
      <th mat-header-cell *matHeaderCellDef> Produto </th>
      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Produto:">
        {{element.decription}}</td>
    </ng-container>

    <ng-container matColumnDef="desc">
      <th mat-header-cell *matHeaderCellDef style="width: 85px;"> Desc </th>
      <td mat-cell *matCellDef="let element" class="has_label_on_mobile td-center" data-label="Info:">
        <div *ngIf="element.bonus.length > 0">
          <div class="cursor-pointer green-ball" (click)="showCampaignProduct(element.reference)">
            <mat-icon svgIcon="discount" class="h-auto"></mat-icon>
          </div>
          <div *ngIf="isProductOpen(element.reference)">
            <app-dialog-product-campaign [productData]="element"></app-dialog-product-campaign>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="info">
      <th mat-header-cell *matHeaderCellDef style="width: 85px;"> Info </th>
      <td mat-cell *matCellDef="let element" class="has_label_on_mobile td-center" data-label="Info:">
        <span class="cursor-pointer" (click)="openProductDetails(element)" aria-label="Mostrar detalhes do produto">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg">
            <path
              d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
              fill="rgba(113,210,214,1)"></path>
          </svg>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="qtd">
      <th mat-header-cell *matHeaderCellDef style="width: 140px;"> Quantidade </th>
      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Quantidade:">
        <div class="d-flex flex-row" style="justify-content: center;">
          <div class="btn d-flex flex-row cursor-pointer" (click)="removeQuantityClick(element)" aria-label="Reduzir quantidade do produto"> 
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg">
              <path
                d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM7 11H17V13H7V11Z"
                fill="rgba(0,174,174,1)"></path>
            </svg>
          </div>
          <span class="d-flex flex-row justify-content-center span-quantity">
            {{element.productQuantity ? element.productQuantity : 0}}
          </span>
          <div class="btn d-flex flex-row cursor-pointer" (click)="addQuantityClick(element)" aria-label="Adicionar quantidade do produto">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg">
              <path
                d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                fill="rgba(0,174,174,1)"></path>
            </svg>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="stock">
      <th mat-header-cell *matHeaderCellDef style="width: 85px;"> Stock </th>
      <td mat-cell *matCellDef="let element" class="has_label_on_mobile td-center" data-label="Stock:">
        <div>
          <span *ngIf="element.stock >= 10">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg">
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="rgba(10,161,116,1)"></path>
            </svg>
          </span>
          <span *ngIf="element.stock < 10 && element.stock > 0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg">
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="rgba(253,176,34,1)"></path>
            </svg>
          </span>
          <span *ngIf="element.stock === 0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg">
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="rgba(249,112,102,1)"></path>
            </svg>
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="preco">
      <th mat-header-cell *matHeaderCellDef style="width: 160px;"> Preço Líquido </th>
      <td mat-cell *matCellDef="let element" class="has_label_on_mobile td-right" data-label="Preço:">
        {{element.currentPrice | number : '1.2-2' }}€</td>
    </ng-container>

    <ng-container matColumnDef="iva">
      <th mat-header-cell *matHeaderCellDef style="width: 85px;"> IVA </th>
      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="IVA:">{{ element.iva !==
        null ? element.iva + '%' : '0%' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsExtract"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsExtract;"></tr>
  </table>
</div>
