import { SubLoginService } from './../../api/services/sub-login.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as XLSX from 'xlsx';
import {
  AnnualConsumptionLineResult,
  BaseStatsLineResult,
  DeliveryAddress,
  SearchStatsModel,
  SearchStatsResponseModel,
  StatisticsViewModel,
  TbLaboratorio,
  TbProduto,
  TbSub,
} from 'src/app/api/models/statistics';

import { StatisticsService } from 'src/app/api/services/statistics.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { Chart } from 'chart.js';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { Address, User } from 'src/app/api/models/getUsers';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EncryptionService } from 'src/app/api/services/encryption.service';
import { StorageKeys } from 'src/app/api/models/storageKeys';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css'],
})
export class StatisticsComponent implements OnInit, OnDestroy {
  loggedUser = this.perfilService.getCurrentUserInfo();
  clientSelected: User | undefined;
  addressSelected: Address | undefined;
  isMenuOpen = false;
  isModalOpen: boolean = false;
  isLoading: boolean = false;
  isViewModelLoaded: boolean = false;
  productTableVisibility: boolean = false;
  substanceTableVisibility: boolean = false;
  laboratoryTableVisibility: boolean = false;
  activeProduct: string = '';
  activeLaboratory: string = '';
  activeLaboratoryName: string = '';
  activeSubstance: string = '';
  activeSubstanceName: string = '';
  statsSearchResults?: SearchStatsResponseModel;
  statsViewModel!: StatisticsViewModel;
  profileRequest!: ProfileDataRequest;
  searchInterval: string = '';
  filteredOptions!: Observable<User[]>;
  isAnnualConsumption: boolean = false;
  isTop25: boolean = false;
  isProductTable: boolean = false;
  isSubstanceTable: boolean = false;
  isLaboratoryTable: boolean = false;
  myControl = new FormControl<string | User>('');
  users: User[] = [];
  previousSearchData: SearchStatsModel = {
    userId: this.loggedUser.userId,
    clientEntityId: this.loggedUser.clientId,
    productId: '',
    laboratoryId: '',
    substanceId: '',
    addressId: '',
    startDate: '',
    endDate: '',
    year: '',
  };
  productList: TbProduto[] = []
  sessionClientNumber = this.encryptSvc.getDecryptedItem(StorageKeys.BIO2_CLIENT_ID)!;
  modalParameter: string = '';
  observerConfig = { attributes: true };
  consumptionChart: any;
  topChart: any;
  ctx: any;
  topChartElement: any;
  unsubscribe: Subscription = new Subscription();
  displayedColumns: string[] = [
    'designation',
    'quantityCashed',
    'quantityBonus',
    'valueCashed',
  ];

  constructor(
    private perfilService: PerfilService,
    private statisticsService: StatisticsService,
    private snackBarService: SnackBarService,
    private SubLoginService: SubLoginService,
    private encryptSvc: EncryptionService
  ) { }

  // When Page loads
  ngOnInit() {
    let canvasElement = document.getElementById(
      'consumptionChart'
    ) as HTMLCanvasElement;
    canvasElement.style.display = '';
    this.ctx = canvasElement.getContext('2d');
    let topChartCanvasElement = document.getElementById(
      'top25chart'
    ) as HTMLCanvasElement;
    topChartCanvasElement.style.display = '';
    this.topChartElement = topChartCanvasElement.getContext('2d');
    // Set observers to prevent canvas to place display
    let annualObserver = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          // The 'style' attribute of the element has changed
          let newStyleValue = canvasElement.getAttribute('style');
          const styleChanged = newStyleValue?.includes('display');
          if (styleChanged && newStyleValue != '') {
            canvasElement.style.display = '';
          }
        }
      }
    });

    let topObserver = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          // The 'style' attribute of the element has changed
          let newStyleValue = topChartCanvasElement.getAttribute('style');

          const styleChanged = newStyleValue?.includes('display');
          if (styleChanged && newStyleValue != '') {
            topChartCanvasElement.style.display = '';
          }
        }
      }
    });

    annualObserver.observe(canvasElement, this.observerConfig);
    topObserver.observe(topChartCanvasElement, this.observerConfig);
    // Create Consumption Chart
    this.consumptionChart = new Chart(this.ctx, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: 'blue',
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            // text: 'Consumo Anual €',
            font: {
              family: 'Poppins, sans-serif', // Set your desired font family
              size: 18,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                family: 'Poppins, sans-serif',
                size: 16,
              },
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              font: {
                family: 'Poppins, sans-serif',
                size: 16,
              },
            },
          },
        },
      },
    });

    // Create Top Chart
    this.topChart = new Chart(this.topChartElement, {
      type: 'pie',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            // text: 'TOP 25 Produtos €',
            font: {
              family: 'Poppins, sans-serif', // Set your desired font family
              size: 18,
            },
          },
        },
      },
    });

    this.GetStatisticsViewModel();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.users.slice();
      })
    );
  }

  GetStatisticsViewModel() {
    this.isLoading = true;
    this.unsubscribe.add(
      this.statisticsService
        .GetStatisticsViewModel(this.loggedUser.clientId)
        .subscribe({
          next: (response) => {
            this.statsViewModel = response;
            this.isViewModelLoaded = true;

            // Load data
            let defaultSearch: SearchStatsModel = {
              userId: this.loggedUser.userId,
              clientEntityId: this.loggedUser.clientId,
              productId: '',
              laboratoryId: '',
              substanceId: '',
              addressId: '000000',
              startDate: '',
              endDate: '',
              year: new Date().getFullYear().toString(),
            };
            this.SearchStats(defaultSearch);
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error:', error);
          }
        })
    );
  }

  callSearchStats(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = event.option.value;
    const clindIdSelected = selectedOption.clientNumber;
    const searchData: SearchStatsModel = {
      userId: this.profileRequest.userId,
      clientEntityId: clindIdSelected,
      productId: '',
      laboratoryId: this.sessionClientNumber,
      substanceId: '',
      addressId: '000000',
      startDate: '',
      endDate: '',
      year: '2023',
    };

    this.SearchStats(searchData);
  }

  // FUNCTION : Responsible for getting Statistics data, based on the user input
  SearchStats(searchData: SearchStatsModel) {
    this.isLoading = true;
    this.previousSearchData = searchData;
    try {
      // Setup Appropriate Titles for the charts
      this.consumptionChart.options.plugins.title.text =
        'Consumo Anual € (' + searchData.year + ')';

      if (searchData.startDate == '' && searchData.endDate == '') {
      } else {
        this.topChart.options.plugins.title.text =
          'TOP 25 Produtos € (' +
          searchData.startDate +
          ' - ' +
          searchData.endDate +
          ')';
      }

      // Show tables that are relavant for the user and save current active search inputs
      if (searchData.productId != null && searchData.productId != '') {
        this.activeProduct = searchData.productId;
        this.productTableVisibility = true;
      } else {
        this.productTableVisibility = false;
        this.activeProduct = '';
      }

      if (searchData.laboratoryId != null && searchData.laboratoryId != '') {
        this.activeLaboratory = searchData.laboratoryId;
        this.activeLaboratoryName =
          this.statsViewModel.laboratoriesList?.find(
            (lab) => lab.id == searchData.laboratoryId
          )?.labName || '';
        this.laboratoryTableVisibility = true;
      } else {
        this.laboratoryTableVisibility = false;
        this.activeLaboratory = '';
      }

      if (searchData.substanceId != null && searchData.substanceId != '') {
        this.activeSubstance = searchData.substanceId;
        this.activeSubstanceName =
          this.statsViewModel.substancesList?.find(
            (sub) => sub.idSubs == searchData.substanceId
          )?.nome || '';
        this.substanceTableVisibility = true;
      } else {
        this.substanceTableVisibility = false;
        this.activeSubstance = '';
      }

      // Make Search
      this.unsubscribe.add(
        this.statisticsService.SearchStats(searchData).subscribe({
          next: (data) => {
            this.statsSearchResults = data;
            if (this.statsSearchResults != null)
              this.LoadStatsData(this.statsSearchResults);
          },
          error: (error) => {
           this.isLoading = false;
            console.log("Error: ", error);
          },
          complete: () => {
           this.isLoading = false;
          }
        })
      );
    } catch (error) {
      this.isLoading = false;
      this.snackBarService.openSnackBar(
        'Erro ao pesquisar por estatísticas',
        '#F97066',
        '../../../assets/Icons/ErrorIcon.png'
      );
    }
  }

  // FUNCTION : Responsible for loading stats data into the charts
  LoadStatsData(data: SearchStatsResponseModel) {
    // Clear data from Top Chart and Annual Consumption
    this.RemoveChartData(this.topChart);
    this.RemoveChartData(this.consumptionChart);

    // Prepare data for graphs
    let topProductsJson = JSON.stringify(data.topProductsResults);
    let topProductsData = JSON.parse(topProductsJson);

    let consumptionJson = JSON.stringify(data.annualConsumptionResults);
    let consumptionData = JSON.parse(consumptionJson);

    // Load Data
    this.AddChartData(
      this.consumptionChart,
      consumptionData.map(
        (consumption: AnnualConsumptionLineResult) => consumption.monthOfTheYear
      ),
      {
        label: 'Valor',
        data: consumptionData.map((consumption: AnnualConsumptionLineResult) =>
          parseFloat(consumption.amount.toString())
        ),
        borderColor: 'rgba(113, 210, 214, 1.0)',
        backgroundColor: 'rgba(113, 210, 214, 0.5)',
        fill: 'origin',
      }
    );

    this.AddChartData(
      this.topChart,
      topProductsData.map(
        (product: BaseStatsLineResult) => product.designation
      ),
      {
        label: 'Valor',
        data: topProductsData.map((product: BaseStatsLineResult) =>
          parseFloat(product.valueCashed.toString())
        ),
        backgroundColor: ['rgb(113, 210, 214)', 'rgb(0, 174, 174)'],
        borderRadius: 10,
      }
    );
  }

  // FUNCTION : Downloads all the statistic data present in the view
  DownloadStatsData() {
    // If there is data in annual consumption
    if (this.statsSearchResults?.annualConsumptionResults?.length != 0) {
      let transformedAnnualData = this.TransformAnnualDataForExcel(
        this.statsSearchResults?.annualConsumptionResults || []
      );
      this.ExportChartToExcel(
        transformedAnnualData,
        'Consumo Anual',
        'annualData.xlsx'
      );
    }

    // If there is data in top 25 products
    if (this.statsSearchResults?.topProductsResults?.length != 0) {
      let transformedTopStatsData = this.TransformTopStatsForExcel(
        this.statsSearchResults?.topProductsResults || []
      );
      this.ExportChartToExcel(
        transformedTopStatsData,
        'Top 25',
        'topProductsData.xlsx'
      );
    }

    // For every table, check if they are visible and download them if the case
    if (this.productTableVisibility) {
      let productTable = document.getElementById(
        'productTable'
      ) as HTMLTableElement;
      this.ExportTableToExcel(productTable, 'produto_' + this.activeProduct);
    }

    if (this.substanceTableVisibility) {
      let substanceTable = document.getElementById(
        'substanceTable'
      ) as HTMLTableElement;
      this.ExportTableToExcel(
        substanceTable,
        'substancia_' + this.activeSubstance
      );
    }

    if (this.laboratoryTableVisibility) {
      let laboratoryTable = document.getElementById(
        'laboratoryTable'
      ) as HTMLTableElement;
      this.ExportTableToExcel(
        laboratoryTable,
        'laboratorio_' + this.activeLaboratory
      );
    }
  }

  // AUX FUNCTION : Removes Data from the chart before the loading process
  RemoveChartData(chart: any) {
    chart.data.labels.length = 0;
    if (chart.data.datasets[0] != null) chart.data.datasets[0].length = 0;
    chart.update();
  }

  // AUX FUNCTION : Add Data to the chart
  AddChartData(chart: any, labels: any, dataset: any) {
    chart.data.labels = labels;
    chart.data.datasets[0] = dataset;
    chart.update();
  }

  // AUX FUNCTION : Exports a table into a file
  ExportTableToExcel(table: any, filename: string) {
    let worksheet = XLSX.utils.table_to_sheet(table);
    let workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate the Excel file (.xlsx)
    XLSX.writeFile(workbook, filename + '.xlsx');
  }

  // AUX FUNCTION : Exports chart data into Excel
  ExportChartToExcel(data: any[], sheetName: string, fileName: string) {
    let worksheet = XLSX.utils.aoa_to_sheet(data);
    let workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // Generate the Excel file (.xlsx)
    XLSX.writeFile(workbook, fileName);
  }

  // AUX FUNCTION : Transforms Annual Data before excel export
  TransformAnnualDataForExcel(data: AnnualConsumptionLineResult[]): any[] {
    let transformed = [];

    // Push headers
    transformed.push(['Month of the Year', 'Amount']);

    // Populate data
    data.forEach((item) => {
      transformed.push([item.monthOfTheYear, item.amount]);
    });

    return transformed;
  }

  // AUX FUNCTION : Transforms Top Data before excel export
  TransformTopStatsForExcel(data: BaseStatsLineResult[]): any[] {
    let transformed = [];

    // Push headers
    transformed.push(['Designation', 'Value Cashed']);

    // Populate data
    data.forEach((item) => {
      transformed.push([item.designation, item.valueCashed]);
    });

    return transformed;
  }

  // EVENTS
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu(): void {
    this.isMenuOpen = false;
  }

  ShowStatsDetails(parameter: string) {
    this.ToggleVisibility(parameter);
    /*
    // Set parameter of what to show
    this.modalParameter = parameter;

    // If annual consumption or top 25 set the interval
    if(parameter == 'AnnualGraph')
      this.searchInterval = this.previousSearchData.year;
    else if(parameter == 'Top25')
      this.searchInterval = this.previousSearchData.startDate + ' - ' + this.previousSearchData.endDate;
    else this.searchInterval = '';
    this.isModalOpen = true;
    */
  }

  CloseDetails(): void {
    this.isModalOpen = false;
  }

  ToggleVisibility(searchParameter: string) {
    switch (searchParameter) {
      case 'AnnualGraph':
        this.isAnnualConsumption = !this.isAnnualConsumption;
        this.isTop25 = false;
        this.isProductTable = false;
        this.isSubstanceTable = false;
        this.isLaboratoryTable = false;
        break;

      case 'Top25':
        this.isTop25 = !this.isTop25;
        this.isAnnualConsumption = false;
        this.isProductTable = false;
        this.isSubstanceTable = false;
        this.isLaboratoryTable = false;
        break;

      case 'ProductTable':
        this.isProductTable = !this.isProductTable;
        this.isTop25 = false;
        this.isAnnualConsumption = false;
        this.isSubstanceTable = false;
        this.isLaboratoryTable = false;
        break;

      case 'SubstanceTable':
        this.isSubstanceTable = !this.isSubstanceTable;
        this.isTop25 = false;
        this.isAnnualConsumption = false;
        this.isProductTable = false;
        this.isLaboratoryTable = false;
        break;

      case 'LaboratoryTable':
        this.isLaboratoryTable = !this.isLaboratoryTable;
        this.isTop25 = false;
        this.isAnnualConsumption = false;
        this.isProductTable = false;
        this.isSubstanceTable = false;
        break;
    }
  }
  selectedClient(event: any) {
    this.addressSelected = undefined;
    this.clientSelected = event.option.value;
  }

  displayFn(user: User): string {
    return user && user.name ? `${user.clientNumber} - ${user.name}` : '';
  }

  private _filter(value: string): User[] {
    const filterValue = value.toLowerCase();
    return this.users.filter(
      (option) =>
        option.name.toLowerCase().includes(filterValue) ||
        option.clientNumber.includes(filterValue)
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }
}
