<div class="d-flex flex-row" style="height: 100vh">
    <div>
        <app-dashboard-side-menu></app-dashboard-side-menu>
    </div>
    <div class="p-content d-flex flex-column">
        <app-dashboard-top-menu></app-dashboard-top-menu>
        <!-- !!Content to be displayed when loading is complete -->
        <div *ngIf="loading; else contentLoaded" class="loading-animation">
            <app-loading></app-loading>
        </div>
        <ng-template #contentLoaded>
            <div class="template-content">
                <div class="w-100 d-flex flex-column justify-content-start">
                    <div class="d-flex">
                        <h2>Importação de registos</h2>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                        <form>
                            <div style="max-width: 440px;">
                                <p>Registo <span *ngIf="isMandatory">* Obrigatório.</span></p>
                                <mat-form-field class="w-100">
                                    <input matInput name="inputRegisto" [(ngModel)]="inputRegisto">
                                </mat-form-field>
                            </div>

                            <div class="mt-3" style="width: 440px;">
                                <label class="form-control checkbox-label">
                                    <input type="checkbox" name="checkbox" (change)="statusChanged($event)" />
                                    <span class="pl-1">Registos atualizados Bio 2</span>
                                </label>
                            </div>
                            <button class="btn-processar mt-4" (click)="processar()" [disabled]="loading">
                                <span>Processar</span>
                                <img src="../../../../assets/Icons/check-line.png" alt="Check">
                            </button>
                        </form>

                        <!--*REGISTOS IMPORTADOS-->
                        <ng-container *ngIf="!loading && data">
                            <div *ngIf="data.length > 0 && data[0].statusCode === 200">
                                <h2 class="mt-4">Resultado:</h2>
                                <div class="response-container">
                                    <div class="file-container" *ngFor="let registo of data">
                                        <div class="file-info">
                                            <p>Registo #{{registo.counter}} | {{registo.registo}}
                                                <span style="color: var(--blue-800)"
                                                *ngIf="registo.message"> | {{registo.message}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-template>
        <app-footer></app-footer>
    </div>
</div>