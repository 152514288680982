<div class="bg-color w-100">
  <div>
    <h2 class="title">Criar Grupo ou Lista de Produtos</h2>
  </div>
  <form [formGroup]="groupForm" class="d-flex flex-column">
    <div class="w-100 d-flex flex-column columnAdjusts">
      <div class="w-100 d-flex flex-column columnAdjusts">
        <div class="d-flex flex-column">
          <span class="text-description">Escolha a opção</span>
          <mat-form-field>
            <mat-select [(value)]="noGroup" (selectionChange)="onGroupTypeChange($event)">
              <mat-option value="S">Sem Grupo</mat-option>
              <mat-option value="C">Com Grupo</mat-option>
            </mat-select>
          </mat-form-field>
          <span class="text-description" style="margin-top: 30px;">Nome de Grupo <span class="required">*</span></span>
          <mat-form-field>
            <input matInput type="text" formControlName="groupName">
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex flex-column">
        <span class="text-description">Produtos <span class="required">*</span></span>
        <app-search-input-products
          [isMultiple]="true"
          [plist]="plist" 
          [searchControl]="myControl" 
          [selectProducts]="selectProducts" 
          [selectedItems]="selectedItens">
        </app-search-input-products>
      </div>
      <div class="d-flex flex-row w-100" style="gap:98px" *ngIf="noGroup ===  'C'">
        <div class="d-flex flex-column w-50">
          <label class="text-description">Imagem p/ Header (1700px x 400px)</label>
          <mat-form-field>
            <ngx-mat-file-input [formControl]="header" [accept]="imgGroupType" #FileInputHeader
              (change)="checkFileSize(FileInputHeader, 5)">
            </ngx-mat-file-input>
          </mat-form-field>
        </div>
        <div class="d-flex flex-column w-50">
          <label class="text-description">Imagem p/ Grupo (200px x 200px)</label>
          <mat-form-field>
            <ngx-mat-file-input [formControl]="image" [accept]="imgGroupType" #FileInputImg
              (change)="checkFileSize(FileInputImg, 5)">
            </ngx-mat-file-input>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex flex-end" style="height: 36px;">
        <button class="submit-inner-button" type="button" mat-button color="primary" (click)="generateGroup()">
          <span>Criar</span>
          <img src="../../../../assets/Icons/icon-done.png" alt="IconDone" style="margin-bottom: 2px;">
        </button>
      </div>
    </div>
  </form>
</div>
