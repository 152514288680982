import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { SendEmail } from '../models/email';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  route = new ApiConfiguration();
  ApiGetContacts = this.route.rootUrl + '/api/contacts';
  ApiSendEmail = this.route.rootUrl + '/api/email';

  constructor(private http: HttpClient) { }

  sendEmail(body: SendEmail): Observable<any> {
    return this.http.post<string>(this.ApiSendEmail, body, { responseType: 'text' as 'json' }).pipe(
      catchError((e) => {
        return e;
      })
    );
  }
}
