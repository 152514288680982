<div class="product-wrapper">
  <div [style]="getBackgroundColor().borderStyle" class="card-product">
    <div class="justify-content-center mobileAdjustsH"
      style="flex-grow: 1; align-items: center; align-self: center; place-content: center;">
      <div class="d-flex card-image-container" *ngIf="product.bonus !== null" [ngClass]="{
          'placeholder-img': showProductImage().endsWith(
            'product_placeholder.png'
          )
        }">
        <img [src]="showProductImage()" alt="Product image" class="cursor-pointer product-img"
          (click)="openProductDetails()" [ngClass]="{
            'placeholder-img-bio2': showProductImage().endsWith(
              'product_placeholder.png'
            )
          }" />
        <div *ngIf="showProductImage().endsWith('product_placeholder.png')" class="conditional-placeholder">
          <span>Imagem não disponibilizada</span>
        </div>
      </div>
      <div class="justify-content-start align-items-end">
        <!-- Labels Container -->
        <div class="labels-container">
          <!-- Type G logic -->
          <div class="over-image-discont" *ngIf="getBackgroundColor().label"
            [style]="getBackgroundColor().backgroundColor">
            <img [src]="getBackgroundColor().icon" alt="icon">
            {{ getBackgroundColor().label }}
          </div>
          <!-- Bonus logic -->
          <div class="over-image-discont" *ngIf="product.bonus.length > 0 || hasActiveQuantityCampaign"
            (click)="openProductBonus()"
            [ngClass]="{'conditionalBonus': getBackgroundColor().label!.length > 0 || product.request}">
            <img src="../../../assets/Icons/BonusProduto.png" alt="icon">
            Produto em campanha
          </div>
        </div>

        <button mat-fab class="over-image-favorite" *ngIf="!product.favorite && !isAdmin && !inFavorite"
          (click)="handleFavoriteClick()">
          <mat-icon>favorite_border</mat-icon>
        </button>
        <button mat-fab class="over-image-favorite" *ngIf="product.favorite && !isAdmin && !inFavorite"
          (click)="handleFavoriteClick()">
          <mat-icon>favorite</mat-icon>
        </button>
        <button mat-fab class="over-image-favorite" *ngIf="product.favorite && !isAdmin && inFavorite"
          (click)="handleRemoveFavoriteClick()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="bottom-part">
      <!-- !! Nome -->
      <div class="p-0 mt-4 d-flex flex-column justify-content-center mobileAdjustM">
        <div class="d-flex flex-row" style="justify-content: space-between;">
          <span class="text-left product-label"
            [ngStyle]="{'color': product.situacao == 'Validade' ? '#F97066' : 'inherit'}"
            style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;"
            matTooltip="{{ product.decription }}" [matTooltipPosition]="'above'" [matTooltipShowDelay]="'500'"
            [matTooltipHideDelay]="'100'">
            {{ product.decription }}
          </span>
          <img *ngIf="product.situacao == 'Validade'" src="../../../assets/Icons/situacao.png" alt="situacao"
            matTooltip="Artigo com validade curta" [matTooltipPosition]="'above'"
            style="align-self: center; cursor: pointer;">
        </div>

        <!-- !! Desconto e Stock -->
        <div>
          <!--Código para controlar quantidade máxima: *ngIf="product.quantityCampaign && product.productQuantity > product.quantityCampaign.quantity && product.quantityCampaign.discountPercentage > 0 && product.productQuantity <= product.quantityCampaign.maxQuantity"-->
          <div *ngIf="hasApplicableQuantityCampaign" class="custom-button" [ngClass]="{
                      green: product.stock >= 10,
                      yellow: product.stock < 10 && product.stock > 0,
                      red: product.stock === 0
                    }">
            {{
            product.stock > 0
            ? product.stock >= 10
            ? "Stock disponível"
            : "Stock limitado"
            : "Sem stock"
            }}
          </div>
          <div class="discounts-stock" [class.ocult]="product.request">
            <div class="d-flex flex-row" style="align-items: center;">
              <span class="slashedPrice" *ngIf="product.oldPrice > product.currentPrice">{{ product.oldPrice | number :
                "1.2-2" }}€</span>
              <span class="discountPercentageSection"
                *ngIf="product.discountPercentage && product.discountPercentage > 0">{{ product.discountPercentage
                }}%</span>
              <!--Código para controlar quantidade máxima: <ng-container *ngIf="product.quantityCampaign && product.productQuantity > product.quantityCampaign.quantity && product.quantityCampaign.discountPercentage > 0 && product.productQuantity <= product.quantityCampaign.maxQuantity"> -->
              <ng-container *ngIf="applicableCampaignDiscountPercentage !== null">
                <span class="slashedPrice" *ngIf="product.currentPrice">
                  {{ product.currentPrice | number: '1.2-2' }}€
                </span>
                <span class="discountPercentageSection">
                  {{ applicableCampaignDiscountPercentage }}%
                </span>
              </ng-container>
              <div [style]="getIva().backgroundColor" class="dinamicIva">
                <span [style]="getIva().color">
                  IVA {{getIva().label}}%
                </span>
              </div>
            </div>
            <!--Código para controlar quantidade máxima: *ngIf="!product.quantityCampaign || product.productQuantity <= product.quantityCampaign.quantity || product.quantityCampaign.discountPercentage <= 0 || product.productQuantity > product.quantityCampaign.maxQuantity"-->
            <div *ngIf="!hasApplicableQuantityCampaign" class="custom-button" [ngClass]="{
                          green: product.stock >= 10,
                          yellow: product.stock < 10 && product.stock > 0,
                          red: product.stock === 0
                        }">
              {{
              product.stock > 0
              ? product.stock >= 10
              ? "Stock disponível"
              : "Stock limitado"
              : "Sem stock"
              }}
            </div>
          </div>
        </div>
      </div>
      <div [class.adjusts-request]="product.request">
        <div [class.request]="product.request" style="display: none">
          Preço sob consulta
        </div>
        <!-- !! BTNS -->
        <div class="d-flex toogle-btns mobileToogle" [class.adjust-mobile-dimentions]="product.request">
          <div class="d-flex flex-row mobileToogle1" [class.ocult]="product.request">
            <button *ngIf="authService.hasPermissions(['27'])" [disabled]="buttonDisable" (click)="toggleButton()"
              [ngClass]="{
                'btn-euro-active d-flex': isCartButtonActive,
                'd-flex btn-euro-disable': !isCartButtonActive,
                'userPetVet': !authService.hasPermissions(['27'])
              }">
              <mat-icon *ngIf="isCartButtonActive; else cartGreenDisable" svgIcon="cart" class="h-auto"></mat-icon>
              <ng-template #cartGreenDisable>
                <mat-icon svgIcon="cart-green" class="svg-icon-points"></mat-icon>
              </ng-template>
              <ng-container *ngIf="product.oldPrice === product.currentPrice; else priceComparison">
                <!--Código para controlar quantidade máxima: <ng-container *ngIf="product.quantityCampaign && product.productQuantity > product.quantityCampaign.quantity && product.quantityCampaign.discountPercentage > 0 && product.productQuantity <= product.quantityCampaign.maxQuantity; else showOldPrice"> -->
                <ng-container *ngIf="applicableQuantityCampaign; else showOldPrice">
                  <span class="price-span">
                    {{ (product.currentPrice - (product.currentPrice * (applicableQuantityCampaign.discountPercentage
                    /
                    100))) |
                    number: '1.2-2' }}€
                  </span>
                </ng-container>
                <ng-template #showOldPrice>
                  <span>{{ product.oldPrice | number : '1.2-2' }}€</span>
                </ng-template>
              </ng-container>
              <ng-template #priceComparison>
                <!--Código para controlar quantidade máxima: <ng-container *ngIf="product.quantityCampaign && product.productQuantity > product.quantityCampaign.quantity && product.quantityCampaign.discountPercentage > 0 && product.productQuantity <= product.quantityCampaign.maxQuantity; else discountedPrice"> -->
                <ng-container *ngIf="applicableQuantityCampaign; else discountedPrice">
                  <span class="price-span">
                    {{ (product.currentPrice - (product.currentPrice * (applicableQuantityCampaign.discountPercentage
                    /
                    100))) | number: '1.2-2' }}€
                  </span>
                </ng-container>
                <ng-template #discountedPrice>
                  <span class="price-span">{{ product.currentPrice | number : '1.2-2' }}€</span>
                </ng-template>
              </ng-template>
            </button>
            <button *ngIf="!authService.hasPermissions(['27'])" [disabled]="buttonDisable"
              class="userPetVet btn-euro-active d-flex">
              <mat-icon *ngIf="isCartButtonActive; else cartGreenDisable" svgIcon="cart" class="h-auto"></mat-icon>
              <ng-template #cartGreenDisable>
                <mat-icon svgIcon="cart-green" class="svg-icon-points"></mat-icon>
              </ng-template>
              <ng-container *ngIf="
                product.oldPrice === product.currentPrice;
                else currentPrice
              ">
                <span>{{ product.oldPrice | number : "1.2-2" }}€</span>
              </ng-container>
              <ng-template #currentPrice>
                <span class="price-span">{{ product.currentPrice | number : "1.2-2" }}€</span>
              </ng-template>
            </button>

            <button *ngIf="authService.hasPermissions(['27'])" [disabled]="buttonDisable || this.isReadingPoints"
              matTooltip="A carregar pontos..." [matTooltipDisabled]="!isReadingPoints" (click)="toggleButton()"
              [ngClass]="{
                'btn-points-disable d-flex': isCartButtonActive,
                'btn-points-active d-flex ': !isCartButtonActive
              }">
              <mat-icon *ngIf="isCartButtonActive; else ticketWhiteIcon" svgIcon="ticket" class="svg-icon-points">
              </mat-icon>
              <ng-template #ticketWhiteIcon>
                <mat-icon svgIcon="ticket-white" class="svg-icon-points"></mat-icon>
              </ng-template>
              <span>{{ product.points }}</span>
            </button>
          </div>
          <!-- !! Mais menos -->
          <div class="d-flex flex-row plus-less" [ngClass]="{ disabled: isProcessing }">
            <div [ngClass]="{ disabled: isProcessing || product.productQuantity == 0 }" class="less-btn">
              <img [ngClass]="{ disabled: isProcessing }" src="../../../assets/Icons/less-icon.png" alt="lessIcon"
                (click)="isProcessing ? null : removeQuantityClick(product)" />
            </div>
            <input [ngClass]="{ disabled: isProcessing }" class="number-input" [ngModel]="product.productQuantity"
              type="number" max="1000" (keydown)="onKeyDown($event)" (keyup.enter)="onEnterPress(input)"
              (blur)="changeQuantity(input.value, product, product.productQuantity)" #input />
            <div [ngClass]="{ disabled: isProcessing }" class="plus-btn">
              <img [ngClass]="{ disabled: isProcessing }" src="../../../assets/Icons/plus-icon.png" alt="PlusIcon"
                (click)="addQuantityClick(product)" />
            </div>
            <div [@moveToTopRight]="animationState" class="ball-container">
              <div class="ball">
                <img [src]="showProductImage()">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showCampaignProductDiv">
    <app-dialog-product-campaign [productData]="product" style="top: 50px;
    left: 54px; position: absolute;"></app-dialog-product-campaign>
  </div>
</div>