import { BackofficeCampaignService } from 'src/app/api/services/backoffice-campaign.service';
import {
  Campaign,
  CampaignGroupMin,
  CampaignMin,
  ProductMin,
} from '../../../api/models/campaigns';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GenericModalComponent } from 'src/app/Custom/generic-modal/generic-modal.component';
import { GenericModal } from 'src/app/api/models/modal-generic';
import { TbProduto } from 'src/app/api/models/statistics';

@Component({
  selector: 'app-display-campaign-group',
  templateUrl: './display-campaign-group.component.html',
  styleUrls: ['./display-campaign-group.component.css'],
})
export class DisplayCampaignGroupComponent implements OnInit, OnDestroy {
  constructor(
    private campaingGroupService: BackofficeCampaignService,
    public dialog: MatDialog
  ) {}
  group!: CampaignGroupMin[];
  panelOpenState = false;
  editing = false;
  unsubscribe: Subscription = new Subscription();
  editing$!: BehaviorSubject<boolean>;
  editGroup$!: BehaviorSubject<{ id: number; group: CampaignGroupMin }>;

  @Input() selectedItems: TbProduto[] = [];
  @Input() isAddingNewGroups: boolean = false;

  ngOnInit(): void {
    this.editing$ = this.campaingGroupService.getEditing();
    this.editGroup$ = this.campaingGroupService.getEditGroup();
    this.unsubscribe.add(
      this.editing$.subscribe((editing) => {
        this.editing = editing;
        if (editing) {
          this.unsubscribe.add(
            this.editGroup$.subscribe((x) => {
              this.group = [x.group];
            })
          );
        } else {
          if(!this.isAddingNewGroups) {
            const campaing = this.campaingGroupService.getCampaign();
            const campaingProds = campaing.products
            const campaingGroups: CampaignGroupMin[] = campaing.group!;
            
            if(campaingProds && campaingProds.length > 0) {
              const newGroupProds: CampaignGroupMin = {
                idCampaignGroup: 0,
                description: 'Produtos sem grupo',
                products: campaingProds
              }
              const exist = campaingGroups.some((g) => g.idCampaignGroup === newGroupProds.idCampaignGroup);
              if(!exist)
                campaingGroups?.push(newGroupProds);
            }
            this.group = campaingGroups;
          }
          else {
            const newGroups = this.campaingGroupService.getNewGroups();
            this.group = newGroups;
          }
        }
      })
    );
  }

  UpdateGroupButton(group: CampaignGroupMin) {
    this.campaingGroupService.setEditGroup(group);
    this.editing$.next(true);
  }

  handleRemoveProduct(product: ProductMin, group: CampaignGroupMin): void {
    const dataToSend = {
      product,
      group,
    };
    const data: GenericModal = {
      title: product.memory,
      description: product.memory,
      action: 'Eliminar',
    };
    const dialogRef = this.dialog.open(GenericModalComponent, {
      data: data,
    });
    this.unsubscribe.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.deleteProductButton(dataToSend);
        }
      })
    );
  }

  deleteProductButton(data: any) {
    this.campaingGroupService.deleteProductFromGroup(data);
    if(this.selectedItems && this.selectedItems.length > 0) {
      const prodIndex = this.selectedItems.findIndex(p => p.referencia == data.product.reference);
      if(prodIndex !== -1) {
        this.selectedItems.splice(prodIndex, 1);
      }
    }
  }

  handleRemoveGroup(group: CampaignGroupMin): void {
    const data: GenericModal = {
      title: 'group',
      description: 'group',
      action: 'Eliminar',
    };

    const dialogRef = this.dialog.open(GenericModalComponent, {
      data: data,
    });
    this.unsubscribe.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.deleteGroupButton(group);
        }
      })
    );
  }

  deleteGroupButton(group: CampaignGroupMin) {
    this.campaingGroupService.deleteGroup(group);
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }
}
