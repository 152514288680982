import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { ProductStockUpdate } from '../models/media';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  route = new ApiConfiguration();

  constructor(private http: HttpClient) { }

  importStock(userId: string): Observable<ProductStockUpdate[]> {
    const apiImportStock = this.route.rootUrl + '/api/interface/stock';
    let params = new HttpParams();
    params = params.append('userId', userId);

    return this.http.get<ProductStockUpdate[]>(apiImportStock, {params})
    .pipe(
      catchError((error) => {
        throw error;
      })
    );
  }
}
