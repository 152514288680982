<div class="d-flex flex-row">
    <div>
        <app-dashboard-side-menu></app-dashboard-side-menu>
    </div>
    <div class="p-content d-flex flex-column w-100">
        <app-dashboard-top-menu></app-dashboard-top-menu>
        <!-- !!Loading animation -->
        <div *ngIf="isLoading; else contentLoaded" class="loading-animation">
            <app-loading></app-loading>
        </div>
        <ng-template #contentLoaded>
            <div class="template-content">
                <div class="w-100 d-flex flex-column justify-content-start">
                    <div *ngIf="isMedia">
                        <h2>Importação de Ficheiros</h2>
                        <button class="btn-processar" (click)="processar()" [disabled]="isLoading">
                            <span>Processar</span>
                            <img src="../../../../assets/Icons/check-line.png" alt="Check">
                        </button>
                        <hr>
                        <div class="mt-4">
                            <p>Fazer upload de ficheiros ('.bl.pdf', '.fl.pdf', '.01.jpg', '.01.png', 'banner_').</p>
                            <div class="d-flex">
                                <input class="upload-input" type="file" multiple accept="image/*, .pdf"
                                    (change)="onFileSelected($event)" />
                                <button [disabled]="selectedFiles.length <= 0" class="btn-processar ml-2"
                                    (click)="uploadFiles()"><span>Enviar Ficheiros</span></button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isMedia">
                        <h2>Importação de Stock</h2>
                        <button class="btn-processar" (click)="stockProcessar()" [disabled]="isLoading">
                            <span>Processar</span>
                            <img src="../../../../assets/Icons/check-line.png" alt="Check">
                        </button>
                    </div>

                    <!--*MEDIA-->
                    <ng-container *ngIf="!isLoading && data">
                        <div *ngIf="data.length > 0 && data[0].statusCode === 200">
                            <h2 class="mt-4">Resultado:</h2>
                            <div class="response-container">
                                <div class="file-container" *ngFor="let file of data">
                                    <div class="file-info d-flex">
                                        <p>{{file.filename }}
                                            <span *ngIf="file.isDuplicate" style="color: var(--error-200)">- Duplicado -</span>
                                            <span *ngIf="file.movedTo">&nbsp;importado com êxito. </span>
                                            <span *ngIf="file.message">&nbsp; - {{file.message}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!--*STOCK-->
                    <ng-container *ngIf="!isLoading && stockData">
                        <div *ngIf="stockData.length > 0 && stockData[0].statusCode === 200">
                            <h2 class="mt-4">Resultado:</h2>
                            <div class="response-container">
                                <div class="file-container" *ngFor="let file of stockData">
                                    <div class="file-info">
                                        <p *ngIf="!file.messageError">Produto #{{file.reference}} - Stock Atualizado
                                            para {{file.stockQtd}}</p>
                                        <p *ngIf="file.messageError">Produto #{{file.reference}} - <span>Não existe na
                                                base de dados</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-template>
        <app-footer></app-footer>
    </div>
</div>
