<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div *ngIf="isLoading && !pageChanged" class="loading-animation" role="alert" aria-live="assertive">
      <app-loading frameborder="0"></app-loading>
    </div>
    <div *ngIf="!isLoading || pageChanged">
      <div class="content">
        <div class="h-100 position-relative ultraWAdjusts">
          <mat-tab-group #tabRef mat-stretch-tabs="false" mat-align-tabs="start"
            *ngIf="!encomendaInfo; else encomendaDetails" aria-label="Abas de encomendas">
            <mat-tab *ngIf="authService.hasPermissions(['3'])" label="Histórico de encomendas" aria-label="Histórico de encomendas" id="Historico">
              <div>
                <div class="mt-4 w-100 finance-dates-inputs">
                  <div class="d-flex flex-wrap mobile-adjust" style="justify-content: center;">
                    <mat-form-field class="date-input date-picker">
                      <div class="d-flex align-items-start">
                        <mat-label for="start-date-input" style="padding-right: 5px">De:</mat-label>
                        <input matInput id="start-date-input" [matDatepicker]="startDatePicker" (click)="startDatePicker.open()"
                          [(ngModel)]="startDate" style="padding-top: 1px" aria-describedby="Data de Início" />
                        <mat-datepicker #startDatePicker></mat-datepicker>
                      </div>
                    </mat-form-field>

                    <mat-form-field class="date-input date-picker ml-3">
                      <div class="d-flex align-items-start">
                        <mat-label for="end-date-input" style="padding-right: 5px">Até:</mat-label>
                        <input matInput id="end-date-input" [matDatepicker]="endDatePicker" (click)="endDatePicker.open()"
                          [(ngModel)]="endDate" style="padding-top: 1px" aria-describedby="Data de Fim"/>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                      </div>
                    </mat-form-field>

                    <div class="d-flex ml-3">
                      <button class="btn btn-green h-auto mobile-search" aria-label="Pesquisar" (click)="onOrdersRequest()"
                        [disabled]="isLoading" matTooltip="Pesquisar" [matTooltipPosition]="'above'">
                        <span class="ocult">Pesquisar</span>
                        <img src="../../assets/Icons/search.png" class="i-font" alt="Pesquisar" />
                      </button>
                    </div>

                    <div class="ml-3">
                      <button class="btn btn-green h-auto mobile-search" aria-label="Exportar CSV" (click)="downloadCsv()" [disabled]="isLoading"
                        [hidden]="encomendaDataSource.data.length <= 0" matTooltip="Exportar CSV"
                        [matTooltipPosition]="'above'">
                        <span class="ocult">Exportar CSV</span>
                        <img src="../../assets/Icons/dashboard-export.png" class="i-font" alt="Pesquisar" />
                      </button>
                    </div>
                  </div>
                </div>

                <div *ngIf="isLoading; else contentLoaded" class="loading-animation" role="alert" aria-live="assertive">
                  <app-loading></app-loading>
                </div>

                <ng-template #contentLoaded>
                  <div *ngIf="encomendaDataSource" class="row finance-dates-inputs" style="overflow: hidden !important">
                    <table mat-table [dataSource]="encomendaDataSource" class="w-100"
                      [ngClass]="{ 'body-opacity': isLoading }" aria-describedby="Tabela de histórico de encomendas" style="padding: 50px; overflow: hidden !important">
                      <ng-container matColumnDef="encomendaNr" style="overflow: hidden !important">
                        <th mat-header-cell *matHeaderCellDef>Encomenda nº</th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Nr Encomenda:">
                          {{ element.numero }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="dataEnc">
                        <th mat-header-cell *matHeaderCellDef>Data Encomenda</th>
                        <td mat-cell class="has_label_on_mobile" *matCellDef="let element" data-label="Data Enc.:">
                          {{ element.dataEncomenda | dateNumericFormat }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="dataAvi">
                        <th mat-header-cell *matHeaderCellDef>Data aviamento</th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Data Avia.">
                          {{ element.dataEntrega | dateNumericFormat }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="valorEnc">
                        <th mat-header-cell *matHeaderCellDef>
                          Valor encomendado
                        </th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right"
                          data-label="Valor Enc.:">
                          {{ element.encomendado }}€
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="valorNaoAv">
                        <th mat-header-cell *matHeaderCellDef>
                          Valor não aviado
                        </th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right"
                          data-label="Valor Não Enc.">
                          {{ element.pendente }}€
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="ValorFat">
                        <th mat-header-cell *matHeaderCellDef>Valor Facturado</th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right"
                          data-label="Valor Fat.:">
                          {{ element.faturado }}€
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell class="has_label_on_mobile t-center" *matCellDef="let element">
                          <button class="btn btn-plus" aria-label="Informações de encomenda" (click)="goToEncomendaInfo(element)" [disabled]="isLoading">
                            <img src="../../assets/Icons/plus-icon.png" class="i-font" alt="Pesquisar" />
                          </button>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>

                    <mat-paginator class="text-right" #historicoPaginator
                      [hidden]="encomendaDataSource.filteredData.length <= 0" [pageSize]="querySize"
                      [pageSizeOptions]="[]" (page)="pageOrdersChanged($event)" showFirstLastButtons>
                    </mat-paginator>

                    <div *ngIf="!encomendaDataSource">
                      <h2>
                        Lamentamos, mas de momento a informação não se encontra
                        disponivel. Tente novamente mais tarde.
                      </h2>
                    </div>
                  </div>
                </ng-template>
              </div>
            </mat-tab>
            <!-- end of first tab -->

            <mat-tab *ngIf="authService.hasPermissions(['3'])" label="Encomendas Pendentes" aria-label="Encomendas pendentes" id="Pendentes">
              <div>
                <div class="mt-4 w-100 finance-dates-inputs" style="justify-content: left;">
                  <div class="d-flex flex-wrap" style="min-width: 30%">
                    <label for="delivery-address-select">Morada de entrega</label>
                    <mat-select class="custom-select" id="delivery-address-select" aria-label="Selectionar morada de entrega" [(value)]="selectedAddressId"
                      (valueChange)="onPendingOrdersRequest()">
                      <mat-option class="custom-options" *ngFor="let address of deliveryAddresses"
                        [value]="address.codigoMorada">{{ address.street }}, {{ address.locality }}, {{ address.postCode }}</mat-option>
                    </mat-select>
                  </div>
                </div>

                <div *ngIf="isLoadingPending; else pending" class="loading-animation" role="alert" aria-live="assertive">
                  <app-loading></app-loading>
                </div>

                <ng-template #pending>
                  <div *ngIf="pendingOrdersDataSource" class="row position-relative finance-dates-inputs">
                    <table mat-table [dataSource]="pendingOrdersDataSource" aria-labelledby="Tabela de encomendas pendentes"
                      [ngClass]="{ 'body-opacity': isLoadingPending }">
                      <ng-container matColumnDef="encomendaNr">
                        <th mat-header-cell *matHeaderCellDef>Encomenda nº</th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Nr Encomenda:">
                          {{ element.encomenda }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="ref">
                        <th mat-header-cell *matHeaderCellDef>Referência</th>
                        <td mat-cell class="has_label_on_mobile" *matCellDef="let element" data-label="Ref:">
                          {{ element.numLinha }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="produto">
                        <th mat-header-cell *matHeaderCellDef>Produto</th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Produto:">
                          {{ element.produto }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="quantidade">
                        <th mat-header-cell *matHeaderCellDef>Quantidade</th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Qtd:">
                          {{ element.quantidade }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="regularizado">
                        <th mat-header-cell *matHeaderCellDef>Regularizadoa</th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Regularizado:">
                          {{ element.regularizado }}
                        </td>
                      </ng-container>
                      <th mat-header-cell *matHeaderCellDef>Regularizado</th>
                      <ng-container matColumnDef="bonus">
                        <th mat-header-cell *matHeaderCellDef>Bonus</th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Bonus:">
                          {{ element.bonus }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="stock">
                        <th mat-header-cell *matHeaderCellDef>Stock</th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Stock:"
                          style="text-align: -webkit-center; text-align: -moz-center;">
                          <div class="custom-button" [ngClass]="{
                              green: element.stock >= 10,
                              yellow: element.stock < 10 && element.stock > 0,
                              red: element.stock === 0
                            }"></div>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="eliminar">
                        <th mat-header-cell *matHeaderCellDef>eliminar</th>
                        <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Eliminar:">
                          <mat-slide-toggle (change)="toggleRowSelection(element)"></mat-slide-toggle>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsPending"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsPending"></tr>
                    </table>
                    <div class="d-flex flex-column w-100">
                      <mat-paginator #pendingPaginator [hidden]="
                        pendingOrdersDataSource.filteredData.length <= 0
                      " [pageSize]="querySizePending" [pageSizeOptions]="[]" (page)="pagePendingChanged($event)"
                        showFirstLastButtons>
                      </mat-paginator>

                      <ng-container *ngIf="selectedRows.length > 0" class="obsDiv">
                        <div class="d-flex flex-column">
                          <label for="obs-input" class="obs">Observações:</label>
                          <textarea class="input-form" id="obs-input" type="text" [(ngModel)]="inputText">
                        </textarea>
                        </div>
                        <div style="padding-top: 15px">
                          <span>
                            Caro Cliente, <br />
                            O seu pedido de remoção de pendentes será efetuado nas
                            próximas 24 a 48h. Informamos que este é um processo
                            manual, por isso pode não ser possível remover os seus
                            pendentes atempadamente, antes da sua próxima
                            encomenda. Recordamos que, apenas poderão ser
                            removidos produtos que não sejam parte integrante de
                            campanhas, ou que não tenham sido requisitados
                            especificamente a pedido do cliente.
                          </span>
                          <div class="aligment-text">
                            <span class="span-title">Produtos selecionados para remoção</span>
                            <span *ngFor="let item of selectedRows" class="span-produtos">
                              {{ item.encomenda }} - {{ item.codigo }} -
                              {{ item.produto }}
                            </span>
                          </div>
                        </div>
                        <div class="d-flex flex-row" style="justify-content: flex-end">
                          <div class="btn-send" (click)="logSelectedRows()">
                            Enviar
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div *ngIf="!pendingOrdersDataSource && !isLoadingPending">
                      <h2>
                        Lamentamos, mas de momento a informação não se encontra
                        disponivel. Tente novamente mais tarde.
                      </h2>
                    </div>
                  </div>
                </ng-template>
              </div>
            </mat-tab>
          </mat-tab-group>

          <!-- ENCOMENDA INFO -->
          <ng-template #encomendaDetails>
            <div *ngIf="isLoading && !pageChanged; else notLoading" class="spinner-container" role="alert" aria-live="assertive">
              <app-loading></app-loading>
            </div>
            <ng-template #notLoading>
              <div style="display: inline-block">
                <button class="btn" (click)="goToOrders()" style="float: left" aria-label="Histórico de Encomendas">
                  <img src="../../assets/Icons/arrow-left-line.png" alt="Back"  />
                </button>
                <p style="overflow: hidden; padding-top: 10px">
                  Histórico de Encomendas
                </p>
              </div>

              <div class="encomenda-container">
                <div *ngIf="encomendaDetails != null" class="encomenda-info">
                  <div class="row">
                    <div class="col-md-6 col-xs-12">
                      <h4>
                        Encomenda Nº: <span>{{ encomendaInfo?.numero }}</span>
                      </h4>
                    </div>
                    <div class="col-md-6 col-xs-12">
                      <h4>
                        Valor: <span>{{ encomendaInfo?.encomendado }}€</span>
                      </h4>
                    </div>
                    <div class="col-md-6 col-xs-12">
                      <h4>
                        Data de compra:
                        <span>{{
                        encomendaInfo!.dataEncomenda | dateNumericFormat
                      }}</span>
                      </h4>
                    </div>
                    <div class="col-md-6 col-xs-12" *ngIf="encomendaProdutos.fatura">
                      <h4>Fatura</h4>
                      <button mat-icon-button matTooltip="Download Fatura" [disabled]="isLoadingPdf" class="btn-pdf"
                        (click)="onPdfClick()" aria-label="Download fatura">
                        <mat-icon svgIcon="pdf_file" class="btn-pdf-icon"></mat-icon>
                      </button>
                    </div>
                    <!--
                  <div class="col-md-6 col-xs-12">
                    <h4>
                      Morada de Entrega:
                      <span>{{ encomendaInfo?.localEntrega }}</span>
                    </h4>
                  </div>
                  -->
                  </div>
                </div>

                <div *ngIf="displayedEncomendaProdutos && displayedEncomendaProdutos.length > 0">
                  <div *ngFor="
                    let product of displayedEncomendaProdutos;
                    let i = index
                  " class="product-container encomenda-info">
                    <div class="row pl-3">
                      <h2>Envio {{ i + 1 }}/{{ encomendaProdutos.productsEncomenda.totalCount }}</h2>
                    </div>
                    <div class="prod-image">
                      <img [src]="productImage(product)" alt="Product" />
                    </div>
                    <div class="product">
                      <div class="row">
                        <div class="col-md-5 col-xs-12">
                          <h4>
                            Produto: <span>{{ product.designacao }}</span>
                          </h4>
                        </div>
                        <div class="col-md-4 col-xs-12">
                          <h4>
                            Referência: <span>{{ product.referencia }}</span>
                          </h4>
                        </div>
                        <div class="col-md-3 col-xs-12">
                          <h4>
                            Quantidade: <span>{{ product.requestNumber }} </span>
                          </h4>
                        </div>
                        <div class="col-md-5 col-xs-12">
                          <h4>
                            Situação: <span>{{ product.status }}</span>
                          </h4>
                        </div>
                        <div class="col-md-4 col-xs-12">
                          <h4>
                            Bonus: <span>{{ product.produtoBonus }}</span>
                          </h4>
                        </div>
                        <div class="col-md-3 col-xs-12">
                          <h4>
                            Servidos: <span>{{ product.served }}</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <button *ngIf="
                      displayedEncomendaProdutos.length <
                      encomendaProdutos.productsEncomenda.totalCount
                    " class="btn-more" [disabled]="pageChanged" aria-label="Mostrar mais resultados" (click)="loadMore()">
                      <mat-spinner *ngIf="pageChanged" diameter="20"></mat-spinner>
                      <span>Mostrar Mais Resultados</span>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
