<div class="main-page">
  <div class="left-side">
    <img src="../../../assets/images/bio2Logo.png" alt="Logótipo da Bio 2" />
  </div>
  <div class="right-side">
    <img src="../../assets/images/bio2Logo.png" class="logoMobile" alt="Logótipo da Bio 2">
    <form>
      <div class="d-flex flex-column">
        <span class="title">Bem-vindo à Loja Bio2!</span>
        <span class="semiTitle">Para entrar com as credenciais de cliente, insira os dados
          abaixo:</span>
      </div>

      <!--*SearchBox DropDown-->
      <div class="d-flex flex-column">
          <label class="user-pass" for="client_search_input">Cliente
            <span class="required">*</span>
          </label>
        <mat-form-field class="w-10">
          <input class="input-txt-address" id="client_search_input" type="text" required="true" matInput [formControl]="myControl" [matAutocomplete]="auto" />
          <mat-autocomplete (optionsScroll)="onScroll()" #auto="matAutocomplete" (optionSelected)="selectedClient($event)" [displayWith]="displayFn">
            <!--!No painel de sugestoes so mostra 100 de cada vez-->
              <mat-option *ngFor="let option of userObservable | async |  slice : 0 : 100" [value]="option">
                {{ option.clientNumber }} - {{ option.name }}
              </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>


      <!--*Moradas Radio Buttons-->
      <div class="d-flex flex-column" style="margin-left: 5px">
        <label class="user-pass" for="address">Morada
          <span class="required">*</span>
        </label>
        <div *ngIf="clientSelected != undefined" class="btn-radio-span">
          <div *ngFor="let address of clientSelected.addresses">
            <label for="address_{{ address.id }}" class="d-flex w-10">
              <input class="d-flex btn-radio-check" id="address_{{ address.id }}" [value]="address" type="radio"
                required="true" name="addresses" [(ngModel)]="addressSelected" />
              <span class="span-addresses-label d-flex">{{ address.street }}, {{ address.locality }},
                {{ address.postCode }}</span>
            </label>
          </div>
        </div>
      </div>

      <!--*Button Submit-->
      <button type="submit" [disabled]="!addressSelected || loading" (click)="saveAddress()">
        <span>Login</span>
        <img src="../../assets/Icons/smallRow.png" alt="Seta para avançar" class="smallRow">
      </button>
    </form>
  </div>
</div>
