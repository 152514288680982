import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  ImportProductsRequest,
  ImportProductsResponse,
} from 'src/app/api/models/interface-products';
import { InterfaceProductsService } from 'src/app/api/services/interface-products.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';

@Component({
  selector: 'app-import-products',
  templateUrl: './import-products.component.html',
  styleUrls: ['./import-products.component.css'],
})
export class ImportProductsComponent {
  loading: boolean = false;
  submitted: boolean = false;
  fileDate!: string;
  numReferencias: number = 1;
  referencia!: string;
  prodsFile: boolean = false;
  seeXml: boolean = false;
  xml: boolean = false;

  mandatory: boolean = false;
  valid: boolean = true;
  data!: ImportProductsResponse[];

  @ViewChild('downloadLink') downloadLink!: ElementRef;

  constructor(
    private interProducts: InterfaceProductsService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    const newDate = new Date();
    const data = newDate.toISOString().split('T')[0].replace(/\D/g, '');
    this.fileDate = `prods_${data}.txt`;
  }

  validateInput(inputString: string): boolean {
    //?só aceita numeros e virgulas
    const regex = /^(\d+,)*\d+$/;
    return regex.test(inputString);
  }

  processar() {
    this.submitted = true;
    this.mandatory = !this.referencia && !this.prodsFile;
    this.valid = !this.mandatory ? true : this.validateInput(this.referencia);

    if (!this.mandatory && this.valid) {
      this.loading = true;
      this.xml = this.seeXml;
      const file = this.prodsFile;

      //split by ',' and remove spaces
      const arrayReferencias: string[] = this.referencia
        ? this.referencia
            .split(',')
            .map((item) => item.trim())
            .filter((item) => item !== '')
        : [];

      const request: ImportProductsRequest = {
        references: arrayReferencias,
        checkXML: this.xml,
        checkFile: file,
      };

      this.interProducts.importProducts(request).subscribe({
        next: (resp) => {
          this.data = resp;
          if (resp[0].statusCode === 500 || resp[0].statusCode === 404) {
            this.snackBarService.openSnackBar(
              `${resp[0].errorMessage}`,
              '#F97066',
              '../../../../assets/Icons/ErrorIcon.png'
            );
          } else {
            this.snackBarService.openSnackBar(
              `Processo concluído.`,
              '#0AA174',
              '../../../../assets/Icons/correct.png'
            );
          }
        },
        error: (err) => {
          this.snackBarService.openSnackBar(
            `Erro na importação de produtos.`,
            '#F97066',
            '../../../../assets/Icons/ErrorIcon.png'
          );
          console.error(err);
          this.seeXml, this.prodsFile, this.loading = false;
        },
        complete: () => {
          this.seeXml = false;
          this.prodsFile = false;
          this.loading = false;
        },
      });
    } else {
      return;
    }
  }

  downloadFile() {
    this.loading = true;
    this.interProducts.downloadFile().subscribe({
      next: (resp) => {
        if (resp.statusCode === 200) {
          const fileContents = atob(resp.data.fileContents); // Decode base64 content
          const blob = new Blob([fileContents], { type: resp.data.contentType });

          const downloadLink = this.downloadLink.nativeElement;
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = this.fileDate;

          // Trigger the click event on the hidden link
          downloadLink.click();

          this.snackBarService.openSnackBar(
            `Ficheiro descarregado.`,
            '#0AA174',
            '../../../../assets/Icons/correct.png'
          );
        } else {
          this.snackBarService.openSnackBar(
            `${resp.message}`,
            '#F97066',
            '../../../../assets/Icons/ErrorIcon.png'
          );
        }
      },
      error: (err) => console.error(err),
      complete: () => (this.loading = false),
    });
  }

  fileStatusChanged(event: any) {
    this.prodsFile = event.target.checked;
  }
  xmlStatusChanged(event: any) {
    this.seeXml = event.target.checked;
  }
}
