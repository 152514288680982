<ng-container>
    <div class="campaign-dialog-content">
        <mat-dialog-content class="pb0">
            <div class="col">
                <div class="row teste1">
                    <div class="header">
                        <div class="header-text">{{data.action}} {{data.title}}</div>
                        <div [mat-dialog-close]="false" class="btn d-flex flex-row close">
                            <span></span>
                            <img src="../../../../assets/Icons/close-line.png" alt="imagem">
                        </div>
                    </div>
                </div>
                <div class="row information">
                    Tem a certeza que pretende {{data.action | lowercase }} {{data.description}}?
                </div>
                <div class="buttons">
                    <div class="btn d-flex flex-row cancel-button" [mat-dialog-close]="false">
                        <span>Cancelar</span>
                    </div>
                    <div *ngIf="data.action === 'Eliminar' " [mat-dialog-close]="true" class="btn d-flex flex-row confirm-button">
                        <span>Eliminar</span>
                        <img src="../../../../assets/Icons/delete.png" alt="imagem">
                    </div>
                    <div *ngIf="data.action === 'Guardar' " [mat-dialog-close]="true" class="btn d-flex flex-row confirm-button">
                        <span>Guardar</span>
                        <img src="../../../../assets/Icons/correct.png" alt="imagem">
                    </div>
                    <div *ngIf="data.action === 'Continuar' " [mat-dialog-close]="true" class="btn d-flex flex-row confirm-button">
                        <span>Continuar</span>
                        <img src="../../../../assets/Icons/correct.png" alt="imagem">
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
</ng-container>
