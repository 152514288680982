import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './Interface/dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SelectionComponent } from './selection/selection.component';
import { AuthGuard } from './api/services/auth-guard.service';
import { ShopComponent } from './Main/shop/shop.component';
import { SubLoginComponent } from './sub-login/sub-login.component';
import { HomeComponent } from './Main/shop/home/home.component';
import { PerfilComponent } from './Main/perfil/perfil.component';
import { StatisticsComponent } from './Main/statistics/statistics.component';
import { EncomendasComponent } from './encomendas/encomendas.component';
import { CampaignsComponent } from './Main/campaigns/campaigns.component';
import { ContactsComponent } from './Main/contacts/contacts.component';
import { FavoritesComponent } from './Main/favorites/favorites.component';
import { FinancesComponent } from './Main/finances/finances.component';
import { CondicoesComponent } from './Main/condicoes/condicoes.component';
import { CheckoutComponent } from './Main/checkout/checkout.component';
import { AvatarChoiceComponent } from './avatar-choice/avatar-choice.component';
import { ComunicadoComponent } from './Main/comunicado/comunicado.component';
import { ComunicadosComponent } from './Interface/comunicados/comunicados.component';
import { AddEditComunicadoComponent } from './Interface/comunicados/add-edit-comunicado/add-edit-comunicado.component';
import { DashboardCampaignsComponent } from './Interface/campanhas/dashboard-campaign/dashboard-campaigns.component';
import { MediaComponent } from './Interface/Import/media/media.component';
import { CreateCampaignComponent } from './Interface/campanhas/create-campaigns/create-campaign/create-campaign.component';
import { ClientsComponent } from './Interface/Import/clients/clients.component';
import { ExportEncomendasComponent } from './Interface/export-encomendas/export-encomendas.component';
import { RegistosComponent } from './Interface/Import/registos/registos.component';
import { SubLoginClientComponent } from './sub-login-client/sub-login-client.component';
import { ImportProductsComponent } from './Interface/Import/import-products/import-products.component';
import { EditCampaignComponent } from './Interface/campanhas/edit-campaign/edit-campaign/edit-campaign.component';
import { ProdutosDestaqueComponent } from './Interface/produtos-destaque/produtos-destaque.component';
import { CombinedAuthGuardService } from './api/services/combined-auth-guard.service';
import { AddProdutosComponent } from './Main/add-produtos/add-produtos.component';
import { AddCampanhasComponent } from './Main/add-campanhas/add-campanhas.component';
import { EditProductComponent } from './Interface/edit-product/edit-product.component';
import { SuggestionsComponent} from "./Interface/suggestions/suggestions.component";
import { CampaignGroupComponent } from './Custom/campaign-group/campaign-group.component';
import { CampaignDetailsComponent } from './Custom/campaign-details/campaign-details.component';
import { AuthGuardUser } from './api/services/auth-guard-user.service';
import { AuthPermissionsGuard } from './api/services/auth-permissions.guard';
import { AuthLabGuard } from './api/services/auth-lab.guard';

// Define the routes
const routes: Routes = [
  { path: 'shop', component: ShopComponent, canActivate: [AuthPermissionsGuard] },
  { path: 'orders', component: EncomendasComponent, canActivate: [AuthPermissionsGuard], data: { permissions: ['3'] } },
  { path: 'recover-password', component: RecoverPasswordComponent },
  { path: 'password-recovery', component: ResetPasswordComponent },
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/subLoginClient', component: SubLoginClientComponent, canActivate: [AuthPermissionsGuard], data: { permissions: ['1'] } },
  { path: 'home', component: HomeComponent },
  { path: 'perfil', component: PerfilComponent, canActivate: [AuthPermissionsGuard], data: { permissions: ['19', '28', '29'] } },
  { path: 'statistics', component: StatisticsComponent, canActivate: [AuthPermissionsGuard], data: { permissions: ['30'] } },
  { path: 'campaigns', component: CampaignsComponent, canActivate: [AuthPermissionsGuard], data: { permissions: ['4'] } },
  { path: 'campaigns/:id', component: CampaignDetailsComponent, canActivate: [AuthPermissionsGuard], data: { permissions: ['4'] }  },
  { path: 'campaigns/:id/group/:idGroup', component: CampaignGroupComponent, canActivate: [AuthPermissionsGuard], data: { permissions: ['4'] }  },
  { path: 'campaigns/:homeId', component: CampaignsComponent, canActivate: [AuthPermissionsGuard], data: { permissions: ['4'] }  },
  { path: 'contacts', component: ContactsComponent },
  {
    path: 'favorites',
    component: FavoritesComponent,
    canActivate: [AuthGuardUser]
  },
  { path: 'finances', component: FinancesComponent, canActivate: [AuthPermissionsGuard], data: { permissions: ['5', '8', '9', '10', '13'] } },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'condicoes', component: CondicoesComponent },
  { path: 'avatar', component: AvatarChoiceComponent },
  { path: 'comunicado', component: ComunicadoComponent },
  { path: 'add/produtos', component: AddProdutosComponent, canActivate: [AuthLabGuard] },
  { path: 'add/campanhas', component: AddCampanhasComponent, canActivate: [AuthLabGuard] },
  {
    path: 'backoffice/dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/comunicados',
    component: ComunicadosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/comunicados/add',
    component: AddEditComunicadoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/comunicados/edit/:id',
    component: AddEditComunicadoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/destaque',
    component: ProdutosDestaqueComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/import/products',
    component: ImportProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/import/media',
    component: MediaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/import/stock',
    component: MediaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/import/clients',
    component: ClientsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/import/registos',
    component: RegistosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/export/encomendas',
    component: ExportEncomendasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/campanhas',
    component: DashboardCampaignsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/campanhas/create',
    component: CreateCampaignComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/campanhas/edit/:id',
    component: EditCampaignComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/edit-product',
    component: EditProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'backoffice/suggestions',
    component: SuggestionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login/selection/subLogin',
    component: SubLoginComponent,
    canActivate: [CombinedAuthGuardService],
  },
  {
    path: 'login/selection',
    component: SelectionComponent,
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
