<div class="contact-box">
  <div class="d-flex flex-column" style="gap: 10px; overflow: hidden">
    <mat-icon [svgIcon]="icon" class="contact-icons p-0 m-0"></mat-icon>
    <span class="title">{{name}}</span>
    <span class="email">{{email}}</span>
  </div>
  <button type="button" class="btn btn-contact mt-2" (click)="openDialog()">
    <span>Enviar mensagem</span>
    <mat-icon class="align-middle">arrow_forward</mat-icon>
  </button>
</div>
