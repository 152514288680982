<div class="main-content d-flex flex-column">
  <div class="d-flex flex-row w-100 header justify-content-between">
    <div>
      <span class="title1">Filtros</span>
    </div>
    <div>
      <div (click)="closeMenu()" class="close-icon">
        <img src="../../../assets/Icons/close-line.png" alt="close">
      </div>
    </div>
  </div>
  <div class="search-topics">
    <div class="search-topic">
      <h4>Família</h4>
      <mat-form-field>
        <mat-select [(value)]="selectedFamily" (selectionChange)="onFamilyChange()"
          [compareWith]="compareFamilyObjects">
          <mat-option>---</mat-option>
          <mat-option *ngFor="let family of sortFamilyItems" [value]="family">
            {{family.descricao}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="search-topic" *ngIf="shouldShow2ndFilter">
      <h4 *ngIf="!shouldChange">Tipo</h4>
      <h4 *ngIf="shouldChange">Substâncias Ativas</h4>
      <mat-form-field>
        <input class="input-txt-address" type="text" matInput [formControl]="filter2ndControl"
          [matAutocomplete]="auto" />
        <mat-autocomplete (optionsScroll)="onScroll('type')" #auto="matAutocomplete"
          (optionSelected)="typeSelection($event)">
          <mat-option *ngFor="let type of typeObservable | async | slice: 0 : 100" [value]="type">
            {{ type.descricao }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="search-topic" *ngIf="shouldShow3rdFilter">
      <h4 *ngIf="!shouldChange">Marca</h4>
      <h4 *ngIf="shouldChange">ATC</h4>
      <mat-form-field>
        <input class="input-txt-address" type="text" matInput [formControl]="filter3rdControl"
          [matAutocomplete]="auto" />
        <mat-autocomplete (optionsScroll)="onScroll('brand')" #auto="matAutocomplete"
          (optionSelected)="brandSelection($event)">
          <mat-option *ngFor="let brand of brandObservable | async | slice: 0 : 100" [value]="brand">
            {{brand.descricao}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="search-topic" *ngIf="shouldShow4thFilter">
      <h4>Idade</h4>
      <mat-form-field>
        <input class="input-txt-address" type="text" matInput [formControl]="filter4thControl"
          [matAutocomplete]="autoIdade" />
        <mat-autocomplete (optionsScroll)="onScroll('structure')" #autoIdade="matAutocomplete"
          (optionSelected)="structureSelection($event)">
          <mat-option *ngFor="let structure of structureObservable | async | slice: 0 : 100" [value]="structure">
            {{structure.descricao}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="search-topic">
      <h4>Especie</h4>
      <mat-form-field>
        <mat-select [(value)]="selectedSpecie" [compareWith]="compareSpeciesObjects">
          <mat-option>---</mat-option>
          <mat-option *ngFor="let specie of sortSpecieItems" [value]="specie">
            {{specie.nomeDisplay}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="btn-apply" (click)="applyFilters()"><span>Aplicar</span></div>
</div>
