<div class="main-side-menu">
  <div class="side-menu-fixed-content">
    <img style="width: 120px" src="../../../assets/images/bio2Logo.png" alt="Bio2Logo" />
    <a class="routerLink" routerLink="/home" routerLinkActive="active">Home</a>
    <a class="routerLink" routerLink="/shop" routerLinkActive="active">Loja</a>
    <a *ngIf="authService.hasPermissions(['4'])" class="routerLink" routerLink="/campaigns" routerLinkActive="active">Campanhas</a>
    <a *ngIf="authService.hasPermissions(['3'])" class="routerLink" routerLink="/orders"
      routerLinkActive="active">Encomendas</a>
    <a *ngIf="authService.hasPermissions(['5', '8', '9', '10', '13'])" class="routerLink" routerLink="/finances"
      routerLinkActive="active">Financeira</a>
    <a *ngIf="authService.hasPermissions(['30'])" class="routerLink" routerLink="/statistics" routerLinkActive="active">Estatísticas</a>
    <a *ngIf="currentUser.role !== 'Administrador'" class="routerLink" routerLink="/favorites"
      routerLinkActive="active">Favoritos</a>
    <a class="routerLink" routerLink="/contacts" routerLinkActive="active">Contactos</a>
  </div>
</div>

<div *ngIf="isSideMenuOpen" class="mobile-side-menu">
  <div style="display: flex; flex-direction: column; margin-bottom: 20px">
    <div style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      ">
      <img style="width: 110px" src="../../../assets/images/bio2Logo.png" alt="Bio2Logo" />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" (click)="closeSideMenu()" height="40" width="40"
        alt="Avatar Icon">
        <path
          d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z">
        </path>
      </svg>
    </div>
    <div style="display: flex; align-items: center">
      <img src="../../../assets/avatars/{{ avatar }}.svg" height="50" alt="Avatar Icon" style="padding-right: 10%" />
      <span style="font-size: 1.2rem">
        Pontos: {{ userPoints ? userPoints : 0 }}
      </span>
    </div>
  </div>
  <a class="routerLink" (click)="closeSideMenu()" routerLink="/home" routerLinkActive="active">Home</a>
  <a *ngIf="authService.hasPermissions(['2'])" class="routerLink" (click)="closeSideMenu()" routerLink="/shop" routerLinkActive="active">Loja</a>
  <a class="routerLink" (click)="closeSideMenu()" routerLink="/campaigns" routerLinkActive="active">Campanhas</a>
  <a *ngIf="authService.hasPermissions(['3'])" class="routerLink" (click)="closeSideMenu()" routerLink="/orders"
    routerLinkActive="active">Encomendas</a>
  <a *ngIf="authService.hasPermissions(['5', '8', '9', '10', '13'])" class="routerLink" (click)="closeSideMenu()" routerLink="/finances"
    routerLinkActive="active">Financeira</a>
  <a *ngIf="authService.hasPermissions(['30'])" class="routerLink" (click)="closeSideMenu()" routerLink="/statistics" routerLinkActive="active">Estatísticas</a>

  <a class="routerLink" (click)="closeSideMenu()" *ngIf="currentUser.role === 'Laboratorio'" routerLink="/add/produtos"
    routerLinkActive="active">Adicionar produto</a>
  <a class="routerLink" (click)="closeSideMenu()" *ngIf="currentUser.role === 'Laboratorio'" routerLink="/add/campanhas"
    routerLinkActive="active">Adicionar campanha</a>
  <a class="routerLink" (click)="closeSideMenu()" routerLink="/contacts" routerLinkActive="active">Contactos</a>
  <a *ngIf="authService.hasPermissions(['19', '28', '29'])" class="routerLink" (click)="closeSideMenu()" routerLink="/perfil" routerLinkActive="active">Perfil</a>
  <a *ngIf="currentUser.role !== 'Administrador'" class="routerLink" (click)="closeSideMenu()" routerLink="/favorites"
    routerLinkActive="active">Favoritos</a>
  <a *ngIf="authService.hasPermissions(['1'])" class="routerLink" (click)="closeSideMenu()"
    routerLink="/login/selection/subLogin" routerLinkActive="active">Alterar Cliente</a>
  <a class="routerLink" (click)="closeSideMenu(); logout()" routerLink="/login" routerLinkActive="active">Logout</a>
</div>
