import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MoveFileResponse, ProductStockUpdate } from 'src/app/api/models/media';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { MediaService } from 'src/app/api/services/media.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { StockService } from 'src/app/api/services/stock.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css'],
})
export class MediaComponent implements OnInit {
  isLoading: boolean = false;
  currentUrl: string = '';
  loggedUser: ProfileDataRequest = this.perfilService.getCurrentUserInfo();
  isMedia: boolean = false;
  data!: MoveFileResponse[];
  stockData!: ProductStockUpdate[];

  selectedFiles: FileList = new DataTransfer().files;;

  constructor(
    private mediaService: MediaService,
    private stockService: StockService,
    private perfilService: PerfilService,
    private router: Router,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    if (this.currentUrl.endsWith('media')) {
      this.isMedia = true;
    }
    if (this.currentUrl.endsWith('stock')) {
      this.isMedia = false;
    }
  }

  processar() {
    this.isLoading = true;
    this.data = [];
    this.mediaService.importMedia().subscribe({
      next: (resp) => {
        this.data = resp;
        if(resp[0].statusCode === 404) {
          this.snackBarService.openSnackBar(
            `${resp[0].message}`,
            '#F97066',
            '../../../../assets/Icons/ErrorIcon.png'
          );
        }else {
          this.snackBarService.openSnackBar(
            `Ficheiros Atualizados`,
            '#0AA174',
            '../../../../assets/Icons/correct.png'
          );
        }
      },
      error: (e) => console.error(e),
      complete: () => (this.isLoading = false),
    });
  }

  uploadFiles() {
    if(this.selectedFiles.length > 0) {
      this.isLoading = true;

      const formData = new FormData();
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append('files', this.selectedFiles[i]);
      }

      this.mediaService.uploadFiles(formData).subscribe({
        next: (resp) => {
          this.data = resp;
          this.snackBarService.openSnackBar(
            `Processo concluído`,
            '#0AA174',
            '../../../../assets/Icons/correct.png'
          );
        },
        error: (err) => {
          console.error(err);
          this.isLoading = false
          this.snackBarService.openSnackBar(
            `Processo não concluído`,
            '#F97066',
            '../../../../assets/Icons/ErrorIcon.png'
          );
        },
        complete: () => this.isLoading = false
      });
    }
    else {
      return;
    }
  }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
  }

  stockProcessar() {
    this.isLoading = true;
    this.stockData = [];
    this.stockService.importStock(this.loggedUser.userId).subscribe({
      next: (resp) => {
        this.stockData = resp;
        if(resp[0].statusCode === 404) {
          this.snackBarService.openSnackBar(
            `${resp[0].fatalError}`,
            '#F97066',
            '../../../../assets/Icons/ErrorIcon.png'
          );
        }else {
          this.snackBarService.openSnackBar(
            `Stock Atualizado`,
            '#0AA174',
            '../../../../assets/Icons/correct.png'
          );
        }
      },
      error: (e) => console.log(e),
      complete: () => (this.isLoading = false),
    });
  }
}
