/* tslint:disable */
/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';

import { ApproveComunicado, Comunicado } from '../models/tb-comunicado';

@Injectable({ providedIn: 'root' })
export class ComunicadosService extends BaseService {
  route = new ApiConfiguration();
  private activeComunicado: any;

  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  ApiComunicadosGetPath = this.config.rootUrl + '/api/comunicados';

  getComunicados(): Observable<Comunicado[]> {
    return this.http.get<Comunicado[]>(this.ApiComunicadosGetPath)
  }

  async getComunicadoByUser(username: string): Promise<any> {
    try {
      const ApiGetComunicadoByUserPath = this.route.rootUrl + '/api/comunicados/user';

      const source = this.http
        .get<Comunicado[]>(`${ApiGetComunicadoByUserPath}/${username}`)
        .pipe(
          catchError((error) => {
            throw error;
          })
        );
        return await lastValueFrom(source);
    } catch (error) {
      throw error;
    }
  }

  getComunicadoByUserObs(username: string): Observable<Comunicado[]> {
    try {
      const ApiGetComunicadoByUserPath = this.route.rootUrl + '/api/comunicados/user';
      return this.http
        .get<Comunicado[]>(`${ApiGetComunicadoByUserPath}/${username}`)
        .pipe(
          catchError((error) => {
            throw error;
          })
        );
    } catch (error) {
      throw error;
    }
  }

  approveComunicado(request: ApproveComunicado): Observable<any> {
    const ApiGetApproveComunicadoPath = this.route.rootUrl + '/api/comunicados/approve';
    return this.http.post<any>(`${ApiGetApproveComunicadoPath}`, request)
    .pipe(
      catchError((error) => {
        throw error;
      })
    );
  }


  getComumicado(id: string): Observable<Comunicado> {
    const apiGetComunicado = this.rootUrl + '/api/comunicados';
    return this.http.get<Comunicado>(`${apiGetComunicado}/${id}`)
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  postComunicado(request: Comunicado): Observable<void> {
    const apiPostComunicado = this.rootUrl + '/api/comunicados';
    const formData = new FormData();

    if(request.image){
      formData.append('file', request.image);
    }

    formData.append('comunicado', JSON.stringify(request));
    return this.http.post<void>(apiPostComunicado, formData)
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  updateComunicado(id: string, request: Comunicado): Observable<Comunicado> {
    const apiPutComunicado = this.rootUrl + '/api/comunicados';
    const formData = new FormData();

    if(request.image){
      formData.append('file', request.image);
    }

    formData.append('comunicado', JSON.stringify(request));

    return this.http.put<Comunicado>(`${apiPutComunicado}/${id}`, formData)
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  deleteComunicado(id:number){
    const apiDeleteComunicado = this.rootUrl + '/api/comunicados';
    return this.http.delete<Comunicado>(`${apiDeleteComunicado}/${id}`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  //Cache mechanism to comunicados
  getActiveComunicado(): Observable<any> {
    if (this.activeComunicado) {
      return of(this.activeComunicado);
    } else {
      return of(null);
    }
  }

  setActiveComunicado(comunicado: any): void {
    this.activeComunicado = comunicado;
  }
}
