import { AuthenticationInterceptor } from './api/authentication.interceptor';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './Interface/dashboard/dashboard.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomSnackBarComponent } from './Custom/custom-snack-bar/custom-snack-bar.component';
import { SelectionComponent } from './selection/selection.component'; // Import BrowserAnimationsModule
import { AuthGuard } from './api/services/auth-guard.service';
import { ShopComponent } from './Main/shop/shop.component';
import { SideMenuComponent } from './Custom/side-menu/side-menu.component';
import { TopMenuComponent } from './Custom/top-menu/top-menu.component';
import { SubLoginComponent } from './sub-login/sub-login.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { EncomendasComponent } from './encomendas/encomendas.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { HomeComponent } from './Main/shop/home/home.component';
import { PerfilComponent } from './Main/perfil/perfil.component';
import { StatisticsComponent } from './Main/statistics/statistics.component';
import { NgChartsModule } from 'ng2-charts';
import { CampaignsComponent } from './Main/campaigns/campaigns.component';
import { CampaignMainComponent } from './Custom/campaigns-main/campaign-main.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { ContactsComponent } from './Main/contacts/contacts.component';
import { ContactCardComponent } from './Custom/contact-card/contact-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ContactDialogComponent } from './Custom/contact-dialog/contact-dialog.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProductShopComponent } from './Custom/product-shop/product-shop.component';
import { ProductShopListComponent } from './Main/shop/product-shop-list/product-shop-list.component';
import { ShopFilterComponent } from './Custom/shop-filter/shop-filter.component';
import { MatSelectModule } from '@angular/material/select';
import { FavoritesComponent } from './Main/favorites/favorites.component';
import { FinancesComponent } from './Main/finances/finances.component';
import { DateFormatPipe } from './Custom/pipes/date-format.pipe';
import { DateNumericFormatPipe } from './Custom/pipes/date-numeric-format.pipe';
import { InvoiceTypePipe } from './Custom/pipes/invoice-type.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CondicoesComponent } from './Main/condicoes/condicoes.component';
import { CheckoutComponent } from './Main/checkout/checkout.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DashboardTopMenuComponent } from './Custom/dashboard-top-menu/dashboard-top-menu.component';
import { ExtractHourPtPipe } from './Custom/pipes/extract-hour-pt.pipe';
import { ExtractDatePtPipe } from './Custom/pipes/extract-date-pt.pipe';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CampaignsTypeConvertPipe } from './Custom/pipes/campaigns-type-convert.pipe';
registerLocaleData(localePt);
import { AvatarChoiceComponent } from './avatar-choice/avatar-choice.component';
import { MatRadioModule } from '@angular/material/radio';
import { ComunicadoComponent } from './Main/comunicado/comunicado.component';
import { ComunicadosComponent } from './Interface/comunicados/comunicados.component';
import { AddEditComunicadoComponent } from './Interface/comunicados/add-edit-comunicado/add-edit-comunicado.component';
import { NgxEditorModule } from 'ngx-editor';
import { DashboardSideMenuComponent } from './Custom/dashboard-side-menu/dashboard-side-menu.component';
import { DashboardNumbersConverterPipe } from './Custom/pipes/dashboard-numbers-converter.pipe';
import { FooterComponent } from './Custom/footer/footer.component';
import { StatsFilterComponent } from './Custom/stats-filter/stats-filter.component';
import { ProductModalComponent } from './Main/shop/product-modal/product-modal.component';
import { FavoriteModalComponent } from './Main/favorites/favorite-modal/favorite-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckoutDialogComponent } from './Custom/checkout-dialog/checkout-dialog.component';
import { LoadingComponent } from './Custom/loading/loading.component';
import { DeleteModalComponent } from './Interface/comunicados/delete-modal/delete-modal.component';
import { DashboardCampaignsComponent } from './Interface/campanhas/dashboard-campaign/dashboard-campaigns.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MediaComponent } from './Interface/Import/media/media.component';
import { CreateCampaignComponent } from './Interface/campanhas/create-campaigns/create-campaign/create-campaign.component';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditCampaignGroupComponent } from './Interface/campanhas/edit-campaign-group/edit-campaign-group.component';
import { DisplayCampaignGroupComponent } from './Interface/campanhas/display-campaign-group/display-campaign-group.component';
import { CreateCampaignGroupComponent } from './Interface/campanhas/create-campaigns/create-campaign-group/create-campaign-group.component';
import { ClientsComponent } from './Interface/Import/clients/clients.component';
import { CampaignModalComponent } from './Interface/campanhas/create-campaigns/campaign-modal/campaign-modal.component';
import { ExportEncomendasComponent } from './Interface/export-encomendas/export-encomendas.component';
import { RegistosComponent } from './Interface/Import/registos/registos.component';
import { SubLoginClientComponent } from './sub-login-client/sub-login-client.component';
import { ImportProductsComponent } from './Interface/Import/import-products/import-products.component';
import { GenericModalComponent } from './Custom/generic-modal/generic-modal.component';
import { EditCampaignComponent } from './Interface/campanhas/edit-campaign/edit-campaign/edit-campaign.component';
import { ProdutosDestaqueComponent } from './Interface/produtos-destaque/produtos-destaque.component';
import { MatSelectScrollBottomDirective } from './Custom/directives/mat-select-bottom-scroll.directive';
import { CombinedAuthGuardService } from './api/services/combined-auth-guard.service';
import { AddProdutosComponent } from './Main/add-produtos/add-produtos.component';
import { AddCampanhasComponent } from './Main/add-campanhas/add-campanhas.component';
import { DialogEncomendasComponent } from './Main/shop/dialog-encomendas/dialog-encomendas.component';
import { EditProductComponent } from './Interface/edit-product/edit-product.component';
import { DialogProductCampaignComponent } from './Custom/product-shop/dialog-product-campaign/dialog-product-campaign.component';
import { CampaignGroupComponent } from './Custom/campaign-group/campaign-group.component';
import { CampaignDetailsComponent } from './Custom/campaign-details/campaign-details.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchInputProductsComponent } from './Custom/search-input-products/search-input-products.component';
import { ProductBonusModalComponent } from './Custom/product-bonus-modal/product-bonus-modal.component';
import { ScrollDirective } from './Custom/directives/scrollDirective';
import { BoosterComponent } from './Main/shop/booster/booster.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    RecoverPasswordComponent,
    ResetPasswordComponent,
    CustomSnackBarComponent,
    SelectionComponent,
    ShopComponent,
    SideMenuComponent,
    TopMenuComponent,
    SubLoginComponent,
    HomeComponent,
    PerfilComponent,
    StatisticsComponent,
    EncomendasComponent,
    CampaignsComponent,
    CampaignMainComponent,
    ContactsComponent,
    ContactCardComponent,
    ContactDialogComponent,
    ProductShopComponent,
    ProductShopListComponent,
    ShopFilterComponent,
    FavoritesComponent,
    FinancesComponent,
    DateFormatPipe,
    DateNumericFormatPipe,
    InvoiceTypePipe,
    CondicoesComponent,
    CheckoutComponent,
    DashboardTopMenuComponent,
    ExtractHourPtPipe,
    ExtractDatePtPipe,
    CampaignsTypeConvertPipe,
    AvatarChoiceComponent,
    ComunicadoComponent,
    ComunicadosComponent,
    AddEditComunicadoComponent,
    DashboardSideMenuComponent,
    DashboardNumbersConverterPipe,
    FooterComponent,
    StatsFilterComponent,
    ProductModalComponent,
    FavoriteModalComponent,
    CheckoutDialogComponent,
    LoadingComponent,
    DeleteModalComponent,
    DashboardCampaignsComponent,
    MediaComponent,
    CreateCampaignComponent,
    EditCampaignGroupComponent,
    DisplayCampaignGroupComponent,
    CreateCampaignGroupComponent,
    ClientsComponent,
    CampaignModalComponent,
    ExportEncomendasComponent,
    RegistosComponent,
    SubLoginClientComponent,
    ImportProductsComponent,
    EditCampaignComponent,
    GenericModalComponent,
    ProdutosDestaqueComponent,
    MatSelectScrollBottomDirective,
    AddProdutosComponent,
    AddCampanhasComponent,
    DialogEncomendasComponent,
    EditProductComponent,
    DialogProductCampaignComponent,
    CampaignGroupComponent,
    CampaignDetailsComponent,
    SearchInputProductsComponent,
    ProductBonusModalComponent,
    ScrollDirective,
    BoosterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatTableModule,
    NgChartsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatDialogModule,
    MatSidenavModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatRadioModule,
    NgxEditorModule,
    MatCheckboxModule,
    ClipboardModule,
    MatStepperModule,
    ReactiveFormsModule,
    NgxMatFileInputModule,
    MatExpansionModule,
    ScrollingModule
  ],
  providers: [
    AuthGuard,
    CombinedAuthGuardService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
