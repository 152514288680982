import { Component, ViewEncapsulation } from '@angular/core';
import { PerfilService } from '../api/services/perfil.service';
import { Router } from '@angular/router';
import { SnackBarService } from '../api/services/snack-bar.service';
import { AuthService, ComunicadosService } from '../api/services';

@Component({
  selector: 'app-avatar-choice',
  templateUrl: './avatar-choice.component.html',
  styleUrls: ['./avatar-choice.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarChoiceComponent {
  isLoading: boolean = false;
  avatarChoice: any;
  currentUser = this.perfilService.getCurrentUserInfo();
  avatars = [
    {
      name: 'dog',
      avatar: '../../assets/avatars/dog.svg',
    },
    {
      name: 'cat',
      avatar: '../../assets/avatars/cat.svg',
    },
    {
      name: 'bird',
      avatar: '../../assets/avatars/bird.svg',
    },
    {
      name: 'fish',
      avatar: '../../assets/avatars/fish.svg',
    },
  ];

  constructor(
    private perfilService: PerfilService,
    private router: Router,
    private snack: SnackBarService,
    private authService: AuthService,
    private comunicadoServ: ComunicadosService
  ) {}

  saveAvatar(): void {
    this.isLoading = true;
    const updateAvatar = this.perfilService
      .updateAvatarIcon(this.currentUser.userId, this.avatarChoice.name)
      .subscribe({
        next: async (resp) => {
          const decodedToken = this.authService.getDecodedAccessToken(
            localStorage.getItem('BIO2_AccessToken')!
          );
          const role = decodedToken['role'];

          if (resp) {
            this.snack.openSnackBar(
              'Avatar Guardado',
              '#0AA174',
              '../../assets/Icons/correct.png'
            );

            localStorage.setItem('avatar', this.avatarChoice.name);
            let activeComunicado =
              await this.comunicadoServ.getComunicadoByUser(
                decodedToken['unique_name']
              );

            if (activeComunicado.length > 0) {
              this.router.navigate(['/comunicado']);
            } else if (role === 'Administrador') {
              this.router.navigate(['/login/selection']);
            } else if (role === 'Laboratorio') {
              this.router.navigate(['/login/selection/sublogin']);
            } else {
              this.router.navigate(['/home']);
            }
          } else {
            this.snack.openSnackBar(
              'Erro ao guardar avatar',
              '#F97066',
              '../../assets/Icons/correct.png'
            );
          }
        },
        error: (e) => {
          console.log(e);
          updateAvatar.unsubscribe();
        },
        complete: () => {
          this.isLoading = false;
          updateAvatar.unsubscribe();
        },
      });
  }
}
