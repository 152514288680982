<mat-form-field class="w-100" style="height: 80px; font-size: 14px;">
  <input
  matInput
  placeholder="Pesquisar produto"
  type="text"
  [formControl]="searchControl"
  (focus)="handleInputFocus()"
  (keyup)="onSearchInputChanged($event)"
  class="border-bottom mb-1"
/>
  <mat-select
    #matSelectProducts
    [formControl]="selectProducts"
    [multiple]="isMultiple ? true : false"
    [value]="selectedItems"
    (openedChange)="openChange($event)"
    [placeholder]="getPlaceholderText()"
    class="mt-2"
  >
    <mat-select-trigger *ngIf="plist; else loading;">
      {{ getPlaceholderText() }}
    </mat-select-trigger>
    <ng-template #loading>
      <mat-spinner></mat-spinner>
    </ng-template>

    <cdk-virtual-scroll-viewport itemSize="50" [style.height.px]="5 * 48">
      <mat-option
        *cdkVirtualFor="let product of filteredProductLists | async"
        [value]="product"
        (onSelectionChange)="onSelectionChange($event)"
        >{{ product.memoria }}</mat-option
      >
    </cdk-virtual-scroll-viewport>
  </mat-select>
</mat-form-field>
