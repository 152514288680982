import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { Observable, catchError } from 'rxjs';
import { Balance, CurrentExtract, CurrentExtractPoints, FinancePdf, RequestPdf } from '../models/finances';

@Injectable({
  providedIn: 'root'
})
export class FinancesService {

  route = new ApiConfiguration();

  ApiGetBalance = this.route.rootUrl + '/api/finance/balance';
  ApiGetCurrentExtract = this.route.rootUrl + '/api/finance/currentExtract';
  ApiGetPdf = this.route.rootUrl + '/api/finance/displayPdf';
  ApiGetCurrentExtractPontos = this.route.rootUrl + '/api/finance/currentExtractPoints';

  constructor(private http: HttpClient) { }

  getBalance(userId: string, numCliente: string, page: number, pageSize: number): Observable<Balance> {
    let params = new HttpParams();
    params = params.append('userId', userId);
    params = params.append('numCliente', numCliente);
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get<Balance>(this.ApiGetBalance, { params })
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  getPdf(request: RequestPdf): Observable<string> {
    let params = new HttpParams();
    params = params.append('userId', request.userId);
    params = params.append('balanceType', request.type);
    params = params.append('balanceNumber', request.number);
    params = params.append('balanceDate', request.date);
    params = params.append('isBalance', request.isBalance);
    //return this.http.get(this.ApiGetPdf, { params, observe: 'response',  responseType: 'json'});
    return this.http.get<string>(this.ApiGetPdf, { params });
  }

  getCurrentExtract(userId: string, numCliente: string, startDate: string, endDate: string, page: number, pageSize: number): Observable<CurrentExtract> {
    let params = new HttpParams();
    params = params.append('userId', userId);
    params = params.append('numCliente', numCliente);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get<CurrentExtract>(this.ApiGetCurrentExtract, { params })
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  getCurrentExtractPontos(userId: string, numCliente: string, startDate: string, endDate: string, page: number, pageSize: number): Observable<CurrentExtractPoints> {
    let params = new HttpParams();
    params = params.append('userId', userId);
    params = params.append('numCliente', numCliente);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    return this.http.get<CurrentExtractPoints>(this.ApiGetCurrentExtractPontos, { params })
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }
}
