import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateNumericFormat'
})
export class DateNumericFormatPipe implements PipeTransform {
    transform(inputDate: string): string {
        const months: any = {
            Jan: '01', Fev: '02', Mar: '03', Abr: '04', Mai: '05', Jun: '06',
            Jul: '07', Ago: '08', Set: '09', Out: '10', Nov: '11', Dez: '12',
        };

        if(inputDate != "n/a"){
            const day = inputDate.slice(0, 2);
            const monthAbbr = inputDate.slice(2, 5);
            const year = inputDate.slice(5);

            const monthNumeric = months[monthAbbr];
            const convertedDate = `${day}-${monthNumeric}-${year}`;
            return convertedDate;
        }
        return "n/a";
    }
}