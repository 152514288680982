import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs';
import { CartProductModel, CheckoutOrderModel, CheckoutOrderResponseModel, CheckoutViewModel } from '../models/checkout';

@Injectable({
    providedIn: 'root',
})

export class CheckoutService{
    route = new ApiConfiguration();
    ApiGetCheckoutViewModelPath = this.route.rootUrl + '/api/Checkout/get-checkout-view-model';
    ApiCrudCartProductPath = this.route.rootUrl + '/api/Checkout/crud-cart-product';
    ApiExecuteCheckoutPath = this.route.rootUrl + '/api/Checkout/execute-checkout';
    ApiCartProductsPath = this.route.rootUrl + '/api/Checkout/return-cart-products';
    ApiGetClientPoints = this.route.rootUrl + '/api/Checkout/get-client-points';

    constructor(private http: HttpClient, private authService: AuthService) {}

    GetCheckoutViewModel(clientId: string) : Observable<CheckoutViewModel>{
        let accessToken = localStorage.getItem('BIO2_AccessToken');
        let tokenData = this.authService.getDecodedAccessToken(accessToken!);
        let userId = tokenData['registoId'];
        let endpoint = `${this.ApiGetCheckoutViewModelPath}/${userId}/${clientId}`;
        
        let headers = new HttpHeaders({
            Authorization: 'Bearer '+accessToken
        });
        return this.http.get<CheckoutViewModel>(endpoint,{headers:headers});
    }

    ExecuteCheckout(searchParameters: CheckoutOrderModel, bypassSGG: boolean): Observable<CheckoutOrderResponseModel[]> {
        let accessToken = localStorage.getItem('BIO2_AccessToken');
        let tokenData = this.authService.getDecodedAccessToken(accessToken!);
        let userId = tokenData['registoId'];
    
        searchParameters.UserId = userId;
    
        // Append bypassSGG as a query parameter
        let endpoint = `${this.ApiExecuteCheckoutPath}?bypassSGG=${bypassSGG}`;
    
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
        });
    
        return this.http.post<CheckoutOrderResponseModel[]>(endpoint, searchParameters, { headers: headers });
    }
    

    storeSGGFailTime(failTime: string) {
        localStorage.setItem('SGG_Fail_Time', failTime);
    }

    CrudCartProduct(searchParameters : CartProductModel) : Observable<boolean>{
        let accessToken = localStorage.getItem('BIO2_AccessToken');
        let tokenData = this.authService.getDecodedAccessToken(accessToken!);
        
        let endpoint = `${this.ApiCrudCartProductPath}`;
        if(searchParameters.userId == ''){
            let userId = tokenData['registoId'];
            searchParameters.userId = userId;
        }

        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json', // Set the content type to JSON
        });

        return this.http.post<boolean>(endpoint, searchParameters, {headers:headers});
    }

    getUserCart(user: string, clientId: string): Observable<CartProductModel[]> {
        let params = new HttpParams();
        params = params.append('userId', String(user));
        params = params.append('clientId', String(clientId));
        let endpoint = `${this.ApiCartProductsPath}/${user}/${clientId}`;
        return this.http.get<CartProductModel[]>(endpoint)
    }

    getClientPoints(user:string, clientId: string): Observable<number>{
        let params = new HttpParams();
        params = params.append('userId', String(user));
        params = params.append('clientId', String(clientId));
        let endpoint = `${this.ApiGetClientPoints}/${user}/${clientId}`;
        return this.http.get<number>(endpoint);
    }
}