<!-- your-dialog.component.html -->
<div class="main-div-dialog">
  <mat-dialog-content class="mat-typography">
    <div>
      <h1>Consultar produtos pendentes</h1>
      <div class="mt-4 w-100 finance-dates-inputs">
        <div class="d-flex flex-wrap adjustments">
          <label for="delivery-address-select">Morada de entrega</label>
          <mat-select id="delivery-address-select" class="custom-select" [(value)]="selectedAddressId" (valueChange)="onPendingOrdersRequest()">
            <mat-option class="custom-options" *ngFor="let address of deliveryAddresses" [value]="address.codigoMorada">
              {{ address.street }}, {{ address.locality }}, {{ address.postCode }}</mat-option>
          </mat-select>
        </div>
      </div>
      <div *ngIf="isLoadingPending; else pending" class="loading-animation" role="alert" aria-live="assertive">
        <app-loading></app-loading>
      </div>
      <ng-template #pending>
        <div *ngIf="pendingOrdersDataSource" class="row position-relative finance-dates-inputs">
          <table mat-table [dataSource]="pendingOrdersDataSource" [ngClass]="{ 'body-opacity': isLoadingPending }" aria-describedby="Tabela de produtos pendentes">
            <ng-container matColumnDef="encomendaNr">
              <th mat-header-cell *matHeaderCellDef>Encomenda nº</th>
              <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Nr Encomenda:">
                {{ element.encomenda }}
              </td>
            </ng-container>

            <ng-container matColumnDef="ref">
              <th mat-header-cell *matHeaderCellDef>Referência</th>
              <td mat-cell class="has_label_on_mobile" *matCellDef="let element" data-label="Ref:">
                {{ element.numLinha }}
              </td>
            </ng-container>

            <ng-container matColumnDef="produto">
              <th mat-header-cell *matHeaderCellDef>Produto</th>
              <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Produto:">
                {{ element.produto }}
              </td>
            </ng-container>

            <ng-container matColumnDef="quantidade">
              <th mat-header-cell *matHeaderCellDef>Quantidade</th>
              <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Qtd:">
                {{ element.quantidade }}
              </td>
            </ng-container>

            <ng-container matColumnDef="regularizado">
              <th mat-header-cell *matHeaderCellDef>Regularizado</th>
              <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Regularizado:">
                {{ element.regularizado }}
              </td>
            </ng-container>

            <ng-container matColumnDef="bonus">
              <th mat-header-cell *matHeaderCellDef>Bonus</th>
              <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Bonus:">
                {{ element.bonus }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsPending"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsPending"></tr>
          </table>
          <mat-paginator #pendingPaginator [hidden]="pendingOrdersDataSource.filteredData.length <= 0"
            [pageSize]="querySizePending" [pageSizeOptions]="[]" (page)="pagePendingChanged($event)"
            showFirstLastButtons>
          </mat-paginator>
          <div *ngIf="!pendingOrdersDataSource && !isLoadingPending">
            <h2>
              Lamentamos, mas de momento a informação não se encontra
              disponivel. Tente novamente mais tarde.
            </h2>
          </div>
        </div>
      </ng-template>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="encomendas-pendentes" (click)="nagivateToPendentes()" aria-label="Consultar produtos pendentes">
      <span>Consultar produtos pendentes</span>
    </div>
    <div class="fechar" [mat-dialog-close]="true" cdkFocusInitial aria-label="Fechar">
      <span>Fechar</span>
    </div>
  </mat-dialog-actions>
</div>
