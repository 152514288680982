import { NgxMatFileInputComponent } from '@angular-material-components/file-input/lib/file-input.component';
import { DatePipe } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import { Subscription } from 'rxjs';
import { Comunicado } from 'src/app/api/models/tb-comunicado';
import { ComunicadosService } from 'src/app/api/services';
import { CustomIconsService } from 'src/app/api/services/custom-icons.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';

@Component({
  selector: 'app-add-edit-comunicado',
  templateUrl: './add-edit-comunicado.component.html',
  styleUrls: ['./add-edit-comunicado.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditComunicadoComponent {
  form!: FormGroup;
  id!: string;
  isAddMode: boolean = false;
  isLoading: boolean = false;
  datePipe = new DatePipe('en-US');
  editor!: Editor;
  comunicadoValid: boolean = true;
  comunicadoError: string = 'Comunicado...';
  unsubscribe: Subscription = new Subscription();
  submitted:boolean = false;
  startDateValid: boolean = true;
  endDateValid: boolean = true;
  imgType = '.png,.jpeg,.jpg,.gif,.webm';

  comunicado!: Comunicado;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private comunicadoServ: ComunicadosService,
    private iconServ: CustomIconsService,
    private snackServ: SnackBarService,
    private snackBarService: SnackBarService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('pt-PT');
  }

  ngOnInit(): void {
    this.unsubscribe.add(this.route.params.subscribe(params => {
      this.id = params['id'];
    }));

    this.isAddMode = !this.id;
    this.editor = new Editor();

    this.form = this.formBuilder.group({
      id: [this.id],
      type: ['', Validators.required],
      isPermanent: [false, Validators.required],
      startingDate: ['', Validators.required],
      endDate: ['', Validators.required],
      description: ['', Validators.required],
      imageComunicado: ['']
    });

    if (!this.isAddMode) {
      this.isLoading = true;
      this.unsubscribe.add(this.comunicadoServ.getComumicado(this.id).subscribe({
        next: (resp: any) => {
          this.form.patchValue({
            id: this.id,
            type: resp.type,
            isPermanent: resp.isPermanent,
            startingDate: resp.startingDate,
            endDate: resp.endDate,
            description: resp.description
          });
        },
        error: (e) => console.error(e),
        complete: () => this.isLoading = false,
      }));
    }
  }

  get description() { return this.form.get('description'); }

  onSubmit(): void {
    this.submitted = true;
    this.comunicadoValid = this.description?.valid! && this.description.value !== '<p></p>';
    this.comunicadoError = this.comunicadoValid ? 'Comunicado...' : 'Comunicado é obrigatório.'

    //Format dates
    const formattedDateS = this.datePipe.transform(this.form.controls['startingDate'].value, 'yyyy-MM-dd');
    const formattedDateE = this.datePipe.transform(this.form.controls['endDate'].value, 'yyyy-MM-dd');
    this.form.controls['startingDate'].setValue(formattedDateS);
    this.form.controls['endDate'].setValue(formattedDateE);

    this.startDateValid = this.form.get("startingDate")?.value <= this.form.get("endDate")?.value
    this.endDateValid = this.form.get("endDate")?.value >= this.form.get("startingDate")?.value

    if (this.form.invalid || !this.comunicadoValid || !this.startDateValid || !this.endDateValid) {
      return;
    }

    this.isLoading = true;
    if (this.isAddMode) {
      this.createComunicado();
    } else {
      this.updateComunicado();
    }
    this.submitted = false;
  }

  updateComunicado(): void {

    this.comunicado = {
      id: 0,
      description: this.form.controls['description'].value,
      endDate: this.form.controls['endDate'].value,
      startingDate: this.form.controls['startingDate'].value,
      type: this.form.controls['type'].value,
      isPermanent: this.form.controls['isPermanent'].value,
      image: this.form.controls['imageComunicado'].value
    }

    this.unsubscribe.add(this.comunicadoServ.updateComunicado(this.id, this.comunicado).subscribe({
      next: (resp) => {
        this.snackServ.openSnackBar(
          'Comunicado Atualizado!',
          '#0AA174',
          '../../../../assets/Icons/correct.png'
        );
        this.router.navigate(['/backoffice/comunicados']);
      },
      error: (e) => {
        this.snackServ.openSnackBar(
          'Comunicado Não Atualizado!',
          '#F97066',
          '../../../../assets/Icons/ErrorIcon.png'
        );
        console.error(e);
      },
      complete: () => this.isLoading = false
    }));
  }

  createComunicado(): void {
    this.comunicado = {
      id: 0,
      description: this.form.controls['description'].value,
      endDate: this.form.controls['endDate'].value,
      startingDate: this.form.controls['startingDate'].value,
      type: this.form.controls['type'].value,
      isPermanent: this.form.controls['isPermanent'].value,
      image: this.form.controls['imageComunicado'].value
    }

    this.unsubscribe.add(
      this.comunicadoServ.postComunicado(this.comunicado).subscribe({
        next: (resp) => {
          this.snackServ.openSnackBar(
            'Comunicado Adicionado!',
            '#0AA174',
            '../../../../assets/Icons/correct.png'
          );
          this.router.navigate(['/backoffice/comunicados']);
        },
        error: (e) => {
          const specificErrorMessage =
            'An existing Comunicado already exists for this period and user type. Only one per period can be available.';
          if (e.error.title.includes(specificErrorMessage)) {
            this.snackServ.openSnackBar(
              'Já existe um comunicado ativo para as datas selecionadas',
              '#F97066',
              '../../../../assets/Icons/ErrorIcon.png'
            );
          } else {
            this.snackServ.openSnackBar(
              'Comunicado Não Adicionado!',
              '#F97066',
              '../../../../assets/Icons/ErrorIcon.png'
            );
          }
          console.error(e);
        },
        complete: () => (this.isLoading = false),
      })
    );
  }

  checkFileSize(
    InputComponent: NgxMatFileInputComponent,
    maxSizeInMB: number
  ): void {
    if (InputComponent) {
      const selectedFile = InputComponent.ngControl.value;
      if (selectedFile) {
        const fileSizeBytes = selectedFile.size;
        const fileSizeMB = fileSizeBytes / (1024 * 1024);
        if (fileSizeMB > 5) {
          this.snackBarService.openSnackBar(
            'Ficheiro demasiado grande (max. 5MB)',
            '#F9841E',
            '../../assets/Icons/Warning-icon.png'
          );
          InputComponent.ngControl.reset();
        }
      }
    }
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
    this.unsubscribe.unsubscribe()
  }
}
