<ng-container *ngIf="data; else loading">
  <div class="dialog-content">
    <mat-dialog-content class="pb-0 mobileOver">
      <div class="row">
        <div class="col-md-4 col-xs-12 text-center mt-5 mobileAdjust1">
          <span class="titleMobile">{{data.decription}}</span>
          <img [src]="showModalImage()" class="w-100" alt="Imagem do produto">
        </div>

        <!--*DETAILS-->
        <div class="col-md-8 col-xs-12">
          <div mat-dialog-title class="header">
            <h2 class="adjustMobile" matTooltip="{{ data.decription }}" [matTooltipPosition]="'above'"
              [matTooltipShowDelay]="'500'" [matTooltipHideDelay]="'100'">{{data.decription}}</h2>
            <mat-icon [mat-dialog-close]="true">close</mat-icon>
          </div>
          <table class="table w-100 mt-4" aria-describedby="Tabela de informações do produto">
            <tr>
              <th>Referência:</th>
              <td>{{data.reference != null ? data.reference : '-'}}</td>
            </tr>
            <tr>
              <th>Designação:</th>
              <td>{{data.decription != null ? data.decription : '-'}}</td>
            </tr>
            <tr>
              <th>Família:</th>
              <td>{{data.family ? data.family : '-'}}</td>
            </tr>
            <tr>
              <th>Grupo:</th>
              <td>{{data.group ? data.group : '-'}}</td>
            </tr>
            <tr>
              <th>ATC:</th>
              <td>{{data.atc ? data.atc : '-'}}</td>
            </tr>
            <tr>
              <th>Laboratório:</th>
              <td>{{data.lab ? data.lab : '-'}}</td>
            </tr>
            <tr>
              <th>Espécies:</th>
              <td>{{data.species ? data.species : '-'}}</td>
            </tr>
            <!--!! Descomentar quando requesitado validade
            <tr>
              <th>Validade:</th>
              <td>{{ getValidityMessage(data.validate) }}</td>
            </tr> -->
            <div *ngIf="!(files == null || files == undefined || files.length == 0 || (files.length == 1 && files[0] == ''))" class="d-flex flex-row" class="btns-bula-folheto">
              <div *ngFor="let file of files" class="file" (click)="onFileClick(file)">
                <span>{{ file }}</span>
              </div>
            </div>

          </table>
        </div>
      </div>
    </mat-dialog-content>
  </div>

</ng-container>

<ng-template #loading>
  <div class="loading-animation">
    <app-loading></app-loading>
  </div>
</ng-template>
