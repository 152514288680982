import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { ImportClientsResponse } from '../models/media';

@Injectable({
  providedIn: 'root'
})
export class ImportClientsService {
  route = new ApiConfiguration();

  constructor(private http: HttpClient) { }

  importClients(tipo: number, utilizadorPortal: string, nCliente: string): Observable<ImportClientsResponse[]> {
    const apiImportClients = this.route.rootUrl + '/api/interface/clients/import';
    let params = new HttpParams();
    params = params.append('tipo', tipo);
    params = params.append('utilizadorPortal', utilizadorPortal);
    params = params.append('nCliente', nCliente);

    return this.http.get<ImportClientsResponse[]>(apiImportClients, {params})
    .pipe(
      catchError((error) => {
        throw error;
      })
    );
  }
}
