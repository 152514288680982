import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-side-menu',
  templateUrl: './dashboard-side-menu.component.html',
  styleUrls: ['./dashboard-side-menu.component.css'],
})
export class DashboardSideMenuComponent {
  constructor(private route: ActivatedRoute, private router: Router) {}

  showImportSubMenu: boolean = false;
  showExportSubMenu: boolean = false;

  ngOnInit() {
    this.showImportSubMenu = this.isAnyImportSubmenuActive();
    this.showExportSubMenu = this.isAnyExportSubmenuActive();
  }

  toggleImportSubMenu() {
    this.showImportSubMenu = !this.showImportSubMenu;
  }

  toggleExportSubMenu() {
    this.showExportSubMenu = !this.showExportSubMenu;
  }

  isActive(route: string): boolean {
    return this.route.snapshot.routeConfig?.path === route;
  }

  isActiveSubmenu(route: string): boolean {   
    return this.route.snapshot.url.some(segment => segment.path.includes(route));
  }

  isAnyImportSubmenuActive(): boolean {
    return this.route.snapshot.url.some(segment =>
      ['products', 'media', 'stock', 'clients', 'registos'].includes(segment.path)
    );
  }

  isAnyExportSubmenuActive(): boolean {
    return this.route.snapshot.url.some(segment =>
      ['encomendas'].includes(segment.path)
    );
  }
}
