import { Component, Inject, Output, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Comunicado } from 'src/app/api/models/tb-comunicado';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css'],
})
export class DeleteModalComponent {
  comunicado!: Comunicado;
  constructor(@Inject(MAT_DIALOG_DATA) public data: Comunicado, public dialogRef: MatDialogRef<DeleteModalComponent>) {}

  ngOnInit() {
    this.comunicado = this.data;
  }

  onConfirm(): void {
    this.dialogRef.close('Delete'); 
  }

  cancelDelete(): void {
    this.dialogRef.close("Cancel");
  }
}
