import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Campaign, CampaignGroup } from 'src/app/api/models/campaigns';
import { ShopItemModel } from 'src/app/api/models/shop-item.model';
import { CampaignsService } from 'src/app/api/services/campaigns.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.css']
})
export class CampaignDetailsComponent implements OnInit {
  @Output() isModalOpen = new EventEmitter<boolean>();
  campaign: Campaign | null = null
  loggedUser = this.perfilService.getCurrentUserInfo();
  isload = true

  constructor(
    private router: Router,
    private campaignService: CampaignsService,
    private perfilService: PerfilService,
    private snack: SnackBarService,
  ) { }



  ngOnInit(): void {
    this.campaign = JSON.parse(sessionStorage.getItem('campaign') ?? '{}');
    const campaignId = this.campaign?.id
    const url: string = this.router.routerState.snapshot.url;
    const pattern: RegExp = /\/(\d+)$/;
    const match: RegExpMatchArray | null = url.match(pattern);
    const URLcampaignId = match ? match[1] : null;
    if (campaignId != parseInt(URLcampaignId!) || this.campaign == null) {
      const sub = this.campaignService.getCampaignDetailsById(parseInt(URLcampaignId!), this.loggedUser.userId, this.loggedUser.commercialCondition).subscribe({
        next: (res) => {
          if (res == null) {
            this.snack.openSnackBar(
              'Campanha não se encontra disponivel',
              '#F97066',
              '../../assets/Icons/Warning-icon.png'
            );
            this.router.navigate(['/campaigns']);
          } else {
            sessionStorage.setItem('campaign', JSON.stringify(res))
            this.campaign = res;
          }
          this.isload = false
        },
        error: () => {
          sub.unsubscribe();
        },
        complete: () => {

          sub.unsubscribe();
        },
      })
    } this.isload = false
  }

  isProdModalOpen(value: boolean) {
    this.isModalOpen.emit(value);
  }

  goToCampaigns() {
    let tabIndex = 0;
    switch (this.campaign?.type) {
      case "P1":
      case "P2":
      case "P3":
      case "P4":
      case "DH":
        tabIndex = 6;
        break;
      case "N":
        tabIndex = 0;
        break;
      case "C":
        tabIndex = 1;
        break;
      case "F":
        tabIndex = 2;
        break;
      case "E":
        tabIndex = 3;
        break;
      case "P":
        tabIndex = 4;
        break;
      case "D":
        tabIndex = 5;
        break;
      default:
        tabIndex = 0;
    }
    this.router.navigate(['/campaigns'], { state: { tabIndex: tabIndex } });
  }

  showCampaignImage() {
    if (this.campaign?.header) {
      return environment.campaigns_images + this.campaign?.header;
    } else {
      return '../../../assets/images/banner_placeholder.png'
    }
  }

  showGroupImage(group: CampaignGroup) {
    if (group.imgGroup) {
      return environment.campaigns_images + group.imgGroup;
    } else {
      return '../../../assets/images/banner_placeholder.png'
    }
  }

  showProductImage(product: ShopItemModel) {
    if (product.productImage) {
      return environment.campaigns_images + product.productImage;
    } else {
      return '../../../assets/images/banner_placeholder.png'
    }
  }

  goToGroup(group: CampaignGroup) {
    sessionStorage.setItem('group', JSON.stringify(group))
    this.router.navigate(['/campaigns' + '/' + this.campaign?.id + '/group/' + group.idCampaignGroup])
  }
}
