import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { Booster, BoosterCheckout, BoosterProductDetails, CheckoutBoosterOrderModel } from '../models/booster';
import { Bonus} from '../models/shop-item.model';
import { AuthService } from './auth.service';
import { CheckoutOrderResponseModel } from '../models/checkout';


@Injectable({
    providedIn: 'root'
})
export class BoostersService {
    route = new ApiConfiguration();
    apiBooster = this.route.rootUrl + '/api/boosters';

    constructor(private http: HttpClient, private authService: AuthService) { }

    getProdutosBoosters(userId: string ): Observable<Booster[]> {
        let params = new HttpParams();
        params = params.append('userId', userId || '');

        return this.http.get<Booster[]>(this.apiBooster, {params}).pipe(
            catchError((error) => {
            throw error;
            })
        );
    }

    getBoosterProductInfo(userId: string): Observable<BoosterProductDetails[]> {
        let params = new HttpParams();
        params = params.append('userId', userId || '');

        return this.http.get<BoosterProductDetails[]>(this.apiBooster + '/info', {params});
    }

    getUserAddress(clientID: string): Observable<BoosterCheckout> {
        let accessToken = localStorage.getItem('BIO2_AccessToken');
        let tokenData = this.authService.getDecodedAccessToken(accessToken!);
        let userId = tokenData['registoId'];
        
        let endpoint = `${this.apiBooster}/address/${userId}/${clientID}`;

        return this.http.get<BoosterCheckout>(endpoint).pipe(
            catchError((error) => {
            throw error;
            })
        );
        
    }
    
    executeCheckout(order: CheckoutBoosterOrderModel): Observable<CheckoutOrderResponseModel> {
        let accessToken = localStorage.getItem('BIO2_AccessToken');
        let tokenData = this.authService.getDecodedAccessToken(accessToken!);
        let userId = tokenData['registoId'];

        order.UserId = userId;

        let endpoint = `${this.apiBooster}/execute-checkout`;
        return this.http.post<CheckoutOrderResponseModel>(endpoint, order);
    }
}   