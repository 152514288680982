import { HttpEvent, HttpEventType } from '@angular/common/http';
import { CreateCampaignService } from './../../api/services/create-campaign.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { sendEmailCampanha } from 'src/app/api/models/uploadProduct';
import { CreateProductService } from 'src/app/api/services/create-product.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PerfilService } from 'src/app/api/services/perfil.service';

@Component({
  selector: 'app-add-campanhas',
  templateUrl: './add-campanhas.component.html',
  styleUrls: ['./add-campanhas.component.css'],
})
export class AddCampanhasComponent implements OnInit {
  constructor(
    private perfilService: PerfilService,
    private createProductService: CreateProductService,
    private fb: FormBuilder,
    private snackBarService: SnackBarService,
    private router: Router
  ) {}

  imgType = '.png,.jpeg,.jpg,.gif';
  fileType = '.pdf';
  excelType = '.xls, .xlsx';
  myForm!: FormGroup;

  loggedUser = this.perfilService.getCurrentUserInfo();
  isLoading: boolean = false;

  ngOnInit(): void {
    this.myForm = this.fb.group({
      imgProduct: ['', Validators.required],
      imgFile: [''],
    });
  }

  get imgProduct() {
    return this.myForm.controls['imgProduct'] as FormControl;
  }
  get imgFile() {
    return this.myForm.controls['imgFile'] as FormControl;
  }

  uploadFiles() {
    const imgFile = this.imgFile?.value;
    const NewCampaignFile = this.imgProduct?.value;

    const formData: FormData = new FormData();
    if (imgFile) {
      formData.append('Files', imgFile, 'Imagem.' + imgFile.type.replace(/(.*)\//g, ''));
    }
    formData.append('Files', NewCampaignFile, 'NewCampaignFile.' + NewCampaignFile.name.split('.').pop());

    if (this.myForm.valid) {
      this.isLoading = true;
      this.createProductService.sendNewData(formData,this.loggedUser.username,this.loggedUser.userId).subscribe({
        next: (resp) => {
          if (resp) {
            this.snackBarService.openSnackBar(
              'Ação concluida com sucesso',
              '#0AA174',
              '../../../assets/Icons/correct.png'
            );
            this.myForm.reset();
            this.isLoading = false;
          }
        },
        error: (e) => {
          this.snackBarService.openSnackBar(
            `Ocorreu um erro`,
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
          console.error(e);
          this.isLoading = false;
        },
      });
    } else {
      this.snackBarService.openSnackBar(
        `Campos não preenchidos`,
        '#F97066',
        '../../../assets/Icons/ErrorIcon.png'
      );
    }
  }

  onCampanhasDownload() {
    const currentUser = this.perfilService.getCurrentUserInfo();
    let fileUrl: string = `${environment.uploadTemplates}TemplateCampanhas${currentUser.username.slice(1)}.xlsx`;
    window.open(fileUrl, '_blank');
    this.snackBarService.openSnackBar(
      'Download concluido com sucesso',
      '#0AA174',
      '../../../assets/Icons/correct.png'
    );
  }

  onFileSelected(event: any): void {
    const selectedFile = this.imgProduct.value.name;
    if (selectedFile) {
      if (this.isExcelFile(selectedFile)) {
      } else {
        this.myForm.get('imgProduct')!.reset();
        this.snackBarService.openSnackBar(
          `Tipo de ficheiro inválido`,
          '#F97066',
          '../../../assets/Icons/ErrorIcon.png'
        );
      }
    }
  }

  isExcelFile(fileName: string): boolean {
    return fileName.endsWith('.xls') || fileName.endsWith('.xlsx');
  }
}
