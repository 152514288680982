import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImportClientsResponse } from 'src/app/api/models/media';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { ImportClientsService } from 'src/app/api/services/import-clients.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit{
  isLoading: boolean = false;
  submitted: boolean = false;
  loggedUser: ProfileDataRequest = this.perfilService.getCurrentUserInfo();
  
  myForm!: FormGroup;
  data!: ImportClientsResponse[];

  constructor(private clientsService: ImportClientsService, private perfilService: PerfilService, private snackBarService: SnackBarService){}

  ngOnInit(): void {
    this.myForm = new FormGroup({
      nCliente: new FormControl('', Validators.required)
    });
  }

  processar() {
    this.submitted = true;
    if(this.myForm.get('nCliente')?.valid) {
      this.isLoading = true;
      this.data = [];
      this.clientsService.importClients(3, this.loggedUser.username, this.myForm.get('nCliente')?.value)
        .subscribe({
          next: (resp) => {
            this.data = resp;
            if(resp[0].statusCode === 404 || resp[0].statusCode == 500) {
              this.snackBarService.openSnackBar(
                `${resp[0].message}`,
                '#F97066',
                '../../../../assets/Icons/ErrorIcon.png'
              );
            }else {
              this.snackBarService.openSnackBar(
                `${resp[0].message}`,
                '#0AA174',
                '../../../../assets/Icons/correct.png'
              );
            }
          },
          error: (err) => console.error(err),
          complete: () => {
            this.isLoading = false;
            this.submitted = false;
          }
        });
    }
  }
}
