<div class="main-page">
  <div class="left-side">
    <img src="../../../assets/images/bio2Logo.png" alt="bio2Logo" />
  </div>
  <div class="right-side">
    <img src="../../assets/images/bio2Logo.png" alt="Bio2LogoMobile" class="logoMobile">
    <form class="d-flex flex-column">
      <div class="d-flex flex-column">
        <span class="recoverTitle">Recuperar Palavra-passe</span>
        <span class="recoverSemiTitle">Se se esqueceu da sua palavra-passe, insira o email e serão enviadas
          as instruções para recuperá-la.</span>
      </div>
      <span class="user-pass" for="password">Email
        <span class="required">*</span>
      </span>
      <div class="d-flex flex-row">
        <input type="email" id="userEmail" [(ngModel)]="userEmail" name="userEmail" required />
        <div class="request-users-btn" (click)="recoverPassword()" *ngIf="userEmail">
          <img src="../../assets/Icons/arrow-down.png" alt="arrow-down">
        </div>
      </div>
      <div class="d-flex flex-column" style="margin-left: 5px">
        <span class="user-pass" for="address">User associado
          <span class="required">*</span>
        </span>
        <div *ngIf="userRegisto.length" class="btn-radio-span">
          <div *ngFor="let user of userRegisto">
            <label for="address_{{ user.nome }}" class="d-flex w-10">
              <input class="d-flex btn-radio-check" [value]="user.idRegisto" type="radio" required="true"
                name="idRegisto" (change)="selectedIdRegisto = user.idRegisto!" />
              <span class="span-addresses-label d-flex">{{ user.numClienteBio2 }}-{{ user.nome}}</span>
            </label>
          </div>
        </div>
      </div>
      <button type="submit" [disabled]=" !userEmail || selectedIdRegisto === null" (click)="sendEmailUser()">
        <span>Recuperar password</span>
      </button>
    </form>
  </div>
</div>
