<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div class="content">
      <div *ngIf="isLoading" class="loading-container">
        <app-loading frameborder="0"></app-loading>
      </div>
      <div *ngIf="!isLoading">
        <span class="title">Novo Produto</span>
        <form [formGroup]="myForm" (ngSubmit)="uploadProduct()">
          <div class="green-div">
            <div class="output-template" (click)="onProductDownload()">
              <span>Download do template</span>
              <img src="../../../assets/Icons/down.png" alt="download" />
            </div>
            <div class="row-double">
              <div>
                <!-- Upload novo produto -->
                <div class="upload-template">
                  <div class="d-flex flex-row">
                    <span class="text-description">Upload novo produto</span><span class="required">*</span>
                  </div>
                  <mat-form-field>
                    <ngx-mat-file-input [formControl]="imgProduct" [accept]="excelType" accept=".xls, .xlsx"
                      (change)="onFileSelected($event)">
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
                <!-- Upload ficha técnica (.PDF) -->
                <div class="upload-ft">
                  <span class="text-description">Upload ficha técnica (.PDF)</span>
                  <mat-form-field>
                    <ngx-mat-file-input [formControl]="technicalFile" [accept]="fileType">
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <!-- Upload de Imagem (550x550px) -->
                <div class="upload-img">
                  <div class="d-flex flex-row">
                    <span class="text-description">Upload de Imagem (550x550px)</span>
                    <span class="required">*</span>
                  </div>
                  <mat-form-field>
                    <ngx-mat-file-input [formControl]="imgFile" [accept]="imgType">
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
                <!-- Upload bula (.PDF) -->
                <div class="upload-ft">
                  <span class="text-description">Upload bula (.PDF)</span>
                  <mat-form-field>
                    <ngx-mat-file-input [formControl]="flyerFile" [accept]="fileType">
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <button class="btn-next btn-green-processar d-flex" type="submit">
            <span>Criar produto</span>
            <img src="../../../assets/Icons/icon-done.png" alt="done" />
          </button>
        </form>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
