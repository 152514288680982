<div class="main-side-menu">
  <img style="width: 120px" src="../../../assets/images/bio2Logo.png" alt="Bio2Logo" />

  <!--! Dashboard -->
  <div class="d-flex flex-row dashboard-div-size">
    <ng-container *ngIf="isActive('backoffice/dashboard'); else notActiveDashboard">
      <img style="width: 20px; height: 20px" src="../../../assets/Icons/dashboard-home-active.png" />
    </ng-container>
    <ng-template #notActiveDashboard>
      <img style="width: 20px; height: 20px" src="../../../assets/Icons/dashboard-home.png" />
    </ng-template>
    <a class="routerLink" routerLink="/backoffice/dashboard" routerLinkActive="active">Dashboard</a>
  </div>

  <!--! Import -->
  <div class="routerLink dashboard-div-size d-flex flex-row" (click)="toggleProdutosSubMenu()">
    <img style="width: 20px; height: 20px" src="../../../assets/Icons/dashboard-import.png" />
    <div class="wrap-txt-icons"><a class="routerLink" routerLink="/..." routerLinkActive="active">Import</a>
      <img *ngIf="showProdutosSubMenu == false" style="width: 20px; height: 20px"
        src="../../../assets/Icons/dashboard-arrow-down.png" /></div>
    <img *ngIf="showProdutosSubMenu == true" style="width: 20px; height: 20px"
      src="../../../assets/Icons/dashboard-arrow-up.png" />
  </div>
  <ng-container *ngIf="showProdutosSubMenu">
    <div class="dropdown-text d-flex flex-column">
      <a class="routerLink" routerLink="/backoffice/import/products" routerLinkActive="active">Produtos</a>
      <a class="routerLink" routerLink="/backoffice/import/media" routerLinkActive="active">Media</a>
      <a class="routerLink" routerLink="/backoffice/import/stock" routerLinkActive="active">Stock</a>
      <a class="routerLink" routerLink="/backoffice/import/clients" routerLinkActive="active">Clientes</a>
      <a class="routerLink" routerLink="/backoffice/import/registos" routerLinkActive="active">Registos</a>
    </div>
  </ng-container>

  <!--! Export -->
  <div class="routerLink dashboard-div-size d-flex flex-row" (click)="toggleExportSubMenu()">
    <img style="width: 20px; height: 20px" src="../../../assets/Icons/dashboard-export.png" />
    <div class="wrap-txt-icons"><a class="routerLink" routerLink="/..." routerLinkActive="active">Export</a>
      <img *ngIf="showExportSubMenu == false" style="width: 20px; height: 20px"
        src="../../../assets/Icons/dashboard-arrow-down.png" /></div>
    <img *ngIf="showExportSubMenu == true" style="width: 20px; height: 20px"
      src="../../../assets/Icons/dashboard-arrow-up.png" />
  </div>
  <ng-container *ngIf="showExportSubMenu">
    <div class="dropdown-text d-flex flex-column">
      <a class="routerLink" routerLink="/backoffice/export/encomendas" routerLinkActive="active">Encomendas</a>
    </div>
  </ng-container>

  <!--! Campanhas -->
  <div class="d-flex flex-row dashboard-div-size">
    <ng-container *ngIf="isActive('backoffice/campanhas'); else notActiveCampanhas">
      <img style="width: 20px; height: 20px" src="../../../assets/Icons/dashboard-campaign-active.png" />
    </ng-container>
    <ng-template #notActiveCampanhas>
      <img style="width: 20px; height: 20px" src="../../../assets/Icons/dashboard-campaign.png" />
    </ng-template>
    <a class="routerLink" routerLink="/backoffice/campanhas" routerLinkActive="active">Campanhas</a>
  </div>

  <!--! Comunicados -->
  <div class="d-flex flex-row dashboard-div-size">
    <ng-container *ngIf="isActive('backoffice/comunicados'); else notActiveComunicados">
      <img style="width: 20px; height: 20px" src="../../../assets/Icons/dashboard-press-release-active.png" />
    </ng-container>
    <ng-template #notActiveComunicados>
      <img style="width: 20px; height: 20px" src="../../../assets/Icons/dashboard-press-release.png" />
    </ng-template>
    <a class="routerLink" routerLink="/backoffice/comunicados" routerLinkActive="active">Comunicados</a>
  </div>

  <!--! Produtos em Destaque -->
  <div class="d-flex flex-row dashboard-div-size">
    <ng-container *ngIf="isActive('backoffice/destaque'); else notActiveDestaque">
      <img style="width: 20px; height: 20px" src="../../../assets/Icons/dashboard-star-active.png" />
    </ng-container>
    <ng-template #notActiveDestaque>
      <img style="width: 20px; height: 20px" src="../../../assets/Icons/dashboard-star.png" />
    </ng-template>
    <a class="routerLink" routerLink="/backoffice/destaque" routerLinkActive="active">Destaques</a>
  </div>

  <!--! Edit Product -->
  <div class="d-flex flex-row dashboard-div-size">
    <ng-container *ngIf="isActive('backoffice/edit-product'); else notActiveEditProd">
      <img style="width: 20px; height: 20px" src="../../../assets/Icons/shopping-basket-line.png" />
    </ng-container>
    <ng-template #notActiveEditProd>
      <img style="width: 20px; height: 20px" src="../../../assets/Icons/shopping-basket-line1.png" />
    </ng-template>
    <a class="routerLink" routerLink="/backoffice/edit-product" routerLinkActive="active">Novo Produto</a>
  </div>
</div>
