import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  DeliveryAddress,
  ProfileDataModel,
  ProfileDataRequest,
  ProfileUpdateAddress,
  ProfileUpdatePassword,
} from 'src/app/api/models/perfil';
import { StorageKeys } from 'src/app/api/models/storageKeys';
import { AuthService } from 'src/app/api/services';
import { CustomIconsService } from 'src/app/api/services/custom-icons.service';
import { EncryptionService } from 'src/app/api/services/encryption.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PerfilComponent {
  isLoading: boolean = false;
  isEditing: boolean = false;
  showPassword: boolean = false;

  loggedUser: ProfileDataRequest = this.perfilService.getCurrentUserInfo();
  userData!: ProfileDataModel;
  moradaDefeito!: DeliveryAddress;
  @ViewChild('myForm') myForm: any;

  canChangeAddress: boolean = false;
  password: string = '';
  selectedAddress: string = '';
  passwordChanged: boolean = false;
  addressChanged: boolean = false;

  isProfileUpdate: boolean = true;

  constructor(
    private perfilService: PerfilService,
    private snackBarService: SnackBarService,
    public authService: AuthService,
    private router: Router,
    private encrypt: EncryptionService,
  ) {
  }

  ngOnInit() {
    this.getUserDetails(true);
  }

  getUserDetails(isUpdating: boolean): void {
    this.isLoading = true;

    this.perfilService.getUserProfileData(this.loggedUser.clientId, this.loggedUser.userId, this.isProfileUpdate)  
      .subscribe({
        next: (resp) => {
          this.userData = resp;

          if(isUpdating){
            localStorage.removeItem("BIO2_User");
            localStorage.removeItem("BIO2_AddressId");
            this.encrypt.setEncryptedItem("BIO2_User", JSON.stringify(resp));
            this.encrypt.setEncryptedItem("BIO2_AddressId", resp.userInfo.preferedDeliveryAddressId)
          }

          if (this.loggedUser.role != 'Administrador') {
            this.moradaDefeito = resp.deliveryAddresses.find(x => x.deliveryAddressId == resp.userInfo.preferedDeliveryAddressId)! ?? {} as DeliveryAddress;
          }
          else {
            let decryptedAdress = this.encrypt.getDecryptedItem("BIO2_AddressId");
            this.moradaDefeito = resp.deliveryAddresses.find(x => x.deliveryAddressId == decryptedAdress)! ?? {} as DeliveryAddress;
          }
        },
        error: (e) => console.error(e),
        complete: () => {
          this.isLoading = false;
          isUpdating = false;
        }
      });
  }


  onSaveData() {
    this.isLoading = true;
    const requestAddress: ProfileUpdateAddress = {
      userId: this.loggedUser.userId,
      deliveryAddressId: this.selectedAddress
    }
    const requestPass: ProfileUpdatePassword = {
      userId: this.loggedUser.userId,
      password: this.password,
    }

    const obsvAddress = this.perfilService.updatePrefAddress(requestAddress);
    const obsvPassword = this.perfilService.updatePassword(requestPass);

    if (this.passwordChanged && this.addressChanged) {
      // Handle the case when both password and address are changed.
      obsvAddress.subscribe((resp) => {
        obsvPassword.subscribe((resp2) => {
          const valid = !!(resp && resp2);
          this.snack(valid);
        });
      });

    } else if (this.passwordChanged) {
      // Handle the case when only the password is changed.
      obsvPassword.subscribe((resp) => {
        this.snack(resp);
      });

    } else if (this.addressChanged) {
      // Handle the case when only the address is changed.
      obsvAddress.subscribe((resp) => {
        this.snack(resp);
      });

    }

    setTimeout(() => {
      this.getUserDetails(true);
      this.myForm.resetForm();
      this.addressChanged = false;
      this.passwordChanged = false;
      this.isEditing = false;
      this.isLoading = false;
    }, 1000);
  }

  inputPassChanged(): void {
    this.passwordChanged = !!(this.password);
  }

  onAddressChange(value: any) {
    if (this.loggedUser.role === 'Administrador' && this.moradaDefeito) {
      localStorage.removeItem("BIO2_AddressId");
      this.encrypt.setEncryptedItem('BIO2_AddressId', value.value);
      this.getUserDetails(false);
    }
    this.selectedAddress = value.value;
    this.addressChanged = !!(this.selectedAddress && this.selectedAddress != this.moradaDefeito.deliveryAddressId);
  }

  onLogout(): void {
    this.authService.clearSessionStorage();
    this.encrypt.clear();
    this.router.navigate(['/login']);
  }

  onChangeClient(): void {
    this.router.navigate(['/login/selection/subLogin']);
  }

  snack(valid: boolean): void {
    if (valid) {
      this.snackBarService.openSnackBar(
        'Alterações guardadas com sucesso.',
        '#0AA174',
        '../../../assets/Icons/correct.png'
      );
    }
    else {
      this.snackBarService.openSnackBar(
        'Erro ao guardar as alterações.',
        '#F97066',
        '../../../assets/Icons/ErrorIcon.png'
      );
    }
  }
}
