import { Component, Input, Output, OnInit, OnDestroy } from '@angular/core';
import {
  ShopItemModel,
  ShopItemRequest,
} from 'src/app/api/models/shop-item.model'
import { ShopService } from 'src/app/api/services/shop.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { Subscription } from 'rxjs';
import { CartProductModel } from 'src/app/api/models/checkout';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { CheckoutService } from 'src/app/api/services/checkout.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  isLoadingMore: boolean = false;
  loggedUser = this.perfilService.getCurrentUserInfo();
  orderByOption: string = 'none';
  totalItems: number = 0;
  request: ShopItemRequest = {
    page: 1,
    pageSize: 10,
    sortColumn: '',
    sortOrder: '',
    filterName: '',
    filterValue: '',
    clientNumber: this.loggedUser.clientId,
    username: this.loggedUser.username,
    userId: this.loggedUser.userId
  };
  unsubscribe: Subscription = new Subscription();
  isModalOpen: boolean = false;

  shopItems: ShopItemModel[] = [];
  
  constructor(private shopService: ShopService, private perfilService: PerfilService, private snackBarService: SnackBarService, private checkoutService: CheckoutService) {}
  ngOnInit(): void {
    this.load();
  }
  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe()
  }

  handleFavoriteChange(product : ShopItemModel) : void {
    this.shopItems = this.shopItems.filter(s => s.reference !== product.reference);
    this.totalItems -= 1;
  }

  load(): void {
    this.isLoading = true;
    this.unsubscribe.add(
      this.shopService
      .getFavoriteItems(this.request)
      .subscribe({
        next: (response) => {
          this.shopItems = response.data
          this.totalItems = response.totalCount;
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        }
      })
    );
  }

  loadMore() {
    this.isLoadingMore = true;
    if (this.shopItems.length < this.totalItems) 
    {
      const requestList = this.request;
      requestList.page += 1;

      this.unsubscribe.add(
        this.shopService
        .getFavoriteItems(this.request)
        .subscribe({
          next: (response) => {
            this.shopItems.push(...response.data)
            this.totalItems = response.totalCount;
          },
          error: () => this.isLoadingMore = false,
          complete: () => this.isLoadingMore = false
        })
      );
    }
  }

  sortProducts(): void {
    let sortColumn: string;
    let sortOrder: string;
  
    const [sort, order] = this.orderByOption.split('-');
    sortColumn = sort === 'none' ? '' : sort === 'stock' ? 'Stock' : 'Valor';
    sortOrder = sort === null ? '' : order === 'menos' ? 'DESC' : 'ASC';
  
    this.request.sortColumn = sortColumn;
    this.request.sortOrder = sortOrder;
  
    this.load();
  }

  isProdModalOpen(value: boolean) {
    this.isModalOpen = value;
  }
}
