import { ActivatedRoute, Route, Router } from '@angular/router';
import { PasswordRecService } from './../api/services/password-rec.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { SnackBarService } from '../api/services/snack-bar.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  token!: string;
  newPassword1: string = '';
  newPassword2: string = '';
  firstBtnClick: boolean = false;
  showPassword2: boolean = false;
  showPassword1: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private PasswordRecService: PasswordRecService,
    private renderer: Renderer2,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    const encodedToken = this.route.snapshot.queryParamMap.get('token');
    this.token = encodedToken || '';
    this.firstBtnClick = false;
  }

  isButtonDisabled(): boolean {
    return (
      this.newPassword1 !== this.newPassword2 ||
      !this.newPassword1 ||
      !this.newPassword2 ||
      this.newPassword1.length < 5 ||
      this.newPassword2.length < 5
    );
  }

  togglePasswordVisibility1(): void {
    this.showPassword1 = !this.showPassword1;
  }
  togglePasswordVisibility2(): void {
    this.showPassword2 = !this.showPassword2;
  }

  resetPassword(): void {
    this.PasswordRecService.resetPassword(
      this.token,
      this.newPassword1
    ).subscribe(
      (response) => {
        //this.firstBtnClick = true;
        //this.changeCircleClassS();
        this.router.navigate(['/login']);
        this.snackBarService.openSnackBar(
          'Password alterada com sucesso',
          '#0AA174',
          '../../assets/Icons/correct.png'
        );
      },
      (error) => {
        this.snackBarService.openSnackBar(
          'Erro a alterar a Password',
          '#F97066',
          '../../assets/Icons/ErrorIcon.png'
        );
      }
    );
  }

  private changeCircleClassS() {
    const circleElement: HTMLElement = document.querySelector(
      '.circleL'
    ) as HTMLElement;
    this.renderer.removeClass(circleElement, 'circleL');
    this.renderer.addClass(circleElement, 'circleLCheck');
  }
}
