import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ShopItemModel } from 'src/app/api/models/shop-item.model';

@Component({
  selector: 'app-dialog-product-campaign',
  templateUrl: './dialog-product-campaign.component.html',
  styleUrls: ['./dialog-product-campaign.component.css'],
})
export class DialogProductCampaignComponent implements OnChanges {
  @Input() productData: ShopItemModel | undefined;

  ngOnChanges(changes: SimpleChanges): void {}
}
