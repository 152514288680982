<div class="form-container">
    <h2 mat-dialog-title>Enviar mensagem para <span class="font-weight-bold">{{recipient}}</span></h2>

    <div style="flex-grow: 1; padding:30px;" [formGroup]="myForm">
        <div class="d-flex flex-wrap flex-column" style="gap:15px">
            <mat-form-field>
                <input matInput placeholder="Email" formControlName="sender">
            </mat-form-field>
            <span class="text-danger"
                *ngIf="(myForm.get('sender')?.touched || submitted) && myForm.get('sender')?.errors?.['required']">
                *Email é obrigatório
            </span>
            <span class="text-danger"
                *ngIf="(myForm.get('sender')?.touched || submitted) && myForm.get('sender')?.errors?.['email']">
                *Email com formatação incorreta
            </span>

            <mat-form-field>
                <input matInput placeholder="Assunto" formControlName="subject">
            </mat-form-field>
            <span class="text-danger"
                *ngIf="(myForm.get('subject')?.touched || submitted) && myForm.get('subject')?.errors?.['required']">
                *Assunto é obrigatório
            </span>

            <mat-form-field>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5" placeholder="Mensagem" formControlName="body">
                </textarea>
            </mat-form-field>
            <span class="text-danger"
                *ngIf="(myForm.get('body')?.touched || submitted) && myForm.get('body')?.errors?.['required']">
                *Mensagem é obrigatória
            </span>
        </div>
    </div>

    <mat-dialog-actions class="d-flex justify-content-around">
        <button class="btn btn-green mr-3" (click)="close()">Fechar</button>
        <button type="submit" [disabled]="myForm.invalid" class="btn btn-green" (click)="send()">Enviar</button>
    </mat-dialog-actions>
</div>