import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Campaign, CampaignGroup } from 'src/app/api/models/campaigns';
import { CampaignsService } from 'src/app/api/services/campaigns.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign-group',
  templateUrl: './campaign-group.component.html',
  styleUrls: ['./campaign-group.component.css']
})
export class CampaignGroupComponent implements OnInit {
  campaignGroup: CampaignGroup | null = null
  campaign: Campaign | null = null
  loggedUser = this.perfilService.getCurrentUserInfo();
  isload = true
  constructor(
    private router: Router,
    private campaignService: CampaignsService,
    private snack: SnackBarService,
    private perfilService: PerfilService,
  ) { }


  ngOnInit(): void {
    this.campaign = JSON.parse(sessionStorage.getItem('campaign') ?? '{}');
    const campaignId = this.campaign?.id
    this.campaignGroup = JSON.parse(sessionStorage.getItem('group') ?? '{}');
    const campaignGroupId = this.campaignGroup?.idCampaignGroup
    const url: string = this.router.routerState.snapshot.url;
    const pattern = /\/(\d+)\/group\/(\d+)/;
    const match = url.match(pattern);
    const URLcampaignId = match![1];
    const URLgroupId = match![2];
    if (this.campaign != null && campaignId == parseInt(URLcampaignId)) {
      var matchingGroup = this.campaign.group?.find(group => group.idCampaignGroup == parseInt(URLgroupId))
      if (!matchingGroup) {
        this.snack.openSnackBar(
          'Grupo não se encontra disponivel',
          '#F97066',
          '../../assets/Icons/Warning-icon.png'
        );
        this.router.navigate(['/campaigns']);
      }
    } else if (campaignId != parseInt(URLcampaignId!) || this.campaign == null) {
      const sub = this.campaignService.getCampaignDetailsById(parseInt(URLcampaignId), this.loggedUser.userId, this.loggedUser.commercialCondition).subscribe({
        next: (res) => {
          if (res != null) {
            var matchingGroup = res.group?.find(group => group.idCampaignGroup == parseInt(URLgroupId))
            if (!matchingGroup) {
              this.router.navigate(['/campaigns']);
              this.snack.openSnackBar(
                'Grupo não se encontra disponivel',
                '#F97066',
                '../../assets/Icons/Warning-icon.png'
              );
            } else {
              this.campaign = res
              this.campaignGroup = matchingGroup
              sessionStorage.setItem('campaign', JSON.stringify(res))
              sessionStorage.setItem('group', JSON.stringify(matchingGroup))
            }
          } else {
            this.router.navigate(['/campaigns']);
            this.snack.openSnackBar(
              'Campanha não se encontra disponivel',
              '#F97066',
              '../../assets/Icons/Warning-icon.png'
            );
          }
          this.isload = false
        },
        error: () => {
          sub.unsubscribe();
        },
        complete: () => {
          sub.unsubscribe();
        },
      })
    }
    this.isload = false
  }

  goToCampaigns() {
    this.router.navigate(['/campaigns']);
  }

  goToCampaign() {
    this.router.navigate(['/campaigns' + '/' + this.campaign?.id]);
  }

  showCampaignGroupImage() {
    if (this.campaignGroup?.headerGroup) {
      return environment.campaigns_images + this.campaignGroup?.headerGroup;
    } else {
      return '../../../assets/images/banner_placeholder.png'
    }
  }
}
