import { Component } from '@angular/core';
import { ImportRegistosResponse } from 'src/app/api/models/tb-registo';
import { InterfaceRegistosService } from 'src/app/api/services/interface-registos.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';

@Component({
  selector: 'app-registos',
  templateUrl: './registos.component.html',
  styleUrls: ['./registos.component.css']
})
export class RegistosComponent {
  loading:boolean = false;
  submitted: boolean = false;
  inputRegisto!: string;
  inputOption: boolean = false;
  isMandatory: boolean = false;
  data!: ImportRegistosResponse[];

  constructor(private registoService: InterfaceRegistosService, private snackBarService: SnackBarService) {}

  processar() {
    this.submitted = true;
    this.isMandatory = (!this.inputOption) && (!this.inputRegisto);
    let optionNumber: number = (this.inputOption) ? 1 : 2; //checked = 1, not checked = 2.

    if((!this.isMandatory && optionNumber == 2) || optionNumber == 1) {
      this.loading = true;
      this.registoService.importRegistos(optionNumber, this.inputRegisto)
      .subscribe({
        next: (resp) => {
          this.data = resp;
          if(resp[0].statusCode === 404) {
            this.snackBarService.openSnackBar(
              `${resp[0].message}`,
              '#F97066',
              '../../../assets/Icons/ErrorIcon.png'
            );
          }else {
            this.snackBarService.openSnackBar(
              `Registos Atualizados`,
              '#0AA174',
              '../../../assets/Icons/correct.png'
            );
          }
        },
        error: (err) => {
          this.loading = false;
          this.snackBarService.openSnackBar(
            'Erro ao importar utilizador.',
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
          console.error(err)
        },
        complete: () => this.loading = false
      });
    }
    else {
      return;
    }
  }

  statusChanged(event: any) {
    this.inputOption = event.target.checked;
  }

}
