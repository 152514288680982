import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'extractHourPt'
})
export class ExtractHourPtPipe implements PipeTransform {
  transform(value: string): any {
    const datePipe = new DatePipe('pt');
    const parsedDate = new Date(value);
    return datePipe.transform(parsedDate, 'HH:mm');
  }
}
