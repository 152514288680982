import { FormControl, FormsModule } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { DeliveryAddress } from 'src/app/api/models/statistics';
import {
  CheckoutLineModel,
  CheckoutOrderModel,
  CheckoutOrderResponseModel,
  CartProductModel,
  BonusProductInfo,
  CheckoutViewModel,
} from 'src/app/api/models/checkout';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { CheckoutService } from 'src/app/api/services/checkout.service';
import { CartService } from 'src/app/api/services/cart.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CheckoutDialogComponent } from 'src/app/Custom/checkout-dialog/checkout-dialog.component';
import { Cliente, ProfileDataRequest } from 'src/app/api/models/perfil';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { Subscription } from 'rxjs';
import { EncomendasService } from 'src/app/encomendas/encomendas.service';
import {
  PendingOrders,
  PendingOrdersRequest,
} from 'src/app/encomendas/encomendas.model';
import { GenericModalComponent } from 'src/app/Custom/generic-modal/generic-modal.component';
import { environment } from 'src/environments/environment';
import { EncryptionService } from 'src/app/api/services/encryption.service';
import { StorageKeys } from 'src/app/api/models/storageKeys';
import { PointsService } from 'src/app/api/services/points.service';
import { AuthService } from 'src/app/api/services';
import { Address } from 'src/app/api/models/getUsers';
import { QuantityCampaignDetails } from 'src/app/api/models/shop-item.model';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  information: string = `${environment.information}`;
  iban: string = `${environment.ibaninfo}`;
  comprovativo: string = `${environment.comprovativo}`;
  user!: ProfileDataRequest;
  isLoading: boolean = true;
  checkoutViewModel!: CheckoutViewModel;
  profileRequest: ProfileDataRequest = this.perfilService.getCurrentUserInfo();
  checkoutLines: CheckoutLineModel[];
  deliveryAddressList: DeliveryAddress[];
  selectedAddress: DeliveryAddress = {
    deliveryAddressId: '',
    clientAddressCode: '',
    address: '',
    zipCodeDesignation: '',
    locality: '',
    zipCodeCP4: '',
    zipCodeCP3: '',
    clientId: '',
    isPreferedAddress: false
  };
  isMasterUser: boolean;
  observations: string;
  orderTotal: number;
  orderPointsTotal: number;
  defaultAddress: string = '';
  checkoutLineToRemove?: CheckoutLineModel;
  changingCheckbox: boolean = false;
  normalCurrencyCheckoutLines: CheckoutLineModel[] = [];
  pointsProductCheckoutLines: CheckoutLineModel[] = [];
  bonusProductCheckoutLines: BonusProductInfo[] = [];
  isCheckoutComplete: boolean = false;
  anySuccess: boolean = false;
  regularOrderSuccess: boolean = false;
  pointsOrderSuccess: boolean = false;
  sggOrderNumber: string = '000000';
  unsubscribe: Subscription = new Subscription();
  moradaDefeito = this.encrypt.getDecryptedItem(
    StorageKeys.BIO2_CLIENT_ADDRESS_ID
  );
  adresses: DeliveryAddress[] = [];
  existsInDb: boolean = false;
  private subscription!: Subscription;
  isReadingPoints: boolean = true;
  pointsSubscription!: Subscription;
  points!: number;
  storageAdresseChanged!: DeliveryAddress;
  referenciaDuplicada: string = '';
  duplicated: boolean = false;

  sggFailed: boolean = false;
  bypassSGG: boolean = false;

  constructor(
    private perfilService: PerfilService,
    private checkoutService: CheckoutService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private encomendaService: EncomendasService,
    public cartService: CartService,
    private cdr: ChangeDetectorRef,
    private encrypt: EncryptionService,
    public pointsService: PointsService,
    public authService: AuthService
  ) {
    this.deliveryAddressList = [];
    this.checkoutLines = [];
    this.isMasterUser = false;
    this.observations = '';
    this.orderTotal = 0;
    this.orderPointsTotal = 0;
  }

  ngOnInit(): void {
    const decryptedAddressesChanged = this.encrypt.getDecryptedItem(StorageKeys.BIO2_SELECTED_CHANGED_ADDRESS);
    this.user = this.perfilService.getCurrentUserInfo();
    if (decryptedAddressesChanged) {
      this.storageAdresseChanged = JSON.parse(decryptedAddressesChanged);
      if (this.storageAdresseChanged !== this.selectedAddress) {
        this.selectedAddress = this.storageAdresseChanged;
      }
    }

    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      let fullKey = this.user.userId + '_' + this.user.clientId + '_' + 'observations'
      if (key! === fullKey) {
        let decriptedObservations = this.encrypt.decrypt(sessionStorage.getItem(key)!);
        this.observations = decriptedObservations!;
        break
      }
    }

    this.subscription = this.pointsService.isReadingPoints$.subscribe(
      value => {
        this.isReadingPoints = value;
      }
    );
    this.pointsSubscription = this.pointsService.readPoints$.subscribe(
      points => {
        this.points = points;
      }
    );

    this.getUserDetails();
    this.GetCheckoutViewModel(this.profileRequest.clientId);
  }

  onKeyDown(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    if (+currentValue >= 1000 && !this.isControlKey(event)) {
      event.preventDefault();
    }
  }

  isControlKey(event: KeyboardEvent): boolean {
    const controlKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'];
    return controlKeys.includes(event.key);
  }

  compareFamilyObjects(f1: any, f2: any): boolean {
    return f1 && f2 ? f1.address === f2.address && f1.clientAddressCode === f2.clientAddressCode : f1 === f2;
  }

  GetCheckoutViewModel(sessionClientNumber: string): void {
    this.unsubscribe.add(
      this.checkoutService.GetCheckoutViewModel(sessionClientNumber).subscribe(
        (response) => {
          this.checkoutViewModel = response;
          this.deliveryAddressList = this.checkoutViewModel.addressLists;

          this.checkoutLines = this.checkoutViewModel.checkoutLines;

          // Store previous point flag to check if the user changed the use points flag
          this.checkoutLines.forEach((element) => {
            element.previousPointFlag = element.usePoints;
          });

          // Set the checkout lines to the correct arrays
          this.normalCurrencyCheckoutLines = this.checkoutLines.filter(
            (line) => !line.usePoints
          );
          this.pointsProductCheckoutLines = this.checkoutLines.filter(
            (line) => line.usePoints
          );

          this.isMasterUser = this.checkoutViewModel.isMasterUser;

          let moradaDefeitoAddress = this.deliveryAddressList.find(address => {
            return address.deliveryAddressId == this.moradaDefeito;
          });

          if (!moradaDefeitoAddress) {
            moradaDefeitoAddress = this.deliveryAddressList[0];
          }

          if (this.selectedAddress.clientAddressCode == "") {

            this.selectedAddress = moradaDefeitoAddress!;
            this.defaultAddress =
              this.checkoutViewModel.userDefaultAddress;
          }
          else {
            this.selectedAddress = this.storageAdresseChanged;
          }

          this.UpdateOrderTotals();
          this.pointsProductCheckoutLines.forEach((x) =>
            this.CheckAndUpdateBonusQuantity(x)
          );
          this.normalCurrencyCheckoutLines.forEach((x) =>
            this.CheckAndUpdateBonusQuantity(x)
          );
          this.isLoading = false;
        },
        (error) => {
          console.error('Error:', error);
          this.isLoading = false;
        }
      )
    );
  }

  // Check duplicated Lines
  checkForDuplicateLines() {
    this.duplicated = false;
    const seenReferences = new Set<string>();
    for (const line of this.checkoutLines) {
      if (seenReferences.has(line.referencia)) {
        this.referenciaDuplicada = line.referencia;
        this.duplicated = true;
        break;
      } else {
        seenReferences.add(line.referencia);
      }
    }
  }

  // Increases or Removes the quantity of the product
  AddOrRemoveLineQuantity(
    line: CheckoutLineModel,
    op: string,
    newValue: string
  ) {
    const oldLineQuantity = line.qtd;
    let numericValue = parseInt(newValue);
    if (op === 'add') {
      if (
        line.usePoints &&
        this.points - line.pontos < 0
      ) {
        this.snackBarService.openSnackBar(
          'Quantidade de pontos insuficiente',
          '#F97066',
          '../../../../assets/Icons/ErrorIcon.png'
        );
        return;
      }
      line.qtd++;
    }
    if (op === 'remove') {
      if (line.qtd - 1 <= 0) {
        this.OpenDeleteConfirmationModal(line);
        return;
      } else {
        line.qtd--;
      }
    }
    if (op === 'update') {
      if (numericValue == 0) {
        this.OpenDeleteConfirmationModal(line);
        return;
      } else {
        if (numericValue === line.qtd || isNaN(numericValue)) {
          line.qtd = NaN;
          this.cdr.detectChanges();
          line.qtd = oldLineQuantity;
          return;
        }
        if (
          line.usePoints &&
          this.points +
          line.qtd * line.pontos -
          numericValue * line.pontos <
          0
        ) {
          line.qtd = NaN;
          this.cdr.detectChanges();
          line.qtd = oldLineQuantity;
          this.snackBarService.openSnackBar(
            'Quantidade de pontos insuficiente',
            '#F97066',
            '../../../../assets/Icons/ErrorIcon.png'
          );
          return;
        }
        line.qtd = numericValue;
      }
    }

    let cartProduct: CartProductModel = {
      userId: '',
      clientId: this.profileRequest.clientId,
      productId: line.referencia,
      quantity: line.qtd,
      previouslyUsingPoints: line.previousPointFlag,
      usePoints: line.usePoints,
      points: line.pontos,
    };

    // Updates the quantity of the product on the backend
    this.unsubscribe.add(
      this.cartService
        .updateProduct(cartProduct)
        .subscribe((opResult: boolean) => {
          if (!opResult) {
            this.snackBarService.openSnackBar(
              'Ocorreu um erro, por favor tente mais tarde',
              '#F97066',
              '../../../assets/Icons/ErrorIcon.png'
            );
          }
        })
    );

    if (line.usePoints) {
      this.cartService.updateUserPoints(
        op,
        line.pontos,
        oldLineQuantity,
        numericValue
      );
    }
    this.UpdateOrderTotals();
    if (line.bonusProductInfo != null) this.CheckAndUpdateBonusQuantity(line);
  }

  // FUNCTION : Removes the line from the order
  RemoveLine(line: CheckoutLineModel): void {
    const usesPoints = line.usePoints;
    const cartLines = usesPoints
      ? this.pointsProductCheckoutLines
      : this.normalCurrencyCheckoutLines;

    const index = cartLines.indexOf(line);

    if (index === -1) {
      return; // Line not found, nothing to do
    }

    const cartProduct: CartProductModel = {
      userId: '',
      clientId: this.profileRequest.clientId,
      productId: line.referencia,
      quantity: 0,
      previouslyUsingPoints: line.previousPointFlag,
      usePoints: line.usePoints,
      points: line.pontos,
    };

    this.unsubscribe.add(
      this.cartService
        .updateProduct(cartProduct)
        .subscribe((opResult: boolean) => {
          if (opResult) {
            this.snackBarService.openSnackBar(
              'Linha removida com sucesso',
              '#12B76A',
              '../../assets/Icons/correct.png'
            );

            cartLines.splice(index, 1);
            this.CheckAndUpdateBonusQuantity(line);
            this.UpdateOrderTotals();
            if (line.usePoints === true) {
              this.cartService.updateUserPoints(
                'update',
                line.pontos,
                line.qtd,
                0
              );
            }
          } else {
            this.snackBarService.openSnackBar(
              'Ocorreu um erro, por favor tente mais tarde',
              '#F97066',
              '../../../assets/Icons/ErrorIcon.png'
            );
          }
        })
    );
  }

  // Function responsible for updating the use points flag on the backend
  // This also merge entries together if necessary
  SignalToUsePoints(line: CheckoutLineModel): void {
    this.changingCheckbox = true;

    let cartProduct: CartProductModel = {
      userId: '',
      clientId: this.profileRequest.clientId,
      productId: line.referencia,
      quantity: line.qtd,
      previouslyUsingPoints: line.previousPointFlag,
      usePoints: line.usePoints,
      points: line.pontos,
    };

    // Updates the quantity of the product on the backend
    this.unsubscribe.add(
      this.cartService
        .productPointsSwitcher(cartProduct)
        .subscribe((opResult: boolean) => {
          if (!opResult) {
            this.snackBarService.openSnackBar(
              'Ocorreu um erro, por favor tente mais tarde',
              '#F97066',
              '../../../assets/Icons/ErrorIcon.png'
            );
            line.usePoints = line.previousPointFlag;
            this.changingCheckbox = false;
          } else {
            line.previousPointFlag = line.usePoints;
            const targetLines = line.usePoints
              ? this.pointsProductCheckoutLines
              : this.normalCurrencyCheckoutLines;
            const otherLines = line.usePoints
              ? this.normalCurrencyCheckoutLines
              : this.pointsProductCheckoutLines;

            const index = targetLines.findIndex(
              (x) => x.referencia === line.referencia
            );
            if (index !== -1) {
              targetLines[index].qtd += line.qtd;
            } else {
              targetLines.push(line);
            }

            otherLines.splice(
              otherLines.findIndex((x) => x.referencia === line.referencia),
              1
            );

            this.UpdateOrderTotals();
            if (line.usePoints) {
              this.cartService.updateUserPoints('add', line.pontos * line.qtd);
            } else {
              this.cartService.updateUserPoints(
                'remove',
                line.pontos * line.qtd
              );
            }
            this.changingCheckbox = false;
          }
        })
    );
  }

  async BeginCheckout(): Promise<void> {
    this.isModalOpen = false;
    if (
      this.normalCurrencyCheckoutLines.length == 0 &&
      this.pointsProductCheckoutLines.length == 0
    ) {
      this.snackBarService.openSnackBar(
        'Não tem produtos no carrinho',
        '#F97066',
        '../../../assets/Icons/ErrorIcon.png'
      );
      return;
    }
    if (this.changingCheckbox == true) {
      // Checks if the user has enough points to complete the order
      if (this.orderPointsTotal > this.points) {
        this.snackBarService.openSnackBar(
          'Sem pontos suficientes para efetuar a compra',
          '#F97066',
          '../../../assets/Icons/ErrorIcon.png'
        );
        return;
      }
    }


    this.isLoading = true;

    const pendingOrdersRequest: PendingOrdersRequest = {
      Page: 0,
      PageSize: 1000,
      userId: this.loggedUser.userId,
      numClient: this.loggedUser.clientId,
      codigoMorada: this.selectedAddress.clientAddressCode
    };

    const dialogData = {
      title: ' com o Checkout?',
      description:
        ' com o Checkout? Pelo menos um destes items encontra-se numa encomenda pendente!',
      action: 'Continuar',
    };

    this.unsubscribe.add(
      this.encomendaService
        .getPendingOrders(pendingOrdersRequest)
        .subscribe((resp) => {
          let normalProduct: PendingOrders[] = [];
          this.normalCurrencyCheckoutLines.forEach(
            (x) =>
            (normalProduct = resp.encomendas.data.filter(
              (y) => y.codigo === x.referencia
            ))
          );

          let pointsProduct: PendingOrders[] = [];
          this.pointsProductCheckoutLines.forEach(
            (x) =>
            (pointsProduct = resp.encomendas.data.filter(
              (y) => y.codigo === x.referencia
            ))
          );

          if (normalProduct.length > 0 || pointsProduct.length > 0) {
            const dialogRef = this.dialog.open(GenericModalComponent, {
              data: dialogData,
            });
            this.unsubscribe.add(
              dialogRef.afterClosed().subscribe((result) => {
                if (result === true) {
                  this.ExecuteCheckout();
                } else {
                  this.isLoading = false;
                  return;
                }
              })
            );
          } else {
            this.ExecuteCheckout();
          }
        })
    );
  }

  // FUNCTION : Send the current data to Checkout
  async ExecuteCheckout(): Promise<void> {
    this.checkForDuplicateLines();
    if (this.duplicated === true) {
      const mensagem = `Não foi possível efetuar o checkout, produto duplicado no carrinho. Queira por favor remover a linha repetida e ajustar as quantidades em conformidade para a referência ${this.referenciaDuplicada}`;
      this.snackBarService.openSnackBar(
        mensagem,
        '#F97066',
        '../../../assets/Icons/ErrorIcon.png',
        15000
      );
      this.isLoading = false;
      return
    }
    let checkoutOrder: CheckoutOrderModel = {
      UserId: '',
      ClientId: this.profileRequest.clientId,
      Address: this.isMasterUser
        ? (this.selectedAddress?.clientAddressCode as string)
        : this.defaultAddress,
      Obs: this.observations,
      HasRecipe: false,
      Currency: 'EUR',
      OrderLines: this.normalCurrencyCheckoutLines.concat(
        this.pointsProductCheckoutLines
      ),
    };


    // Check if SGG_Fail_Time exists
    const storedTime = localStorage.getItem('SGG_Fail_Time');
    if (storedTime) {
      // Convert the stored string back to a Date object
      const storedDate = new Date(storedTime);
      const currentDate = new Date();

      // Calculate the difference in milliseconds
      const differenceInMs = currentDate.getTime() - storedDate.getTime();

      // Check if the difference is greater than 1 hour (3600000 ms)
      if (differenceInMs > 3600000) 
        this.bypassSGG = true;
    }


    this.unsubscribe.add(
      this.checkoutService
        .ExecuteCheckout(checkoutOrder, this.bypassSGG)
        .subscribe(async (response) => {
          let checkoutResponse: CheckoutOrderResponseModel[] = response;
          this.existsInDb = Number(checkoutResponse[0].dbOrderNumber) > 0;          
          // No responses returned
          if (checkoutResponse.length == 0) {
            this.snackBarService.openSnackBar(
              'Não foi possível efetuar o checkout, por favor tente mais tarde',
              '#F97066',
              '../../../assets/Icons/ErrorIcon.png'
            );
            this.isLoading = false;
          }

          // There are responses
          else {
            // For each entry, check if there is a success and register of what type of order it was
            let orderCount = 0;
            for (let i = 0; i < checkoutResponse.length; i++) {
              let element = checkoutResponse[i];
              if (element.sggSuccess || this.existsInDb) {
                this.anySuccess = true;
                if (element.orderOfPoints) this.pointsOrderSuccess = true;
                else this.regularOrderSuccess = true;

                if (orderCount == 0) {
                  this.sggOrderNumber = element.sggOrderNumber;
                  orderCount++;
                } else this.sggOrderNumber += ' / ' + element.sggOrderNumber;
              }

              if(!element.sggSuccess)
                this.sggFailed = true;
            }


            if (this.sggFailed && !localStorage.getItem('SGG_Fail_Time')) {
              // Get current date and time
              let currentDateTime = new Date();
              
              // Store as a string in ISO format for easy comparison later
              this.checkoutService.storeSGGFailTime(currentDateTime.toISOString());
            }
            
            if (!this.sggFailed && localStorage.getItem('SGG_Fail_Time')) 
            {
              // Clear the local storage entry for SGG fail time when there is success
              localStorage.removeItem('SGG_Fail_Time');
            }


            // if there was no success and Order DB Id equal to zero
            if (!this.anySuccess && !this.existsInDb) {
              this.snackBarService.openSnackBar(
                'Não foi possível efetuar o checkout, por favor tente mais tarde',
                '#F97066',
                '../../../assets/Icons/ErrorIcon.png'
              );
              this.isCheckoutComplete = false;
              this.isLoading = false;
            }
            // Check for all the possible success cases
            else {
              if (
                (response.length == 1 && this.regularOrderSuccess) ||
                (response.length == 1 && this.pointsOrderSuccess) ||
                (response.length == 2 &&
                  this.regularOrderSuccess &&
                  this.pointsOrderSuccess) ||
                (response.length > 0 && this.existsInDb)
              ) {
                this.snackBarService.openSnackBar(
                  'Pedido efetuado com sucesso',
                  '#12B76A',
                  '../../assets/Icons/correct.png'
                );
                this.isCheckoutComplete = true;
                this.isLoading = false;
                this.cartService.loadCart();

                let fullKey = this.user.userId + '_' + this.user.clientId + '_' + 'observations';
                sessionStorage.removeItem(fullKey);
              }
              // This was a 2 part order and it only was partly successful
              else {
                this.snackBarService.openSnackBar(
                  'Pedido efetuado com sucesso de forma parcial. Tente fazer nova encomenda para os produtos que não foram encomendados.',
                  '#12B76A',
                  '../../assets/Icons/correct.png'
                );
                this.isCheckoutComplete = true;
                this.isLoading = false;
              }
            }
          }
        })
    );
    localStorage.removeItem('BIO2_SelectedChangedAddress')
  }

  // FUNCTION : Checks all of the checkout lines and updates the order totals
  UpdateOrderTotals() {
    let total = 0;
    let pointsTotal = 0;

    this.normalCurrencyCheckoutLines.forEach((line) => {
      const applicableCampaign = this.getApplicableCampaign(line);

      if (applicableCampaign) {
        total += line.qtd * Math.round((line.valor_Liquido - (line.valor_Liquido * (applicableCampaign.discountPercentage / 100)))* 100) / 100;
      } else {
        total += line.qtd * line.valor_Liquido;
      }
    });

    // ---------------------- PONTOS - ATUALIZAR -------------------->
    this.pointsProductCheckoutLines.forEach((line) => {
      pointsTotal += line.qtd * line.pontos;
    });

    this.orderTotal = parseFloat(total.toFixed(2));
    this.orderPointsTotal = pointsTotal;
  }

  // Add this variable to keep track of the current bonus index
  currentBonusIndex: number = 0;

  // FUNCTION : Checks if line has a bonus product and if it does, checks and updates the bonus quantity to receive
  CheckAndUpdateBonusQuantity(line: CheckoutLineModel): void {
    if (line.bonusProductInfo != null) {
      line.bonusProductInfo.forEach((bonusInfo) => {
        let productReference = line.referencia;
        let totalProductQuantity = 0;
        let bonusProductReference = bonusInfo.bonusProductReference;
        let totalBonusQuantity = 0;

        // Gets the total quantity of the product in normal currency
        this.normalCurrencyCheckoutLines.forEach((normalLine) => {
          if (normalLine.referencia == productReference)
            totalProductQuantity += normalLine.qtd;
        });

        // Gets the total quantity of the product in points
        this.pointsProductCheckoutLines.forEach((pointsLine) => {
          if (pointsLine.referencia == productReference)
            totalProductQuantity += pointsLine.qtd;
        });

        // Sort bonus tiers in descending order of requiredProductQuantity
        let sortedTiers = bonusInfo.bonusTiers.sort(
          (a, b) => b.requiredProductQuantity - a.requiredProductQuantity
        );

        let remainingQuantity = totalProductQuantity;

        // Iterate through bonus tiers and apply bonuses step by step
        sortedTiers.forEach((tier) => {
          let applicableBonusCount = Math.floor(
            remainingQuantity / tier.requiredProductQuantity
          );

          if (applicableBonusCount > 0) {
            totalBonusQuantity += applicableBonusCount * tier.bonusProductQuantityGiven;
            remainingQuantity -= applicableBonusCount * tier.requiredProductQuantity;
          }
        });

        // Update the accumulated bonus
        bonusInfo.bonusAccumulated = totalBonusQuantity;

        // If the bonus quantity is 0
        if (totalBonusQuantity == 0) {
          // Remove existing bonus product line if present
          let index = this.bonusProductCheckoutLines.findIndex(
            (x) => x.bonusProductReference == bonusProductReference
          );
          if (index != -1) this.bonusProductCheckoutLines.splice(index, 1);
        } else {
          // Find existing bonus product line
          let index = this.bonusProductCheckoutLines.findIndex(
            (x) => x.bonusProductReference == bonusProductReference
          );

          if (index != -1) {
            // Update the quantity
            this.bonusProductCheckoutLines[index].bonusAccumulated = totalBonusQuantity;
          } else {
            // Add new bonus product
            let bonusProductInfo: BonusProductInfo = {
              bonusProductReference: bonusProductReference,
              bonusProductName: bonusInfo.bonusProductName,
              bonusAccumulated: totalBonusQuantity,
              bonusTiers: bonusInfo.bonusTiers,
            };
            this.bonusProductCheckoutLines.push(bonusProductInfo);
            // Increment the currentBonusIndex when adding a new bonus product
            this.currentBonusIndex++;
          }
        }
      });
    }
  }

  // FUNCTION : Opens the modal to confirm the deletion of the line
  OpenDeleteConfirmationModal(checkoutLine: CheckoutLineModel) {
    this.checkoutLineToRemove = checkoutLine;
    const dialogRef = this.dialog.open(CheckoutDialogComponent, {
      width: '350px',
    });

    this.unsubscribe.add(
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.RemoveLine(checkoutLine);
        }
      })
    );
  }

  onEnterPress(inputElement: HTMLInputElement): void {
    inputElement.blur();
  }

  OnkeyDown(e: any) {
    if (isNaN(e.key) && e.key !== 'Backspace' && e.key !== 'Enter') {
      e.preventDefault();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
    if (this.pointsSubscription) {
      this.pointsSubscription.unsubscribe();
    }
  }

  loggedUser: ProfileDataRequest = this.perfilService.getCurrentUserInfo();
  userData!: Cliente;

  getUserDetails(): void {
    this.perfilService
      .getUserProfileData(this.loggedUser.clientId, this.loggedUser.userId)
      .subscribe({
        next: (resp) => {
          this.userData = resp.client;
        },
        error: (e) => console.error(e),
      });
  }

  saveObservations() {
    let obsEncripted = this.encrypt.encrypt(this.observations);
    sessionStorage.setItem(this.user.userId + '_' + this.user.clientId + '_' + 'observations', obsEncripted!);
  }

  isButtonDisabled(): boolean {
    if (this.orderPointsTotal !== 0) {
      const sggFailTime = localStorage.getItem('SGG_Fail_Time');
      if (sggFailTime) {
        const failTime = new Date(sggFailTime);
        const currentTime = new Date();
        if ((currentTime.getTime() - failTime.getTime()) > 3600000) {
          return false;
        }
      }
    }
    if (this.orderPointsTotal === 0) {
      return false;
    }
    if (this.isReadingPoints || (this.orderPointsTotal > 0 && this.orderPointsTotal > this.points) || (this.orderTotal + this.orderPointsTotal) == 0) {
      return true;
    }
    else return false
  }

  onAddressSelect(event: any) {
    let newAddress = event.value
    this.encrypt.setEncryptedItem(
      StorageKeys.BIO2_SELECTED_CHANGED_ADDRESS,
      JSON.stringify(newAddress)
    );
  }

  isModalOpen: boolean = false;

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  closeOuside(event: Event) {
    if ((<HTMLElement>event.target).classList.contains('modal')) {
      this.closeModal();
    }
  }

  getApplicableCampaign(line: { quantityCampaign?: QuantityCampaignDetails[]; qtd: number }): QuantityCampaignDetails | undefined {   
    return line.quantityCampaign?.find(campaign => 
        line.qtd > campaign.quantity && 
        (campaign.maxQuantity === 0 || line.qtd <= campaign.maxQuantity) &&
        campaign.discountPercentage > 0 
    );
  }  
  
  getFormattedTotal(line: { quantityCampaign?: QuantityCampaignDetails[]; qtd: number; valor_Liquido: number }): string {
    const campaign = this.getApplicableCampaign(line);
    let total: number;
  
    if (campaign) {
      // Apply discount and round to 2 decimals
      const discountedPrice = Math.round((line.valor_Liquido * (1 - campaign.discountPercentage / 100)) * 100) / 100;
      total = discountedPrice * line.qtd;
    } else {
      total = line.qtd * line.valor_Liquido;
    }
  
    // Rounding final total
    return (Math.round(total * 100) / 100).toFixed(2);
  }
  
}
