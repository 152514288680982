import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {
  catchError,
  from,
  Observable,
  throwError,
  switchMap,
  of,
  tap,
  Subject,
  BehaviorSubject,
  filter,
  take,
} from 'rxjs';
import { AuthService } from '../api/services/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Bio2Tokens } from './models/bio2token';
import { SnackBarService } from '../api/services/snack-bar.service';
import { CampaignsService } from './services/campaigns.service';
import { StorageKeys } from './models/storageKeys';
import { PerfilService } from './services/perfil.service';
import { ProfileDataRequest } from './models/perfil';

interface HasHeaderFunction {
  (request: HttpRequest<any>, header: string): boolean;
}

const hasHeader: HasHeaderFunction = (
  request: HttpRequest<any>,
  header: string
): boolean => {
  return request && request.headers && request.headers.has(header);
};

interface HasBodyFunction {
  (request: HttpRequest<any>): boolean;
}

const hasBody: HasBodyFunction = (request: HttpRequest<any>): boolean => {
  return (
    request.method === 'POST' ||
    request.method === 'PUT' ||
    request.method === 'PATCH'
  );
};

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  accessToken: string | null;
  private cache: Map<string, HttpResponse<any>> = new Map();
  private isRefreshingtoken: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private readonly AuthService: AuthService,
    private router: Router,
    private snackBarService: SnackBarService,
    private campaignService: CampaignsService,
    private perfilData: PerfilService
  ) {
    this.accessToken = this.AuthService.getAccessToken();
  }
  cacheRequest(req: HttpRequest<any>, key: string, next: HttpHandler) {
    const cacheRequest = this.cache.get(key);
    if (cacheRequest) {
      return of(cacheRequest.clone());
    } else {
      return next.handle(req).pipe(
        tap((stateEvent) => {
          if (stateEvent instanceof HttpResponse) {
            this.cache.set(key, stateEvent.clone());
          }
        }),
        catchError((err) => {
          return this.handleError(err, req, next);
        })
      );
    }
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.AuthService.getAccessToken();
    if (accessToken) {
      const profileData: ProfileDataRequest = this.perfilData.getCurrentUserInfo();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          'X-User-Id': profileData.userId ? profileData.userId : '',
          'X-Client-Id': profileData.clientId ? profileData.clientId : '',
        },
      });
    }

    //Check if there is a client selected when is a admin
    const client = localStorage.getItem(StorageKeys.BIO2_CLIENT_ADDRESS_ID);
    if(accessToken){
      const decodedToken = this.AuthService.getDecodedAccessToken(accessToken);
      const role = decodedToken['role'];
      const ignored = ['login', 'backoffice']
      const routesIgnored = ignored.some(r => this.router.url.includes(r));
      if(role === 'Administrador' && !client && !routesIgnored){
        this.router.navigate(['/login/selection/subLogin']);
      }
    }
      

    const clearCache = request.headers.get('clearCache');
    if (clearCache && clearCache === 'clear') {
      this.cache.clear();
    }
    if (request.url.includes('campaign/allActive')) {
      return this.cacheRequest(request, 'campaign/allActive', next);
    }
    if(request.url.includes('campaign/product')){
      let search = request.params.get('search');
      if(search === null){
        search = '';
      }
      return this.cacheRequest(request, 'campaign/product/' + search, next);
    }

    return next.handle(request).pipe(
      catchError((err) => {
        return this.handleError(err, request, next);
      })
    );
  }

  handleError(err: any, request: HttpRequest<any>, next: HttpHandler) {
    if (
      err.status === 401 &&
      request.url !== this.AuthService.ApiAuthRefreshTokenPostPath
    ) {
      if (!this.isRefreshingtoken) {
        this.isRefreshingtoken = true;
        this.refreshTokenSubject.next(null);

        return this.refreshToken(request, next);
      } else {
        return this.refreshTokenSubject.pipe(
          filter((token) => token != null),
          take(1),
          switchMap((token) => {
            return this.updateTokenAndRetry(request, next, token);
          })
        );
      }
    }
    if (err.status === 0 || err.status === 504 || err.status === 400) {
      this.snackBarService.openSnackBar(
        'Algo não correu bem, estamos a trabalhar para corrigir',
        '#F9841E',
        '../../assets/Icons/Warning-icon.png'
      );
    }
    if (err.status > 499 && err.status < 600) {
      return throwError(() => err);
    }
    this.AuthService.clearSessionStorage();
    this.router.navigate(['/login']);
    return throwError(() => err);
  }

  refreshToken(request: any, next: any) {
    return this.AuthService.getRefreshToken().pipe(
      switchMap((t) => {
        this.isRefreshingtoken = false;
        this.refreshTokenSubject.next(t);

        return this.updateTokenAndRetry(request, next, t);
      })
    );
  }

  updateTokenAndRetry(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: Bio2Tokens
  ): Observable<HttpEvent<any>> {
    // Update local stored user

    this.AuthService.storeTokens(token);
    // Add the new token to the request
    return this.executeRequest(request, next);
  }
  executeRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const profileData: ProfileDataRequest = this.perfilData.getCurrentUserInfo();

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('BIO2_AccessToken')}`,
        'X-User-Id': profileData.userId ? profileData.userId : '',
        'X-Client-Id': profileData.clientId ? profileData.clientId : '',
      },
    });

    return next.handle(request);
  }
}
