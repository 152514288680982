<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div *ngIf="isload" class="loading-animation" role="alert" aria-live="assertive">
      <app-loading></app-loading>
    </div>
    <ng-container *ngIf="!isload">
      <div class="content">
        <div class="back-btn">
          <button class="btn" (click)="goToCampaigns()" style="float: left">
            <img src="../../assets/Icons/arrow-left-line.png" alt="Back" />
          </button>
          <span style="overflow: hidden;">
            Campanhas /
          </span>
          <span class="separador" (click)="goToCampaign()">
            {{campaign?.name}}
          </span>
          <span style="overflow: hidden; margin-left: 5px;">
            /
          </span>
          <span class="separador">
            {{campaignGroup?.description}}
          </span>
        </div>
        <div *ngIf="!(showCampaignGroupImage().endsWith('webm') || showCampaignGroupImage().endsWith('mp4'))">
          <img [src]="showCampaignGroupImage()" alt="Imagem da Campanha" class="img-campanha" />
        </div>
        <div *ngIf="(showCampaignGroupImage().endsWith('webm')) || (showCampaignGroupImage().endsWith('mp4'))">
          <video autoplay muted loop [src]="showCampaignGroupImage()" alt="Vídeo da Campanha"
            class="img-campanha"></video>
        </div>
        <div *ngIf="campaignGroup?.products?.length! > 1" class="div-span">
          <span class="span-inter">
            Produtos em Destaque
          </span>
        </div>
        <div class="mt-4">
          <div class="d-flex flex-row flex-wrap justify-content-start" style="gap:20px">
            <app-product-shop *ngFor="let product of campaignGroup?.products" [product]="product">
            </app-product-shop>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
