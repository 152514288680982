import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShopItemModel } from 'src/app/api/models/shop-item.model';

@Component({
  selector: 'app-favorite-modal',
  templateUrl: './favorite-modal.component.html',
  styleUrls: ['./favorite-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FavoriteModalComponent {
  product!: ShopItemModel
 constructor(@Inject(MAT_DIALOG_DATA) public data: ShopItemModel, public dialogRef: MatDialogRef<FavoriteModalComponent>) {
  this.product = data;
 }

  ngOnInit() {}

  cancelDelete(): void {
    this.dialogRef.close("Cancel");
  }
}
