<div class="d-flex flex-row">
    <div>
        <app-dashboard-side-menu></app-dashboard-side-menu>
    </div>
    <div class="p-content d-flex flex-column w-100">
        <app-dashboard-top-menu></app-dashboard-top-menu>
        <!-- !!Loading animation -->
        <div *ngIf="isLoading; else contentLoaded" class="loading-animation">
            <app-loading></app-loading>
        </div>
        <ng-template #contentLoaded>
            <div class="template-content">
                <div class="w-100 d-flex flex-column justify-content-start">
                    <form>
                        <div>
                            <h2>Exportação de Encomendas</h2>
                            <div class="row mb-3 d-flex justify-content-start">
                                <div class="col-md-6 col-xs-12" style="max-width: 500px;">
                                    <p>Inicial<span>*</span> <span
                                            *ngIf="!inicioValid && isSubmitted">Obrigatório</span></p>
                                    <mat-form-field class="w-100">
                                        <input matInput name="encomendaInicio" [(ngModel)]="encomendaInicio"
                                            (ngModelChange)="updateEncomendaFim()">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col-xs-12" style="max-width: 500px;">
                                    <p>Final<span>*</span> <span *ngIf="!fimValid && isSubmitted">Obrigatório</span></p>
                                    <mat-form-field class="w-100">
                                        <input matInput name="encomendaFim" [(ngModel)]="encomendaFim">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div style="width: 90px;">
                                <label class="form-control checkbox-label">
                                    <input type="checkbox" name="checkbox" (change)="statusChanged($event)" />
                                    <span class="pl-1">Ver XML</span>
                                </label>
                            </div>

                            <button class="btn-processar mt-4" (click)="processar()" [disabled]="isLoading">
                                <span>Processar</span>
                                <img src="../../../../assets/Icons/check-line.png" alt="Check">
                            </button>
                        </div>
                    </form>

                    <!--*ENCOMENDAS-->
                    <ng-container *ngIf="!isLoading && responses">
                        <div *ngIf="responses.length > 0">
                            <div class="response-container">
                                <div class="file-container" *ngFor="let resp of responses">
                                    <div class="file-info">
                                        <div *ngIf="xml">
                                            <p>{{ resp.pedidoInicial }}</p>
                                            <br>
                                            <p>{{ resp.pedido }}</p>
                                            <br>
                                            <p>{{ resp.resposta }}</p>
                                        </div>
                                        <p>{{ resp.result }}</p>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-template>
        <app-footer></app-footer>
    </div>
</div>