import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';
import {
  SearchStatsModel,
  SearchStatsResponseModel,
  StatisticsViewModel,
} from '../models/statistics';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  route = new ApiConfiguration();
  ApiGetStatsViewModelPath =
    this.route.rootUrl + '/api/Estatisticas/get-stats-view-model';
  ApiSearchStatsPath = this.route.rootUrl + '/api/Estatisticas/search-stats';

  constructor(private http: HttpClient, private authService: AuthService) {}

  GetStatisticsViewModel(clientId: string): Observable<StatisticsViewModel> {
    let endpoint = `${this.ApiGetStatsViewModelPath}/${clientId}` ;
    let accessToken = localStorage.getItem('BIO2_AccessToken');
    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    });
    return this.http.get<StatisticsViewModel>(endpoint, { headers: headers });
  }

  SearchStats(
    searchParameters: SearchStatsModel
  ): Observable<SearchStatsResponseModel> {
    let endpoint = `${this.ApiSearchStatsPath}`;
    let accessToken = localStorage.getItem('BIO2_AccessToken');
    let tokenData = this.authService.getDecodedAccessToken(accessToken!);

    searchParameters.userId = tokenData['registoId'];

    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    });

    return this.http.post<SearchStatsResponseModel>(
      endpoint,
      searchParameters,
      { headers: headers }
    );
  }
}
