import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';

export const AuthPermissionsGuard: CanActivateFn = (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  const requiredPermission = route.data['permissions'];

  if(requiredPermission) {
    const hasPermission = authService.hasPermissions(requiredPermission);
    if (!hasPermission) {
      router.navigate(['/home']);
      return false;
    }
    return true;
  }

  return true;
};
