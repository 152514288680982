import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css'],
})
export class SelectionComponent {
  constructor(private Router: Router) {}
  private unsubscriber: Subject<void> = new Subject<void>();

  navigateToSubLogin() {
    this.Router.navigate(['login/selection/subLogin']);
  }

  navigateToBackofice() {
    this.Router.navigate(['backoffice/dashboard']);
  }

  ngOnInit(): void {
    history.pushState(null, '');

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
