import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, catchError, combineLatest, concatMap, finalize, from, mergeMap, of, switchMap, tap } from 'rxjs';
import { CartProductModel } from '../models/checkout';
import { ShopItemModel } from '../models/shop-item.model';
import { PerfilService } from './perfil.service';
import { CheckoutService } from './checkout.service';
import { ProfileDataRequest } from '../models/perfil';
import { EncryptionService } from './encryption.service';
import { StorageKeys } from '../models/storageKeys';

@Injectable({
  providedIn: 'root',
})

export class CartService {
  private sideMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sideMenu$: Observable<boolean> = this.sideMenuSubject.asObservable();

  private shopCart = new BehaviorSubject<CartProductModel[]>([]);
  shopCart$: Observable<CartProductModel[]> = this.shopCart.asObservable();

  public userTotalPoints: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  userTotalPoints$ = this.userTotalPoints.asObservable();

  public cartLoaded = false;

  public pointsLoaded = false;

  private userCartPoints: number = 0;

  private loggedUser!: ProfileDataRequest;

  constructor(
    private perfilService: PerfilService,
    private checkoutService: CheckoutService,
    private encryptSvc: EncryptionService) {
  }

  public getShopCart(): BehaviorSubject<CartProductModel[]> {
    return this.shopCart;
  }

  loadCart() {
    this.loggedUser = this.perfilService.getCurrentUserInfo();
    this.checkoutService.getUserCart(this.loggedUser.userId, this.loggedUser.clientId)
      .pipe(
        concatMap(response => {
          this.updateShopCart(response);
          this.userCartPoints = 0;
          response.forEach(x => {
            if (x.usePoints === true) {
              this.userCartPoints += x.points * x.quantity;
            }
          });
          return this.checkoutService.getClientPoints(this.loggedUser.userId, this.loggedUser.clientId);
        }),
        finalize(() => {
        })
      )
      .subscribe(response => {
        this.userTotalPoints.next(response - this.userCartPoints);
      });
  }

  getCart() {
    return this.shopCart.asObservable();
  }

  toggleSideMenu() {
    this.sideMenuSubject.next(!this.sideMenuSubject.value);
  }

  updateShopCart(newCart: CartProductModel[]) {
    this.shopCart.next(newCart);
  }

  updateUserPoints(operation: string, points: number, oldQuantity?: number, newQuantity?: number) {
    let currentPoints = this.userTotalPoints.getValue();
    if (operation === 'add') {
      currentPoints -= points;
    }
    if (operation === 'remove') {
      currentPoints += points;
    }
    if (operation === 'update' && oldQuantity !== undefined && newQuantity !== undefined) {
      currentPoints += oldQuantity * points;
      currentPoints -= newQuantity * points;
    }

    this.userTotalPoints.next(currentPoints);
  }

  increaseCartMethod($event: ShopItemModel, usePoints: boolean, deferUpdate: boolean): Observable<boolean> {
    this.loggedUser = this.perfilService.getCurrentUserInfo();
    const currentShopCart = this.shopCart.getValue();
    const updatedCart = [...currentShopCart];
    this.loggedUser = this.perfilService.getCurrentUserInfo();
    let product = updatedCart.find(p => p.productId === $event.reference && p.usePoints === usePoints && p.previouslyUsingPoints === usePoints);
    if (!deferUpdate) {
      if (!product) {
        product = {
          userId: this.loggedUser.userId,
          clientId: this.loggedUser.clientId,
          productId: $event.reference,
          quantity: 1,
          previouslyUsingPoints: usePoints,
          usePoints: usePoints,
          points: $event.points
        };
        updatedCart.push(product);
      } else {
        product.quantity++;
      }
      if (usePoints) {
        this.updateUserPoints('add', $event.points);
      }
      this.shopCart.next(updatedCart);
    }
    if (product && deferUpdate) {
      this.updateBackendCart(product, false).subscribe(opResult => {
        this.shopCart.next(updatedCart);
      });
      return of(true);
    }
    return of(false);
  }

  decreaseCartMethod($event: ShopItemModel, usePoints: boolean, deferUpdate: boolean): Observable<boolean> {
    const currentShopCart = this.shopCart.getValue();
    const updatedCart = [...currentShopCart];
    const productIndex = updatedCart.findIndex(p => p.productId === $event.reference && p.usePoints === usePoints && p.previouslyUsingPoints === usePoints);
    if (productIndex !== -1) {
      const product = updatedCart[productIndex];
      if (!deferUpdate) {
        if (product.quantity > 0) {
          product.quantity--;
        }
        this.shopCart.next(updatedCart);
        if (usePoints) {
          this.updateUserPoints('remove', $event.points);
        }
      }
      if (deferUpdate) {
        this.updateBackendCart(product, false).subscribe(opResult => {
          this.shopCart.next(updatedCart);
        });
        return of(true);
      }
    }
    return of(false);
  }

  changeQuantityCartMethod($event: ShopItemModel, usePoints: boolean, input: number): Observable<boolean> {
    const currentShopCart = this.shopCart.getValue();
    let product = currentShopCart.find(p => p.productId === $event.reference && p.usePoints === usePoints);
    let oldQuantityForPoints = 0;


    if(this.loggedUser === undefined) 
      this.loggedUser = this.perfilService.getCurrentUserInfo();

    if (!product) {
      product = {
        userId: this.loggedUser.userId,
        clientId: this.loggedUser.clientId,
        productId: $event.reference,
        quantity: input,
        previouslyUsingPoints: usePoints,
        usePoints: usePoints,
        points: $event.points
      };
      currentShopCart.push(product);
    } else {
      if (input == 0) {
        const existingProductIndex = currentShopCart.findIndex(p => p.productId === $event.reference && p.usePoints === $event.isPoints);
        currentShopCart.splice(existingProductIndex, 1);
      }
      oldQuantityForPoints = product.quantity;
      product.quantity = input;
    }

    this.shopCart.next(currentShopCart);
    if (usePoints === true) {
      this.updateUserPoints('update', $event.points, oldQuantityForPoints, input);
    }

    return this.updateBackendCart(product, false).pipe(
      switchMap(opResult => {
        return of(opResult);
      })
    );
  }

  updateProduct(product: CartProductModel): Observable<boolean> {
    const currentShopCart = this.shopCart.getValue();
    const existingProductIndex = currentShopCart.findIndex(p => p.productId === product.productId && p.usePoints === product.usePoints);

    if (existingProductIndex !== -1) {
      if (product.quantity === 0) {
        currentShopCart.splice(existingProductIndex, 1);
      } else {
        currentShopCart[existingProductIndex].quantity = product.quantity;
      }
    } else if (product.quantity > 0) {
      currentShopCart.push(product);
    }

    this.shopCart.next(currentShopCart);

    return this.updateBackendCart(product, false).pipe(
      switchMap(opResult => {
        return of(opResult);
      })
    );
  }

  productPointsSwitcher(product: CartProductModel): Observable<boolean> {
    const currentShopCart = this.shopCart.getValue();

    let productPresent = currentShopCart.findIndex(p => p.productId === product.productId && p.usePoints === !(product.usePoints));
    let productToIncrement = currentShopCart.findIndex(p => p.productId === product.productId && p.usePoints === (product.usePoints));

    currentShopCart[productPresent].usePoints = product.usePoints;
    currentShopCart[productPresent].previouslyUsingPoints = product.usePoints;

    if (productToIncrement !== -1) {
      currentShopCart[productPresent].quantity += currentShopCart[productToIncrement].quantity;
      currentShopCart.splice(productToIncrement, 1);
    }

    this.shopCart.next(currentShopCart);

    return this.updateBackendCart(product, false).pipe(
      switchMap(opResult => {
        return of(opResult);
      })
    );
  }

  updateBackendCart(product: CartProductModel, isBonus: boolean): Observable<boolean> {
    return from(this.checkoutService.CrudCartProduct(product)).pipe(
      mergeMap(response => {
        return new Observable<boolean>(observer => {
          let opResult: boolean = response;
          observer.next(opResult);
          observer.complete();
        });
      }),
      catchError(error => {
        return new Observable<boolean>(observer => observer.next(false));
      })
    );
  }
}