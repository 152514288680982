<div class="main-top-menu">
  <div class="d-inline padding-fixer pb-5 m-auto search-bar">
    <div class="d-flex flex-row" style="align-items: center;">
      <img src="../../../assets/Icons/search.png" class="search-icon" alt="Ícone de pesquisa" (click)="search()" />
      <input class="input-txt-address" type="text" matInput [formControl]="searchformControl"
        [matAutocomplete]="productAuto" placeholder="Pesquisa" (keydown.enter)="search()"
        (input)="handleInputSearch($event)" aria-label="Pesquisa por artigo" />
      <mat-autocomplete #productAuto="matAutocomplete">
        <div *ngIf="searchformControl.value !== '' && showAutocomplete">
          <mat-option *ngFor="let product of filteredProducts" [value]="product.memory"
            (click)="search(product.reference)">
            {{ product.memory }}
          </mat-option>
        </div>
      </mat-autocomplete>
      <div class="search-clear" *ngIf="searchformControl.value !== ''">
        <img src="../../../assets/Icons/x-button.png" alt="Limpar pesquisa" (click)="cleanSearch()">
      </div>
    </div>
  </div>
  <div *ngIf="authService.hasPermissions(['27']) || this.currentUser.role === 'Cliente'" class="label-pontos" style="max-height: 1, 5 rem">
    <span *ngIf="loadingCoins === false" [@fadeInOut] class="pontos"> Pontos: {{ ReadPoints ? ReadPoints : 0 }} </span>
    <img *ngIf="loadingCoins === true" [@fadeInOut] src="../../../assets/Icons/LoadingCoins.gif" alt="Ícone de pontos">
  </div>

  <div class="d-flex flex-row align-items-center">
    <div>
      <div class="carCircle" *ngIf="cartQuantity !== 0">
        <span class="carCounter">{{ cartQuantity }}</span>
      </div>
      <img class="car-shop-img" routerLink="/checkout" src="../../../assets/Icons/shopping-cart.png" alt="Ícone de checkout" />
    </div>
    <img class="profile" [matMenuTriggerFor]="profileMenu" src="../../../assets/avatars/{{ avatar }}.svg" height="40"
      alt="Ícone de avatar pessoal" />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="menuMobile" (click)="toggleSideMenu()"
      style="height: 40px; width: 40px">
      <path d="M3 4H21V6H3V4ZM9 11H21V13H9V11ZM3 18H21V20H3V18Z"></path>
    </svg>

    <mat-menu #profileMenu="matMenu" xPosition="before">
      <button *ngIf="authService.hasPermissions(['19'])" aria-label="Abrir perfil" mat-menu-item (click)="goPerfil()">
        <mat-icon svgIcon="emotion" iconPositionEnd class="h-auto"></mat-icon>
        Perfil
      </button>
      <button *ngIf="!isAdmin" mat-menu-item aria-label="Abrir favoritos" (click)="goFavoritos()">
        <mat-icon svgIcon="heart" iconPositionEnd class="h-auto"></mat-icon>
        Favoritos
      </button>
      <button mat-menu-item (click)="goProduto()" aria-label="Adicionar produto" *ngIf="currentUser.role === 'Laboratorio'">
        <mat-icon svgIcon="newprod" iconPositionEnd class="h-auto"></mat-icon>
        Produto
      </button>
      <button mat-menu-item (click)="goCampanha()" aria-label="Adicionar campanha" class="campanha" *ngIf="currentUser.role === 'Laboratorio'">
        <mat-icon svgIcon="newcamp" iconPositionEnd class="h-auto"></mat-icon>
        Campanha
      </button>
      <button mat-menu-item *ngIf="currentUser.role === 'Laboratorio'" aria-label="Alterar Cliente" (click)="onChangeClient()">
        <mat-icon svgIcon="profile" iconPositionEnd class="h-auto"></mat-icon>
        Alterar Cliente
      </button>
      <button mat-menu-item *ngIf="currentUser.role === 'Administrador'" aria-label="Alterar Cliente" (click)="onChangeClient()">
        <mat-icon svgIcon="profile" iconPositionEnd class="h-auto"></mat-icon>
        Alterar Cliente
      </button>
      <button mat-menu-item *ngIf="currentUser.role === 'Administrador'" aria-label="Alterar Portal" (click)="onChangeToBackoffice()">
        <mat-icon svgIcon="changeportal" iconPositionEnd class="h-auto"></mat-icon>
        Alterar Portal
      </button>
      <button mat-menu-item (click)="onLogout()" aria-label="Logout">
        <mat-icon svgIcon="door" iconPositionEnd class="h-auto"></mat-icon>
        Logout
      </button>
    </mat-menu>
  </div>
</div>
