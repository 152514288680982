<div class="d-flex flex-row">
  <div>
    <app-dashboard-side-menu></app-dashboard-side-menu>
  </div>
  <div class="p-content d-flex flex-column w-100">
    <app-dashboard-top-menu></app-dashboard-top-menu>
    <div *ngIf="loading; else contentLoaded" class="loading-animation">
      <app-loading></app-loading>
    </div>
    <ng-template #contentLoaded>
      <div class="content">
        <form [formGroup]="myForm" (ngSubmit)="alterProd()">
          <span class="title">Editar produto</span>
          <div class="green-div">
            <div class="row-double">
              <div>
                <!-- Upload novo produto -->
                <div class="upload-template">
                  <span class="text-description">Selecione o produto</span>
                  <app-search-input-products
                    [isMultiple]="false"
                    [plist]="plist" 
                    [searchControl]="myControl" 
                    [selectProducts]="selectProducts" 
                    [selectedItems]="selectedItens">
                  </app-search-input-products>
                </div>
                <!-- Upload ficha técnica (.PDF) -->
                <div class="upload-ft">
                  <span class="text-description">Upload ficha técnica (.PDF)</span>
                  <mat-form-field>
                    <ngx-mat-file-input [formControl]="technicalFileUpdt" [accept]="fileTypeUpdt">
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <!-- Upload de Imagem (550x550px) -->
                <div class="upload-img">
                  <span class="text-description">Upload de Imagem (550x550px)</span>
                  <mat-form-field>
                    <ngx-mat-file-input [formControl]="imgFileUpdt" [accept]="imgTypeUpdt">
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
                <!-- Upload bula (.PDF) -->
                <div class="upload-ft">
                  <span class="text-description">Upload bula (.PDF)</span>
                  <mat-form-field>
                    <ngx-mat-file-input [formControl]="flyerFileUpdt" [accept]="fileTypeUpdt">
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <button class="btn-next btn-processar d-flex" type="submit">
            <span>Editar produto</span>
            <img src="../../../assets/Icons/icon-done.png" alt="done" />
          </button>
        </form>
      </div>


    </ng-template>
    <app-footer></app-footer>
  </div>
</div>
