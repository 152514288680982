<div class="main-top-menu">
  <div class="d-flex justify-content-start w-100">
    <div class="d-flex flex-row">
      <div class="test">
        <img class="perfil-img" src="../../../assets/avatars/{{avatar}}.svg" height="40">
      </div>
      <div class="d-flex flex-column" style="margin-left:15px !important;">
        <span class="welcome-text">Bem-vindo(a),</span>
        <span>{{currentUser.name}}</span>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row">
    <img class="car-shop-img" [matMenuTriggerFor]="logoutMenu" src="../../../assets/Icons/interface-logout.png"
      alt="interface-logout" style="cursor: pointer;" />
  </div>
</div>

<!-- !LOGOUT DROP MENU -->
<mat-menu #logoutMenu="matMenu" xPosition="before">
    <button mat-menu-item (click)="onChangeClient()">
      <mat-icon svgIcon="profile" iconPositionEnd></mat-icon>
      Alterar Portal
    </button>
    <button mat-menu-item (click)="navigateToLogin()">
      <mat-icon svgIcon="door" iconPositionEnd></mat-icon>
      Logout
    </button>
</mat-menu>