import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  AllCampaigns,
  AllCampaignsRequest,
  Campaign,
} from 'src/app/api/models/campaigns';
import { InterfaceCampaignsService } from 'src/app/api/services/interface-campaigns.service';
import { CampaignModalComponent } from '../create-campaigns/campaign-modal/campaign-modal.component';
import { Subscription } from 'rxjs';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';

@Component({
  selector: 'app-dashboard-campaigns',
  templateUrl: './dashboard-campaigns.component.html',
  styleUrls: ['./dashboard-campaigns.component.css'],
})
export class DashboardCampaignsComponent {
  loading: boolean = false;
  displayedColumns: string[] = [
    'name',
    'startingDate',
    'endingDate',
    'type',
    'actions',
  ];

  request: AllCampaignsRequest = {
    page: 1,
    pageSize: 10,
    sortColumn: '',
    sortOrder: '',
    filterName: '',
    filterValue: '',
  };
  campaignPageSize: number = 10;
  campaingCurrentPage: number = 1;
  campaigns!: AllCampaigns;
  dataSourceCampaigns: MatTableDataSource<Campaign> = new MatTableDataSource();
  @ViewChild('backofficeCampaignPaginator') paginator!: MatPaginator;
  nextPage: boolean = false;
  campaign: Campaign[] = [];
  apiResponse: number = 0;
  unsubscribe: Subscription = new Subscription();

  constructor(
    private interfaceCampaignService: InterfaceCampaignsService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  sortTable(sortColumn: string, sortOrder: string) {
    this.request.page = 1;
    this.request.sortColumn = sortColumn;
    this.request.sortOrder = sortOrder;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.unsubscribe.add(
      this.interfaceCampaignService.getCampaigns(this.request).subscribe({
        next: (response: AllCampaigns) => {
          this.campaigns = response;
          this.nextPage = response.hasNextPage;
          this.apiResponse = response.totalCount;
          this.dataSourceCampaigns.data = this.campaigns.data;
        },
        error: (e) => console.error(e),
        complete: () => (this.loading = false),
      })
    );
  }

  pageListChanged(event: PageEvent) {
    this.request.pageSize = event.pageSize;
    this.request.page = event.pageIndex + 1;
    this.unsubscribe.add(
      this.interfaceCampaignService.getCampaigns(this.request).subscribe({
        next: (response: AllCampaigns) => {
          this.campaigns = response;
          this.nextPage = response.hasNextPage;
          this.apiResponse = response.totalCount;
          this.dataSourceCampaigns.data = this.campaigns.data;
        },
        error: (e) => console.error(e),
        complete: () => (this.loading = false),
      })
    );
  }

  handleRemoveCampaign(campaign: Campaign): void {
    const selectedCampaign = campaign;
    const dialogRef = this.dialog.open(CampaignModalComponent, {
      data: selectedCampaign,
    });
    this.unsubscribe.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.removeCampaign(campaign);
        }
      })
    );
  }

  removeCampaign(campaign: Campaign): void {
    this.unsubscribe.add(
      this.interfaceCampaignService
        .deleteCampaign(campaign.id)
        .subscribe((x) => {
          this.dataSourceCampaigns.data = this.dataSourceCampaigns.data.filter(
            (row) => row.id != campaign.id
          );
          if (this.dataSourceCampaigns.data.length === 0 && this.paginator.hasPreviousPage()) {
            // Navigate to the previous page if the there is no data in the current page
            this.paginator.previousPage();
          }
        })
    );
    this.snackBarService.openSnackBar(
      'Campanha removida com sucesso!',
      '#0AA174',
      '../../../../assets/Icons/correct.png'
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }
}
