import { Observable, BehaviorSubject } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Injectable } from '@angular/core';
import {
  Campaign,
  CampaignGroupMin,
  CampaignMin,
  ProductMin,
} from '../models/campaigns';
import { TbProduto } from '../models/statistics';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class BackofficeCampaignService {
  private groupForm: FormGroup;
  private editGroup: BehaviorSubject<{
    id: number;
    group: CampaignGroupMin;
  }>;
  private productList: TbProduto[];
  private campaign: CampaignMin;
  private editing: BehaviorSubject<boolean>;
  private newGroups: CampaignGroupMin[] = [];
  private original!: CampaignMin;

  constructor(private fb: FormBuilder, private snackBarService: SnackBarService) {
    this.groupForm = this.fb.group({
      groupName: new FormControl('', Validators.required),
      header: new FormControl(''),
      image: new FormControl(''),
      selectProducts: new FormControl('', Validators.required),
    });

    this.productList = [];
    this.campaign = {} as CampaignMin;
    this.editing = new BehaviorSubject(false);
    this.editGroup = new BehaviorSubject({
      id: -1,
      group: {} as CampaignGroupMin,
    });

    this.original = JSON.parse(JSON.stringify(this.campaign)) as CampaignMin;
  }

  getOriginal(): CampaignMin {
    return  this.original
  }

  getNewGroups() : CampaignGroupMin[] {
    return this.newGroups;
  }

  clearNewGroups() {
    for (let i = this.newGroups.length - 1; i >= 0; i--) {
      const element = this.newGroups[i];
      this.deleteGroup(element);
    }
  }

  getGroupForm(): FormGroup {
    return this.groupForm;
  }

  getEditGroup() {
    return this.editGroup;
  }

  setEditGroup(editGroup: CampaignGroupMin) {
    const groupPosition = this.getGroupPosition(editGroup);
    if (groupPosition === -1) {
      return;
    }
    this.editGroup.next({
      id: groupPosition,
      group: editGroup,
    });
  }

  updateCurrentGroup(group: CampaignGroupMin) {
    this.editGroup.next({
      id: this.editGroup.value.id,
      group: group,
    });
  }

  resetEdit(originalGroup?: CampaignGroupMin[]) {
    if (originalGroup) {
      const editingGroupIndex = this.campaign.group?.findIndex(g => g.description === originalGroup[0].description);
      if (editingGroupIndex !== -1 && this.campaign.group) {
        // Replace the editing group with the original group
        this.campaign.group.splice(editingGroupIndex!, 1, originalGroup[0]);

        // Update the campaign in the service
        this.setCampaign(this.campaign);
      }
    }else {
      this.editGroup.next({
        id: -1,
        group: {
          products: [] as ProductMin[],
        } as CampaignGroupMin,
      });
    }

    this.editing.next(false);
  }

  getProductList(): TbProduto[] {
    return this.productList;
  }

  setProductList(productList: TbProduto[]) {
    this.productList = productList;
  }

  getCampaign(): CampaignMin {
    return this.campaign;
  }

  setCampaign(campaign: CampaignMin) {
    this.campaign = campaign;
    this.original = JSON.parse(JSON.stringify(this.campaign)) as CampaignMin;
  }

  getEditing() {
    return this.editing;
  }

  getGroupPosition(group: CampaignGroupMin) {
    for (let i = 0; i < this.campaign.group!.length; i++) {
      if (this.campaign.group![i].description === group.description) {
        return i;
      }
    }
    return -1;
  }

  addGroup(group: CampaignGroupMin) {
    if (
      !this.campaign.group
        ?.map((g) => g.description)
        .includes(group.description)
    ) {
      this.campaign.group!.push(group);
      this.newGroups.push(group);
      this.setCampaign(this.campaign);
    }else {
      this.snackBarService.openSnackBar(
        `Já existe um grupo com nome ${group.description}`,
        '#F9841E',
          '../../assets/Icons/Warning-icon.png'
      )
    }
  }

  checkIfGroupExists(groupName: string, groupId: number): boolean {
    const name = groupName.trim();
    if (name === 'Produtos sem grupo') {
        return false;
    } else {
        // Check if any group with the same name but different id exists
        const groupWithSameName = this.campaign.group?.find(g => g.description === name && g.idCampaignGroup !== groupId);
        return !!groupWithSameName;
    }
  }

  deleteGroup(group: CampaignGroupMin) {
    if (this.campaign.group) {
      const index = this.campaign.group.findIndex(
        (g) => g.description === group.description
      );
      if (index !== -1) {
        this.campaign.group.splice(index, 1);
        if (this.editing.value) {
          this.editing.next(false);
        }
      }
    }

    if(this.newGroups) {
      const index = this.newGroups.findIndex(g => g.description === group.description)
      if(index !== -1) {
        this.newGroups.splice(index, 1);
      }
    }
  }

  deleteProductFromGroup(data: any) {
    if (this.campaign.group) {
      const group = this.campaign.group.find(g => g.description === data.group.description);
      if(group) {
        const productIndex = group.products.findIndex(p => p.reference === data.product.reference);
        if (productIndex !== -1) {
            group.products.splice(productIndex, 1);
        }
      }
    }
    const editG = this.getEditGroup().value;
    if(editG && editG.group.products) {
      const productIndex = editG.group.products.findIndex(p => p.reference == data.product.reference);
      if (productIndex !== -1) {
        editG.group.products.splice(productIndex, 1);
        this.setEditGroup(editG.group);
      }
    }

    if (this.newGroups.length > 0) {
      const group = this.newGroups.find(g => g.description === data.group.description);
      if(group) {
        const productIndex = group.products.findIndex(p => p.reference === data.product.reference);
        if (productIndex !== -1) {
            group.products.splice(productIndex, 1);
        }
      }
    }
  }

  saveEditGroup() {
    if (this.campaign.group) {
      this.campaign.group[this.editGroup.value.id] = this.editGroup.value.group;
      this.editGroup.next({
        id: -1,
        group: {} as CampaignGroupMin,
      });
      this.setCampaign(this.campaign);
      this.editing.next(false);
    }
  }
}
