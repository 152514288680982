<div class="inner-content">
  <div class="inner-div">
    <div class="d-flex flex-row">
      <button id='close' (click)="closeModal()">close</button>
    </div>
  </div>
  <div class="inner-div">
    <span class="span-title">Produtos de oferta</span>
      <div class="inside-content2" *ngIf="bonusData && bonusData.length > 0">
        <div *ngFor="let product of bonusData; let i=index">
          <div class="product-bonus">
            <div *ngIf="!product.imageURL.endsWith('product_placeholder.png')">
              <img [src]="product.imageURL" [alt]="product.referenceBonus">
            </div>
            <div *ngIf="product.imageURL.endsWith('product_placeholder.png')" class="placeholderDiv">
              <img [src]="product.imageURL" [alt]="product.referenceBonus">
              <span>Imagem não disponibilizada</span>
            </div>
            <div class="text-center">
              <span>{{ product.bonuses[0].description }}</span>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-end">
            <div *ngFor="let bonus of product.bonuses" class="plus-bonus">
              <span>{{ bonus.bonus }}</span>
              <div *ngIf="shouldShowAddButton" class="plus-btn" (click)="callCrudCart(passedData, bonus.bonus)">+</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 text-center mobileAdjust1 quantityCampaignSection" *ngIf="data.bonusData.quantityCampaign?.quantity && data.bonusData.quantityCampaign!.quantity > 0 && data.bonusData.quantityCampaign?.discountPercentage && data.bonusData.quantityCampaign!.discountPercentage > 0" >
        Na compra de mais de {{ data.bonusData.quantityCampaign?.quantity ?? '-' }} unidades, o artigo tem um desconto de {{ data.bonusData.quantityCampaign?.discountPercentage ?? '-' }}%.
      </div>
</div>
