<div class="main-page">
  <div class="left-side">
    <img src="../../../assets/images/bio2Logo.png" alt="bio2Logo" />
  </div>
  <div class="right-side">
    <form (submit)="resetPassword()" class="d-flex flex-column">
      <div class="d-flex flex-row scheme">
        <div class="circleLCheck"></div>
        <div class="line"></div>
        <div class="circleR" [ngClass]="{ circleLCheck: firstBtnClick }"></div>
      </div>
      <span class="recoverTitle">Recuperar Palavra-passe</span>
      <span class="user-pass" for="newPassword1"
        >Nova palavra-passe
        <span class="required">*</span>
      </span>
      <div class="password-input-wrapper">
        <input
          class="input-eye"
          type="{{ showPassword1 ? 'text' : 'password' }}"
          id="newPassword1"
          name="newPassword1"
          [(ngModel)]="newPassword1"
          required
        />
        <div
          class="lock-icon"
          [style.background-image]="'url(../../assets/Icons/Eye.png)'"
          (click)="togglePasswordVisibility1()"
        ></div>
      </div>
      <span class="user-pass" for="newPassword2"
        >Confirmar palavra-passe
        <span class="required">*</span>
      </span>
      <div class="password-input-wrapper">
        <input
          type="{{ showPassword2 ? 'text' : 'password' }}"
          class="input-eye"
          id="newPassword2"
          name="newPassword2"
          [(ngModel)]="newPassword2"
          required
        />
        <div
          class="lock-icon"
          [style.background-image]="'url(../../assets/Icons/Eye.png)'"
          (click)="togglePasswordVisibility2()"
        ></div>
      </div>
      <button
        type="submit"
        [attr.disabled]="firstBtnClick || isButtonDisabled() ? true : null"
      >
        <span>Repor palavra-passe</span>
      </button>
    </form>
  </div>
</div>
