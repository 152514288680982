import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconsCode } from 'src/assets/Icons/iconsCode';

@Injectable({
  providedIn: 'root',
})
export class CustomIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.registerIcons();
  }

  private registerIcons(): void {
    this.matIconRegistry.addSvgIconLiteral(
      'pdf_file',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.PDF_FILE)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'search',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.SEARCH)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'arrow_right',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.ARROW_RIGHT)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'mail',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.MAIL)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'coin',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.COIN)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'package',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.PACKAGE)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'truck',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.TRUCK)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'clock',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.CLOCK)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'phone',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.PHONE)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'pin_map',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.PIN_MAP)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'pencil',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.PENCIL)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'heart',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.HEART)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'door',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.DOOR)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'check',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.CHECK)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'info',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.INFO)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'profile',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.PROFILE)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'emotion',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.EMOTION)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'arrow_left_sm',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.ARROW_LEFT_SM)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'discount',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.DISCOUNT)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'cart',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.CART)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'cart-green',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.CARTGREEN)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'ticket',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.TICKET)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'ticket-white',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.TICKETWHITE)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'grid',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.GRID)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'list_check',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.LIST_CHECK)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'filter',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.FILTER)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'newprod',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.NEWPROD)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'newcamp',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.NEWCAMP)
    );
    this.matIconRegistry.addSvgIconLiteral(
      'changeportal',
      this.domSanitizer.bypassSecurityTrustHtml(IconsCode.CHANGEPORTAL)
    );
  }
}
