import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, startWith, Subscription, tap } from 'rxjs';
import {
  Campaign,
  CampaignGroup,
  CampaignGroupMin,
  CampaignMin,
} from 'src/app/api/models/campaigns';
import { TbLaboratorio, TbProduto } from 'src/app/api/models/statistics';
import { BackofficeCampaignService } from 'src/app/api/services/backoffice-campaign.service';
import { CampaignsService } from 'src/app/api/services/campaigns.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { SearchInputProductsService } from 'src/app/Custom/search-input-products/search-input-products.service';

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.css'],
})
export class EditCampaignComponent implements OnInit, OnDestroy {
  editingGroup!: CampaignGroup;
  productList: TbProduto[] = [];
  filteredProductLists!: Observable<TbProduto[]>;
  loading: boolean = true;
  edit: boolean = false;
  loadingEdit: boolean = false;
  campaingForm!: FormGroup;
  groupForm!: FormGroup;
  selectedItens: TbProduto[] = [];
  imgGroupType = '.png,.jpeg,.jpg,.gif';
  imgType = '.png,.jpeg,.jpg,.gif,.webm';
  pdfType = '.pdf';
  unsubscribe: Subscription = new Subscription();
  nextSize: number = 50;
  plist: TbProduto[] = [];
  myControl = new FormControl('');
  isAdding: boolean = false;
  isEddingNewGroups: boolean = false;
  originalCampaign!: CampaignMin;
  campaign!: CampaignMin;
  originalGroups!: CampaignGroupMin[];
  originalGroup!: CampaignGroupMin[];
  group!: CampaignGroupMin[];
  newEditingOriginal!: CampaignGroupMin[];
  selectedItems!: TbProduto[];
  selectedlaboratoryOption?: TbLaboratorio;
  @Output() selectedLaboratoryChange = new EventEmitter<TbLaboratorio>();
  laboratoriesList: TbLaboratorio[] = [];
  filteredLaboratoryOptions!: Observable<TbLaboratorio[]>;
  receivedLab: TbLaboratorio = {
    id: '',
    labName: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    private campaignService: CampaignsService,
    private route: ActivatedRoute,
    private router: Router,
    private campaingGroupService: BackofficeCampaignService,
    private snackBarService: SnackBarService,
    private searchInputProducts: SearchInputProductsService
  ) {}

  ngOnInit(): void {
    this.isAdding = false;
    this.unsubscribe.add(
      this.campaingGroupService.getEditing().subscribe((x) => {
        this.isEddingNewGroups = x;
      })
    );
    const id = this.route.snapshot.paramMap.get('id') ?? 0;
    this.unsubscribe.add(
      this.campaignService.getAllProducts('').subscribe({
        next: (productList) => {
          this.productList = productList;
          this.plist = this.productList;
        },
        complete: () => {
          this.searchInputProducts.setPlist(true);
        },
      })
    );
    //const lab = laboratoriesList.find(laboratory => laboratory.id === id);

    this.unsubscribe.add(
      this.campaignService.getLabs().subscribe((data) => {
        this.laboratoriesList = data;
      })
    );

    this.unsubscribe.add(
      this.campaignService
        .getCampaignById(id as number)
        .subscribe((campaign) => {
          // Clone the campaign and groups to maintain the originals
          this.originalCampaign = JSON.parse(
            JSON.stringify(campaign.campaign[0])
          ) as CampaignMin;
          this.originalGroups = JSON.parse(
            JSON.stringify(this.originalCampaign.group)
          ) as CampaignGroupMin[];

          // Set the current campaign and groups for editing
          this.campaign = JSON.parse(
            JSON.stringify(this.originalCampaign)
          ) as CampaignMin;

          this.campaingForm = this.formBuilder.group({
            campaignType: new FormControl(
              this.campaign.type,
              Validators.required
            ),
            campaignName: new FormControl(
              this.campaign.name,
              Validators.required
            ),
            campaignLink: new FormControl({
              value: this.campaign.link,
              disabled: true,
            }),
            startDate: new FormControl(
              this.campaign.startingDate,
              Validators.required
            ),
            endDate: new FormControl(
              this.campaign.endingDate,
              Validators.required
            ),
            priceLevel: new FormControl(
              this.campaign.priceLevel?.map((level) => '' + level),
              Validators.required
            ),
            laboratoryControl: new FormControl({
              value: this.receivedLab.id,
              disabled: false,
            }),

            cpgHeader: new FormControl(this.campaign.header ?? ''),
            cpgPdf: new FormControl(this.campaign.image ?? ''),
            cpgImage: new FormControl(this.campaign.pdf ?? ''),
          });

          this.unsubscribe.add(
            this.campaingGroupService.getEditing().subscribe((editing) => {
              this.edit = editing;
              if (editing) {
                this.unsubscribe.add(
                  this.campaingGroupService
                    .getEditGroup()
                    .subscribe((group) => {
                      if (group.id === -1) {
                        return;
                      }
                      const originalGroup = this.originalGroups.find(
                        (g) => g.idCampaignGroup === group.group.idCampaignGroup
                      );
                      if (originalGroup) this.originalGroup = [originalGroup];
                      this.group = [group.group];

                      const prods = group.group.products.map((value) => {
                        const prod = {
                          referencia: value.reference,
                          memoria: value.memory,
                          description: value.memory,
                        };
                        return prod;
                      });

                      const isDisable =
                        this.group[0].description == 'Produtos sem grupo';
                      this.groupForm = this.formBuilder.group({
                        groupName: new FormControl(
                          {
                            value: group.group.description,
                            disabled: isDisable,
                          },
                          Validators.required
                        ),
                        imgHeader: new FormControl(group.group.header ?? ''),
                        imgGroup: new FormControl(group.group.image ?? ''),
                        selectProducts: new FormControl(
                          prods,
                          Validators.required
                        ),
                      });
                      this.selectedItens = this.selectProducts.value;
                    })
                );

                this.loadingEdit = true;
              } else {
                this.group = JSON.parse(JSON.stringify(this.originalGroups));
              }
            })
          );

          this.campaignService.getLabs().subscribe((data) => {
            this.laboratoriesList = data;
            this.receivedLab = this.getLabNameById(
              this.campaign.idlaboratorio!
            );
            this.campaingForm.patchValue({
              laboratoryControl: this.receivedLab.labName,
            });
          });

          this.campaingGroupService.setCampaign(this.campaign);

          this.filteredLaboratoryOptions =
            this.laboratoryControl.valueChanges.pipe(
              startWith(''),
              map((value) => {
                const name = typeof value === 'string' ? value : value?.labName;
                return name
                  ? this.filterLaboratory(name)
                  : this.laboratoriesList.slice();
              })
            );

          this.loading = false;
        })
    );
  }

  getLabNameById(id: string): TbLaboratorio {
    const lab = this.laboratoriesList.find(
      (laboratory) => laboratory.id === id
    );
    return lab ? lab : { id: '', labName: '' };
  }

  get cpgPdf() {
    return this.campaingForm.controls['cpgPdf'] as FormControl;
  }
  get cpgHeader() {
    return this.campaingForm.controls['cpgHeader'] as FormControl;
  }
  get cpgImage() {
    return this.campaingForm.controls['cpgImage'] as FormControl;
  }

  get laboratoryControl() {
    return this.campaingForm.controls['laboratoryControl'] as FormControl;
  }

  get groupDescription() {
    return this.groupForm.controls['description'] as FormControl;
  }

  get groupHeader() {
    return this.groupForm.controls['imgHeader'] as FormControl;
  }
  get groupImg() {
    return this.groupForm.controls['imgGroup'] as FormControl;
  }
  get selectProducts() {
    return this.groupForm.controls['selectProducts'] as FormControl;
  }

  displayLaboratory(laboratory: TbLaboratorio): string {
    return laboratory && laboratory.labName ? laboratory.labName : '';
  }

  selectedLaboratory(event: MatAutocompleteSelectedEvent): void {
    this.selectedlaboratoryOption = event.option.value;
    this.selectedLaboratoryChange.emit(this.selectedlaboratoryOption);
  }

  private filterLaboratory(value: string): TbLaboratorio[] {
    const filterValue = value.toLowerCase();
    return this.laboratoriesList.filter((option) =>
      option.labName.toLowerCase().includes(filterValue)
    );
  }

  clearNewGroups(adding: boolean) {
    this.isAdding = adding;
    this.loadingEdit = false;
    this.campaingGroupService.clearNewGroups();
  }

  getNewGroups() {
    return this.campaingGroupService.getNewGroups();
  }

  handleEditState() {
    if (!this.groupForm.valid) {
      this.snackBarService.openSnackBar(
        `Campos obrigatórios por preencher`,
        '#F9841E',
        '../../assets/Icons/Warning-icon.png'
      );
      return;
    }

    this.unsubscribe.add(
      this.campaingGroupService.getEditGroup().subscribe((oldGroup) => {
        const groupName =
          this.group[0].description == 'Produtos sem grupo'
            ? 'Produtos sem grupo'
            : this.groupForm.value.groupName;

        if (
          this.campaingGroupService.checkIfGroupExists(
            groupName,
            this.group[0].idCampaignGroup
          )
        ) {
          this.snackBarService.openSnackBar(
            `Já existe grupo com nome ${groupName}`,
            '#F9841E',
            '../../assets/Icons/Warning-icon.png'
          );
          return;
        }

        const prodList = this.selectProducts.value.map((p: TbProduto) => ({
          memory: p.memoria,
          reference: p.referencia,
        }));

        const newGroup = {
          idCampaignGroup: this.group[0].idCampaignGroup,
          description: groupName,
          products: prodList,
          header: this.groupForm.value.imgHeader,
          image: this.groupForm.value.imgGroup,
        } as CampaignGroupMin;

        this.loadingEdit = false;
        const editedGroup = this.campaign.group![oldGroup.id];
        editedGroup.description = newGroup.description;
        editedGroup.products = newGroup.products;
        editedGroup.header = newGroup.header;
        editedGroup.image = newGroup.image;
        this.saveToOriginal(newGroup);
        this.campaingGroupService.getEditing().next(false);
      })
    );
  }

  saveToOriginal(newGroup: CampaignGroupMin) {
    const groupId = newGroup.description;
    const originalGroupIndex = this.originalGroups.findIndex(
      (g) => g.description === groupId
    );

    if (originalGroupIndex !== -1) {
      // Replace the original group with the new group
      this.originalGroups.splice(originalGroupIndex, 1, newGroup);
    }
    this.campaingGroupService.setCampaign(this.campaign);
  }

  handleCancelButton() {
    this.isEddingNewGroups = false;

    if (!this.newEditingOriginal) {
      //get original editing group;
      const groupId = this.group[0].idCampaignGroup;
      const originalGroup = this.originalGroups.find(
        (g) => g.idCampaignGroup === groupId
      );

      //find group in campaing
      if (originalGroup) {
        // Find the editing group in the campaign
        const editingGroupIndex = this.campaign.group?.findIndex(
          (g) => g.idCampaignGroup === groupId
        );
        if (editingGroupIndex !== -1 && this.campaign.group) {
          // Replace the editing group with the original group
          this.campaign.group.splice(editingGroupIndex!, 1, originalGroup);

          // Update the campaign in the service
          this.campaingGroupService.setCampaign(this.campaign);
        }
      }
    } else {
      const editingGroupIndex = this.campaign.group?.findIndex(
        (g) => g.description === this.newEditingOriginal[0].description
      );
      if (editingGroupIndex !== -1 && this.campaign.group) {
        // Replace the editing group with the original group
        this.campaign.group.splice(
          editingGroupIndex!,
          1,
          this.newEditingOriginal[0]
        );

        // Update the campaign in the service
        this.campaingGroupService.setCampaign(this.campaign);
      }
    }
    this.loadingEdit = false;
    this.newEditingOriginal = [];
    this.campaingGroupService.getEditing().next(false);
  }

  updateGroup() {
    const changes = this.groupForm.value;
    let newProducts = this.selectedItens.map((p) => ({
      reference: p.referencia,
      memory: p.memoria,
    }));

    const groupName =
      this.group[0].description == 'Produtos sem grupo'
        ? 'Produtos sem grupo'
        : changes.groupName;
    this.campaingGroupService.updateCurrentGroup({
      idCampaignGroup: this.group[0].idCampaignGroup,
      products: newProducts,
      image: changes.imgGroup,
      description: groupName,
      header: changes.imgHeader,
    } as CampaignGroupMin);

    //get editing group
    const editingGroup = this.campaingGroupService.getEditGroup().value;

    if (editingGroup) {
      const groupIndex = this.campaign.group?.findIndex(
        (g) => g.idCampaignGroup == editingGroup.group.idCampaignGroup
      );
      if (groupIndex !== -1 && this.campaign.group) {
        this.campaign.group.splice(groupIndex!, 1, editingGroup.group);

        // Update the campaign in the service
        this.campaingGroupService.setCampaign(this.campaign);
      }
    }

    this.campaingGroupService.getEditing().next(true);
  }

  addNewGroupBtn() {
    this.isAdding = true;
    this.campaingGroupService.clearNewGroups();
    this.campaingGroupService.setCampaign(this.campaign);
  }

  addNewGroup() {
    this.loading = true;

    const newGroups = this.getNewGroups();
    if (newGroups && newGroups.length > 0) {
      for (let index = 0; index < newGroups.length; index++) {
        const group = newGroups[index];
        if (group.products.length <= 0) {
          this.snackBarService.openSnackBar(
            `Grupo "${group.description}" não contém produtos`,
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
          this.loading = false;
          return;
        }
      }
    }

    this.unsubscribe.add(
      this.campaignService
        .addNewGroupsToCampaign(
          this.campaign,
          this.campaingGroupService.getNewGroups()
        )
        .subscribe({
          next: () => {},
          error: (error) => {
            this.snackBarService.openSnackBar(
              `Erro ao atualizar campanha`,
              '#F97066',
              '../../../assets/Icons/ErrorIcon.png'
            );
            this.loading = false;
          },
          complete: () => {
            const campaignMap = this.campaign as Campaign;
            this.campaignService
              .getCampaignById(campaignMap.id)
              .subscribe((res) => {
                this.originalCampaign = JSON.parse(
                  JSON.stringify(res.campaign[0])
                ) as CampaignMin;
                this.campaingGroupService.setCampaign(
                  res.campaign[0] as CampaignMin
                );
                this.campaign = this.campaingGroupService.getCampaign();

                this.isAdding = false;
                this.loading = false;
                this.unsubscribe.add(
                  this.snackBarService.openSnackBar(
                    'Grupo adicionado com sucesso',
                    '#0AA174',
                    '../../assets/Icons/correct.png'
                  )
                );
              });
          },
        })
    );
  }

  convertDate(dateString: string): string {
    const originalDate: Date = new Date(dateString);
    const formattedDate = new Date(originalDate);
    formattedDate.setHours(formattedDate.getHours() + 1);
    return formattedDate.toISOString();
  }

  submitCampaign() {
    this.loading = true;
    let campaign = this.campaingGroupService.getCampaign();

    const sDate = this.convertDate(this.campaingForm.value.startDate);
    const eDate = this.convertDate(this.campaingForm.value.endDate);

    campaign.name= this.campaingForm.controls['campaignName'].value;
    campaign.link= this.campaingForm.controls['campaignLink'].value;
    campaign.header= this.campaingForm.controls['cpgHeader'].value;
    campaign.image= this.campaingForm.controls['cpgImage'].value;
    campaign.pdf= this.campaingForm.controls['cpgPdf'].value;
    campaign.startingDate= sDate;
    campaign.endingDate= eDate;
    campaign.type= this.campaingForm.controls['campaignType'].value;
    campaign.priceLevel= this.campaingForm.controls['priceLevel'].value;

    if (this.selectedlaboratoryOption?.id) {
      campaign.idlaboratorio = this.selectedlaboratoryOption?.id;
    }
    else (
      campaign.idlaboratorio = this.receivedLab.id
    )

    this.campaingGroupService.setCampaign(campaign);

    if (campaign.group && campaign.group.length > 0) {
      for (let index = 0; index < campaign.group!.length; index++) {
        const group = campaign.group[index];
        if (group.products.length <= 0) {
          this.snackBarService.openSnackBar(
            `Grupo "${group.description}" não contém produtos`,
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
          this.loading = false;
          return;
        }
      }
    }

    this.unsubscribe.add(
      this.campaignService.updateCampaign(campaign).subscribe({
        next: () => {
          this.loading = false;
          this.router.navigate(['../backoffice/campanhas']);
        },
        error: (error) => {
          this.loading = false;
          this.snackBarService.openSnackBar(
            'Ocorreu um erro ao alterar Campanha',
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
        },
        complete: () => {
          this.unsubscribe.add(
            this.snackBarService.openSnackBar(
              'Campanha alterada com sucesso',
              '#0AA174',
              '../../assets/Icons/correct.png'
            )
          );
        },
      })
    );
  }

  newEditingGroupOriginal(gOriginal: CampaignGroupMin[]) {
    this.newEditingOriginal = gOriginal;
  }

  getSelectedItems(event: TbProduto[]) {
    this.selectedItems = event;
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }

  navigateBack() {
    if (this.isAdding && !this.isEddingNewGroups) {
      this.isAdding = false;
      this.loadingEdit = false;
    } else if (this.isAdding && this.isEddingNewGroups) {
      this.isEddingNewGroups = false;
    } else {
      window.history.back();
    }
  }
}