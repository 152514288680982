import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  // Generate a 256-bit (32-byte) random key
  private secretKey = environment.encryptKey;
  private IV = environment.IV

  encrypt(data: string): string | null {
    if(data) {
      const key = CryptoJS.enc.Utf8.parse(this.secretKey);
      const iv = CryptoJS.enc.Utf8.parse(this.IV);
      const cipherText = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString();
      return cipherText;
    }
    return null;
  }

  decrypt(encryptedData: string): string | null {
    if(encryptedData) {
      const key = CryptoJS.enc.Utf8.parse(this.secretKey);
      const iv = CryptoJS.enc.Utf8.parse(this.IV);
      const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
        keySize: 128 / 8,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: iv
      });
      if(bytes.sigBytes > 0) {
        return bytes.toString(CryptoJS.enc.Utf8);
      }
    }
    return null;
  }

  setEncryptedItem(key: string, data: string): void {
    const encryptedData = this.encrypt(data.toString());
    if (encryptedData) {
      localStorage.setItem(key, encryptedData);
    }
  }

  getDecryptedItem(key: string): string | null {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
      const decryptedData = this.decrypt(encryptedData);
      return decryptedData;
    }
    return null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
