import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { SpDashboard } from '../models/sp-dashboard';
import { ExtensionCountResponse } from '../models/storage';

@Injectable({ providedIn: 'root' })
export class DashboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  route = new ApiConfiguration()

  static readonly ApiInterfaceDashboardGetPath = '/api/interface/dashboard';

  apiInterfaceDashboard(
    params?: { body?: SpDashboard },
    context?: HttpContext
  ): Observable<SpDashboard> {
    const accessToken = localStorage.getItem('BIO2_AccessToken');
    if (params) {
      const { body } = params;
    }
    const requestoptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
      }),
    };
    return this.http.get(
      this.rootUrl + DashboardService.ApiInterfaceDashboardGetPath,
      requestoptions
    );
  }

  getExtensionCount(): Observable<ExtensionCountResponse> {
    const apiExtensionCount = this.route.rootUrl + '/api/storage/extension-count';
    return this.http.get<ExtensionCountResponse>(apiExtensionCount);
  }
}
