import { Router } from '@angular/router';
import { Campaign, CampaignType } from 'src/app/api/models/campaigns';
import { Component, ViewChild, OnDestroy, OnInit, HostListener } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subscription, filter } from 'rxjs';
import { CartProductModel } from 'src/app/api/models/checkout';
import {
  SaveFilters,
  ShopItemModel,
  ShopItemRequest,
  searchModel,
} from 'src/app/api/models/shop-item.model';
import { AuthService } from 'src/app/api/services';
import { CheckoutService } from 'src/app/api/services/checkout.service';
import { CustomIconsService } from 'src/app/api/services/custom-icons.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { ShopService } from 'src/app/api/services/shop.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { CampaignsService } from 'src/app/api/services/campaigns.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogEncomendasComponent } from './dialog-encomendas/dialog-encomendas.component';
import { StorageKeys } from 'src/app/api/models/storageKeys';
import { EncryptionService } from 'src/app/api/services/encryption.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css'],
})
export class ShopComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  loadMore: boolean = false;
  pageChanged: boolean = false;
  loggedUser!: ProfileDataRequest;
  shopListPageSize: number = 10;
  shopListCurrentPage: number = 1;
  nextPage: boolean = false;
  filters!: searchModel;
  search: string = '';
  isMenuOpen = false;
  viewOption = 1;
  request: ShopItemRequest = {} as ShopItemRequest;
  publicity: Campaign[] = [];
  @ViewChild('shopListPaginator') paginator!: MatPaginator;
  shopItems: ShopItemModel[] = [];
  shopItemsList: ShopItemModel[] = [];
  apiResponse: number = 0;
  isModalOpen: boolean = false;
  orderByOption: string = 'none';
  unsubscribe: Subscription = new Subscription();
  existingRedDivPositions: number[] = [];
  fullPub: number[] = [];
  allDHCampaigns!: Campaign[];
  firstDHCampaign!: Campaign;
  secondDHCampaign!: Campaign;
  maxNumber!: number;
  increase: number = 0;
  dialogRef: MatDialogRef<DialogEncomendasComponent> | undefined;
  savedSetPositions: number[] = [];
  loadingMoreResults: boolean = false;


  constructor(
    public dialog: MatDialog,
    private router: Router,
    private shopService: ShopService,
    private perfilService: PerfilService,
    private AuthService: AuthService,
    private perfil: PerfilService,
    private campaignsService: CampaignsService,
    private serviceShop: ShopService,
    private encrypt: EncryptionService
  ) {
    const biot = localStorage.getItem('BIO2_AccessToken');
    this.AuthService.decodeToken(biot!);
    this.shopService.setSearching(false);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogEncomendasComponent, {
      //width: '400px', // You can adjust the width as needed
      // Other dialog configuration options go here
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  closeDialog(): void {
    if (this.dialogRef && this.dialogRef.close) {
      this.dialogRef.close();
    }
  }

  // Function to generate seeded random number
  seededRandom(seed: number): () => number {
    let state = seed;
    return () => {
      state = (state * 9301 + 49297) % 233280;
      return state / 233280;
    };
  }

  calculateAdsPositions(totalCount: number) {
    if (totalCount == 0) {
      this.increase = 0;
      this.isLoading = false;
      return;
    }
    if (totalCount == 1) {
      this.fullPub = [];
      this.increase = 0;
      let rand1 = 0;
      this.fullPub.push(rand1);
      return;
    }
    let rand1 = Math.ceil(Math.random() * totalCount);
    let rand2 = Math.floor(Math.random() * totalCount);
    if (this.existingRedDivPositions.length === 0) {
      if (totalCount > 1) {
        while (rand2 === rand1) {
          rand2 = Math.floor(Math.random() * totalCount);
        }
        while (
          (rand1 % 2 === 0 && rand2 % 2 === 0) ||
          (rand1 % 2 !== 0 && rand2 % 2 !== 0) ||
          rand1 == totalCount ||
          rand2 == totalCount
        ) {
          rand1 = Math.floor(Math.random() * totalCount);
          rand2 = Math.floor(Math.random() * totalCount);
        }
        this.existingRedDivPositions = [rand1, rand2];
        this.savedSetPositions.push(...this.existingRedDivPositions);
        this.fullPub.push(...this.existingRedDivPositions);
        this.fullPub.sort((a, b) => a - b);
      } else {
        this.existingRedDivPositions = [rand1, rand2];
        this.savedSetPositions.push(...this.existingRedDivPositions);
        this.fullPub.push(...this.existingRedDivPositions);
        this.fullPub.sort((a, b) => a - b);
      }
    } else {
      this.increase += 10;
      if (totalCount < 10) {
        this.fullPub = [];
        this.increase = 0;
      }
      rand1 = rand1 + this.increase;
      rand2 = rand2 + this.increase;
      while (
        (rand1 % 2 === 0 && rand2 % 2 === 0) ||
        (rand1 % 2 !== 0 && rand2 % 2 !== 0) ||
        rand1 == totalCount + this.increase ||
        rand2 == totalCount + this.increase
      ) {
        rand1 = Math.floor(Math.random() * totalCount);
        rand2 = Math.floor(Math.random() * totalCount);
        rand2 += this.increase;
        rand1 += this.increase;
      }
      this.fullPub.sort((a, b) => a - b);
      this.fullPub.push(rand1, rand2);
    }
  }


private lastScrollPosition = 0;

@HostListener('window:scroll', ['$event'])
onScroll(event: any) {

  const  currentScrollPosition = window.scrollY + window.innerHeight;
  const contentDiv = document.querySelector('.content') as HTMLElement;

  if (contentDiv) 
  {
    const contentBottomPosition = contentDiv.offsetTop + contentDiv.offsetHeight;
    if (currentScrollPosition >= contentBottomPosition && currentScrollPosition > this.lastScrollPosition) 
    {
      if ((window.innerHeight + currentScrollPosition) >= document.body.offsetHeight) 
      {
        if (this.loadMore || this.pageChanged || this.loadingMoreResults) 
        {
          this.lastScrollPosition = currentScrollPosition;
          return;
        }
  
        if (this.nextPage) 
        {
          this.loadingMoreResults = true;
          this.loadMore = true;
          setTimeout(() => {
            this.LoadMore();
            this.loadingMoreResults = false;
          }, 500);
        }
      }
    }
  }
  this.lastScrollPosition = currentScrollPosition;
}

goToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
}


async checkNeedForMoreResults() {
  await this.LoadMore();
  
  while(this.nextPage && !this.hasScrollbar()) 
  {
    if(!this.loadingMoreResults)
      await this.LoadMore();

    if(this.hasScrollbar())
      break;

    //timeout to avoid infinite loop
    await new Promise(resolve => setTimeout(resolve, 1000)); 
  }
}

  hasScrollbar(): boolean {
    return window.innerHeight < document.body.scrollHeight;
  }

  async LoadMore(): Promise<void> {
    this.loadMore = true;
    this.loadingMoreResults = true;
    this.request.page += 1;
    this.unsubscribe.add(
      this.shopService.getShopItems(this.request).subscribe((response) => {
        const incomingProducts = response.data;
        this.shopItems = this.shopItems.concat(incomingProducts);
        this.nextPage = response.hasNextPage;
        this.apiResponse = response.totalCount;
        
        if (response.pageSize > response.totalCount) {
          this.maxNumber = response.totalCount;
        } else if (response.totalCount >= response.pageSize) {
          this.maxNumber = response.pageSize;
        }
        this.calculateAdsPositions(this.maxNumber);
        this.loadMore = false;;
        this.loadingMoreResults = false;
      })
    );
    // Save the positions for the current set
    this.savedSetPositions.push(...this.existingRedDivPositions);
  }

  ngOnInit(): void {
    sessionStorage.removeItem("filters")
    this.loggedUser = this.perfilService.getCurrentUserInfo();
    let firstTimeModalShop = sessionStorage.getItem('firstTimeDialogShop');
    if (!firstTimeModalShop && this.AuthService.hasPermissions(['3'])) {
      this.openDialog();
    }
    sessionStorage.setItem('firstTimeDialogShop', '0');
    const searched = sessionStorage.getItem('searched');
    if (searched) {
      this.shopService.setSearching(true);
      this.load(searched);
    } else {
      this.shopService.setSearching(false);
      this.load('');
    }

    let numCliente
    let user = this.loggedUser.usernameClient ?? this.loggedUser.username;
    if(this.loggedUser.role == "Administrador" || this.loggedUser.role == "Laboratorio")
        numCliente = this.encrypt.getDecryptedItem(StorageKeys.BIO2_CLIENT_ID) ?? "";
      else
        numCliente = "";

    this.campaignsService.getAllCampaigns(user, numCliente,  CampaignType.DH,environment.prefix).subscribe({
      next: (res) => {
        this.allDHCampaigns = res.campaignShop;

        if (
          this.allDHCampaigns !== undefined &&
          this.allDHCampaigns.length > 0
        ) {
          this.firstDHCampaign = this.allDHCampaigns[0];
          this.secondDHCampaign = this.allDHCampaigns[1];
        }
      },
    });
  }

  getSearchingValue() {
    return this.shopService.getSearching();
  }

  load(data: string): void {
    this.isLoading = true;
    const stored = JSON.parse(sessionStorage.getItem("Productfilters") ?? '{}') as SaveFilters;
    this.request = {
      page: 1,
      pageSize: 10,
      sortColumn: this.request.sortColumn,
      sortOrder: this.request.sortOrder,
      filterName: '',
      filterValue: '',
      clientNumber: this.loggedUser.clientId,
      username: this.loggedUser.username,
      userId: this.loggedUser.userId,
      Search: {
        Search: data,
        Family: stored.Family?.codClasse,
        Type: stored.Type?.codClasse,
        Brand: stored.Brand?.codClasse,
        Structure: stored.Structure?.codClasse,
        Specie: stored.Specie?.idEspecie.toString(),
      },
    };
    this.unsubscribe.add(
      this.shopService.getShopItems(this.request).subscribe({
        next: (response) => {
          const incomingProducts = response.data;
          this.shopItemsList = incomingProducts;
          this.shopItems = incomingProducts;
          this.nextPage = response.hasNextPage;
          this.apiResponse = response.totalCount;

          if (response.pageSize > response.totalCount) {
            this.maxNumber = response.totalCount;
            this.calculateAdsPositions(this.maxNumber);
          } else this.maxNumber = response.pageSize;
          this.calculateAdsPositions(this.maxNumber);
          this.checkNeedForMoreResults();
        },
        error: (e) => console.error(e),
        complete: () => (this.isLoading = false),
      })
    );
  }

  applyFilters(data: any) {
    this.request = {
      page: 1,
      pageSize: 10,
      sortColumn: this.request.sortColumn,
      sortOrder: this.request.sortOrder,
      filterName: '',
      filterValue: '',
      clientNumber: this.loggedUser.clientId,
      username: this.loggedUser.username,
      userId: this.loggedUser.userId,
      Search: {
        Search: data.filters.Search,
        Family: data.filters.Family,
        Type: data.filters.Type,
        Brand: data.filters.Brand,
        Structure: data.filters.Structure,
        Specie: data.filters.Specie,
      },
    };

    this.unsubscribe.add(
      this.shopService.getShopItems(this.request).subscribe((response) => {
        const incomingProducts = response.data;
        this.shopItems = incomingProducts;
        this.nextPage = response.hasNextPage;
        this.apiResponse = response.totalCount;
        this.checkNeedForMoreResults();
      })
    );
  }

  backgroundImgPublicity1(): string {
    if (this.firstDHCampaign !== undefined) {
      return `${environment.campaigns_images}${this.firstDHCampaign.image}`;
    }
    return '../../../assets/images/bio2Logo.png';
  }

  backgroundImgPublicity2(): string {
    if (this.secondDHCampaign !== undefined) {
      return `${environment.campaigns_images}${this.secondDHCampaign.image}`;
    }
    return '../../../assets/images/bio2Logo.png';
  }

  pageShopListChanged(event: PageEvent) {
    this.pageChanged = true;
    if (
      (event.pageIndex + 1) * event.pageSize > this.shopItems.length &&
      this.shopItems.length !== this.apiResponse
    ) {
      const requestList = this.request;
      requestList.pageSize = event.pageSize;
      requestList.page = event.pageIndex + 1;
      this.unsubscribe.add(
        this.shopService.getShopItems(requestList).subscribe((response) => {
          const incomingProducts = response.data;
          this.shopItemsList = incomingProducts;
          this.apiResponse = response.totalCount;
          this.pageChanged = false;
        })
      );
    } else {
      this.shopItemsList = this.shopItems.slice(
        event.pageIndex * event.pageSize,
        (event.pageIndex + 1) * event.pageSize
      );
      this.pageChanged = false;
    }
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.toggleShopFilterVisibility(this.isMenuOpen);
  }

  closeMenu(): void {
    this.isMenuOpen = false;
    this.toggleShopFilterVisibility(false);
  }

  toggleShopFilterVisibility(visible: boolean): void {
    const shopFilterElement = document.querySelector('.app-shop-filter');
    if (shopFilterElement) {
      if (visible) {
        shopFilterElement.classList.remove('hidden');
      } else {
        shopFilterElement.classList.add('hidden');
      }
    }
  }

  isProdModalOpen(value: boolean) {
    this.isModalOpen = value;
  }

  sortProducts(): void {
    let sortColumn: string;
    let sortOrder: string;
  
    const [sort, order] = this.orderByOption.split('-');
    sortColumn = sort === 'none' ? '' : sort === 'stock' ? 'Stock' : 'Valor';
    sortOrder = sort === null ? '' : order === 'menos' ? 'DESC' : 'ASC';
  
    this.request.sortColumn = sortColumn;
    this.request.sortOrder = sortOrder;
  
    this.load(this.request.Search?.Search ?? '');
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
    this.closeDialog();
  }

  goToCampaign(campaignId: Number) {
    this.router.navigate(['/campaigns', campaignId])
}}
