import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {TbProduto} from "../models/statistics";
import {ApiConfiguration} from "../api-configuration";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(private http: HttpClient) {}

  route = new ApiConfiguration();
  ApiProducts = this.route.rootUrl + '/api/interface/products';

  getProducts(): Observable<TbProduto[]> {
    return this.http.get<TbProduto[]>(`${this.ApiProducts}/suggested-products`);
  }

  postProductsSugestion(products: TbProduto[]): Observable<TbProduto[]> {
    return this.http.put<TbProduto[]>(`${this.ApiProducts}/update-suggested-products`, products);
  }
}
