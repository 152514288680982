import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { CustomIconsService } from 'src/app/api/services/custom-icons.service';

@Component({
  selector: 'app-dashboard-top-menu',
  templateUrl: './dashboard-top-menu.component.html',
  styleUrls: ['./dashboard-top-menu.component.css']
})
export class DashboardTopMenuComponent {
  constructor(
    private Router: Router,
    private perfilService: PerfilService,
    private iconsService: CustomIconsService) {}

  currentUser: ProfileDataRequest = this.perfilService.getCurrentUserInfo();
  sessStorageAvatar = sessionStorage.getItem('avatar');
  avatar =
    this.currentUser.avatar != 'NULL'
      ? this.currentUser.avatar
      : this.sessStorageAvatar;

  navigateToLogin() {
    localStorage.clear();
    this.Router.navigate(['/login']);
  }

  onChangeClient(): void {
    this.Router.navigate(['/login/selection']);
  }
}
