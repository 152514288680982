import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShopItemModel } from 'src/app/api/models/shop-item.model';
import { ProductModalComponent } from '../product-modal/product-modal.component';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/api/services/cart.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { MatTableDataSource } from '@angular/material/table';
import { DialogProductCampaignComponent } from 'src/app/Custom/product-shop/dialog-product-campaign/dialog-product-campaign.component';

@Component({
  selector: 'app-product-shop-list',
  templateUrl: './product-shop-list.component.html',
  styleUrls: ['./product-shop-list.component.css'],
})
export class ProductShopListComponent implements OnChanges, OnDestroy {
  @Input() shopItems!: Array<ShopItemModel>;
  @Output() isModalOpen = new EventEmitter<boolean>();
  @Input() isPageChange: boolean = false;
  @Input() sortOption: string = 'none';
  @Input() product!: ShopItemModel;
  showCampaignProductDiv: boolean = false;
  productData!: ShopItemModel;
  unsubscribe: Subscription = new Subscription();
  currentOpenProductId: string | null = null;
  shopItemWrappers: MatTableDataSource<ShopItemModel> =
    new MatTableDataSource();
  displayedColumnsExtract = [
    'codigo',
    'produto',
    'desc',
    'info',
    'qtd',
    'stock',
    'preco',
    'iva',
  ];

  constructor(
    public dialog: MatDialog,
    private cartService: CartService,
    private snackBarService: SnackBarService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shopItems'] && changes['shopItems'].currentValue) {
      this.shopItemWrappers.data = this.shopItems;
    }
    if (changes['sortOption'] && changes['sortOption'].currentValue) {
      this.shopItemWrappers.data = this.shopItemWrappers.data.sort((a, b) => {
        if (this.sortOption === 'preco-mais') {
          return a.currentPrice - b.currentPrice;
        } else if (this.sortOption === 'preco-menos') {
          return b.currentPrice - a.currentPrice;
        } else if (this.sortOption === 'stock-menos') {
          return a.stock - b.stock;
        } else if (this.sortOption === 'stock-mais') {
          return b.stock - a.stock;
        } else {
          return 0;
        }
      });
    }
  }

  showCampaignProduct(productId: string): void {
    if (this.currentOpenProductId === productId) {
      this.currentOpenProductId = null;
    } else {
      this.currentOpenProductId = productId;
    }
  }

  isProductOpen(productId: string): boolean {
    return this.currentOpenProductId === productId;
  }


  animationState = 'start';
  private productQueue: ShopItemModel[] = [];
  private animationTimeout: any;
  isProcessing = false;

  addQuantityClick(shopItemWrapper: ShopItemModel) {
    this.productQueue.push(shopItemWrapper);

    if (!this.isProcessing) {
      this.startProcessing();
    }

    clearTimeout(this.animationTimeout);
    this.animationTimeout = setTimeout(() => {
      this.finalizeUpdate(true);
    }, 500);
  }

  private finalizeUpdate(isAdding: boolean): void {
    this.isProcessing = true;
    const lastProduct = this.productQueue[this.productQueue.length - 1];
    if (lastProduct) {
      if (isAdding) {
        this.unsubscribe.add(
          this.cartService.increaseCartMethod(lastProduct, false, true).subscribe({
            next: (resp) => {
              if (resp) {
                this.snackBarService.openSnackBar(
                  'Produto adicionado ao carrinho!',
                  '#0AA174',
                  '../../assets/Icons/correct.png'
                );
              }
              this.isProcessing = false;
            }
          })
        );
      }
      else {
        this.unsubscribe.add(
          this.cartService
            .decreaseCartMethod(lastProduct, false, true)
            .subscribe((operation) => {
              setTimeout(() => {
                if (operation) {
                  this.snackBarService.openSnackBar(
                    'Produto removido do carrinho!',
                    '#0AA174',
                    '../../assets/Icons/correct.png'
                  );
                }
                this.isProcessing = false;
              }, 250);
            })
        );
      }
    }
  }

  startProcessing(): void {
    if (this.productQueue.length === 0) {
      this.isProcessing = false;
      return;
    }
    const shopItemWrapper = this.productQueue[this.productQueue.length - 1];
    shopItemWrapper.productQuantity = Number(shopItemWrapper.productQuantity) || 0;
    shopItemWrapper.productQuantity += 1;

    this.unsubscribe.add(
      this.cartService
        .increaseCartMethod(shopItemWrapper, false, false)
        .subscribe()
    );
  }

  removeQuantityClick(shopItem: ShopItemModel): void {
    this.productQueue.push(shopItem);

    if (!this.isProcessing) {
      this.startProcessingRemove();
    }

    clearTimeout(this.animationTimeout);
    this.animationTimeout = setTimeout(() => {
      this.finalizeUpdate(false);
    }, 500);
  }

  startProcessingRemove(): void {
    const shopItemWrapper = this.productQueue[this.productQueue.length - 1];
    if (shopItemWrapper.productQuantity > 0) {
      shopItemWrapper.productQuantity -= 1;
      this.unsubscribe.add(
        this.cartService
          .decreaseCartMethod(shopItemWrapper, false, false)
          .subscribe((operation) => {
            if (operation) {
              this.snackBarService.openSnackBar(
                'Quantidade reduzida do carrinho!',
                '#0AA174',
                '../../assets/Icons/correct.png'
              );
            }
          })
      );
    }
  }

  openProductDetails(product: ShopItemModel): void {
    this.isModalOpen.emit(true);
    this.dialog.open(ProductModalComponent, {
      data: product,
    });
    this.unsubscribe.add(
      this.dialog.afterAllClosed.subscribe((resp) =>
        this.isModalOpen.emit(false)
      )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }
}
