import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { Address, User, UsersRequest, UsersResponse } from '../models/getUsers';
import { ApiConfiguration } from '../api-configuration';

@Injectable({
  providedIn: 'root',
})
export class SubLoginService {
  route = new ApiConfiguration();
  ApiGetSubLoginPath = this.route.rootUrl + '/api/sublogin';
  constructor(private http: HttpClient) {}

  getUser(userId: string, loginId: string): Observable<User> {
    return this.http.get<User>(`${this.ApiGetSubLoginPath}/${userId}/${loginId}`);
  }

  getUsers(request: UsersRequest, searchTerm: string): Observable<UsersResponse> {
    let params = new HttpParams();
    params = params.append('Page', String(request.page));
    params = params.append('PageSize', String(request.pageSize));
    params = params.append('SortColumn', request.sortColumn || '');
    params = params.append('SortOrder', request.sortOrder || '');
    params = params.append('FilterName', request.filterName || '');
    params = params.append('FilterValue', request.filterValue || '');
    params = params.append('searchTerm', searchTerm);

    return this.http.get<UsersResponse>(this.ApiGetSubLoginPath, { params });
  }

  getAllUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(this.ApiGetSubLoginPath);
  }

  updateAddress(userId: number, request: Address) {    
    return this.http.put(this.ApiGetSubLoginPath + `/${userId}`, request)
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }
}
