<div class="inner-content">
  <div class="inner-div">
    <div class="d-flex flex-row">
      <button id='close' (click)="closeModal()">close</button>
    </div>
  </div>
  <div class="inner-div">
    <span class="span-title">{{
      hasQuantityCampaign()
      ? 'Detalhes da campanha'
      : bonusData && bonusData.length > 0
      ? 'Produtos de oferta'
      : 'Detalhes da campanha'
      }}
    </span>
    <div class="inside-content2" *ngIf="bonusData && bonusData.length > 0">
      <div *ngFor="let product of bonusData; let i=index">
        <div class="product-bonus">
          <div *ngIf="!product.imageURL.endsWith('product_placeholder.png')">
            <img [src]="product.imageURL" [alt]="product.referenceBonus">
          </div>
          <div *ngIf="product.imageURL.endsWith('product_placeholder.png')" class="placeholderDiv">
            <img [src]="product.imageURL" [alt]="product.referenceBonus">
            <span>Imagem não disponibilizada</span>
          </div>
          <div class="text-center">
            <span>{{ product.bonuses[0].description }}</span>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-end">
          <div *ngFor="let bonus of product.bonuses" class="plus-bonus">
            <span>{{ bonus.bonus }}</span>
            <div *ngIf="shouldShowAddButton" class="plus-btn" (click)="callCrudCart(passedData, bonus.bonus)">+</div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="data.bonusData.quantityCampaign?.length">
      <ng-container *ngFor="let campaign of data.bonusData.quantityCampaign; let i = index">
        <div class="col-md-12 col-xs-12 text-center mobileAdjust1 quantityCampaignSection"
          *ngIf="campaign.quantity >= 0 && campaign.discountPercentage > 0"
          [ngStyle]="{'margin-top': i === 0 ? '20px' : '10px'}">
          <ng-container *ngIf="i === (data.bonusData.quantityCampaign?.length || 0) - 1">
            Na compra de {{ campaign.quantity + 1 }} ou mais unidades, o artigo tem um desconto de {{
            campaign.discountPercentage }}%.
          </ng-container>
          <ng-container *ngIf="data.bonusData.quantityCampaign && i < data.bonusData.quantityCampaign.length - 1">
            Na compra de {{ campaign.quantity + 1 }} a {{ data.bonusData.quantityCampaign[i + 1].quantity || 0 }}
            unidades, o artigo tem um desconto de {{ campaign.discountPercentage }}%.
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>