<div class="main-content d-flex flex-column" id="stats-filters-container">
  <div class="d-flex flex-row w-100 header justify-content-between">
    <div>
      <span class="title1">Filtros</span>
    </div>
    <div>
      <div (click)="CloseMenu()" class="close-icon">
        <img src="../../../assets/Icons/close-line.png" alt="Fechar janela de filtros">
      </div>
    </div>
  </div>
  <div class="search-topics">
    <!-- Product-->
    <div class="search-topic">
      <h4 class="filter-title">Produto</h4>
      <mat-form-field style="height: 40px;">
        <input style="justify-content: center;" type="text" matInput [formControl]="productControl"
          [matAutocomplete]="productAuto" aria-label="Pesquisa por produto" />
        <mat-autocomplete #productAuto="matAutocomplete" (optionSelected)="SelectedProduct($event)"
          [displayWith]="DisplayProduct">

          <mat-option *ngFor="let option of filteredProductOptions | async | slice : 0 : 100" [value]="option">
            {{ option.memoria }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <!-- Laboratories -->
    <div class="search-topic" *ngIf="this.profileRequest.role !== 'Laboratorio'">
      <h4 class="filter-title">Laboratório</h4>
      <mat-form-field>
        <input style="justify-content: center;" type="text" aria-label="Pesquisa por laboratório" matInput [formControl]="laboratoryControl"
          [matAutocomplete]="labAuto" />
        <mat-autocomplete #labAuto="matAutocomplete" (optionSelected)="SelectedLaboratory($event)"
          [displayWith]="DisplayLaboratory">
          <mat-option *ngFor="let option of filteredLaboratoryOptions | async " [value]="option">
            {{ option.labName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <!-- Substances -->
    <div class="search-topic" *ngIf="this.profileRequest.role !== 'Laboratorio'">
      <h4 class="filter-title">Substância</h4>
      <mat-form-field>
        <input style="justify-content: center;" type="text" aria-label="Pesquisa por substância" matInput [formControl]="substanceControl"
          [matAutocomplete]="subAuto" />
        <mat-autocomplete #subAuto="matAutocomplete" (optionSelected)="SelectedSubstance($event)"
          [displayWith]="DisplaySubstance">
          <mat-option *ngFor="let option of filteredSubstanceOptions | async | slice : 0 : 100" [value]="option">
            {{ option.nome }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <!-- Addresses -->
    <div class="search-topic">
      <h4 class="filter-title">Moradas</h4>
      <mat-form-field>
        <mat-select [(ngModel)]="selectedAddress" name="addressesSelect" aria-label="Seleção de morada de entrega" id="addressesSelect">
          <mat-option *ngFor="let address of deliveryAddressList" [value]="address">
            {{address.address}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <!-- Start Date-->
    <div class="search-topic">
      <h4 class="filter-title">Data de Início</h4>
      <mat-form-field>
        <input matInput [matDatepicker]="startDatePicker" aria-labelledby="Data de início" [(ngModel)]="startDate" name="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker aria-haspopup="dialog"></mat-datepicker>
      </mat-form-field>
    </div>

    <!-- End Date -->
    <div class="search-topic">
      <h4 class="filter-title">Data de Fim</h4>
      <mat-form-field>
        <input matInput [matDatepicker]="endDatePicker" aria-labelledby="Data de fim" [(ngModel)]="endDate" name="endDate">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker aria-haspopup="dialog"></mat-datepicker>
      </mat-form-field>
    </div>

    <!-- Year -->
    <div class="search-topic">
      <h4 class="filter-title">Ano</h4>
      <mat-form-field>
        <mat-select [(ngModel)]="selectedYear" aria-label="Seleção de ano de pesquisa" name="yearSelect" id="yearSelect">
          <mat-option *ngFor="let year of yearsList" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!--   <div class="btn-apply" (click)="SearchStats()"><span>Aplicar</span></div>
 -->

  <div *ngIf="this.profileRequest.role === 'Laboratorio' else otherDiv">
    <div class="btn-apply" aria-label="Aplicar filtros" (click)="callSearchStats()"><span>Aplicar</span></div>
  </div>
  <ng-template #otherDiv>
    <div class="btn-apply" aria-label="Pesquisar estatísticas" (click)="SearchStats()"><span>Aplicar</span></div>
  </ng-template>
</div>
