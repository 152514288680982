import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { CampaignGroupMin, ProductMin } from 'src/app/api/models/campaigns';
import { TbProduto } from 'src/app/api/models/statistics';
import { BackofficeCampaignService } from 'src/app/api/services/backoffice-campaign.service';
import { CampaignsService } from 'src/app/api/services/campaigns.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { NgxMatFileInputComponent } from '@angular-material-components/file-input';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { SearchInputProductsService } from 'src/app/Custom/search-input-products/search-input-products.service';

@Component({
  selector: 'app-create-campaign-group',
  templateUrl: './create-campaign-group.component.html',
  styleUrls: ['./create-campaign-group.component.css'],
})
export class CreateCampaignGroupComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private campaignService: CampaignsService,
    private campaingGroupService: BackofficeCampaignService,
    private snackBarService: SnackBarService,
    readonly sd: ScrollDispatcher,
    private searchInputProduct: SearchInputProductsService
  ) {}

  @Output() createGroup = new EventEmitter<CampaignGroupMin>();
  groupForm!: FormGroup;
  filteredProductLists!: Observable<TbProduto[]>;
  selectedItens: TbProduto[] = [];
  imgGroupType = '.png,.jpeg,.jpg,.gif,.webm';
  unsubscribe: Subscription = new Subscription();
  plist: TbProduto[] = [];
  noGroup = 'C';
  myControl = new FormControl('');

  ngOnInit(): void {
    this.groupForm = this.campaingGroupService.getGroupForm();
    this.unsubscribe.add(
      this.campaignService
      .getAllProducts('')
      .subscribe({
        next: (response) => {
          this.plist = response;
        },
        complete: () => {
          this.searchInputProduct.setPlist(true);
        },
      })
    );
  }

  get header() {
    return this.groupForm.controls['header'] as FormControl;
  }
  get image() {
    return this.groupForm.controls['image'] as FormControl;
  }

  get selectProducts() {
    return this.groupForm.controls['selectProducts'] as FormControl;
  }

  onGroupTypeChange(event: any): void {
    this.noGroup = event.value;
    var groupName = this.groupForm.get('groupName');
    if (this.noGroup == 'S') {
      groupName?.setValue('Produtos sem grupo');
      groupName?.disable();
    } else {
      groupName?.setValue('');
      groupName?.enable();
    }
  }

  generateGroup() {
    let groupName: string;
    if (this.noGroup === 'S') {
      groupName = 'Produtos sem grupo';
    } else {
      groupName = this.groupForm.value['groupName'];
    }
    const products: ProductMin[] = this.selectedItens.map((p) => {
      return {
        reference: p.referencia,
        memory: p.memoria,
        description: p.description,
      };
    });
    const group: CampaignGroupMin = {
      idCampaignGroup: 0,
      description: groupName,
      products: products,
      header: this.groupForm.value['header'],
      image: this.groupForm.value['image'],
    };
    var campaignGroup = this.campaingGroupService.getCampaign().group;
    var exists = false;
    if (campaignGroup && campaignGroup.length > 0) {
      campaignGroup.forEach((element) => {
        if (
          element.description === 'Produtos sem grupo' &&
          groupName === 'Produtos sem grupo'
        ) {
          exists = true;
        }
      });
    }
    if (exists) {
      this.snackBarService.openSnackBar(
        'Já existe um grupo de produtos sem grupos',
        '#F9841E',
        '../../assets/Icons/Warning-icon.png'
      );
      this.noGroup = 'C';
      this.groupForm.reset();
      this.groupForm.controls['groupName'].enable();
      this.selectedItens = [];
    } else {
      if(this.groupForm.valid) {
        this.campaingGroupService.addGroup(group);
        this.selectedItens.slice(0, this.selectedItens.length);
        this.noGroup = 'C';
        this.groupForm.reset();
        var groupFormName = this.groupForm.get('groupName');
        groupFormName?.setValue('');
        groupFormName?.enable();
        this.selectedItens = [];
      }
      else {
        this.snackBarService.openSnackBar(
          'Campos obrigatórios por preencher',
          '#F9841E',
          '../../assets/Icons/Warning-icon.png'
        );
      }
    }
  }

  checkFileSize(
    InputComponent: NgxMatFileInputComponent,
    maxSizeInMB: number
  ): void {
    if (InputComponent) {
      const selectedFile = InputComponent.ngControl.value;
      if (selectedFile) {
        const fileSizeBytes = selectedFile.size;
        const fileSizeMB = fileSizeBytes / (1024 * 1024);
        if (fileSizeMB > 5) {
          this.snackBarService.openSnackBar(
            'Ficheiro demasiado grande (max. 5MB)',
            '#F9841E',
            '../../assets/Icons/Warning-icon.png'
          );
          InputComponent.ngControl.reset();
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }
}
