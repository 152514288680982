<mat-accordion>
  <div *ngFor="let group of group" style="padding-bottom: 24px;">
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex title-justify-content">
            <div>
              {{ group.description }}
            </div>
            <div>
              <button class="button" *ngIf="!editing" (click)="UpdateGroupButton(group)">
                <img src="../../../assets/Icons/edit.png" width="20px" height="20px"/>
              </button>
              <button class="button" (click)="handleRemoveGroup(group)" *ngIf="editing">
                <img src="../../../assets/Icons/trash.png" width="20px" height="20px"/>
              </button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table class="w-100">
          <tr>
            <th class="w-column bordered-cell no-border-left">Referencia</th>
            <th class="w-column bordered-cell no-border-right">Descrição do Produto</th>
            <th class="bordered-cell no-border-right" *ngIf="editing"> </th>
          </tr>
            <ng-container *ngIf="group.products && group.products.length > 0">
              <tr *ngFor="let product of group.products">
                <td class="w-column bordered-cell no-border-left">{{ product.reference }}</td>
                <td class="w-column bordered-cell no-border-right">{{ product.memory }}</td>
                <td class="buttons-content bordered-cell no-border-right" *ngIf="editing">
                <button class="button" (click)="handleRemoveProduct(product, group)">
                  <img src="../../../assets/Icons/trash.png" width="20px" height="20px"/>
                </button>
                </td>
              </tr>
            </ng-container>
        </table>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
  