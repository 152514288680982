import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ImportRegistosResponse } from '../models/tb-registo';

@Injectable({
  providedIn: 'root'
})
export class InterfaceRegistosService {
  route = new ApiConfiguration();
  ApiInterfaceRegistos = this.route.rootUrl + '/api/registos/interface/import';

  constructor(private http: HttpClient) { }

  importRegistos(option: number, utilizadorBio2?: string): Observable<ImportRegistosResponse[]> {
    let params = new HttpParams();
    params = params.append('option', option);
    if(utilizadorBio2) {
      params = params.append('utilizadorBio2', utilizadorBio2);
    }
    return this.http.get<ImportRegistosResponse[]>(this.ApiInterfaceRegistos, {params});
  }
}
