import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { CampaignGroupMin, CampaignMin, ProductMin } from 'src/app/api/models/campaigns';
import { TbProduto } from 'src/app/api/models/statistics';
import { BackofficeCampaignService } from 'src/app/api/services/backoffice-campaign.service';
import { CampaignsService } from 'src/app/api/services/campaigns.service';
import { SearchInputProductsService } from 'src/app/Custom/search-input-products/search-input-products.service';

@Component({
  selector: 'app-edit-campaign-group',
  templateUrl: './edit-campaign-group.component.html',
  styleUrls: ['./edit-campaign-group.component.css'],
})
export class EditCampaignGroupComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private campaignService: CampaignsService,
    private campaingGroupService: BackofficeCampaignService,
    private searchInputProducts: SearchInputProductsService
  ) { }

  group!: CampaignGroupMin;
  productList: TbProduto[] = [];
  groupForm!: FormGroup;
  filteredProductLists!: ProductMin[];
  selectedItens: TbProduto[] = [];
  imgGroupType = '.png,.jpeg,.jpg,.gif,.webm';
  unsubscribe: Subscription = new Subscription();
  nextSize: number = 50;
  plist: TbProduto[] = [];
  originalCampaign!: CampaignMin;
  campaign!: CampaignMin;
  originalGroups!: CampaignGroupMin[];
  originalGroup!: CampaignGroupMin[];
  myControl = new FormControl('');
  @Output() emitOriginalGroup = new EventEmitter<CampaignGroupMin[]>();
  @Output() outSelectedItems = new EventEmitter<TbProduto[]>();

  ngOnInit(): void {
    this.unsubscribe.add(
      this.campaignService.getAllProducts('').subscribe({
        next: (productList) => {
          this.productList = productList;
          this.plist = this.productList;
        },
        complete: () => {
          this.searchInputProducts.setPlist(true);
        }
      })
    );

    const campaing = this.campaingGroupService.getOriginal();
    this.originalCampaign = JSON.parse(JSON.stringify(campaing)) as CampaignMin;
    this.campaign = JSON.parse(JSON.stringify(this.originalCampaign)) as CampaignMin;
    this.campaingGroupService.setCampaign(this.campaign);
    this.originalGroups = JSON.parse(JSON.stringify(this.originalCampaign.group)) as CampaignGroupMin[];
    
    this.unsubscribe.add(
      this.campaingGroupService.getEditGroup().subscribe((x) => {
        if (x.group.description && x.group.description !== '') {
          const originalGroup = this.originalGroups.find(g => g.description == x.group.description);
          if(originalGroup){
            this.originalGroup = [originalGroup]
            this.emitOriginalGroup.emit(this.originalGroup);
          }
            
          this.group = x.group;

          const prods = x.group.products.map(value => {
            const prod = {
              referencia: value.reference,
              memoria: value.memory,
              description: value.memory,
            }
            return prod;
          });

          const isDisable = this.group.description == 'Produtos sem grupo';
          this.groupForm = this.formBuilder.group({
            groupName: new FormControl(
              {
                value: this.group.description, 
                disabled: isDisable
              },
              Validators.required,
            ),
            selectProducts: new FormControl(
              prods,
              Validators.required
            ),
            imgHeader: new FormControl(this.group.header, Validators.required),
            imgGroup: new FormControl(this.group.image, Validators.required),
          });
          this.selectedItens = this.selectProducts.value;
          this.outSelectedItems.emit(this.selectedItens);
        }
      })
    );
  }

  get imgHeader() {
    return this.groupForm.controls['imgHeader'] as FormControl;
  }
  get imgGroup() {
    return this.groupForm.controls['imgGroup'] as FormControl;
  }

  get selectProducts() {
    return this.groupForm.controls['selectProducts'] as FormControl;
  }

  updateGroup() {
    const changes = this.groupForm.value;
    let newProducts = this.selectedItens.map((p) => ({
      reference: p.referencia,
      memory: p.memoria,
    }));

    const groupName = this.group.description == 'Produtos sem grupo' ? 'Produtos sem grupo' : changes.groupName;
    this.campaingGroupService.updateCurrentGroup({
      idCampaignGroup: this.group.idCampaignGroup,
      products: newProducts,
      image: changes.imgGroup,
      description: groupName,
      header: changes.imgHeader,
    } as CampaignGroupMin);

    //get editing group
    const editingGroup = this.campaingGroupService.getEditGroup().value;
  
    if (editingGroup) {
      const groupIndex = this.campaign.group?.findIndex(g => g.description == editingGroup.group.description);
      if (groupIndex !== -1 && this.campaign.group) {
        this.campaign.group.splice(groupIndex!, 1, editingGroup.group);

        // Update the campaign in the service
        this.campaingGroupService.setCampaign(this.campaign);
      }
    }

    this.campaingGroupService.getEditing().next(true);
  }



  cancelOperationButton() {
    this.campaingGroupService.resetEdit(this.originalGroup);
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }
}
