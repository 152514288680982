<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div class="content">
      <div class="ultraWAdjusts">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" aria-label="Abas de Finanças">
          <mat-tab *ngIf="authService.hasPermissions(['8'])" label="Documentos/Saldos em Aberto" aria-label="Aba de Documentos/Saldos em Aberto">
            <div *ngIf="isLoadingBalance && !isPageChange; else contentLoaded" class="loading-animation h-100" role="alert" aria-live="assertive">
              <app-loading></app-loading>
            </div>
            <ng-template #contentLoaded>
              <div *ngIf="financesData">
                <div class="row mt-4 cards">
                  <div class="col d-flex mb-md-3">
                    <div class="fincance-card">
                      <div class="fincance-card-text">
                        <h2>Saldo à data</h2>
                        <p>{{ financesData.balanceDate | dateFormat }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col d-flex mb-md-3">
                    <div class="fincance-card">
                      <div class="fincance-card-text">
                        <h2>Total a vencer</h2>
                        <p>{{ financesData.dueBalanceTotal }}*</p>
                      </div>
                    </div>
                  </div>
                  <div class="col d-flex">
                    <div class="fincance-card">
                      <div class="fincance-card-text">
                        <h2>Total vencido</h2>
                        <p>{{ financesData.overdueBalanceTotal }}*</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4 finance-smal-text">
                  <p class="mb-0">
                    *Os descontos de pronto pagamento mencionados são relativos à
                    data e hora de consulta/impressão e já abatidos ao total a
                    vencer.
                  </p>
                  <p>
                    *Os documentos pagos por débito direto podem já não ser
                    apresentados, aguardando a boa cobrança dos mesmos.
                  </p>
                </div>
                <div class="mt-3 position-relative finance-dates-inputs">
                  <div *ngIf="(isLoadingBalance && isPageChange) || isLoadingPdf" class="spinner-container" role="alert" aria-live="assertive">
                    <app-loading></app-loading>
                  </div>

                  <table *ngIf="!financesData.hasError && financesData.financeBalances.data.length > 0" mat-table
                    [dataSource]="dataSourceBalance" class="w-100 h-100"
                    [ngClass]="{ 'opacity-30': isPageChange || isLoadingPdf }" aria-labelledby="Tabela de Documentos/Saldos em Aberto">
                    <ng-container matColumnDef="balanceType">
                      <th mat-header-cell *matHeaderCellDef>Tipo</th>
                      <td mat-cell *matCellDef="let element" matTooltip="{{ element.balanceType }}"
                        matTooltipPosition="below" class="has_label_on_mobile" data-label="Tipo:">
                        {{ element.balanceType | invoiceType }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="balanceNumber">
                      <th mat-header-cell *matHeaderCellDef style="width: 220px">
                        Número
                      </th>
                      <td mat-cell class="has_label_on_mobile" *matCellDef="let element" data-label="Número:">
                        <div class="pdf-cell">
                          {{ element.balanceNumber }}
                          <button mat-icon-button matTooltip="Download PDF" [disabled]="isLoadingPdf" aria-label="Abrir fatura" class="btn-pdf"
                            (click)="onPdfClick(element, true)">
                            <mat-icon svgIcon="pdf_file"></mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="balanceDate">
                      <th mat-header-cell *matHeaderCellDef>Data</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Data:">
                        {{ element.balanceDate | dateNumericFormat }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="balanceOverdueDate">
                      <th mat-header-cell *matHeaderCellDef>Data Venc.</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Data Venc.:">
                        {{ element.balanceOverdueDate | dateNumericFormat }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="balanceOverdueDays">
                      <th mat-header-cell *matHeaderCellDef>Dias Venc.</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-center"
                        data-label="Dias Venc.:">
                        {{ element.balanceOverdueDays }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="balanceStatus">
                      <th mat-header-cell *matHeaderCellDef>Estado</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Estado:">
                        {{ element.balanceStatus }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="balanceValue">
                      <th mat-header-cell *matHeaderCellDef>Valor</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right" data-label="Valor:">
                        {{ element.balanceValue }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="balanceDiscont">
                      <th mat-header-cell *matHeaderCellDef>Desconto PP</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right"
                        data-label="Desconto PP:">
                        {{ element.balanceDiscont }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="balanceValueToPay">
                      <th mat-header-cell *matHeaderCellDef>Valor a Pagar</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right"
                        data-label="Valor a Pagar:">
                        {{ element.balanceValueToPay }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="balanceObsv">
                      <th mat-header-cell *matHeaderCellDef>Obs.</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Obs.:">
                        {{ element.balanceObsv }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>

                  <div *ngIf="financesData.hasError || financesData.financeBalances.data.length === 0">
                    <h2>
                      {{ financesData.hasError }} 
                    </h2>
                  </div>
                </div>
              </div>
            </ng-template>
            <mat-paginator #balancePaginator class="mb-4"
              [disabled]="(isLoadingBalance && isPageChange) || isLoadingPdf"
              [hidden]="isLoadingBalance || dataSourceBalance.filteredData.length <= 0" [pageSize]="balancePageSize"
              [pageSizeOptions]="[]" (page)="onPageChangeBalance($event)" showFirstLastButtons>
            </mat-paginator>
          </mat-tab>
          <mat-tab *ngIf="authService.hasPermissions(['5', '9', '10', '13'])" label="Extrato Conta Corrente">
            <div class="row finance-dates-inputs">
              <form [formGroup]="formDates" (ngSubmit)="getCurrentExtract()"
                class="d-flex flex-wrap align-items-center w-100 justify-content-between">
                <div class="d-flex flex-wrap justify-content-center align-items-center" style="gap: 8px">
                  <mat-form-field class="date-input date-picker">
                    <div class="d-flex align-items-start position-fixed" style="top: 0">
                      <p class="p-0 mr-1">De:</p>
                      <input matInput [matDatepicker]="dataInicio" (click)="dataInicio.open()"
                        formControlName="startDate" />
                      <mat-datepicker #dataInicio></mat-datepicker>
                    </div>
                  </mat-form-field>

                  <mat-form-field class="date-input date-picker" style="width: 136px">
                    <div class="d-flex align-items-start position-fixed" style="top: 0">
                      <p class="mr-1">Até:</p>
                      <input matInput [matDatepicker]="dataFim" formControlName="endDate" (click)="dataFim.open()" />
                      <mat-datepicker #dataFim></mat-datepicker>
                    </div>
                  </mat-form-field>
                  <button type="submit" class="btn btn-green h-auto mobile-search"
                    [disabled]="!formDates.valid || isLoadingExtract">
                    <span>Pesquisar</span>
                    <img src="../../../assets/Icons/search.png" class="i-font" alt="Pesquisar Icon">
                  </button>
                </div>

                <div class="d-flex mobile-update" *ngIf="currentExtract && !currentExtract.hasError">
                  <p *ngIf="lastUpdateDate" class="m-0">
                    Última atualização: {{ lastUpdateDate }} &#x2022;
                    {{ lastUpdateTime }}
                    <span class="ml-2 saldo-final">Saldo Final: {{ currentExtract.finalBalance
                                        }}€</span>
                  </p>
                </div>
              </form>
            </div>
            <div *ngIf="isLoadingExtract && !isPageChange; else contentLoaded2" class="loading-animation h-100">
              <app-loading></app-loading>
            </div>
            <ng-template #contentLoaded2>
              <div *ngIf="currentExtract" class="position-relative">
                <div class="row finance-dates-inputs" *ngIf="!currentExtract.hasError">
                  <div *ngIf="(isLoadingExtract && isPageChange) || isLoadingPdf" class="spinner-container">
                    <app-loading></app-loading>
                  </div>
                  <p *ngIf="extractNull != ''">*{{extractNull}}</p>
                  <table mat-table [dataSource]="dataSourceExtract" class="w-100 h-100"
                    [ngClass]="{ 'opacity-30': isPageChange || isLoadingPdf }">
                    <ng-container matColumnDef="data">
                      <th mat-header-cell *matHeaderCellDef style="width: 180px">
                        Data
                      </th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Data:">
                        {{ element.extractDate | dateNumericFormat }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="docNumber">
                      <th mat-header-cell *matHeaderCellDef style="width: 260px">
                        Número
                      </th>
                      <td mat-cell class="has_label_on_mobile" *matCellDef="let element" data-label="Número:">
                        <div class="pdf-cell">
                          {{ element.extractNumber }}
                          <button mat-icon-button matTooltip="Download PDF" [disabled]="isLoadingPdf" class="btn-pdf"
                            (click)="onPdfClick(element, false)">
                            <mat-icon svgIcon="pdf_file"></mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="docType">
                      <th mat-header-cell *matHeaderCellDef>Tipo Doc.</th>
                      <td mat-cell *matCellDef="let element" matTooltip="{{ element.extractDocType }}"
                        matTooltipPosition="below" class="has_label_on_mobile" data-label="Tipo Doc.">
                        {{ element.extractDocType | invoiceType }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="sinal">
                      <th mat-header-cell *matHeaderCellDef>Sinal</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-center"
                        data-label="Sinal:">
                        {{ element.extractSinal }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="valor">
                      <th mat-header-cell *matHeaderCellDef>Valor</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right" data-label="Valor:">
                        {{ element.extractValue }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="saldo">
                      <th mat-header-cell *matHeaderCellDef>Saldo</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right" data-label="Saldo:">
                        {{ element.extractBalance }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsExtract"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsExtract"></tr>
                  </table>
                </div>
                <div *ngIf="currentExtract && currentExtract.hasError" class="mt-3">
                  <h2>
                    {{ currentExtract.hasError }}
                  </h2>
                </div>
              </div>
            </ng-template>

            <mat-paginator #extractPaginator [disabled]="(isLoadingExtract && isPageChange) || isLoadingPdf"
              [hidden]="isLoadingExtract || dataSourceExtract.filteredData.length <= 0" [pageSize]="extractPageSize"
              [pageSizeOptions]="[]" (page)="onPageExtractChange($event)" showFirstLastButtons>
          </mat-paginator>
          </mat-tab>

          <mat-tab *ngIf="authService.hasPermissions(['5', '9', '10', '13'])" aria-label="Extrato de Pontos" label="Extrato de Pontos">
            <div class="row finance-dates-inputs">
              <form [formGroup]="formDatesPoints" (ngSubmit)="getCurrentExtractPontos()"
                class="d-flex flex-wrap align-items-center w-100 justify-content-between">
                <div class="d-flex flex-wrap justify-content-center align-items-center" style="gap: 8px">
                  <mat-form-field class="date-input date-picker">
                    <div class="d-flex align-items-start position-fixed" style="top: 0">
                      <p class="p-0 mr-1">De:</p>
                      <input matInput [matDatepicker]="dataInicioPontos" (click)="dataInicioPontos.open()"
                        formControlName="startDatePoints" aria-labelledby="Data de início"/>
                      <mat-datepicker #dataInicioPontos aria-haspopup="dialog"></mat-datepicker>
                    </div>
                  </mat-form-field>

                  <mat-form-field class="date-input date-picker" style="width: 136px">
                    <div class="d-flex align-items-start position-fixed" style="top: 0">
                      <p class="mr-1">Até:</p>
                      <input matInput [matDatepicker]="dataFimPontos" aria-labelledby="Data de fim" formControlName="endDatePoints"
                        (click)="dataFimPontos.open()" />
                      <mat-datepicker #dataFimPontos aria-haspopup="dialog"></mat-datepicker>
                    </div>
                  </mat-form-field>
                  <button type="submit" class="btn btn-green h-auto mobile-search"
                    [disabled]="!formDatesPoints.valid || isLoadingExtractPoints" aria-label="Pesquisar">
                    <span>Pesquisar</span>
                    <img src="../../../assets/Icons/search.png" class="i-font" alt="Pesquisar Icon">
                  </button>
                </div>

                <div class="d-flex mobile-update" *ngIf="currentExtractPoints && !currentExtractPoints.hasError">
                  <p *ngIf="lastUpdateDatePoints" class="m-0">
                    Última atualização: {{ lastUpdateDatePoints }} &#x2022;
                    {{ lastUpdateTimePoints }}
                    <span class="ml-2 saldo-final">Pontos: {{ReadPoints ? ReadPoints : 0  }}</span>
                  </p>
                </div>
              </form>
            </div>
            <div *ngIf="isLoadingExtractPoints && !isPageChange; else contentLoaded3" class="loading-animation h-100" role="alert" aria-live="assertive">
              <app-loading></app-loading>
            </div>
            <ng-template #contentLoaded3>
              <div *ngIf="currentExtractPoints" class="position-relative">
                <div class="row finance-dates-inputs" *ngIf="!currentExtractPoints.hasError">
                  <div *ngIf="(isLoadingExtractPoints && isPageChange) || isLoadingPdf" class="spinner-container" role="alert" aria-live="assertive">
                    <app-loading></app-loading>
                  </div>
                  <p *ngIf="extractPointsNull != ''">*{{extractPointsNull}}</p>
                  <table mat-table [dataSource]="dataSourceExtractPoints" class="w-100 h-100"
                    [ngClass]="{ 'opacity-30': isPageChange || isLoadingPdf }" aria-labelledby="Tabela de extrato de pontos">
                    <ng-container matColumnDef="extractDate">
                      <th mat-header-cell *matHeaderCellDef style="width: 180px">
                        Data
                      </th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Data:">
                        {{ element.extractDate | dateNumericFormat }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="extractNumber">
                      <th mat-header-cell *matHeaderCellDef style="width: 260px">
                        Número
                      </th>
                      <td mat-cell class="has_label_on_mobile" *matCellDef="let element" data-label="Número:">
                        <div class="pdf-cell">
                          {{ element.extractNumber }}
                          <button mat-icon-button matTooltip="Download PDF" [disabled]="isLoadingPdf" aria-label="Download PDF" class="btn-pdf"
                            (click)="onPdfClick(element, false)">
                            <mat-icon svgIcon="pdf_file"></mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="extractType">
                      <th mat-header-cell *matHeaderCellDef>Tipo Doc.</th>
                      <td mat-cell *matCellDef="let element" matTooltip="{{ element.extractDocType }}"
                        matTooltipPosition="below" class="has_label_on_mobile" data-label="Tipo Doc.">
                        {{ element.extractDocType | invoiceType }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="realizacao">
                      <th mat-header-cell *matHeaderCellDef>Realização</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile"
                        data-label="Realização:">
                        {{ element.realizacao | dateNumericFormat }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="regularizacao">
                      <th mat-header-cell *matHeaderCellDef>Regularização</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Regularização:">
                        {{ element.regularizacao | dateNumericFormat }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="vencimento">
                      <th mat-header-cell *matHeaderCellDef>Vencimento</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile" data-label="Vencimento:">
                        {{ element.vencimento | dateNumericFormat }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="estimados">
                      <th mat-header-cell *matHeaderCellDef>Estimados</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right" data-label="Estimados:">
                        {{ element.estimados }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="realizados">
                      <th mat-header-cell *matHeaderCellDef>Realizados</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right" data-label="Realizados:">
                        {{ element.realizados }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="perdidos">
                      <th mat-header-cell *matHeaderCellDef>Perdidos</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right" data-label="Perdidos:">
                        {{ element.perdidos }}
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="regularizados">
                      <th mat-header-cell *matHeaderCellDef>Regularizados</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right" data-label="Regularizados:">
                        {{ element.regularizados }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="equipamentos">
                      <th mat-header-cell *matHeaderCellDef>Equipamentos</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right" data-label="Equipamentos:">
                        {{ element.equipamentos }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="saldoadata">
                      <th mat-header-cell *matHeaderCellDef>Saldo</th>
                      <td mat-cell *matCellDef="let element" class="has_label_on_mobile text-right" data-label="Saldo:">
                        {{ element.saldoadata }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsExtractPoints"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsExtractPoints"></tr>
                  </table>
                </div>
                <div *ngIf="currentExtractPoints && currentExtractPoints.hasError" class="mt-3">
                  <h2>
                    {{ currentExtractPoints.hasError }} 
                  </h2>
                </div>
              </div>
            </ng-template>
            <mat-paginator #extractPointsPaginator [disabled]="(isLoadingExtractPoints && isPageChange) || isLoadingPdf"
                [hidden]="isLoadingExtractPoints || dataSourceExtractPoints.filteredData.length <= 0" [pageSize]="extractPointsPageSize"
                [pageSizeOptions]="[]" (page)="onPageChangePoints($event)" showFirstLastButtons>
            </mat-paginator>
          </mat-tab>

        </mat-tab-group>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
