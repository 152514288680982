<div class="main-content">
  <div class="app-side-menu-wrapper">
    <app-side-menu></app-side-menu>
  </div>
  <div class="w-100 d-flex">
    <div class="d-flex flex-column w-100">
      <app-top-menu></app-top-menu>
      <div *ngIf="isLoading; else contentLoaded" class="loading-animation">
        <app-loading></app-loading>
      </div>
      <ng-template #contentLoaded>
        <div class="d-flex flex-column w-100 position-relative content">
          <div class="d-flex flex-row flex-wrap justify-content-between mb-2">
            <div class="d-flex flex-row filter-left justify-content-center fit-content">
              <h2 class="ml-3">Favoritos</h2>
            </div>
            <div class="filter-right d-flex flex-row order">
              Ordenar:
              <mat-form-field class="select-box mr-2">
                <mat-select [(value)]="orderByOption" (valueChange)="sortProducts()">
                  <mat-select-trigger>
                    <ng-container *ngIf="orderByOption !== 'none'">
                      {{ orderByOption.includes('preco') ? 'Preço' : 'Stock' }}
                      <img *ngIf="orderByOption === 'preco-mais'"
                        src="../../../assets/Icons/dashboard-arrow-up-black.png" alt="imagem" />
                      <img *ngIf="orderByOption === 'preco-menos'"
                        src="../../../assets/Icons/dashboard-arrow-down-black.png" alt="imagem" />
                      <img *ngIf="orderByOption === 'stock-menos'"
                        src="../../../assets/Icons/dashboard-arrow-down-black.png" alt="imagem" />
                      <img *ngIf="orderByOption === 'stock-mais'"
                        src="../../../assets/Icons/dashboard-arrow-up-black.png" alt="imagem" />
                    </ng-container>
                  </mat-select-trigger>
                  <mat-option value="none">Nenhum</mat-option>
                  <mat-option value="preco-mais">
                    Preço
                    <img src="../../../assets/Icons/dashboard-arrow-up-black.png" alt="imagem" class="option-icon" />
                  </mat-option>
                  <mat-option value="preco-menos">
                    Preço
                    <img src="../../../assets/Icons/dashboard-arrow-down-black.png" alt="imagem" class="option-icon" />
                  </mat-option>
                  <mat-option value="stock-menos">
                    Stock
                    <img src="../../../assets/Icons/dashboard-arrow-down-black.png" alt="imagem" class="option-icon" />
                  </mat-option>
                  <mat-option value="stock-mais">
                    Stock
                    <img src="../../../assets/Icons/dashboard-arrow-up-black.png" alt="imagem" class="option-icon" />
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="results" matTooltip="{{ totalItems }} resultados" [matTooltipPosition]="'above'"
                [matTooltipShowDelay]="'500'" [matTooltipHideDelay]="'100'">
                <span>{{ totalItems }}</span>
                <img src="../../../assets/Icons/shopping-basket-line.png" alt="">
              </div>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-between align-items-center align-self-stretch mt-2">
            <div class="products-grid">
              <app-product-shop (favoriteChange)="handleFavoriteChange($event)" *ngFor="let product of shopItems"
                [product]="product" [inFavorite]="true" (isModalOpen)="isProdModalOpen($event)"></app-product-shop>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4" *ngIf="shopItems.length < totalItems">
            <div style="width: fit-content">
              <button class="btn-more" *ngIf="!isLoading" [disabled]="isLoadingMore" (click)="loadMore()">
                <mat-spinner *ngIf="isLoadingMore" diameter="20"></mat-spinner>
                <span>Mostrar mais resultados</span>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
      <app-footer></app-footer>
    </div>
  </div>
</div>
