import { Component, Inject, Input } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate('2s ease-in')]),
      transition(':leave', animate('2s ease-in', style({ opacity: 0 }))),
    ]),
  ],
})
export class CustomSnackBarComponent {
  @Input() message!: string;
  @Input() backgroundColor!: string;
  @Input() iconUrl!: string;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
