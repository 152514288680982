<div class="d-flex flex-row" style="height: 100vh">
  <div>
    <app-dashboard-side-menu></app-dashboard-side-menu>
  </div>
  <div class="p-content d-flex flex-column">
    <app-dashboard-top-menu></app-dashboard-top-menu>
    <!-- !!Loading animation -->
    <div *ngIf="loading; else contentLoaded" class="loading-animation">
      <app-loading></app-loading>
    </div>
    <ng-template #contentLoaded>
      <!-- !!Content to be displayed when loading is complete -->
      <div class="template-content">
        <div class="d-flex flex-row justify-content-between mat-card-blocks">
          <div class="mat-card">
            <div>
              <div class="card-main d-flex flex-row">
                <img src="../../../assets/Icons/dashboard-box.png" />
                <div class="d-flex flex-column">
                  <span>Nº de</span>
                  <span>Encomendas</span>
                </div>
              </div>
            </div>
            <div>
              <span class="inner-dashboard-card-text">{{
              orders[0].numberOfOrders | dashboardNumbersConverter
            }}</span>
            </div>
          </div>

          <div class="mat-card">
            <div>
              <div class="card-main d-flex flex-row">
                <img src="../../../assets/Icons/dashboard-box.png" />
                <div class="d-flex flex-column">
                  <span>Produtos</span>
                  <span>Encomendados</span>
                </div>
              </div>
            </div>
            <div>
              <span class="inner-dashboard-card-text">{{
              orders[0].productsOrdered | dashboardNumbersConverter
            }}</span>
            </div>
          </div>

          <div class="mat-card">
            <div>
              <div class="card-main d-flex flex-row">
                <img src="../../../assets/Icons/dashboard-box.png" />
                <div class="d-flex flex-column">
                  <span>Quantidades</span>
                  <span>Encomendadas</span>
                </div>
              </div>
            </div>
            <div>
              <span class="inner-dashboard-card-text">{{
              orders[0].quantityOfOrders | dashboardNumbersConverter
            }}</span>
            </div>
          </div>

          <div class="mat-card">
            <div>
              <div class="card-main d-flex flex-row">
                <img src="../../../assets/Icons/dashboard-box.png" />
                <div class="d-flex flex-column">
                  <span>Valor</span>
                  <span>Total</span>
                </div>
              </div>
            </div>
            <div>
              <span class="inner-dashboard-card-text">{{ orders[0].totalValue | dashboardNumbersConverter}}</span>
            </div>
          </div>
        </div>

        <div class="d-flex flex-row bottom-segment justify-content-between">
          <div class="pendingOrders-card">
            <h2>Encomendas por enviar</h2>
            <div *ngFor="let pendingOrder of pendingOrders" class="pend-orders">
              <span style="width: 45px; justify-content: center;"
                class="d-flex">{{ pendingOrder.dateAndHour! | extractHourPt }}</span>
              <div class="d-flex flex-column ball-line">
                <div class="ball"></div>
                <div class="line"></div>
              </div>
              <span class="order-number">{{ pendingOrder.orderNumber }}</span>
              <div class="pending-ball"></div>
              <span>{{ pendingOrder.dateAndHour! | extractDatePt }}</span>
            </div>
          </div>
          <div class="teste"></div>

          <div class="d-flex flex-column inner-bottom-segment">
            <div class="media-card">
              <span class="title-media">Media</span>
              <div class="d-flex flex-row justify-content-between inner-card">
                <div class="item d-flex flex-column">
                  <span class="media-number">{{pdfNumber}}</span>
                  <span class="media-span">PDF</span>
                </div>
                <div class="item d-flex flex-column">
                  <span class="media-number">{{jpgNumber}}</span>
                  <span class="media-span">JPG</span>
                </div>
                <div class="item d-flex flex-column">
                  <span class="media-number">{{jpegNumber}}</span>
                  <span class="media-span">JPEG</span>
                </div>
                <div class="item d-flex flex-column">
                  <span class="media-number">{{pngNumber}}</span>
                  <span class="media-span">PNG</span>
                </div>
              </div>
            </div>

            <div class="campaigns-card">
              <div>
                <h2>Campanhas</h2>
              </div>
              <div>
                <ul *ngFor="let campaign of campaigns">
                  <li>
                    <span>{{ campaign.type! | campaignsTypeConvert }} - </span>
                    <span class="campaign-text">{{ campaign.name }} </span>
                    <span>até {{ campaign.endDate! | extractDatePt }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
