import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard-side-menu',
  templateUrl: './dashboard-side-menu.component.html',
  styleUrls: ['./dashboard-side-menu.component.css'],
})
export class DashboardSideMenuComponent {
  constructor(private route: ActivatedRoute) {}
  showProdutosSubMenu: boolean = false;
  showExportSubMenu: boolean = false;

  toggleProdutosSubMenu() {
    this.showProdutosSubMenu = !this.showProdutosSubMenu;
  }

  toggleExportSubMenu() {
    this.showExportSubMenu = !this.showExportSubMenu;
  }
  isActive(route: string): boolean {
    return this.route.snapshot.routeConfig?.path === route;
  }
}
