import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    transform(inputDate: string): string {
        const months: any = {
            Jan: 'Janeiro', Fev: 'Fevereiro', Mar: 'Março', Abr: 'Abril', Mai: 'Maio', Jun: 'Junho',
            Jul: 'Julho', Ago: 'Agosto', Set: 'Setembro', Out: 'Outubro', Nov: 'Novembro', Dez: 'Dezembro'
        };

        if (inputDate && inputDate != "n/a") {
            const day = inputDate.slice(0, 2);
            const monthAbbr = inputDate.slice(2, 5);
            const year = inputDate.slice(5);

            const monthFull = months[monthAbbr];
            const convertedDate = `${day} ${monthFull}, ${year}`;

            return convertedDate;
        }
        return '-';
    }
}