import { TbLaboratorio, TbProduto } from './../models/statistics';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';
import { Observable, catchError } from 'rxjs';
import {
  Campaign,
  CampaignGroup,
  CampaignGroupMin,
  CampaignGroupRequest,
  CampaignMin,
  CampaignRequest,
  CampaignShop,
} from '../models/campaigns';
import { updateProdutoRequest } from '../models/statistics';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  route = new ApiConfiguration();
  ApiGetCampaignByTypePath = this.route.rootUrl + '/api/shop/campaign';
  ApiGetCampaignInterface = this.route.rootUrl + '/api/interface/campaign';
  campaign: Campaign | null = null
  campaignGroup: CampaignGroup | null = null

  constructor(private http: HttpClient) {}

  getAllCampaigns(userId: string, numCliente:string, types: string, prefix: string): Observable<CampaignShop> {
    try {
      const ApiGetAllCampaigns = this.route.rootUrl + '/api/shop/campaign';
      
      let params = new HttpParams();
      params = params.append('user', userId);
      params = params.append('numCliente', numCliente);
      params = params.append('types', types);
      params = params.append('prefix', prefix);
      
      return this.http.get<CampaignShop>(ApiGetAllCampaigns, { params }).pipe(
        catchError((error) => {
          throw error;
        })
      );
    } catch (error) {
      throw error;
    }
  }

  getCampaignDetailsById(id: number, userId: string, priceLevel: number): Observable<Campaign> {
    try {
      const ApiGetCampaignById = `${this.route.rootUrl}/api/shop/campaign/${id}/details`;
      
      let params = new HttpParams();
      params = params.append('user', userId);
      params = params.append('priceLevel', priceLevel);
      
      return this.http.get<Campaign>(ApiGetCampaignById, { params }).pipe(
        catchError((error) => {
          throw error;
        })
      );
    } catch (error) {
      throw error;
    }
  }

  createNewCampaign(campaign: CampaignMin): Observable<any> {
    const formData = new FormData();
    if (campaign.header) {
      formData.append(
        'files',
        campaign.header!,
        campaign.type +
        '_' +
        'campaign_header_' +
          campaign.link +
          campaign.header.name.substring(campaign.header.name.length - 4)
      );
    }
    if (campaign.image) {
      formData.append(
        'files',
        campaign.image!,
        campaign.type +
        '_' +
        'campaign_image_' +
          campaign.link +
          campaign.image.name.substring(campaign.image.name.length - 4)
      );
    }
    if (campaign.pdf) {
      formData.append(
        'files',
        campaign.pdf!,
        campaign.type +
        '_' +
        'campaign_pdf_' 
        + campaign.pdf!.name
      );
    }
    if (campaign.group?.length! > 0) {
      campaign.group!.forEach((element) => {
        if (element.header) {
          formData.append(
            'files',
            element.header!,
            element.description + '_group_header_' + element.header!.name
          );
        }
        if (element.image) {
          formData.append(
            'files',
            element.image!,
            element.description + '_group_image_' + element.image!.name
          );
        }
      });
    }
    campaign.priceLevel = campaign.priceLevel?.map((x) => Number(x));
    formData.append('campaign', JSON.stringify(campaign));
    return this.http.post(this.ApiGetCampaignInterface, formData);
  }

  getAllProducts(filter: string): Observable<TbProduto[]> {
    let params = new HttpParams();
    params = params.append('search', filter);
    return this.http.get<TbProduto[]>(
      this.ApiGetCampaignInterface + '/product',
      { params }
    );
  }

  getLabs(): Observable<TbLaboratorio[]> {
    return this.http.get<TbLaboratorio[]>(this.ApiGetCampaignInterface + `/labs`);
  }

  getCampaignById(id: number): Observable<CampaignRequest> {
    return this.http.get<CampaignRequest>(
      this.ApiGetCampaignInterface + '/' + id
    );
  }

  updateCampaign(campaign: CampaignMin): Observable<Campaign> {
    const campaignMap = campaign as Campaign;
    campaignMap.group?.forEach((x) => (x.idCampaign = campaignMap.id));
    const formData = new FormData();
    if (campaign.header) {
      formData.append(
        'files',
        campaign.header!,
        campaign.type +
        '_' +
        'campaign_header_' +
          campaign.link +
          campaign.header.name.substring(campaign.header.name.length - 4)
      );
    }
    if (campaign.image) {
      formData.append(
        'files',
        campaign.image!,
        campaign.type +
        '_' +
        'campaign_image_' +
          campaign.link +
          campaign.image.name.substring(campaign.image.name.length - 4)
      );
    }
    if (campaign.pdf) {
      formData.append(
        'files',
        campaign.pdf!,
        campaign.type +
        '_' +
        'campaign_pdf_' 
        + campaign.pdf!.name
      );
    }
    if (campaign.group?.length! > 0) {
      campaign.group!.forEach((element) => {
        if (element.header) {
          formData.append(
            'files',
            element.header!,
            element.description + '_group_header_' + element.header!.name
          );
        }
        if (element.image) {
          formData.append(
            'files',
            element.image!,
            element.description + '_group_image_' + element.image!.name
          );
        }
      });
    }
    campaign.priceLevel = campaign.priceLevel?.map((x) => Number(x));
    formData.append('campaign', JSON.stringify(campaign));
    return this.http.put<Campaign>(
      this.ApiGetCampaignInterface + '/' + campaignMap.id,
      formData
    );
  }

  addNewGroupsToCampaign(campaign: CampaignMin, campaignGroup: CampaignGroupMin[]){
    const campaignMap = campaign as Campaign;
    campaignMap.group?.forEach((x) => (x.idCampaign = campaignMap.id));
    const formData = new FormData();
    if (campaignGroup.length > 0) {
      campaignGroup!.forEach((element) => {
        if (element.header) {
          formData.append(
            'files',
            element.header!,
            element.description + '_group_header_' + element.header!.name
          );
        }
        if (element.image) {
          formData.append(
            'files',
            element.image!,
            element.description + '_group_image_' + element.image!.name
          );
        }
      });
    }
    formData.append('groups', JSON.stringify(campaignGroup));
    const apiUrl = `${this.ApiGetCampaignInterface}/${campaignMap.id}/group`;
    return this.http.post(apiUrl, formData);
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------//
}
