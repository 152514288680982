<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div class="content">
      <div class="h-100 ultraWAdjusts">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0" class="custom-tab-group"
          [selectedIndex]="tabIndex" aria-label="Abas de campanhas">
          <mat-tab label="Produtos Exclusivos" aria-label="Aba de produtos exclusivos">
            <app-campaign-main class="h-100" [isLoading]="isLoading" [campanhas]="novidades"
              (isModalOpen)="isProdModalOpen($event)"></app-campaign-main>
          </mat-tab>
          <mat-tab label="Campanhas" aria-label="Aba de campanhas">
            <app-campaign-main class="h-100" [isLoading]="isLoading" [campanhas]="campanhas"
              (isModalOpen)="isProdModalOpen($event)"></app-campaign-main>
          </mat-tab>
          <mat-tab label="Preços Outlet" aria-label="Aba de preços outlet">
            <app-campaign-main class="h-100" [isLoading]="isLoading" [campanhas]="outlet"
              (isModalOpen)="isProdModalOpen($event)"></app-campaign-main>
          </mat-tab>
          <mat-tab label="Campanhas Bio2" aria-label="Aba de campanhas Bio 2">
            <app-campaign-main class="h-100" [isLoading]="isLoading" [campanhas]="exclusivos"
              (isModalOpen)="isProdModalOpen($event)"></app-campaign-main>
          </mat-tab>
          <mat-tab label="Parcerias" aria-label="Aba de parcerias">
            <app-campaign-main class="h-100" [isLoading]="isLoading" [campanhas]="parcerias"
              (isModalOpen)="isProdModalOpen($event)"></app-campaign-main>
          </mat-tab>
          <mat-tab label="Destaques" aria-label="Aba de destaques">
            <app-campaign-main class="h-100" [isLoading]="isLoading" [campanhas]="emDestaque"
              (isModalOpen)="isProdModalOpen($event)"></app-campaign-main>
          </mat-tab>
          <mat-tab label="Publicidade" #publicidadeTab aria-label="Aba de publicidade">
            <app-campaign-main class="h-100" [isLoading]="isLoading" [campanhas]="publicidade"
              (isModalOpen)="isProdModalOpen($event)"></app-campaign-main>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="app-backdrop" [ngClass]="{ 'open': isModalOpen }" role="dialog" aria-modal="true"></div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
