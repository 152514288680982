import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  catchError,
  Observable,
  switchMap,
  BehaviorSubject,
  filter,
  take,
} from 'rxjs';
import { AuthService } from './services/auth.service';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { SnackBarService } from './services/snack-bar.service';
import { Bio2Tokens } from './models/bio2token';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private isRefreshingtoken: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private snackBarService: SnackBarService,
    private router: Router,
    private injector: Injector,
  ) {
  }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !request.url.includes("auth/refresh-token")) {
          return this.handle401Error(request, next, error);
        } else {
          return this.errorHandler(error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, response: HttpErrorResponse){
    if (!this.isRefreshingtoken) {
      this.isRefreshingtoken = true;
      this.refreshTokenSubject.next(null);
      var refreshToken = localStorage.getItem('BIO2_RefreshToken') ?? '';

      if(!!refreshToken){
        const authService = this.injector.get(AuthService);
        return authService.getRefreshToken().pipe(
          switchMap((result: Bio2Tokens) => {
            this.isRefreshingtoken = false;
            this.refreshTokenSubject.next(result.biO2_AccessToken);
            return next.handle(request);
          }),
          catchError(response => {
            this.isRefreshingtoken = false;
            return this.errorHandler(response);
          })
        );
      }
      else {
        this.isRefreshingtoken = false;
        return this.errorHandler(response);
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => {
          return next.handle(request);
        })
      );
    }
  }

  private errorHandler(response: any): Observable<any> {
    if (response instanceof HttpErrorResponse) {
      if (response.status === 401) {

        sessionStorage.clear();
        localStorage.clear();
        this.snackBarService.openSnackBar('Acesso negado. Dados de autenticação inválidos. Por favor autentique-se novamente.', '#F9841E','../../assets/Icons/Warning-icon.png');
        this.router.navigate(['/login']);
      } else if (response.status === 400) {
        this.snackBarService.openSnackBar('Pedido inválido. Volte a tentar mais tarde ou contacte o suporte técnico.', '#F9841E','../../assets/Icons/Warning-icon.png');
      } else if (response.status === 403) {
        this.snackBarService.openSnackBar('Acesso negado. O utilizador não tem autorização para aceder aos conteúdos pedidos.', '#F9841E','../../assets/Icons/Warning-icon.png');
      } else if (response.status === 404) {
        this.snackBarService.openSnackBar('Não encontrado. O pedido não foi encontrado.', '#F9841E','../../assets/Icons/Warning-icon.png');
      } else if (response.status === 410) {
        this.snackBarService.openSnackBar('Ação desabilitada. Por favor contacte o suporte técnico.', '#F9841E','../../assets/Icons/Warning-icon.png');
      } else if (response.status === 412) {
        this.snackBarService.openSnackBar('Pré-condição falhou. Por favor tente novamente ou contacte o suporte técnico.', '#F9841E','../../assets/Icons/Warning-icon.png');
      } else if (response.status === 0 || response.status === 500) {
        this.snackBarService.openSnackBar('Ocorreu um erro do sistema. Volte a tentar mais tarde ou contacte o nosso suporte técnico.', '#F9841E','../../assets/Icons/Warning-icon.png');
      } else if (response.message) {
        this.snackBarService.openSnackBar('Ocorreu um erro do sistema. Volte a tentar mais tarde ou contacte o nosso suporte técnico.', '#F9841E','../../assets/Icons/Warning-icon.png');
      }
    }

    throw response;
  }
}
