import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'invoiceType'
})
export class InvoiceTypePipe implements PipeTransform {
    transform(value: string): string {
        const invoceType = (value.trim().toLowerCase().match('factura a cliente') ? "Factura" : "Crédito");
        return invoceType;
    }
}