import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { TbProduto } from "../../api/models/statistics";
import { Subscription } from "rxjs";
import { CampaignsService } from "../../api/services/campaigns.service";
import { SearchInputProductsService } from "../../Custom/search-input-products/search-input-products.service";
import {ProductsService} from "../../api/services/products.service";
import {SnackBarService} from "../../api/services/snack-bar.service";

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.css']
})
export class SuggestionsComponent implements OnInit, OnDestroy {
  myForm!: FormGroup;
  loading = false;
  unsubscribe = new Subscription();
  plist: TbProduto[] = [];
  myControl = new FormControl('');
  selectProducts!: FormControl;
  selectedItens: TbProduto[] = [];

  constructor(
    private fb: FormBuilder,
    private campaignService: CampaignsService,
    private productsService: ProductsService,
    private searchInputProducts: SearchInputProductsService,
    private snack: SnackBarService
  ) {}

  ngOnInit(): void {
    this.myForm = this.fb.group({
      selectProducts: ['']
    });
    this.loading = true;
    this.unsubscribe.add(
      this.campaignService.getAllProducts('').subscribe({
        next: (response) => {
          this.plist = response;
        },
        complete: () => {
          this.searchInputProducts.setPlist(true);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      }),
    );
    this.unsubscribe.add(
      this.productsService.getProducts().subscribe({
        next: (response) => {
          this.selectedItens = response;
        },
        complete: () => {
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }

  onSelectionChange(selectedProducts: TbProduto[]): void {
    console.log(selectedProducts);
    this.snack.openSnackBar(
      'Produto adicionado aos sugeridos.',
      '#0AA174',
      '../../../assets/Icons/correct.png'
    );
    this.selectedItens = selectedProducts;
  }

  deleteProduct(index: number): void {
    this.selectedItens.splice(index, 1);
    this.snack.openSnackBar(
      'Produto removido dos sugeridos.',
      '#F97066',
      '../../../assets/Icons/Warning-icon.png'
    );
    console.log(this.selectedItens);
  }

  submitSelectedProducts(): void {
    console.log(this.selectedItens);
    this.productsService.postProductsSugestion(this.selectedItens).subscribe({
      next: (response) => {
        this.snack.openSnackBar(
          'Lista de produtos sugeridos atualizada.',
          '#0AA174',
          '../../../assets/Icons/correct.png'
        );
      },
      error: (error) => {
        this.snack.openSnackBar(
          'Erro ao atualizar lista de produtos.',
          '#F97066',
          '../../../assets/Icons/Warning-icon.png'
        );
      }
    });
  }
}
