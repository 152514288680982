import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';

export const AuthLabGuard: CanActivateFn = (route, state) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  const accessToken = authService.getAccessToken();

  if (accessToken) 
  {
    const decodedToken = authService.getDecodedAccessToken(accessToken);
    const role = decodedToken['role'];
    if (role !== 'Laboratorio') {
        router.navigate(['/home']);
        return false;
    }
  } 
  else 
  {
    router.navigate(['/login']);
    return false;
  }

  return true;
};
