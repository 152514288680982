import { Injectable } from '@angular/core';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordRecService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  route = new ApiConfiguration();

  ApiAuthPassPostPath = this.route.rootUrl + '/api/auth/password-recover/registos';
  ApiAuthResetPassPostPath = this.route.rootUrl + '/api/auth/password-authenticate';
  ApiAuthPassEmailUser = this.route.rootUrl + '/api/auth/password-recover';

  recover(userEmail: string): Observable<HttpResponse<any>> {
    const apiUrl = `${this.ApiAuthPassPostPath}?email=${encodeURIComponent(userEmail)}`;
    return this.http.get<any>(apiUrl, { observe: 'response' });
  }

  recoverEmailUser(email: string, userId: number): Observable<HttpResponse<any>> {
    const apiUrl = `${this.ApiAuthPassEmailUser}/${encodeURIComponent(email)}/${userId}`;
    return this.http.post<any>(apiUrl, { observe: 'response' });
  }

  resetPassword(token: string, newPassword1: string): Observable<any> {
    const body = { resetToken: token, resetPassword: newPassword1 };
    return this.http.post(this.ApiAuthResetPassPostPath, body);
  }
}
