import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CombinedAuthGuardService {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    const accessToken = localStorage.getItem('BIO2_AccessToken');

    if (accessToken) {
      const decodedToken = this.authService.getDecodedAccessToken(accessToken);
      const role = decodedToken['role'];

      if (role === 'Administrador' || role === 'Laboratorio') {
        return true;
      }
    }
    this.router.navigate(['/login']);
    return false;
  }
}
