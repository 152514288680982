<div class="termos-page">
  <div class="curve-container">
    <img src="../../../assets/images/wave.svg" alt="Waves" />
  </div>
  <div>
    <img src="../../../assets/images/bio2Logo.png" class="logo-bio2" alt="Logo BIO2" />
  </div>
  <div class="termos-container">
    <span class="title-terms">Termos e Condições</span>
    <div class="termos-text">
      <p [innerHTML]="termosText"></p>
    </div>
    <div class="d-flex flex-row btns">
      <div class="btn-cancelar" (click)="onCancelar()">Cancelar</div>
      <div class="btn-continue d-flex flex-row-reverse" (click)="onAccept()">
        <mat-icon svgIcon="arrow_right" iconPositionEnd></mat-icon>
        Prosseguir
      </div>
    </div>
  </div>
  <div class="fish-container">
    <img src="../../../assets/images/ellipse.svg" class="ellipse" alt="Ellipse" />
    <img src="../../../assets/images/fish.png" class="fish" alt="Fish" />
  </div>
  <img class="vector-5" src="../../../assets/images/Vector 5.png" alt="vetor-5" />
</div>
