import { ShopItemModel } from './shop-item.model';
import { TbProduto } from './statistics';

export interface Campaign {
  id: number;
  name: string;
  link: string;
  header?: string;
  image?: string;
  startingDate: string;
  endingDate: string;
  type: string;
  priceLevel?: null | number[];
  group?: null | CampaignGroup[];
  products?: null | ShopItemModel[];
  isSrcVideo?: boolean;
  idLaboratorio?: string;
}

export interface CampaignGroup {
  idCampaignGroup: number;
  idCampaign: number;
  description: string;
  headerGroup?: string;
  imgGroup?: string;
  products: ShopItemModel[];
}

export interface CampaignMin {
  name: string;
  link: string;
  header?: File;
  image?: File;
  pdf?: File;
  startingDate: string;
  endingDate: string;
  type: string;
  priceLevel?: null | number[];
  group?: null | CampaignGroupMin[];
  products?: null | ProductMin[];
  idlaboratorio?: null | string;
}

export interface CampaignGroupMin {
  idCampaignGroup: number;
  description: string;
  header?: File;
  image?: File;
  products: ProductMin[];
}

export interface ProductMin {
  reference: string;
  memory: string;
}

export interface AllCampaigns {
  data: Campaign[];
  page?: number;
  pageSize?: number;
  totalCount: number;
  hasNextPage: boolean;
  hasPreviousPage?: boolean;
}

/*!! DUPLICADO REVER !! */
export interface CampaignRequest {
  campaign: Campaign[];
}

export interface CampaignShop {
  campaignShop: Campaign[];
}
/* '''''''''''''''''''' */

export interface AllCampaignsRequest {
  page: number;
  pageSize: number;
  sortColumn: string;
  sortOrder: string;
  filterName: string;
  filterValue: string;
}

export interface CampaignEditRequest {
  id?: number;
  name?: string;
  link?: string;
  startingDate?: string;
  endingDate?: string;
  type?: string;
  priceLevel?: null | number[];
}

export interface CampaignGroupRequest {
  description: string;
  products: CampaignProductRequest[];
}

export interface CampaignProductRequest {
  reference: string;
}

export interface Campaigns {
  endDate?: string;
  name?: null | string;
  type?: null | string;
}

export enum CampaignType {
  P1 = "P1", //Position 1 of homepage slots
  P2 = "P2", //Position 2 of homepage slots
  P3 = "P3", //Position 3 of homepage slots
  P4 = "P4", //Position 4 of homepage slots
  N = "N", //News, appear on "Novidades" tab
  C = "C", //Campaigns, appear on "Campanhas" tab
  F = "F", //Flash, appear on "Flash" tab
  E = "E", //Exclusives, appear on "Exclusivas" tab
  P = "P", //Partnerships, appear on "Parcerias" tab
  DH = "DH", //Campaigns to appear on products catalog
  B = "B" //Campaigns to appear on homepage, on banner location
}

export const AllCampaignTypes = "P1,P2,P3,P4,N,C,F,E,P,DH,B";

/* export interface CampaignProduct {
  reference: string;
  description: string;
  memory: string;
  price: string;
  points: string;
} */

/* export interface CampaignHome {
  id: number;
  type: string;
  link: string;
  banner?: string;
  designacao: string;
} */
