<div class="main-page">
    <div class="left-side">
        <img src="../../../assets/images/bio2Logo.png" alt="bio2Logo" />
    </div>
    <div class="right-side">
        <form (ngSubmit)="saveAvatar()">
            <div class="d-flex flex-column mb-4">
                <span class="title">Escolha o Avatar de Perfil</span>
            </div>
            <div class="d-flex flex-column">
                <div class="row justify-content-center">
                    <mat-radio-group name="avatarsIcons" [(ngModel)]="avatarChoice">
                        <mat-radio-button *ngFor="let avatar of avatars" [value]="avatar" class="m-4">
                            <img src="{{avatar.avatar}}" alt="Dog Avatar">
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <button [disabled]="!avatarChoice" type="submit"><span>Login</span></button>
        </form>
    </div>
</div>