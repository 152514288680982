<div class="d-flex flex-row">
  <div>
    <app-dashboard-side-menu></app-dashboard-side-menu>
  </div>
  <div class="p-content d-flex flex-column w-100">
    <app-dashboard-top-menu></app-dashboard-top-menu>
    <div *ngIf="loading; else contentLoaded" class="loading-animation">
      <app-loading></app-loading>
    </div>
    <ng-template #contentLoaded>
      <div class="template-content">
        <div class="d-flex" style="justify-content: flex-end; padding-bottom: 50px">
          <button [routerLink]="['/backoffice/comunicados/add']" class="button-novo-comunicado">Novo comunicado
            +</button>
        </div>
        <div class="w-100 d-flex flex-column justify-content-start">
          <table>
            <thead>
              <tr style="border-radius: 10px !important;">
                <th>ID</th>
                <th>Descrição</th>
                <th>Data Inicio</th>
                <th>Data Fim</th>
                <th>Tipo</th>
                <th>Permanente</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let comunicado of comunicadosDisplay; let i = index">
                <tr>
                  <td>{{comunicado.comunicado.id}}</td>
                  <td style="max-width: 300px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                    <div style="height: 50px; padding: 15px;" [innerHTML]="comunicado.comunicado.description"></div>
                  </td>
                  <td>{{ comunicado.comunicado.startingDate | date: 'dd/MM/yyyy'}}</td>
                  <td>{{ comunicado.comunicado.endDate | date: 'dd/MM/yyyy'}}</td>
                  <td>{{ comunicado.comunicado.type }}</td>
                  <td>{{ comunicado.comunicado.isPermanent ? 'Sim' : 'Não' }}</td>
                  <td style="width: 10%;">
                    <div class="d-flex justify-content-center p-2" style="gap: 5px;">
                      <button [routerLink]="['/backoffice/comunicados/edit', comunicado.comunicado.id]" class="btn-action">
                        <img src="../../../assets/Icons/edit.png" alt="Edit">
                      </button>

                      <button (click)="openDialog(comunicado.comunicado)" class="btn-action">
                        <img src="../../../assets/Icons/trash.png" alt="Edit">
                      </button>

                      <button *ngIf="!comunicado.showDescription" (click)="togglePanel(comunicado)" class="btn-action">
                        <img src="../../../assets/Icons/arrow-down.png" alt="Edit">
                      </button>
                      <button *ngIf="comunicado.showDescription" (click)="togglePanel(comunicado)" class="btn-action">
                        <img src="../../../assets/Icons/arrow-up.png" alt="Edit">
                      </button>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="comunicado.showDescription">
                  <td colspan="7">
                    <div class="description-content" [innerHTML]="comunicado.comunicado.description"></div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div>
            <mat-paginator #comunicadosListPaginator [hidden]="comunicados.length <= 0" [pageSize]="10"
              [pageSizeOptions]="[]" [length]="comunicados.length" (page)="pageComunicadosChanged($event)"
              showFirstLastButtons style="background-color: transparent;">
            </mat-paginator>
          </div>
        </div>
      </div>
    </ng-template>
    <app-footer></app-footer>
  </div>
</div>

