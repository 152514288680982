import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericModalComponent } from 'src/app/Custom/generic-modal/generic-modal.component';
import { ApiResponse } from 'src/app/api/models/interface-products';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { ShopItemModel } from 'src/app/api/models/shop-item.model';
import { InterfaceProductsService } from 'src/app/api/services/interface-products.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';

@Component({
  selector: 'app-produtos-destaque',
  templateUrl: './produtos-destaque.component.html',
  styleUrls: ['./produtos-destaque.component.css'],
})
export class ProdutosDestaqueComponent {
  loading: boolean = false;
  loggedUser: ProfileDataRequest = this.perfil.getCurrentUserInfo();
  submitted: boolean = false;
  referencias!: string;
  mandatory: boolean = false;
  valid: boolean = true;
  data!: ApiResponse;

  emDestaque!: ShopItemModel[];
  panelOpenState: boolean = false;

  constructor(
    private snackBarService: SnackBarService,
    private interProducts: InterfaceProductsService,
    private perfil: PerfilService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getEmDestaque();
  }

  validateInput(inputString: string): boolean {
    //?só aceita numeros e virgulas
    const regex = /^(\d+,)*\d+$/;
    return regex.test(inputString);
  }

  getEmDestaque() {
    this.loading = true;
    this.interProducts.getProdutosDestaque().subscribe({
      next: (resp) => {
        this.emDestaque = resp;
      },
      error: (err) => console.error(err),
      complete: () => (this.loading = false),
    });
  }

  processar() {
    this.submitted = true;
    this.mandatory = !this.referencias;
    this.valid = this.validateInput(this.referencias);

    if (!this.mandatory && this.valid) {
      this.loading = true;

      //split by ',' and remove spaces
      const arrayReferencias: string[] = this.referencias
        ? this.referencias
            .split(',')
            .map((item) => item.trim())
            .filter((item) => item !== '')
        : [];

      this.interProducts.setProdutosDestaque(arrayReferencias, 'S').subscribe({
        next: (resp) => {
          this.data = resp;
          this.snackBarService.openSnackBar(
            `Produtos adicionados a destaque.`,
            '#0AA174',
            '../../../../assets/Icons/correct.png'
          );
        },
        error: (err) => {
          console.error(err);
          this.snackBarService.openSnackBar(
            `Erro no processamento.`,
            '#F97066',
            '../../../../assets/Icons/ErrorIcon.png'
          );
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
          this.getEmDestaque();
        },
      });
    } else {
      return;
    }
  }

  openModal(referencia: string) {
    const data = {
      title: `Produto #${referencia}.`,
      description: '',
      action: 'Eliminar',
    };

    const dialogRef = this.dialog.open(GenericModalComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.removeDestaque(referencia);
      }
    });
  }

  removeDestaque(ref: string) {
    this.loading = true;
    const arrayReferencias: string[] = [ref];
    this.interProducts.setProdutosDestaque(arrayReferencias, 'N').subscribe({
      next: (resp) => {
        this.data = resp;
        this.snackBarService.openSnackBar(
          `Produto Eliminado.`,
          '#0AA174',
          '../../../../assets/Icons/correct.png'
        );
      },
      error: (err) => {
        console.error(err);
        this.snackBarService.openSnackBar(
          `Erro ao eliminar.`,
          '#F97066',
          '../../../../assets/Icons/ErrorIcon.png'
        );
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
        this.getEmDestaque();
      },
    });
  }
}
