import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Campaign } from 'src/app/api/models/campaigns';

@Component({
  selector: 'app-campaign-modal',
  templateUrl: './campaign-modal.component.html',
  styleUrls: ['./campaign-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignModalComponent {
  campaign!: Campaign
  constructor(@Inject(MAT_DIALOG_DATA) public data: Campaign, public dialogRef: MatDialogRef<CampaignModalComponent>) {
    this.campaign = data;
  }

  ngOnInit() {}

  cancelDelete(): void {
    this.dialogRef.close("Cancel");
  }
}
