import { Component } from '@angular/core';
import { SubLoginService } from '../api/services/sub-login.service';
import { Address, User } from '../api/models/getUsers';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../api/services';
import { SnackBarService } from '../api/services/snack-bar.service';

@Component({
  selector: 'app-sub-login-client',
  templateUrl: './sub-login-client.component.html',
  styleUrls: ['./sub-login-client.component.css']
})
export class SubLoginClientComponent {
  users: User[] = []
  loading = true;
  currentUser: User | undefined;
  addressSelected: Address | undefined;

  constructor(
    private subLoginService: SubLoginService,
    private router: Router,
    private authService: AuthService,
    private snackBarService: SnackBarService,) {

  }

  unsubscribe: Subscription = new Subscription();

  ngOnInit() {
    const bio2Token = localStorage.getItem('BIO2_AccessToken');
    if(bio2Token) {
      const decodedToken = this.authService.getDecodedAccessToken(bio2Token);
      
      this.unsubscribe.add(this.subLoginService
        .getAllUsers()
        .subscribe(
          (resp: Array<User>) => {
            this.users = resp;
            this.currentUser = this.users.find(x => x.name === decodedToken['name'])
          },
          (error) => {
            console.error('Error fetching users:', error);
          }
        )
      )
      this.loading = false;
    }
  }

  saveAddress() {
    this.loading = true;
    if(this.currentUser === undefined || this.addressSelected === undefined){
      console.error("Utilizador ou Address undefined")
      this.loading = false
    }
    else {
      this.unsubscribe.add(this.subLoginService
        .updateAddress(this.currentUser.id, this.addressSelected)
        .subscribe({
          next: (resp) => {
            this.snackBarService.openSnackBar(
              'Morada Atualizada!',
              '#0AA174',
              '../../assets/Icons/correct.png'
            );
            this.router.navigateByUrl('/home');
          },
          error: (e) => {
            this.snackBarService.openSnackBar(
              'Erro na atualização de morada!',
              '#F97066',
              '../../assets/Icons/ErrorIcon.png'
            );
            console.error(e);
          },
          complete: () => this.loading = false
        })
      );
    }
  }

}
